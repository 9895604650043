var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "ok-disabled": _vm.formSubmitted,
      "id": "new_request",
      "centered": true,
      "ok-title": "Submit",
      "title": "Add New Request",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.handleSubmit,
      "hidden": function ($event) {
        return _vm.hideModal();
      }
    }
  }, [_c('b-form-group', {
    staticClass: "pt-0",
    attrs: {
      "label": "Member-Policy",
      "label-for": "input-4",
      "invalid-feedback": "Member-Policy is required"
    }
  }, [_c('template', {
    slot: "label"
  }, [_vm._v("Member-Policy"), _c('span', [_vm._v(" *")])]), _c('b-form-select', {
    attrs: {
      "id": "input-4",
      "options": _vm.policyIds,
      "state": _vm.validateState('policy_id')
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("Select Member-Policy")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.$v.form.policy_id.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.policy_id, "$model", $$v);
      },
      expression: "$v.form.policy_id.$model"
    }
  })], 2), _c('b-form-group', {
    attrs: {
      "label-for": "input-1",
      "invalid-feedback": "Information is required"
    }
  }, [_c('template', {
    slot: "label"
  }, [_vm._v("Information"), _c('span', [_vm._v(" *")])]), _c('b-form-textarea', {
    attrs: {
      "id": "input-1",
      "state": _vm.validateState('information')
    },
    model: {
      value: _vm.$v.form.information.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.information, "$model", $$v);
      },
      expression: "$v.form.information.$model"
    }
  }), !_vm.$v.form.information.required ? _c('span', {
    staticClass: "field_error",
    staticStyle: {
      "display": "none"
    }
  }, [_vm._v("Field is required")]) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }