var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "messages_new"
  }, [_c('div', {
    staticClass: "message_dropdown"
  }), _c('div', {
    staticClass: "message_table_new"
  }, [_c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_vm.message_loader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 5,
      "columns": 2,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.message_loader ? _c('b-tabs', {
    attrs: {
      "pills": "",
      "card": "",
      "vertical": ""
    }
  }, _vm._l(_vm.rows.records, function (item, index) {
    var _item$created_user;

    return _c('b-tab', {
      key: index,
      on: {
        "click": function ($event) {
          'knowledge_modal_' + item.id;
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('div', {
            staticClass: "list-icon"
          }, [_c('img', {
            attrs: {
              "src": require("@/assets/images/message-icon.svg"),
              "alt": ""
            }
          })]), _c('div', {
            staticClass: "list-sub"
          }, [_c('h3', [_vm._v(_vm._s(item.title))]), _c('p', [_vm._v(_vm._s(_vm.formatDate(item.created_at)))])])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card-text', {
      staticClass: "message-details-div",
      attrs: {
        "id": 'knowledge_modal_' + item.id
      }
    }, [_vm.message_loader ? _c('b-skeleton-table', {
      attrs: {
        "rows": 2,
        "columns": 1,
        "table-props": {
          bordered: true,
          striped: true
        }
      }
    }) : _vm._e(), _c('div', {
      staticClass: "rcv-msgcontent"
    }, [_c('div', {
      staticClass: "rcv-msg-sender"
    }, [_c('div', {
      staticClass: "rcv-snder-icon"
    }, [_c('img', {
      staticClass: "msg-userIcon",
      attrs: {
        "src": _vm.$dummyImage,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "rcv-sender-details"
    }, [_c('h6', [_vm._v(_vm._s((_item$created_user = item.created_user) === null || _item$created_user === void 0 ? void 0 : _item$created_user.rep_full_name))]), _c('p', [_c('i', {
      staticClass: "far fa-calendar-alt"
    }), _vm._v(" " + _vm._s(item.published_date) + " ")])])])]), _c('h5', [_vm._v("Title: " + _vm._s(item.title))]), _c('hr'), _c('div', {
      staticClass: "ql-snow"
    }, [_c('div', {
      staticClass: "ql-editor"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    })])]), item.files && item.files.length > 0 ? _c('div', [_c('h6', [_vm._v("Attachments:")]), _c('ul', {
      staticStyle: {
        "list-style": "none"
      }
    }, _vm._l(item.files, function (file) {
      return _c('li', {
        key: file.id
      }, [_c('a', {
        attrs: {
          "href": file.path,
          "target": "__blank",
          "download": ""
        }
      }, [_c('i', {
        staticClass: "fa fa-file"
      }), _vm._v(" " + _vm._s(file.filename) + " ")])]);
    }), 0)]) : _vm._e()])], 1)], 1);
  }), 1) : _vm._e(), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "showPageRecord": false
    },
    on: {
      "update:rows": function ($event) {
        _vm.rows = $event;
      }
    }
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }