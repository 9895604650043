var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.showPageRecord ? 'd-flex justify-content-end  align-items-center' : ''
  }, [_vm.from && _vm.to && _vm.total && _vm.showPageRecord ? _c('div', {
    staticClass: "page-record"
  }, [_c('span', [_vm._v("Showing " + _vm._s(_vm.from) + " to " + _vm._s(_vm.to) + " of " + _vm._s(_vm.total) + " results")])]) : _vm._e(), _vm.items.total > _vm.perPage ? _c('div', {
    staticClass: "table-pagination"
  }, [_vm.items.total > 0 ? _c('b-pagination', {
    attrs: {
      "total-rows": _vm.items.total,
      "per-page": _vm.perPage,
      "align": "right",
      "prev-text": "Prev",
      "next-text": "Next",
      "first-text": "First",
      "last-text": "Last",
      "size": "sm"
    },
    on: {
      "change": _vm.handlePageChange
    },
    model: {
      value: _vm.items.current_page,
      callback: function ($$v) {
        _vm.$set(_vm.items, "current_page", $$v);
      },
      expression: "items.current_page"
    }
  }) : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }