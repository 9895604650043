<template>
  <div class="top-content">
    <h2>Welcome, {{ personal_details.fname }}!</h2>
    <router-link :to="{ name: 'MemberDashboard' }"
      ><small>Member Dashboard</small></router-link>

    <div class="right-top">
      <p><span>IP Address: </span>{{ personal_details.ip_address }}</p>
      <p><span>Date: </span>{{ login_time }}</p>
      <p>
        <span v-if="login_time !=''">Last login: {{ last_login }}</span> 
        </p>
    </div>
  </div>

</template>
<script>
import axios from 'axios'
export default {
  data: function () {
    return {
      personal_details : [],
      last_login: '',
      login_time: '',
      
    };
  },
  mounted() {
    this.getUserDetails();
  },
  methods: {
   async getUserDetails() {
      let app = this;
      await axios.get("/get-user-details").then((response) => {
        app.personal_details = response.data.data;
        let formateedDate = ''
        this.$store.commit('setGroupType', response.data.data.employer_type)
        if(response.data.data.last_login_date != '') {
        let testDate = new Date(response.data.data.last_login_date)
        let ampm = (testDate.getHours() >= 12) ? "PM" : "AM";
         formateedDate = testDate.toLocaleString('default', { month: 'long' })+' '+testDate.getDate()+', '+testDate.getFullYear()+' | '+testDate.getHours()%12 +' '+ampm
        }
        app.last_login = formateedDate
        let currentDate = new Date();
        let currentAMPM = (currentDate.getHours() >= 12) ? "PM" : "AM";
        app.login_time = currentDate.toLocaleString('default', { month: 'long' })+' '+currentDate.getDate()+', '+currentDate.getFullYear()+' | '+currentDate.getHours()%12 +' '+currentAMPM
        
      });
     
    },
  },
};
</script>
<style>
@import "../assets/css/welcome_content.css";
</style>