var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard-new"
  }, [_c('div', {
    staticClass: "header-new"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-3 col-lg-3 col-md-4 col-sm-6 col-7 main-logo"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'DashboardNew'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/logo-new-cor.png"),
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "col-xl-7 col-lg-6 col-md-5 col-sm-12 login-status"
  }, [_c('ul', [_c('li', [_c('p', [_c('span', [_vm._v("IP Address: ")]), _vm._v(_vm._s(_vm.user.ip))])]), _c('li', [_c('p', [_vm._v(" Last login: "), _c('span', [_vm._v(_vm._s(_vm.user.last_login))])])])])]), _c('div', {
    staticClass: "col-xl-2 col-lg-3 col-md-3 col-sm-6 col-5 top-user"
  }, [_c('ul', [_c('li', {
    staticClass: "top-user-dropdown"
  }, [_c('img', {
    attrs: {
      "src": _vm.user.image || '/img/default_profile.52292c62.jpg',
      "alt": "profile"
    }
  }), _c('span', {
    staticClass: "header-user-name"
  }, [_vm._v("  " + _vm._s(_vm.user.name))]), _c('b-dropdown', {
    attrs: {
      "right": ""
    }
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.changePassword",
      modifiers: {
        "changePassword": true
      }
    }],
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_vm._v("Change Password")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.changeEmail",
      modifiers: {
        "changeEmail": true
      }
    }],
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_vm._v("Change Login Email")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function ($event) {
        return _vm.logout();
      }
    }
  }, [_vm._v("Logout")]), _c('b-dropdown-divider', {
    staticClass: "responsive-view-only"
  }), _c('b-dropdown-item', {
    staticClass: "responsive-view-only",
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('p', [_c('span', [_vm._v("IP Address: ")]), _vm._v(_vm._s(_vm.user.ip) + " ")])]), _c('b-dropdown-item', {
    staticClass: "responsive-view-only",
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('p', [_vm._v(" Last login: "), _c('span', [_vm._v(_vm._s(_vm.user.last_login))])])])], 1)], 1)])])])])]), _c('change-email-modal'), _c('change-password-modal'), _c('SideNav')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }