import { render, staticRenderFns } from "./Certification.vue?vue&type=template&id=97249662&scoped=true&"
import script from "./Certification.vue?vue&type=script&lang=js&"
export * from "./Certification.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/W9_form/certification.css?vue&type=style&index=0&id=97249662&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97249662",
  null
  
)

export default component.exports