var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard-card dashboard-card-lt mt-4 pt-3 new-req-tbl"
  }, [_c('div', {
    staticClass: "table_content table_add-req table-responsive mt-2"
  }, [_c('table', [_vm._m(0), _c('tbody', _vm._l(_vm.rows.records, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "font-family": "SF UI Display Medium"
      }
    }, [_vm._v(_vm._s(item.policy_id))]), _c('td', {
      staticStyle: {
        "font-family": "SF UI Display Medium"
      }
    }, [_vm._v(_vm._s(item.type_name))]), _c('td', [_c('div', {
      staticClass: "member-card-container p-0"
    }, [_vm._v(" " + _vm._s(item.information) + " ")])]), _c('td', {
      class: _vm.getStatusClass(item.status_name)
    }, [_vm._v(" " + _vm._s(item.status_name) + " ")]), _c('td', [_vm._v(_vm._s(item.created_at))])]);
  }), 0)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', {
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v("System ID")]), _c('td', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("Type")]), _c('td', {
    staticStyle: {
      "width": "50%",
      "word-break": "break-all"
    }
  }, [_vm._v("Information")]), _c('td', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("Status")]), _c('td', {
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("Created At")])])]);

}]

export { render, staticRenderFns }