<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="forgot_welcome">
        <h1>Forgot your Password?</h1>
        <p>
          Don't Worry! we've got your back. Just follow the instructions and
          you'll be good :)
        </p>
      </div>
      <div class="form">
        <div class="reset_form">
          <h3>Reset Password</h3>

          <b-form-group label="New Password" label-for="input-1">
            <b-form-input id="input-1" type="text" required v-model="newpassword" placeholder="Enter your new password"></b-form-input>
            <eye-button id="input-1" hideOnDefault="true" type="text"></eye-button>
          </b-form-group>
          <b-form-group label="Confirm Password" label-for="input-2">
            <b-form-input id="input-2" type="text" required v-model="confirmpassword" placeholder="Repeat new password"></b-form-input>
            <eye-button id="input-2" hideOnDefault="true" type="text"></eye-button>
          </b-form-group>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
          <button @click="changePassword" :disabled="verifyLoader">
            <b-spinner v-if="verifyLoader == true" small></b-spinner>
            Change Password
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "../resource/Helper";
import axios from "axios";
import EyeButton from "../components/common/EyeButton";

export default {
  components: {
    EyeButton,
  },
  data() {
    return {
      newpassword: "",
      confirmpassword: "",
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
    };
  },
  methods: {
    changePassword() {
      let app = this;
      app.formErrorFlag = false;
      if (app.confirmpassword == "" || app.newpassword == "") {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Please enter password.";
        return;
      }
      if (app.confirmpassword != app.newpassword) {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Password mismatch!";
        return;
      }
      if (helper.validatePassword(this.newpassword) == false) {
        app.formErrorFlag = true;
        app.formErrors["error"] =
          "Password should be 8 characters long and contain at least 1 uppercase,1 lowercase, number & special character.";
        return;
      }
      app.verifyLoader = true;
      axios
        .post("auth/reset-password-code", {
          password: app.newpassword,
          reset_code: this.$store.state.OTPCode,
          option: 'email',
          data: this.$store.state.memberEmail,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            helper.delayRedirect("Login", 0);
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.verifyLoader = false;
        });
    },
  },
};
</script>
<style scoped>
.toggleSSN {
  position: absolute;
  left: 70%;
  margin-top: -2%;
}
</style>