var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employer_info_new dashboard-card dashboard-card-lt mt-4"
  }, [_c('h3', [_vm._v(" Employer Information ")]), _c('div', {
    staticClass: "table_content"
  }, [_c('table', [_vm._m(0), _c('tbody', _vm._l(_vm.employer_info, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "font-family": "SF UI display Bold"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('td', [_vm._v(_vm._s(item.phone))]), _c('td', [_vm._v(_vm._s(item.employment_start_date))])]);
  }), 0)])]), _c('b-modal', {
    attrs: {
      "id": "employer_add",
      "centered": true,
      "ok-title": "Submit",
      "title": "Employer Information - Add",
      "size": "lg"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Employer Name",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Employer Phone Number",
      "label-for": "input-2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-2",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Occupation",
      "label-for": "input-3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-3",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Weekly Hours",
      "label-for": "input-4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-4",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Start Date",
      "label-for": "input-5"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "input-5",
      "placeholder": "Choose a date",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Employment Status",
      "label-for": "input-6"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "input-6",
      "options": _vm.employment_status,
      "required": ""
    },
    model: {
      value: _vm.selected_employment,
      callback: function ($$v) {
        _vm.selected_employment = $$v;
      },
      expression: "selected_employment"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Address 1",
      "label-for": "input-7"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-7",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Address 2",
      "label-for": "input-8"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-8",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "input-9"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-9",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "State",
      "label-for": "input-10"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-10",
      "required": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "ZIP",
      "label-for": "input-11"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-11",
      "required": ""
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Name")]), _c('td', [_vm._v("Phone")]), _c('td', [_vm._v("Start date")])])]);

}]

export { render, staticRenderFns }