var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row body-main-row"
  }, [_c('div', {
    staticClass: "col-md-10 member-body-content-wrap plan"
  }, [_c('div', {
    staticClass: "row contents"
  }, [_vm._m(0), _c('div', {
    staticClass: "first col-12"
  }, [_c('div', {
    staticClass: "row inner-row"
  }, [_c('div', {
    staticClass: "col-xl-8 col-lg-12 plan_details mb-4"
  }, [_c('div', {
    staticClass: "dashboard-card"
  }, [_c('div', {
    staticClass: "table_content"
  }, [_vm.policy_details_loader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 4,
      "columns": 4,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.policy_details_loader ? _c('table', {}, [_vm._m(1), _c('tbody', [_vm._l(_vm.policy_details.plans, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      class: item.status == 'ACTIVE' ? '' : 'plan_not_active',
      staticStyle: {
        "font-family": "SF UI display Bold",
        "text-align": "left"
      },
      domProps: {
        "innerHTML": _vm._s(item.plan_name.replace('\n', '<br/>'))
      }
    }), _c('td', {
      staticStyle: {
        "color": "rgb(50, 205, 50)",
        "font-weight": "bold"
      }
    }, [_vm._v(_vm._s(item.status))]), _c('td', [_vm._v(_vm._s(item.plan_effective_date))]), _c('td', [_vm._v(_vm._s(item.plan_term_date))]), _c('td', [_vm._v(_vm._s("$" + item.price))])]);
  }), _c('tr')], 2), _c('tfoot', [_c('tr', [_c('td', [_vm._v(" Tier: "), _c('span', [_vm._v(_vm._s(_vm.policy_details.tier))])]), _c('td', {
    staticClass: "res_none"
  }), _c('td', {
    staticClass: "res_none"
  }, [_vm.policy_details.billing_type == 'cc' ? _c('span', [_vm._v("Payment Service Fee: $" + _vm._s(_vm.policy_details.cc_fee))]) : _vm._e()]), _c('td', {
    staticClass: "res_none"
  }, [_vm._v(" Total: ")]), _c('td', {
    staticClass: "res_none",
    staticStyle: {
      "color": "#ff7a00",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s("$" + _vm.policy_details.plan_total) + " ")])]), _c('tr', [this.employer_type != 'employer' ? _c('td', {
    attrs: {
      "colspan": "1"
    }
  }, [_vm._v(" Billing Method: "), _c('span', [_vm._v(_vm._s(_vm.policy_details.billing_type))])]) : _vm._e(), this.employer_type != 'employer' ? _c('td', [_vm._v(" Bill Date: "), _c('span', [_vm._v(_vm._s(_vm.policy_details.bill_date))])]) : _vm._e(), _c('td', [_vm._v(" Enrollement Date: "), _c('span', [_vm._v(_vm._s(_vm.policy_details.enrollment_date))])])])])]) : _vm._e(), _c('br')], 1), _vm.policy_details.billing_type == 'eft' ? _c('div', {
    staticClass: "planPayment-via"
  }, [_c('h3', [_vm._v("Payment Via")]), _vm.policy_details.billing_type == 'eft' ? _c('div', {
    staticClass: "row p-0"
  }, [_vm.policy_details.show_default_bank ? _c('div', {
    staticClass: "col-xxl-5 col-xl-6 col-md-6 mb-3"
  }, [_c('div', {
    staticClass: "paymentBlock"
  }, [_c('span', {
    staticClass: "ac-name"
  }, [_vm._v(" " + _vm._s(_vm.policy_details.default_bank_account.bank_accountname) + " ")]), _c('h4', [_vm._v(" " + _vm._s(_vm.policy_details.default_bank_account.bank_name) + " ")]), _c('div', {
    staticClass: "row p-0"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('label', [_vm._v("A/C Number")]), _c('span', [_vm._v(" " + _vm._s(_vm.policy_details.default_bank_account.bank_account_number) + " ")])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('label', [_vm._v("Routing Number")]), _c('span', [_vm._v(" " + _vm._s(_vm.policy_details.default_bank_account.bank_routing) + " ")])])]), _c('span', {
    staticClass: "type"
  }, [_vm._v("Default")])])]) : _vm._e(), _vm.policy_details.show_recurring_bank ? _c('div', {
    staticClass: "col-xxl-5 col-xl-6 col-md-6 mb-3"
  }, [_c('div', {
    staticClass: "paymentBlock"
  }, [_c('span', {
    staticClass: "ac-name"
  }, [_vm._v(" " + _vm._s(_vm.policy_details.recurring_bank_account.bank_accountname) + " ")]), _c('h4', [_vm._v(" " + _vm._s(_vm.policy_details.recurring_bank_account.bank_name) + " ")]), _c('div', {
    staticClass: "row p-0"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('label', [_vm._v("A/C Number")]), _c('span', [_vm._v(" " + _vm._s(_vm.policy_details.recurring_bank_account.bank_account_number) + " ")])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('label', [_vm._v("Routing Number")]), _c('span', [_vm._v(" " + _vm._s(_vm.policy_details.recurring_bank_account.bank_routing) + " ")])])]), _c('span', {
    staticClass: "type"
  }, [_vm._v("Recurring")])])]) : _vm._e()]) : _vm._e()]) : _vm._e()])]), _vm.show_hide.resource ? _c('div', {
    staticClass: "col-xl-4 col-lg-5 col-md-6 col-sm-12 resource_new mb-4"
  }, [_c('div', {
    staticClass: "dashboard-card"
  }, [_c('h3', [_vm._v("Resources")]), _vm.resources_loader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 4,
      "columns": 1,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.resources_loader ? _c('div', {
    staticClass: "resources-list-div"
  }, [_c('div', {
    staticClass: "resources-list-div-inner"
  }, _vm._l(_vm.resources.files, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "resources-list"
    }, [_c('div', {
      staticClass: "icon_item",
      on: {
        "click": function ($event) {
          return _vm.downloadFile(item.url);
        }
      }
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('svg', {
      staticStyle: {
        "enable-background": "new 0 0 482.14 482.14"
      },
      attrs: {
        "version": "1.1",
        "id": "Capa_1",
        "xmlns": "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "x": "0px",
        "y": "0px",
        "viewBox": "0 0 482.14 482.14",
        "xml:space": "preserve"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69\n                              c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z"
      }
    }), _c('path', {
      attrs: {
        "d": "M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394\n                              c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z"
      }
    }), _c('path', {
      attrs: {
        "d": "M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404\n                              c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223\n                              c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796\n                              c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646\n                              c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811\n                              c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748\n                              c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233\n                              c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z\n                              M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282\n                              c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222\n                              C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42\n                              v19.875H314.892z"
      }
    })]), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g'), _c('g')])]), _c('div', {
      staticClass: "resource_item"
    }, [_c('p', [_vm._v(_vm._s(item.filename))]), _c('small', [_c('span'), _vm._v(_vm._s(item.header))])])]), _c('div', {
      staticClass: "test",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.downloadFile(item.url);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-download"
    })])]);
  }), 0)]) : _vm._e()], 1)]) : _vm._e(), _c('div', {
    staticClass: "second col-xl-6 col-lg-7 col-md-6 col-sm-12 mb-4"
  }, [_c('div', {
    staticClass: "dependent_info dashboard-card"
  }, [_c('h3', [_vm._v("Dependent Information")]), _c('div', {
    staticClass: "table_content"
  }, [_vm.policy_details_loader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 4,
      "columns": 1,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.policy_details_loader ? _c('table', [_vm._m(2), _c('tbody', _vm._l(_vm.policy_details.dependent_details, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "font-family": "SF UI display Medium"
      }
    }, [_vm._v(" " + _vm._s(item.first_name) + " " + _vm._s(item.last_name) + " ")]), _c('td', [_vm._v(_vm._s(item.gender))]), _c('td', [_vm._v(_vm._s(item.relation))]), _c('td', [_vm._v(_vm._s(item.birthdate))]), _c('td', [_vm._v(_vm._s(item.ssn))])]);
  }), 0)]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "second col-xl-6 col-lg-12 col-md-6 col-sm-12 mb-4"
  }, [_vm.payment_history.length ? _c('div', {
    staticClass: "payment_history"
  }, [_c('div', {
    staticClass: "dashboard-card"
  }, [_c('h3', [_vm._v("Payment History")]), _c('div', {
    staticClass: "table_content"
  }, [_vm.payment_history_loader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 4,
      "columns": 1,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.payment_history_loader ? _c('table', [_vm._m(3), _c('tbody', _vm._l(_vm.payment_history, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "font-family": "SF UI display Medium"
      }
    }, [_vm._v(" " + _vm._s(item.payment_id) + " ")]), _c('td', [_vm._v(_vm._s(item.payment_date))]), _c('td', [_vm._v(_vm._s(item.payment_paid_through_date))]), _c('td', [_vm._v(_vm._s(item.payment_method))]), _c('td', [_vm._v(_vm._s("$" + item.payment_amount))])]);
  }), 0)]) : _vm._e()], 1)])]) : _vm._e()])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('h3', [_vm._v("Plan Details")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v("Plan")]), _c('td', [_vm._v("Status")]), _c('td', [_vm._v("Effective Date")]), _c('td', [_vm._v("Term Date")]), _c('td', [_vm._v("Amount")])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Name")]), _c('td', [_vm._v("Gender")]), _c('td', [_vm._v("Relation")]), _c('td', [_vm._v("DOB")]), _c('td', [_vm._v("SSN")])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Payment ID")]), _c('td', [_vm._v("Payment Date")]), _c('td', [_vm._v("Paid Through Date")]), _c('td', [_vm._v("Method")]), _c('td', [_vm._v("Amount")])])]);

}]

export { render, staticRenderFns }