<template>
 <li>
      <strong>Your Current Plan:</strong>
      <span class="mx-4"> {{currentPlan.formatted_plan_name}}</span>
      <a href="javascript:void(0);" v-b-modal.alert-popup1 class="notice-btn"
        >Keep Current Plan At Renewal</a
      >
      <dashboard-enrollment-current-plan-modal :current-plan="currentPlan" />
    </li>
</template>
<script>
import DashboardEnrollmentCurrentPlanModal from './DashboardEnrollmentCurrentPlanModal.vue'
export default {
  components: { DashboardEnrollmentCurrentPlanModal },
    name:"DashboardEnrollmentCurrentPlan",
    props:{
        currentPlan:{
            required:true,
            type:Object
        }
    }
}
</script>