<template>
  <b-modal
    id="changeEmail"
    :centered="true"
    title="Change Email"
    :hide-footer="true"
    size="md"
    no-close-on-backdrop
  >
    <div class="modal-body">
      <div class="form form_change">
        <div class="input_form">
          <b-form-group label="Current Password" label-for="input-2">
            <b-form-input
              id="input-2"
              type="password"
              v-model="input.password"
              required
              placeholder="Current Password"
            ></b-form-input>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["password"] }}
            </div>
          </b-form-group>

          <b-form-group label="New Email" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              v-model="input.username"
              required
              placeholder="New Login Email"
            ></b-form-input>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["email"] }}
            </div>
          </b-form-group>

          <a class="">
            <button
              type="submit"
              @click="changeEmail()"
              :disabled="loginLoader"
            >
              <b-spinner v-if="loginLoader == true" small></b-spinner>
              Submit
            </button>
          </a>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer"></div>
  </b-modal>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import helper from "@/resource/Helper";
export default {
  data: () => ({
    input: { username: "", password: "" },
    formErrorFlag: false,
    loginLoader: false,
    formErrors: [],
  }),
  methods: {
    changeEmail() {
      let app = this;
      this.formErrors = [];
      let inputError = false;
      if (
        this.input.username == "" ||
        helper.validateEmail(this.input.username) == false
      ) {
        this.formErrors["email"] = "Please enter valid email address.";
        this.formErrorFlag = true;
        inputError = true;
      }
      if (this.input.password == "") {
        this.formErrors["password"] = "Please enter password.";
        this.formErrorFlag = true;
        inputError = true;
      }
      if (inputError) return;
      this.loginLoader = true;
      axios
        .post("/change-email", {
          new_email: this.input.username,
          password: this.input.password,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            // eslint-disable-next-line
            helper.infoMessage(
              app,
              response.data.status,
              "Email changed successfully, please login with your new email."
            );
            helper.logout();
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
            app.loginLoader = false;
          }
        });
    },
  },
};
</script>
<style >
.input_form button {
  margin-top: 10 px;
  padding: 10 px;
  width: 100%;
  font-size: 18px;
  background: #00ccff;
  color: white;
}
</style>
