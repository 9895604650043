var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page"
  }, [_c('div', {
    staticClass: "main_page"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "top-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('button', {
    staticClass: "sign_up_inactive"
  }, [_vm._v("Login")])])], 1), _c('div', {
    staticClass: "reset_form"
  }, [_c('h3', [_vm._v("Verify OTP")]), _c('b-form-group', {
    attrs: {
      "label": "Enter OTP Code",
      "label-for": "input-2"
    }
  }, [_c('b-input-group', {
    attrs: {
      "id": "input-2"
    }
  }, [_c('div', {
    attrs: {
      "id": "otp"
    }
  }, [_c('b-form-input', {
    class: _vm.errorClass,
    attrs: {
      "type": "text",
      "maxlength": "1",
      "id": "otp_1",
      "required": "",
      "placeholder": "-"
    },
    on: {
      "keyup": function ($event) {
        return _vm.otpChange($event, 1);
      }
    },
    model: {
      value: _vm.otp.one,
      callback: function ($$v) {
        _vm.$set(_vm.otp, "one", $$v);
      },
      expression: "otp.one"
    }
  }), _c('b-form-input', {
    class: _vm.errorClass,
    attrs: {
      "type": "text",
      "maxlength": "1",
      "id": "otp_2",
      "required": "",
      "placeholder": "-"
    },
    on: {
      "keyup": function ($event) {
        return _vm.otpChange($event, 2);
      }
    },
    model: {
      value: _vm.otp.two,
      callback: function ($$v) {
        _vm.$set(_vm.otp, "two", $$v);
      },
      expression: "otp.two"
    }
  }), _c('b-form-input', {
    class: _vm.errorClass,
    attrs: {
      "type": "text",
      "maxlength": "1",
      "id": "otp_3",
      "required": "",
      "placeholder": "-"
    },
    on: {
      "keyup": function ($event) {
        return _vm.otpChange($event, 3);
      }
    },
    model: {
      value: _vm.otp.three,
      callback: function ($$v) {
        _vm.$set(_vm.otp, "three", $$v);
      },
      expression: "otp.three"
    }
  }), _c('b-form-input', {
    class: _vm.errorClass,
    attrs: {
      "type": "text",
      "maxlength": "1",
      "id": "otp_4",
      "required": "",
      "placeholder": "-"
    },
    on: {
      "keyup": function ($event) {
        return _vm.otpChange($event, 4);
      }
    },
    model: {
      value: _vm.otp.four,
      callback: function ($$v) {
        _vm.$set(_vm.otp, "four", $$v);
      },
      expression: "otp.four"
    }
  })], 1)])], 1), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e(), _c('OTPTimer', {
    attrs: {
      "title": "Not received? Click to resend",
      "expirySecond": 60
    },
    on: {
      "sendOTP": _vm.sendOtpCode
    }
  }), _c('button', {
    attrs: {
      "disabled": _vm.verifyLoader
    },
    on: {
      "click": _vm.verifyToken
    }
  }, [_vm.verifyLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Verify ")], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "forgot_welcome"
  }, [_c('h1', [_vm._v("Verify your otp?")]), _c('p', [_vm._v(" Don't Worry! we've got your back. Just follow the instructions and you'll be good :) ")])]);

}]

export { render, staticRenderFns }