<template>
  <div class="container-fluid">
    <div class="row body-main-row">
      <div class="member_dashboard member-body-content-wrap">
        <div class="provider-list-wrap">
          <div class="contents">
            <div class="provider-title mb-4">
              <h1>Provider List</h1>
            </div>
            <div>
              <div>
                <div class="row">
                  <div class="col-12">
                    <div class="row provider-heading-container">
                      <div class="col-3 text-center"><strong></strong></div>
                      <div class="col-3 text-center"><strong>Name</strong></div>
                      <div class="col-3 text-center"><strong>Network</strong></div>
                      <div class="col-3 text-center"><strong>Action</strong></div>
                    </div>
                    <div class="row provider-list-container" v-for="(provider, index) in providerList" :key="index">
                      <div class="col-3 d-flex justify-content-center provider-logo">
                        <img role="button" @click="routeToProviderLink(provider.link)"
                             :src="provider.logo"
                             alt="">
                      </div>
                      <div class="col-3 provider-data-alignment">
                        <p class="d-flex justify-content-center mb-0">{{ provider.name }}</p>
                      </div>
                      <div class="col-3 provider-data-alignment"> <p class="d-flex justify-content-center mb-0">{{ provider.network }}</p></div>
                      <div class="col-3 text-center provider-data-alignment"> <a :href="provider.link" target="_blank"> Provider Search</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ProviderList",
  data () {
    return {
      providerList: []
    }
  },
  methods: {
    routeToProviderLink (link) {
      window.open(link, '_blank')
    },
    getProviderList() {
      let app = this
      let loader = app.$loading.show();
      axios.get("/provider/get-provider-list").then((res) => {
        app.providerList = res.data.data
      })
          .finally(() => {
            loader.hide();
          });
    }
  },
  mounted () {
    let app = this
    app.getProviderList();
  }
}
</script>

<style scoped>
.provider-list-wrap {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}

.contents {
  padding: 0!important;
  min-height: calc(100vh - 305px);
}

.provider-list-wrap h1 {
  font-size: 20px;
  font-weight: 700;
}
.provider-heading-container {
  border-radius: 3px;
  padding: 9px 0;
  margin: 14px 0;
  background: #ceeaf7;
  box-shadow: 0 1px 6px rgba(50,60,71,.101960784);
}
.provider-list-container {
  border-radius: 3px;
  padding: 9px 0;
  margin: 14px 0;
  background: #ffff;
  box-shadow: 0 1px 6px rgba(50,60,71,.101960784);
  img {
    width: 100px;
    height: 50px;
  }
}
.provider-data-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #0cf;
  }
}
@media (max-width:992px) {
  .member-body-content-wrap{
    padding-left: 3rem;
  }

}
</style>
