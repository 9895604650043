<template>
 <div>
 <div class="content_wrapper" ref="save">
         <agent-info></agent-info>
        <div class="contract_content">
            <contract-form-sidebar/>

            <div class="right_container">
                   <h3>Confirmation & Signature</h3>
                   <div class="description agreement">
                       <b-card no-body class="mb-1">

      <b-card-header header-tag="header" class="" role="tab">
           <b-button block v-b-toggle.accordion-1 variant="info" style="text-align:left;font-size:18px;">Fee Schedule [Level {{level}}] 
               <small>The following fee schedule is effective January 1,2020</small>
           <strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong></b-button>
      </b-card-header>
      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" v-model="isVisible">
        <b-card-body>
          <b-card-text>
            <div class="fee-schedule">
              <table id="fee-schedule" style="width: 100%;">
                  <tr v-if="!(contract_type === 'categoryL7' || contract_type === 'categoryL9' || contract_type === 'carrier')">
                      <th>Association Membership Fees </th>
                      <th>
                        <b>
                          <p>Monthly Commission Level*</p>
                        </b>
                        <p>(Paid Monthly and in arrears)
                        </p>
                      </th>                                                 
                  </tr>
                  <tr v-for="(value, index) in items" :key="index">
                      <td>
                        <strong>{{ value.plan_name || capitalizeFirstLetter(value.configuration_name) }}</strong>
                      </td>
                      <td v-if="value.configuration && value.configuration.hasOwnProperty('Lifeline Bronze') && value.configuration.hasOwnProperty('Lifeline Silver, Gold & Platinum')">
                        <table style="width: 100%;">
                          <thead>
                            <tr v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                              <th >
                                <strong>{{ configKey }}</strong>
                              </th>
                              <th >
                                <table style="width: 100%;">
                                  <thead>
                                    <tr v-for="(configVal, configKey , index) in configValue" :key="'tier-header-' + configKey" >
                                      <template v-if="index === 0">
                                      <th>
                                        <strong>Tier</strong>
                                      </th>
                                      <th v-for="(tierVall, tierrKey) in configVal" :key="'tier-header-' + tierrKey">
                                        <strong >
                                                {{ tierrKey }}
                                              </strong>
                                      </th>
                                    </template>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(configVal, configKey) in configValue" :key="'data-' + configKey">
                                      <td > 
                                        <strong >{{ configKey }}</strong>
                                      </td>
                                      <td v-for="(conval, conKey) in configVal" :key="'data-' + conKey">
                                        <strong >{{ conval }}</strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </td>
                      <td v-else-if="value.configuration ">
                        <table style="width: 100%;">
                          <thead>
                            <tr>
                              <th v-for="(configValue, configKey) in value.configuration" :key="'header-' + configKey">
                                <strong>{{ configKey }}</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td v-for="(configValue, configKey) in value.configuration" :key="'data-' + configKey">
                                <strong v-if="configValue == '0.0%'">{{'Paid By Up-Line'}}</strong>  
                                <strong v-else>{{ configValue }}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td v-else>
                        <strong v-if="value.commission == '0.00'">{{'Paid By Up-Line'}}</strong>                          
                        <strong v-else-if="value.commission == '' || value.commission == null">{{'N/A'}}</strong>                          
                        <strong v-else>{{value.commission}}</strong>                          
                      </td>
                  </tr>
              </table>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>     

             <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info" style="text-align:left;font-size:18px;">Signature
             <strong v-if="isNotVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong></b-button>
      </b-card-header>
      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel" v-model="isNotVisible">
        <b-card-body>         
          <b-card-text>    
            <div class="agreement_signature">
                
                  <span class="sign_container">
                    <span class="signature_block">
                      <p class="left">
                          <span class="sign">
                            <p>Signature of Independent Marketer</p>
                              <canvas id="rep_signature" class="signature"></canvas><br>
                              <small class="error_message" v-if="formErrorFlag == true">{{formErrors['fee_signature_message']}}</small>
                              <button class="clear" type="button" name="button" @click="clear">Clear</button>                              
                              </span><br>
                          <span><p class="print-Name">Print Name</p>
                            <input type="text" name="rep_name" value="" v-model="formFields.rep_name.value " class="mt-3"
                          :readonly="formFields.rep_name.read_only"></span><br>
                              <small class="error_message" v-if="formErrorFlag == true">{{formErrors['fee_name_message']}}</small><br><br>
                          <span><p>Date Signed</p>
                            <input type="text" name="date_signed" v-model="formFields.date_signed1.value"
                          :readonly="formFields.date_signed1.read_only"></span><br>
                              <small class="error_message" v-if="formErrorFlag == true">{{formErrors['fee_date_message']}}</small>

                      </p>
                      <p class="right">
                          <span class="sign">
                            <p>
                                EWA Membership</p>
                              <canvas id="signature" class="signature"></canvas>
                              </span><br>
                          <span><p class="membership-seciton">Print Name and Title</p><br>
                            <input
                          type="text"
                          name="officer_name"
                          v-model="formFields.officer_name.value"
                          :readonly="formFields.officer_name.read_only"
                        /></span><br><br>
                          <span><p>Date Signed</p>
                            <input type="text" name="date_signed1" v-model="formFields.date_signed1.value"
                          :readonly="formFields.date_signed1.read_only"></span>
                      </p>
                    </span>
                  </span>
            </div>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 
                   </div>

                   <div class="button_container">
                   <button class="back" type="button" name="button" @click="navigatePrevious()">Back</button>
                    <div class="button">
                    <!-- <a href="#"><button class="save">Save</button></a> -->
                    <!-- <a href="#"> -->
                      <button class="save" @click="saveFeeStructure()">Save</button>
                    <!-- </a> -->
                </div>
                </div>

            </div>
        </div>
    </div>
  </div>
</template>
<script>
  import SignaturePad from 'signature_pad';
  import axios from 'axios';
  import AgentInfo from "../common/AgentInfo.vue";
  import ContractFormSidebar from '../partial/ContractFormSidebar.vue';
  import html2canvas from 'html2canvas';
  import * as  Helper from '../../utlis/helper.js';
  export default {
    name: "MyComponent",
      components: {AgentInfo, ContractFormSidebar },
    data() {
      return {
        tempId:'',
        level:'',
        type:'',
        agent_id:'',
        isVisible: false,
        isNotVisible: true,
        formFields:{
          fee_level: {
              value: '',
              required: false,
              read_only: false,
          },
          rep_name: {
              value: '',
              required: true,
              read_only: false,
          },
          officer_name: {
              value: '',
              required: true,
              read_only: true,
          },
          rep_signature: {
              value: '',
              required: true,
              read_only: false,
          },
          officer_signature: {
              value: '',
              required: true,
              read_only: true,
          },
          date_signed: {
              value: '',
              required: true,
              read_only: false,
          },
          date_signed1: {
              value: '',
              required: true,
              read_only: true,
          }
        },
        signaturePad:null,
        signaturePad1:null,
        items:[],
        formErrorFlag: false,
        formErrors: [],
        contractType:['medical','dental','vision','dentalSolstice','visionSolstice','termLife','bundled','limitedMedical','accident','critical','hospital','lifeStyle','pet','guaranteedIssue','teleMedicine']
      };
    },
    mounted(){
      let app = this;
      app.tempId= window.localStorage.getItem('tempId');
      app.type= window.localStorage.getItem('type');
      app.level= window.localStorage.getItem('level');
      app.agent_id= window.localStorage.getItem('agentId');
      app.contract_type= window.localStorage.getItem('contract_type');
      app.getFeeStructure();
      app.getFeeStructureInfo();
      let canvas = document.querySelector('#signature');
      app.signaturePad = new SignaturePad(canvas, {
        backgroundColor: "rgba(255, 255, 255, 0)",
        onEnd: () => {
          app.signaturePad.toDataURL();
        }
      });           
      let canvas1 = document.querySelector('#rep_signature');
      app.signaturePad1 = new SignaturePad(canvas1, {
        backgroundColor: "rgba(255, 255, 255, 0)",
        onEnd: () => {
          app.signaturePad1.toDataURL();
        }
      });     
      app.signaturePad.off();
    },
    methods:{
      getFeeStructure() {
        const app = this;
        let url = '';
        let typeContract = '';
        let urlType = '';
        if (app.contract_type === 'categoryL7') {
          typeContract = 'L7';
          urlType = 'Category';
        }
        else if(app.contract_type === 'categoryL9'){
          typeContract = 'L9';
          urlType = 'Category';
        }
        else if(app.contract_type === 'carrier'){
          urlType = 'Carrier'
        }
        if (app.contract_type === 'categoryL7' || app.contract_type === 'categoryL9' || app.contract_type === 'carrier') {
          url = `${process.env.VUE_APP_PURENROLL_SYSTEM}/commissionConfigurationBy${urlType}/${app.level}`;
          if(typeContract){
            url += `?level=${typeContract}`;
          }
          axios
            .get(url, {
              params: {
                type: app.type,
              },
            })
            .then((response) => {
              app.items = response.data.data;
            })
            .catch((error) => {
              const errorMsg = error.response?.data?.message || error.message;
              this.handleErrorRedirect(errorMsg);
            });
        } else {
          let typeSelect = '';
        if (app.type === 'patriotAncillary') {
          typeSelect = 'patriot';
        }
        else if(app.type === 'patriotPremier' || app.type === 'fegliPremier'){
          typeSelect = 'premier';
        } else if (app.type === 'fegliAncillary') {
          typeSelect = 'fegli';
        }

        let url = `${process.env.VUE_APP_COMM_PURENROLL_SYSTEM}/feeScheduleByLevel/${app.level}`;
        if (typeSelect) {
          url += `?type=${typeSelect}`;
        }
        else{
          url +=`/${app.agent_id}`;
        }
          axios
            .get(url)
            .then((response) => {
              app.items = response.data.data;
            })
            .catch((error) => {
              const errorMsg = error.response?.data?.message || error.message;
              this.handleErrorRedirect(errorMsg);
            });
        }
      },

      handleErrorRedirect(errorMsg) {
        if (errorMsg === 'Form Completed.') {
          window.location = '/contract-form/contract_message/completed';
        } else if (errorMsg === 'Agent does not exist.') {
          window.location = '/contract-form/contract_message/not_found';
        } else {
          console.error('An error occurred:', errorMsg);
        }
      },
      capitalizeFirstLetter(str) {
        return Helper.capitalizeFirstLetter(str);
      },
      getFeeStructureInfo(){
        let app = this;
        axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-form-data-fee-info?', {
        params: {
            tempid: app.tempId,
        }
        })
        .then(function (response) {
            let fetchedData = [];
            if (response.data.status == 'success') {
                for(var i in response.data.data.result) {
                    fetchedData.push(response.data.data.result[i]);
                }
                fetchedData.forEach(function(item) {
                    app.formFields[item.field_name].value = item.value;
                    app.formFields[item.field_name].read_only = item.read_only;
                });
            }
            app.loadSignature();
            })
            .catch(function (error) {
              if(error.message == 'Form Completed.'){
                window.location = '/contract-form/contract_message/completed';
              }else if(error.message == 'Agent does not exist.'){
                window.location = '/contract-form/contract_message/not_found';
              } else {
                window.location = '/contract-form/contract_message/error';
               console.log("error");
             }
         })
         .catch(function (error) {
            console.log("ERROR SAVING CONTRACT INFO!!!");
         });
       },
       loadSignature(){
         let app = this;
         var canvas1 = document.getElementById('signature'),
         context = canvas1.getContext('2d');
         app.make_base(context);
       },
       make_base(context){
         let app = this;
         var base_image = new Image();
         base_image.src = app.formFields.officer_signature.value;
         base_image.onload = function(){
             context.drawImage(base_image, 0, 0,435,180);
         }
       },
       clear() {
           let app = this;
           app.signaturePad1.clear();
           app.signatureError = false;
           console.log('cleared');
       },
       saveFeeStructure() {
         let app = this;
         app.isVisible = false;
         app.isNotVisible = true;
         setTimeout(() => { 
         const el = app.$refs.save;
         (async () => {
             app.saveScreenShot((await html2canvas(el)).toDataURL('image/png'),'feeStructure');
         })();
         }, 1000);
         app.formErrorFlag = false;
         app.formErrors    = [];
         if(app.signaturePad1.isEmpty()){
           app.formErrorFlag = true;
           app.formErrors['fee_signature_message'] = "The rep signature field is required";
           return false;
         }
         let params = {
           tempid:               app.tempId,
           fee_name:             app.formFields.rep_name.value,
           fee_signature:        app.signaturePad1.toDataURL('image/png'),
           fee_date:             app.formFields.date_signed1.value,
         };
         console.log(params);
         //discard null values
         for (const key of Object.keys(params)) {
           if (params[key] === null) {
               delete params[key];
           }
         }
         axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-fee-information', params)
         .then(function (response) {
             if (response.data.status == 'success') {
               console.log("success");
               app.saveContractInfo();
             }
         })
         .catch(function (error) {
             if (error.response.data.status == 'error') {
               error.response.data.data.forEach(function(item) {
                 console.log(item.error_message);
                   app.formErrorFlag = true;
                   app.formErrors[item.target_element+'_message'] = item.error_message;
               });  
             }
         });
       },
       saveContractInfo(){
        let app = this;
        let params = {
          tempid:app.tempId,
          contract_type:app.contract_type
        };
        axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-data', params)
        .then(function (response) {
            if (response.data.status == 'success') {
               app.generatePdf();
              console.log("success");
              window.localStorage.clear();
            }else {
               window.location = '/contract-form/contract_message/error';
              console.log("error");
            }
        })
        .catch(function (error) {
           console.log("ERROR SAVING CONTRACT INFO!!!");
        });
      },
       navigatePrevious() {
         //Update APIs
         //Navigate to next page
         console.log(window.localStorage.getItem('type'));
         if (window.localStorage.getItem('type') == 'ancillary' || window.localStorage.getItem('type') == 'bexAncillary') {
           this.$router.push("commission_sheet");
           window.localStorage.setItem('sheet_complete', 'incomplete');
         } else {
           this.$router.push("agreement_and_signature");
           window.localStorage.setItem('agreement_complete', 'incomplete');
         }
      },
      saveScreenShot (image, name) {
         let data = {
             "agent_id" : window.localStorage.getItem('agentId'),
             "level" : window.localStorage.getItem('level'),
             "contract_type" : window.localStorage.getItem('type'),
             "image" : image,
             "name" : name,
             "tempid" : this.tempId,
         };
         let config = {
             method: 'post',
             url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
             headers: { },
             data : data
         };
         axios(config)
             .then(function (response) {
                 if (response.data.status == 'success') {
                     console.log('screenshot saved');
                 }
             })
             .catch(function (error) {
                 console.log('screenshot not saved');
                 console.log(error);
             });
       },
      scrollTop(){
          window.scrollTo(0,0);
      },
      generatePdf(){
        let app = this;
        var url;
        var baseUrl = window.location.origin;
        console.log(baseUrl);
        if (app.type == 'ancillary') {
          // url = baseUrl+'/contractTemplate/ancillary/'+app.tempId;
          url = '/contractTemplate/ancillary/'+app.tempId;
        } else if (app.type == 'bexAncillary') {
          // url = baseUrl+'/contractTemplate/bex/'+app.tempId;
          url = '/contractTemplate/bexAncillary/'+app.tempId;
        }else if (app.type == 'bexPremier') {
          // url = baseUrl+'/contractTemplate/bex/'+app.tempId;
          url = '/contractTemplate/bexPremier/'+app.tempId;
        } else if (app.type == 'premier') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/premier/'+app.tempId;
        }
        else if (app.type == 'patriotAncillary') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/patriotAncillary/'+app.tempId;
        }
        else if (app.type == 'fegliPremier') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/fegliPremier/'+app.tempId;
        }
        else if (app.type == 'fegliAncillary') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/fegliAncillary/'+app.tempId;
        }
        else if (app.type == 'patriotPremier') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/patriotPremier/'+app.tempId;
        }
        else if (app.type == 'critical') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/critical/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'medical') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/medical/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'dental') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/dental/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'dentalSolstice') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/dentalSolstice/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'vision') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/vision/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'visionSolstice') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/visionSolstice/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'termLife') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/termLife/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'bundled') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/bundled/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'limitedMedical') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/limitedMedical/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'accident') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/accident/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'hospital') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/hospital/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'lifeStyle') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/lifeStyle/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'pet') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/pet/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'guaranteedIssue') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/guaranteedIssue/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'teleMedicine') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = `/contractTemplate/teleMedicine/${app.tempId}/${app.contract_type}`;
        }
        else if (app.type == 'beyondMedical') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/beyondMedical/'+app.tempId;
        }
        else if (app.type == 'prudentialIssue') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/prudentialIssue/'+app.tempId;
        }
        else if (app.type == 'pinnacleBenefitsService') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/pinnacleBenefitsService/'+app.tempId;
        }
        else if (app.type == 'metropolitanLifeInsuranceCompany') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/metropolitanLifeInsuranceCompany/'+app.tempId;
        }
        else if (app.type == 'optionsPlus') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/optionsPlus/'+app.tempId;
        }
        else if (app.type == 'solsticeBenefits') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/solsticeBenefits/'+app.tempId;
        }
        else if (app.type == 'lifelineMedical') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/lifelineMedical/'+app.tempId;
        }
        else if (app.type == 'eNANAWU') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/eNANAWU/'+app.tempId;
        }
        else if (app.type == 'iHAHealth') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/iHAHealth/'+app.tempId;
        }
        else if (app.type == 'northwellAnthem') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/northwellAnthem/'+app.tempId;
        }
        else if (app.type == 'l713Anthem') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/l713Anthem/'+app.tempId;
        }
        else if (app.type == 'cigna') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/cigna/'+app.tempId;
        }
        else if (app.type == 'local713') {
          // url = baseUrl+'/contractTemplate/premier/'+app.tempId;
          url = '/contractTemplate/local713/'+app.tempId;
        }
        // window.open(url);
        window.location = url;
        // window.close();
      }
    }
  };
</script>
    
<style src="../../assets/css/contract_form/fee_structure.css" scoped>
</style>
