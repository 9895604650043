<template>
    <div class="page-wrapper document-view-details">
        <div class="container-fluid">
            <!--<div class="page-title">
                <h1>Document View Details</h1>
            </div> -->
            <div class="row body-main-row">
                <div class="col-md-10 member_dashboard" style="margin:0px auto">
          <b-skeleton-table
          v-if="skeletonLoader" :rows="15" :columns="1" :table-props="{ striped: true }">
          </b-skeleton-table>
                    <div class="document-view-body" v-if="!skeletonLoader" style="margin-top:9%">

                                 <div style="padding:16%" v-if="isComplete">  <p><strong>Signature submitted successfully.</strong></p>
                      <router-link :to="{ name: 'Login' }" ><p>  <button type="button" class="btn btn-primary"> Go to Dashboard</button></p></router-link>
                   </div>


                      <div v-if="!isComplete">
                        <div class="directory-block">
                            <h2>{{ data.username }} </h2>
                        </div>
                        <p><strong>Your medical plan has been updated, and your new updated Medical Plan is:</strong></p>
                        <div class="new-rate-details">
                            <ul>
                                <li>Plan: <strong>{{ data.plan_name }}</strong></li>
                                <li>Tier: <strong>{{ data.tier }}</strong></li>
                                <li>New Rate: <strong>${{data.plan_price}}</strong></li>
                            </ul>
                        </div>
                              <div class="signature">
        <div class="signature_title">
          <p>
            <b>Application Signature (Required): </b>
            <span>(Sign Name using your mouse or touch screen in the box
              below)</span
            >
          </p>
        </div>
        <signature-pad ref="signpad" @sign="getSign"></signature-pad>
        <div
         v-if="isSignRequired"
          tabindex="-1"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style="display: block"
          class="invalid-feedback"
        >
          Sign is required.
        </div>
        <div>
        </div>
                        <p class="osf"><em>With signature we will get your confirmation for the plan changes.</em></p>
                     <br>
                     <br>
                     <button @click="submitSign()" type="button" class="btn btn-primary">
                         <b-spinner v-if="signatureLoader == true" small></b-spinner>
                          Submit Signature</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import SignaturePad from "@/components/SignaturePad";
import helper from "../resource/Helper";

/*eslint-disable*/
export default {
    name: 'PlanChangedConfirmationSign',
      components: {
    SignaturePad,
  },
   data: () => ({
        data: [],
        skeletonLoader: true,
        sign: '',
        policyId: '',
        isSignRequired: false,
        sign_hash: '',
        isComplete : false,
        signatureLoader: false
    }),
     methods: {
        async getData(){
            //let loader = this.$loading.show();
            await axios
            .get('/get-mm-policy-detail?policy_id='+this.policyId)
            .then((res) => {
                this.data = res.data.data;
            })
            .finally(() => {
                this.skeletonLoader = false
               // loader.hide();
            });
        },
      async submitSign(){
          
            if(this.sign == ''){
                this.isSignRequired = true;
                return;
            }
            this.signatureLoader = true;
             await axios.post('/update-plan-change-sign',{
                policy_id: this.policyId,
                sign: this.sign,
                sign_hash: this.sign_hash
            })
            .then((res) => {
                this.data = res.data.data;
                this.isComplete = true;
                helper.infoMessage(this, "success", res.data.message);
            }).catch(function(err) {
                helper.infoMessage(this, "error", err.response.data.message);
            })
            .finally(() => {
                this.skeletonLoader = false
                this.isSignRequired = false;
                this.$refs.signpad.clear();
                this.signatureLoader = false
               // loader.hide();
            });
        },
      getSign(sign = "") {
      this.sign = sign;
    },
    },
    created() {
    this.sign_hash = (Math.random() + 1).toString(36).substring(7)
    this.policyId = atob(this.$route.params.policyID);
    this.getData();
  },
}
</script>

<style>
.document-view-body {
    background: #fff;
    box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
    padding: 30px;
    padding-top: 20px;
    margin-top: 20px;
    border-radius: 15px;
}
.document-view-body p {
    font-size: 14px;
    margin-bottom: 10px;
}
.new-rate-details {
    padding: 30px;
    max-width: 800px;
    margin-bottom: 13px;
    background: #f3f7fa;
    border-radius: 10px;
    margin-top: 15px;
}
.document-view-body .osf {
    opacity: 0.7;
    margin-bottom: 5px;
}
.new-rate-details ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.new-rate-details ul li {
    display: inline-block;
}
.new-rate-details strong {
    color: #03c3ec;
}
.doc-footer p {
    margin-bottom: 4px;
}
.doc-footer p:first-child {
    margin-top: 2%;
}
.doc-footer .osf {
    margin-bottom: 2px;
}
.elevate-logo {
    width: 20%;
    display: block;
    margin: 0px auto;
    margin-top:2%
}
.directory-block {margin-bottom:3%}
</style>
<style scoped>
#footer { padding-top:2% !important}
</style>