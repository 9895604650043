var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "changeEmail",
      "centered": true,
      "title": "Change Email",
      "hide-footer": true,
      "size": "md",
      "no-close-on-backdrop": ""
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "form form_change"
  }, [_c('div', {
    staticClass: "input_form"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Current Password",
      "label-for": "input-2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-2",
      "type": "password",
      "required": "",
      "placeholder": "Current Password"
    },
    model: {
      value: _vm.input.password,
      callback: function ($$v) {
        _vm.$set(_vm.input, "password", $$v);
      },
      expression: "input.password"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["password"]) + " ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "New Email",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "text",
      "required": "",
      "placeholder": "New Login Email"
    },
    model: {
      value: _vm.input.username,
      callback: function ($$v) {
        _vm.$set(_vm.input, "username", $$v);
      },
      expression: "input.username"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["email"]) + " ")]) : _vm._e()], 1), _c('a', {}, [_c('button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.loginLoader
    },
    on: {
      "click": function ($event) {
        return _vm.changeEmail();
      }
    }
  }, [_vm.loginLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)]), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "modal-footer"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }