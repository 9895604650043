var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message_center_new dashboard-card mt-4"
  }, [_c('div', {
    staticClass: "message_head"
  }, [_c('h3', [_vm._v("Knowledge Center")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'ViewKnowledge'
      }
    }
  }, [_c('small', [_vm._v("View All")])])], 1), _vm.skeletonLoader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 5,
      "columns": 2,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), !_vm.skeletonLoader ? _c('div', {
    staticClass: "dashboard-msg-body"
  }, _vm._l(_vm.knowledge_feeds, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "msg-list"
    }, [_c('div', {
      staticClass: "message"
    }, [_c('p', [_vm._v(_vm._s(_vm.formatTitle(item.title)))]), _c('small', [_vm._v(_vm._s(_vm.formatDate(item.published_date)))])]), _c('button', {
      staticClass: "read",
      on: {
        "click": function ($event) {
          return _vm.$bvModal.show('knowledge_modal_' + item.id);
        }
      }
    }, [_vm._v(" Read More ")])]);
  }), 0) : _vm._e(), _vm._l(_vm.knowledge_feeds, function (item, index) {
    var _item$created_user$ag, _item$created_user, _item$created_user$re, _item$created_user2;

    return _c('b-modal', {
      key: index,
      staticClass: "message_modal new_one",
      attrs: {
        "title": item.title,
        "id": 'knowledge_modal_' + item.id,
        "centered": true,
        "hide-footer": true,
        "size": "lg"
      }
    }, [_c('div', {
      staticClass: "modal-body"
    }, [_c('div', {
      staticClass: "rcv-msgcontent"
    }, [_c('div', {
      staticClass: "rcv-msg-sender"
    }, [_c('div', {
      staticClass: "rcv-snder-icon"
    }, [_c('img', {
      staticClass: "msg-userIcon",
      attrs: {
        "src": (_item$created_user$ag = item === null || item === void 0 ? void 0 : (_item$created_user = item.created_user) === null || _item$created_user === void 0 ? void 0 : _item$created_user.agent_profile_image) !== null && _item$created_user$ag !== void 0 ? _item$created_user$ag : _vm.$dummyImage,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "rcv-sender-details"
    }, [_c('h6', [_vm._v(_vm._s((_item$created_user$re = item === null || item === void 0 ? void 0 : (_item$created_user2 = item.created_user) === null || _item$created_user2 === void 0 ? void 0 : _item$created_user2.rep_full_name) !== null && _item$created_user$re !== void 0 ? _item$created_user$re : 'ADMIN'))]), _c('p', [_c('i', {
      staticClass: "far fa-calendar-alt"
    }), _vm._v(" " + _vm._s(_vm._f("date_format")(item.published_date, "YYYY-MM-DD", "MM/DD/YYYY", item.published_date)) + " ")])])])]), _c('h5', [_vm._v("Title: " + _vm._s(item.title))]), _c('hr'), _c('div', {
      staticClass: "ql-snow"
    }, [_c('div', {
      staticClass: "ql-editor"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    })])]), item.file && item.file.length > 0 ? _c('div', [_c('h6', [_vm._v("Attachments:")]), _c('ul', {
      staticStyle: {
        "list-style": "none"
      }
    }, _vm._l(item.file, function (file) {
      return _c('li', {
        key: file.id
      }, [_c('a', {
        attrs: {
          "href": file.path,
          "target": "__blank",
          "download": ""
        }
      }, [_c('i', {
        staticClass: "fa fa-file"
      }), _vm._v(" " + _vm._s(file.filename) + " ")])]);
    }), 0)]) : _vm._e()])]), _c('div', {
      staticClass: "modal-footer"
    })]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }