<template>
  <div class="employer_info_new dashboard-card dashboard-card-lt mt-4">
    <h3>
      Employer Information
      <!--<a v-b-modal.employer_add> <img src="../../assets/images/add.png" /></a> -->
    </h3>
    <div class="table_content">
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Phone</td>
            <td>Start date</td>
            <!-- <td>Status</td> -->
          
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in employer_info" :key="index">
            <td style="font-family: SF UI display Bold">
              {{ item.name }}
            </td>
            <td>{{ item.phone }}</td>
            <td>{{ item.employment_start_date }}</td>
            <!-- <td>{{ item.is_primary ? 'Active' : 'Inactive' }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal
      id="employer_add"
      :centered="true"
      ok-title="Submit"
      title="Employer Information - Add"
      size="lg"
    >
      <b-form-group label="Employer Name" label-for="input-1">
        <b-form-input id="input-1" required></b-form-input>
      </b-form-group>

      <b-form-group label="Employer Phone Number" label-for="input-2">
        <b-form-input id="input-2" required></b-form-input>
      </b-form-group>

      <b-form-group label="Occupation" label-for="input-3">
        <b-form-input id="input-3" required></b-form-input>
      </b-form-group>

      <b-form-group label="Weekly Hours" label-for="input-4">
        <b-form-input id="input-4" required></b-form-input>
      </b-form-group>

      <b-form-group label="Start Date" label-for="input-5">
        <b-form-datepicker
          id="input-5"
          placeholder="Choose a date"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          required
        ></b-form-datepicker>
      </b-form-group>

      <b-form-group label="Employment Status" label-for="input-6">
        <b-form-select
          id="input-6"
          v-model="selected_employment"
          :options="employment_status"
          required
        >
        </b-form-select>
      </b-form-group>

      <b-form-group label="Address 1" label-for="input-7">
        <b-form-input id="input-7" required></b-form-input>
      </b-form-group>

      <b-form-group label="Address 2" label-for="input-8">
        <b-form-input id="input-8" required></b-form-input>
      </b-form-group>

      <b-form-group label="City" label-for="input-9">
        <b-form-input id="input-9" required></b-form-input>
      </b-form-group>
      <b-form-group label="State" label-for="input-10">
        <b-form-input id="input-10" required></b-form-input>
      </b-form-group>
      <b-form-group label="ZIP" label-for="input-11">
        <b-form-input id="input-11" required></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      employer_info: [],
      employment_status: [
        { value: null, text: "Select one", disabled: true },
        { value: "Employed", text: "Employed" },
        { value: "Unemployed", text: "Unemployed" },
      ],
      selected_employment: null,
    };
  },
  mounted() {
    this.getEmployerDetails();
  },
  methods: {
    getEmployerDetails() {
      let app = this;
      return axios.get("/get-employers").then((response) => {
        app.employer_info = response.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>