var render = function render(){
  var _vm$data$user_info, _vm$data$user_info2, _vm$data, _vm$data$term_request, _vm$data2, _vm$data2$term_reques;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper document-view-details"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row body-main-row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-8 member_dashboard"
  }, [_vm.skeletonLoader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 15,
      "columns": 1,
      "table-props": {
        striped: true
      }
    }
  }) : _vm._e(), !_vm.skeletonLoader ? _c('div', {
    staticClass: "document-view-body",
    staticStyle: {
      "margin-top": "9%"
    }
  }, [_vm.isComplete ? _c('div', {
    staticStyle: {
      "padding": "16%"
    }
  }, [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.alreadySubmitted ? "Signature has already been submitted" : "Signature submitted successfully."))])]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('p', [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Go to Dashboard ")])])])], 1) : _vm._e(), !_vm.isPolicyActive ? _c('div', {
    staticStyle: {
      "padding": "16%"
    }
  }, [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.inactiveMessage))])])]) : _vm._e(), !_vm.isComplete && _vm.isPolicyActive ? _c('div', [_c('div', {
    staticClass: "directory-block signatureData"
  }, [_c('h2', [_vm._v(_vm._s((_vm$data$user_info = _vm.data.user_info) === null || _vm$data$user_info === void 0 ? void 0 : _vm$data$user_info.cfname) + " " + _vm._s((_vm$data$user_info2 = _vm.data.user_info) === null || _vm$data$user_info2 === void 0 ? void 0 : _vm$data$user_info2.clname))])]), _vm._m(0), _vm._m(1), _c('div', {
    staticClass: "new-rate-details"
  }, [_c('ul', [_c('li', [_vm._v(" Enrollment #: "), _c('strong', [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$term_request = _vm$data.term_request_detail) === null || _vm$data$term_request === void 0 ? void 0 : _vm$data$term_request.policy_id))])])])]), _vm._m(2), _c('div', {
    staticClass: "new-rate-details"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Plans")]), _c('ul', _vm._l(_vm.data.plan, function (item, index) {
    return _c('li', {
      key: index
    }, [_vm._v(_vm._s(item) + " ")]);
  }), 0), _c('h4', {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_vm._v("Requested Term Date")]), _c('ul', [_c('li', [_vm._v(_vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$term_reques = _vm$data2.term_request_detail) === null || _vm$data2$term_reques === void 0 ? void 0 : _vm$data2$term_reques.term_date) + " ")])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Request By (Representative)")]), _c('ul', [_c('li', [_c('strong', [_vm._v(_vm._s(_vm.data.rep_detail.agent_fname) + " " + _vm._s(_vm.data.rep_detail.agent_lname))])]), _c('li', [_vm._v(_vm._s(_vm.data.rep_detail.agent_code))]), _c('li', [_vm._v(_vm._s(_vm.data.rep_detail.agent_email))]), _c('li', [_vm._v(_vm._s(_vm.data.rep_detail.agent_phone1))])])])])])]), _c('div', {
    staticClass: "signatureTerms"
  }, [_c('label', {
    staticClass: "checkbox-inline"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.is_term_agree,
      expression: "is_term_agree"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.is_term_agree) ? _vm._i(_vm.is_term_agree, null) > -1 : _vm.is_term_agree
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.is_term_agree,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.is_term_agree = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.is_term_agree = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.is_term_agree = $$c;
        }
      }
    }
  }), _vm._v(" I agree to the termination ")])]), _c('div', {
    staticClass: "signature"
  }, [_vm._m(3), _c('signature-pad', {
    ref: "signpad",
    on: {
      "sign": _vm.getSign
    }
  }), _vm.isSignRequired ? _c('div', {
    staticClass: "invalid-feedback",
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "tabindex": "-1",
      "role": "alert",
      "aria-live": "assertive",
      "aria-atomic": "true"
    }
  }, [_vm._v(" Sign is required. ")]) : _vm._e(), _c('p', {
    staticClass: "osf"
  }), _c('br'), _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "submitSignature",
    attrs: {
      "type": "button",
      "disabled": !_vm.is_term_agree
    },
    on: {
      "click": function ($event) {
        return _vm.submitSign();
      }
    }
  }, [_vm.signatureLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit Signature ")], 1)])], 1)]) : _vm._e()]) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signatureTerms"
  }, [_c('p', [_vm._v("This is to verfiy your consent for the plan termination initiated by your Representative, please sign below to proceed further. Please validate by your signature below for confirmation.")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Sign in and Complete the process for Termination Request")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Termination Request")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signature_title"
  }, [_c('p', [_c('b', [_vm._v("Signature (Required): ")]), _c('span', [_vm._v("(Sign Name using your mouse or touch screen in the box below)")])])]);

}]

export { render, staticRenderFns }