var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "app-download-modal",
      "no-close-on-backdrop": ""
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('a', {
    attrs: {
      "href": "javascript:;"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/corenroll-logo.png"),
      "alt": "corenroll-logo"
    }
  })])]), _c('div', {
    staticClass: "title-text"
  }, [_c('h1', [_c('strong', [_vm._v("For Better "), _c('br'), _vm._v("Experience "), _c('br'), _vm._v("Download App")])])]), _c('div', {
    staticClass: "row my-2"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "motive"
  }, [_c('p', [_vm._v(" Experience the ease of managing your all information in one place. ")])]), _c('div', {
    staticClass: "w-100"
  }, [_c('button', {
    staticClass: "univ-btn mw-100 btn-block download-app-btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.redirectToDownload
    }
  }, [_vm._v(" Download App ")])]), _c('div', {
    staticClass: "w-100"
  }, [_c('button', {
    staticClass: "univ-btn mw-100 web-btn btn-block",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.enableWebVersion();
      }
    }
  }, [_vm._v(" Continue with Web Version ")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }