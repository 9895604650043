var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "benefit_store_new dashboard-card"
  }, [_c('h3', [_vm._v("Benefit Store")]), _vm.skeletonLoader ? _c('b-skeleton-img', {
    attrs: {
      "card-img": "top",
      "aspect": "3:2"
    }
  }) : _vm._e(), !_vm.skeletonLoader ? _c('div', {
    staticClass: "benefit"
  }, [_c('VueSlickCarousel', {
    attrs: {
      "autoplay": true,
      "arrows": false,
      "dots": true
    }
  }, [_vm.benefitStore.benefit ? _c('div', {
    staticClass: "slider"
  }, [_c('div', {
    staticClass: "benefit_icon"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.benefitStore.link
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/benefit_store_icon.png"),
      "width": "95",
      "height": "95",
      "alt": "benefit_icon"
    }
  })])]), _c('div', {
    staticClass: "detail"
  }, [_c('h4', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.benefitStore.link
    }
  }, [_vm._v("Go To Benefit Store")])]), _c('p', [_vm._v(" View and apply for additional benefits! ")])])]) : _vm._e(), _vm._l(_vm.benefits, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "slider"
    }, [_c('div', {
      staticClass: "benefit_icon"
    }, [_c('img', {
      attrs: {
        "src": item.image,
        "width": "95",
        "height": "95",
        "alt": "benefit_icon"
      }
    })]), _c('div', {
      staticClass: "detail"
    }, [_c('h4', [_vm._v(_vm._s(item.title))]), _c('p', [_vm._v(" " + _vm._s(item.description) + " ")])])]);
  })], 2)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }