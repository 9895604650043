<template>
  <div class="page-wrapper document-view-details">
    <div class="container">
      <!--<div class="page-title">
                <h1>Document View Details</h1>
            </div> -->
      <div class="row body-main-row justify-content-center">
        <div class="col-md-8 member_dashboard">
          <b-skeleton-table
            v-if="skeletonLoader"
            :rows="15"
            :columns="1"
            :table-props="{ striped: true }"
          >
          </b-skeleton-table>
          <div
            class="document-view-body"
            v-if="!skeletonLoader"
            style="margin-top: 9%"
          >

            <div style="padding: 16%" v-if="isComplete">
              <p><strong>{{ alreadySubmitted ? "Signature has already been submitted" : "Signature submitted successfully."  }}</strong></p>
              <router-link :to="{ name: 'Login' }"
                ><p>
                  <button type="button" class="btn btn-primary">
                    Go to Dashboard
                  </button>
                </p></router-link
              >
            </div>
     
            <div style="padding: 16%" v-if="!isPolicyActive">
              <p><strong>{{ inactiveMessage }}</strong></p>
            </div>

            <div v-if="!isComplete && isPolicyActive">
              <div class="directory-block signatureData">
                <h2>{{ data.user_info.name }}</h2>
                <figure>Effective Date: {{ data.enrollment_information.effective_date }}</figure>
              </div>

              <div class="signatureTerms">
                  <p>This is to verfiy your email {{ data.user_info.email }} to proceed further. We will notify your further through this email. Please validate by your signature below for confirmation.</p>      
              </div>

              <p><strong>Sign in and Complete your Membership Enrollment</strong></p>
              <div class="new-rate-details">
                <ul>
                  <li>
                    Enrollment #: <strong>{{ data.enrollment_information.policy_id }}</strong>
                  </li>
                  <li>
                    Tier: <strong>{{ data.tier }}</strong>
                  </li>
                </ul>
              </div>
              <p><strong>Policy Information</strong></p>
              <div class="new-rate-details">
                  <div class="row">
                        <div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Enrollment Information</h4>
                                <ul>
                                    <li><strong>System ID Number:</strong> {{ data.enrollment_information.policy_id }}</li>
                                    <li><strong>Enrollment Date:</strong> {{ data.enrollment_information.enrollment_date }}</li>
                                    <li><strong>Effective Date:</strong> {{ data.enrollment_information.effective_date }}</li>
                                    <li><strong>Group Name :</strong>  {{ data.enrollment_information.group_name }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Enrolled In</h4>
                                <ul>
                                    <li v-for="(item, index) in data.plan_list" :key="index">{{ item }} </li>
                                   
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Primary Applicant</h4>
                                <ul>
                                    <li><strong> {{ data.user_info.name }}</strong></li>
                                    <li><strong>Age:</strong> {{ data.user_info.age }}</li>
                                    <li><strong>Employer/Group:</strong>{{ data.enrollment_information.group_name }}</li>
                                    <li><strong>Phone:</strong>{{ data.user_info.phone }}</li>
                                    <li><strong>Email:</strong> {{ data.user_info.email }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Address</h4>
                                <ul>
                                    <li>{{ data.user_info.address1 }}</li>
                                    <li>{{ data.user_info.address2 }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="!data.user_info.is_employer_type">
                            <div class="policy-Info mb-4">
                                <h4>Payment Info</h4>
                                <ul>
                                    <li><strong>Membership Processing Fee: </strong>{{ data.payment_info.enrollment_fee }}</li>
                                    <li><strong>First Month Total: </strong>{{ data.payment_info.first_month }}</li>
                                    <li v-if="data.payment_info.payment_method == 'cc'"><strong>Payment Service Fee </strong>{{ data.payment_info.cc_fee }}</li>
                                    <li><strong>Recurring Month Total: </strong> {{ data.payment_info.recurring_amount }}</li>
                                    <li><strong>Payment Method: </strong> {{ data.payment_info.payment_method }}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Representative</h4>
                                <ul>
                                    <li><strong>{{ data.rep.agent_name }}</strong> </li>
                                    <li>{{ data.rep.agent_code }}</li>
                                    <li>{{ data.rep.agent_email }}</li>
                                    <li>{{ data.rep.agent_phone }}</li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
              <div class="carrier-message-container mb-3" v-if="carrierSpecialMessage">
                {{carrierSpecialMessage}}
              </div>
              <div class="signatureTerms">
                  <h2>Terms & Conditions</h2>
                  <ul>
                      <li>The member shall agree that the Plan is final as written and will be administrated as such.</li>
                      <li>The member shall agree that all plan and eligibility changes must be approved by the Plan Supervisor.</li>
                      <li>The member shall agree to be drafted on the payment date noted in the confirmation email monthly.</li>
                      <li>The member shall submit a request for termination in advance of the premium draft corresponding to the termination date. All terminations will be processed effective the last day of the month.</li>
                      <li>The member shall make up any failed payment within 30 days of the scheduled draft date. Failure to remit payment within this time frame will result in plan termination.</li>
                  </ul>
                  <label class="checkbox-inline">
                    <input type="checkbox" v-model="is_term_agree"> I Agree Terms & Conditions
                   </label>
              </div>
              <div class="signature">
                <div class="signature_title">
                  <p>
                    <b>Application Signature (Required): </b>
                    <span
                      >(Sign Name using your mouse or touch screen in the box
                      below)</span
                    >
                  </p>
                </div>
                <signature-pad ref="signpad" @sign="getSign"></signature-pad>
                <div
                  v-if="isSignRequired"
                  tabindex="-1"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                  style="display: block"
                  class="invalid-feedback"
                >
                  Sign is required.
                </div>
                <p class="osf">
                  <em>With signature your enrollment will be complete.</em>
                </p>
                <br />
                <div class="text-center">
                    <button
                    @click="submitSign()"
                    type="button"
                    class="submitSignature"
                    :disabled="!is_term_agree"
                    >
                    <b-spinner v-if="signatureLoader == true" small></b-spinner>
                    Submit Signature
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import SignaturePad from "@/components/SignaturePad";
import helper from "../resource/Helper";

/*eslint-disable*/
export default {
  name: "PolicySignature",
  components: {
    SignaturePad,
  },
  data: () => ({
    data: [],
    skeletonLoader: true,
    sign: "",
    policyId: "",
    isSignRequired: false,
    sign_hash: "",
    isComplete: false,
    alreadySubmitted: false,
    signatureLoader: false,
    is_term_agree: false,
    isPolicyActive: true,
    inactiveMessage: '',
    carrierSpecialMessage: ''
  }),
  methods: {
    async getData() {
      //let loader = this.$loading.show();
      await axios
        .get("/receipt/get-policy-info/" + this.policyId)
        .then((res) => {
          this.isComplete = this.alreadySubmitted = res.data.data.has_already_submitted
          this.data = res.data.data;
          this.carrierSpecialMessage = res.data.data.special_message
        })
        .catch((error) => {
          if (error.response.data.statusCode == 412) {
            this.inactiveMessage = error.response.data.message
            this.isPolicyActive = false
          }
        })
        .finally(() => {
          this.skeletonLoader = false;
          // loader.hide();
        });
    },
    async submitSign() {
      if (this.sign == "") {
        this.isSignRequired = true;
        return;
      }
      this.signatureLoader = true;
      await axios
        .post("/update-plan-change-sign?receipt=1", {
          policy_id: this.policyId,
          sign: this.sign,
          sign_hash: this.sign_hash,
        })
        .then((res) => {
          //this.data = res.data.data;
          this.isComplete = true;
          helper.infoMessage(this, "success", res.data.message);
        })
        .catch(function (err) {
          helper.infoMessage(this, "error", err.response.data.message);
        })
        .finally(() => {
          this.skeletonLoader = false;
          this.isSignRequired = false;
          this.$refs.signpad.clear();
          this.signatureLoader = false;
          // loader.hide();
        });
    },
    getSign(sign = "") {
      this.sign = sign;
    },
  },
  created() {
    this.sign_hash = (Math.random() + 1).toString(36).substring(7);
    this.policyId = atob(this.$route.params.policyID);
    this.getData();
  },
};
</script>

<style>
.document-view-details {
  background: #f3f6f8;
  padding: 5rem 0;
}
.document-view-body {
  background: #fff;
  box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
  padding: 30px;
  padding-top: 20px;
  margin-top: 20px;
  border-radius: 15px;
}
.document-view-body p {
  font-size: 14px;
  margin-bottom: 10px;
}
.new-rate-details {
  padding: 15px;
  max-width: 800px;
  margin-bottom: 13px;
  background: #f3f7fa;
  border-radius: 10px;
  margin-top: 15px;
  font-size: 14px;
}
.document-view-body .osf {
  opacity: 0.7;
  margin-bottom: 5px;
}
.directory-block h2 {
  font-size: 18px;
  font-weight: bold;
}
.new-rate-details ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.new-rate-details ul li {
  display: inline-block;
}
.new-rate-details strong {
  color: #03c3ec;
}
.doc-footer p {
  margin-bottom: 4px;
}
.doc-footer p:first-child {
  margin-top: 2%;
}
.doc-footer .osf {
  margin-bottom: 2px;
}
.elevate-logo {
  width: 20%;
  display: block;
  margin: 0px auto;
  margin-top: 2%;
}
.signatureTerms h2{
    font-size: 17px;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.signatureTerms ul{
    font-size: 14px;
    list-style: auto;
    padding-left: 20px;
}
.signatureTerms ul li{
    list-style: initial;
    padding-bottom: 5px;
}
.signatureTerms .checkbox-inline{
    font-size: 14px;
}
.signatureTerms input{
    margin-right: 10px;
}
.signatureData{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.signatureData figure{
        font-size: 14px;
    background: #f3f6f8;
    padding: 5px 13px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 0;
}
.submitSignature{
    background: #29bcff;
    border: 0px;
    padding: 10px 30px;
    color: #fff;
    font-size: 14px;
    outline: none;
    border-radius: 6px;
}
.signature .clear{
    background: #333;
    color: #fff;
    padding: 5px 30px;
    float: inherit;
}
.policy-Info ul,
.policy-Info ul li{
    display: block;
    font-size: 13px;
    line-height: 20px;
}
.policy-Info h4{
    font-size: 14px;
    font-weight: 700;
}
button.submitSignature:disabled {
    background: grey;
}
.carrier-message-container {
  background-color: #d1ecf1;
  border-color: #bee5eb;
  border-radius: 8px;
  padding: 12px 10px;
  margin-top: 15px;
  text-transform: capitalize;
  text-align: center;
  font-size: 15px;
  width: 100%;
}
</style>
<style scoped>
#footer {
  padding-top: 50px !important;
}

</style>