<template>
  <div class="col-xl-10">
    <div class="notice" v-if="false">
      <h2>Open Enrollment Plan renewal Alert</h2>
      <p>

        Your plan renews Jan 1, 2024, you can keep your existing plan or choose
        to change to another plan. Deadline to change is 12/10/2023. If you do
        not choose a new plan, your current plan will renew at the renewal rate.
      </p>
      <ul>
        <dashboard-enrollment-current-plan :current-plan.sync="currentPlan" />
        <dashboard-enrollment-renew-plan
         :current-plan.sync="currentPlan"
          @onSwitchedPlan="getCurrentNewPlan()"
         />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Api from "@/resource/MemberApi";
import DashboardEnrollmentRenewPlan from "./DashboardEnrollment/DashboardEnrollmentRenewPlan.vue";
import DashboardEnrollmentCurrentPlan from "./DashboardEnrollment/DashboardEnrollmentCurrentPlan.vue";

export default {
  name: "DashboardEnrollmentPlan",
  components: {
    DashboardEnrollmentRenewPlan,
    DashboardEnrollmentCurrentPlan,
  },
  data: () => ({
    currentPlan: false,
  }),
  computed: {
    ...mapGetters({ memberId: "memberId" }),
  },
  methods: {
    getCurrentNewPlan(){
      this.getCurrentPlan()
      this.$emit("onSwitchedPlanGetProducts")
    },
    getCurrentPlan() {
      const url = `v2/get-current-major-medical-plan/${this.memberId}`;
      Api.get(url)
        .then((res) => {
          this.currentPlan = res.data.data;
        })
        .catch(() => {
          this.currentPlan = null;
        });
    },
  },
  created() {
    this.getCurrentPlan();
  },
};
</script>
        