<template>
  <div class="page-wrapper document-view-details">
    <div class="container">
      <!--<div class="page-title">
                <h1>Document View Details</h1>
            </div> -->
      <div class="row body-main-row justify-content-center">
        <div class="col-md-8 member_dashboard">
          <b-skeleton-table
            v-if="skeletonLoader"
            :rows="15"
            :columns="1"
            :table-props="{ striped: true }"
          >
          </b-skeleton-table>
          <div
            class="document-view-body"
            v-if="!skeletonLoader"
            style="margin-top: 9%"
          >

            <div style="padding: 16%" v-if="isComplete">
              <p><strong>{{ alreadySubmitted ? "Signature has already been submitted" : "Signature submitted successfully."  }}</strong></p>
              <router-link :to="{ name: 'Login' }"
                ><p>
                  <button type="button" class="btn btn-primary">
                    Go to Dashboard
                  </button>
                </p></router-link
              >
            </div>
     
            <div style="padding: 16%" v-if="!isPolicyActive">
              <p><strong>{{ inactiveMessage }}</strong></p>
            </div>

            <div v-if="!isComplete && isPolicyActive">
              <div class="directory-block signatureData">
                <h2>{{ data.user_info?.cfname }} {{ data.user_info?.clname }}</h2>
              </div>

              <div class="signatureTerms">
                  <p>This is to verfiy your consent for the plan termination initiated by your Representative, please sign below to proceed further. Please validate by your signature below for confirmation.</p>      
              </div>

              <p><strong>Sign in and Complete the process for Termination Request</strong></p>
              <div class="new-rate-details">
                <ul>
                  <li>
                    Enrollment #: <strong>{{ data?.term_request_detail?.policy_id }}</strong>
                  </li>
                </ul>
              </div>
              <p><strong>Termination Request</strong></p>
              <div class="new-rate-details">
                  <div class="row">
                  
                        <div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Plans</h4>
                                <ul>
                                    <li v-for="(item, index) in data.plan" :key="index">{{ item }} </li>
                                   
                                </ul>
                                <h4 style="margin-top:10px">Requested Term Date</h4>
                                <ul>
                                    <li>{{ data?.term_request_detail?.term_date }} </li>
                                   
                                </ul>
                            </div>
                        </div>
                        <!--<div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Primary Applicant</h4>
                                <ul>
                                    <li><strong> {{ data?.user_info?.name }}</strong></li>
                                    <li><strong>Age:</strong> {{ data?.user_info?.age }}</li>
                                    <li><strong>Employer/Group:</strong>{{ data?.enrollment_information?.group_name }}</li>
                                    <li><strong>Phone:</strong>{{ data?.user_info?.phone }}</li>
                                    <li><strong>Email:</strong> {{ data?.user_info?.email }}</li>
                                </ul>
                            </div>
                        </div>-->
                       
                        <div class="col-md-6">
                            <div class="policy-Info mb-4">
                                <h4>Request By (Representative)</h4>
                                <ul>
                                    <li><strong>{{ data.rep_detail.agent_fname }} {{ data.rep_detail.agent_lname }}</strong> </li>
                                    <li>{{ data.rep_detail.agent_code }}</li>
                                    <li>{{ data.rep_detail.agent_email }}</li>
                                    <li>{{ data.rep_detail.agent_phone1 }}</li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
              <div class="signatureTerms">
                  <label class="checkbox-inline">
                    <input type="checkbox" v-model="is_term_agree"> I agree to the termination
                   </label>
              </div>
              <div class="signature">
                <div class="signature_title">
                  <p>
                    <b>Signature (Required): </b>
                    <span
                      >(Sign Name using your mouse or touch screen in the box
                      below)</span
                    >
                  </p>
                </div>
                <signature-pad ref="signpad" @sign="getSign"></signature-pad>
                <div
                  v-if="isSignRequired"
                  tabindex="-1"
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                  style="display: block"
                  class="invalid-feedback"
                >
                  Sign is required.
                </div>
                <p class="osf">
                  <!-- <em>With signature the termination request will be completed.</em> -->
                </p>
                <br />
                <div class="text-center">
                    <button
                    @click="submitSign()"
                    type="button"
                    class="submitSignature"
                    :disabled="!is_term_agree"
                    >
                    <b-spinner v-if="signatureLoader == true" small></b-spinner>
                    Submit Signature
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import SignaturePad from "@/components/SignaturePad";
import helper from "../resource/Helper";

/*eslint-disable*/
export default {
  name: "TermRequestSign",
  components: {
    SignaturePad,
  },
  data: () => ({
    data: [],
    skeletonLoader: true,
    sign: "",
    termID: "",
    isSignRequired: false,
    sign_hash: "",
    isComplete: false,
    alreadySubmitted: false,
    signatureLoader: false,
    is_term_agree: false,
    isPolicyActive: true,
    inactiveMessage: ''
  }),
  methods: {
    async getData() {
      //let loader = this.$loading.show();
      await axios
        .get("/term-request/get/" + this.termID)
        .then((res) => {
          this.isComplete = this.alreadySubmitted = res.data.data.has_already_submitted
          debugger;
          this.data = res.data.data;
        })
        .catch((error) => {
          if (error.response.data.statusCode == 412) {
            this.inactiveMessage = error.response.data.message
            this.isPolicyActive = false
          }
        })
        .finally(() => {
          this.skeletonLoader = false;
          // loader.hide();
        });
    },
    async submitSign() {
      if (this.sign == "") {
        this.isSignRequired = true;
        return;
      }
      this.signatureLoader = true;
      await axios
        .post("/term-request/save-sign", {
          term_id: this.termID,
          sign: this.sign,
          sign_hash: this.sign_hash,
        })
        .then((res) => {
          //this.data = res.data.data;
          this.isComplete = true;
          helper.infoMessage(this, "success", res.data.message);
        })
        .catch(function (err) {
          helper.infoMessage(this, "error", err.response.data.message);
        })
        .finally(() => {
          this.skeletonLoader = false;
          this.isSignRequired = false;
          this.$refs.signpad.clear();
          this.signatureLoader = false;
          // loader.hide();
        });
    },
    getSign(sign = "") {
      this.sign = sign;
    },
  },
  created() {
    this.sign_hash = (Math.random() + 1).toString(36).substring(7);
    this.termID = atob(this.$route.params.requestID);
    this.getData();
  },
};
</script>

<style>
.document-view-details {
  background: #f3f6f8;
  padding: 5rem 0;
}
.document-view-body {
  background: #fff;
  box-shadow: 0 2px 15px rgb(216 227 235 / 33%);
  padding: 30px;
  padding-top: 20px;
  margin-top: 20px;
  border-radius: 15px;
}
.document-view-body p {
  font-size: 14px;
  margin-bottom: 10px;
}
.new-rate-details {
  padding: 15px;
  max-width: 800px;
  margin-bottom: 13px;
  background: #f3f7fa;
  border-radius: 10px;
  margin-top: 15px;
  font-size: 14px;
}
.document-view-body .osf {
  opacity: 0.7;
  margin-bottom: 5px;
}
.directory-block h2 {
  font-size: 18px;
  font-weight: bold;
}
.new-rate-details ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}
.new-rate-details ul li {
  display: inline-block;
}
.new-rate-details strong {
  color: #03c3ec;
}
.doc-footer p {
  margin-bottom: 4px;
}
.doc-footer p:first-child {
  margin-top: 2%;
}
.doc-footer .osf {
  margin-bottom: 2px;
}
.elevate-logo {
  width: 20%;
  display: block;
  margin: 0px auto;
  margin-top: 2%;
}
.signatureTerms h2{
    font-size: 17px;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}
.signatureTerms ul{
    font-size: 14px;
    list-style: auto;
    padding-left: 20px;
}
.signatureTerms ul li{
    list-style: initial;
    padding-bottom: 5px;
}
.signatureTerms .checkbox-inline{
    font-size: 14px;
}
.signatureTerms input{
    margin-right: 10px;
}
.signatureData{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.signatureData figure{
        font-size: 14px;
    background: #f3f6f8;
    padding: 5px 13px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 0;
}
.submitSignature{
    background: #29bcff;
    border: 0px;
    padding: 10px 30px;
    color: #fff;
    font-size: 14px;
    outline: none;
    border-radius: 6px;
}
.signature .clear{
    background: #333;
    color: #fff;
    padding: 5px 30px;
    float: inherit;
}
.policy-Info ul,
.policy-Info ul li{
    display: block;
    font-size: 13px;
    line-height: 20px;
}
.policy-Info h4{
    font-size: 14px;
    font-weight: 700;
}
button.submitSignature:disabled {
    background: grey;
}
</style>
<style scoped>
#footer {
  padding-top: 50px !important;
}

</style>