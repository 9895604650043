<template>
  <div class="personal_info_new dashboard-card">
    <div class="personal_head">
      <h3>Personal Info</h3>
      <a v-b-modal.personal_edit><i class="fas fa-edit"></i></a>
    </div>

    <!-- Personal Address Update Start -->
    <b-modal :ok-disabled="formSubmitted" id="personal_edit" :centered="true" ok-title="Submit" @ok="updatePersonalInfo"
      title="Personal Information" size="lg" no-close-on-backdrop>
      <b-form-group label="Email" label-for="input-123" invalid-feedback="Email  is required" class="required">
        <b-form-input id="input-123" v-model.lazy="personal_address_update.email" disabled></b-form-input>
      </b-form-group>
      <b-form-group label="First Name" label-for="input_1_fname" invalid-feedback="First Name is required"
        class="required">
        <b-form-input id="input_1_fname" :state="validateUpdateDetails('fname')"
          v-model.lazy="$v.personal_address_update.fname.$model"></b-form-input>
      </b-form-group>
      <b-form-group label="Middle Name" label-for="input_1_middle_name">
        <b-form-input id="input_1_middle_name" v-model="personal_address_update.mname" required></b-form-input>
      </b-form-group>
      <b-form-group label="Last Name" label-for="input_1_lastname" invalid-feedback="Last Name is required"
        class="required">
        <b-form-input id="input_1_lastname" :state="validateUpdateDetails('lname')"
          v-model.lazy="$v.personal_address_update.lname.$model" required></b-form-input>
      </b-form-group>
      <b-form-group label="Phone Number" label-for="input-2" :invalid-feedback="validationErrors.phone"
        class="required">
        <b-form-input id="input-2" :state="validateUpdateDetails('phone')" type="number"
          v-model.lazy="$v.personal_address_update.phone.$model" required></b-form-input>
      </b-form-group>
     <!-- <b-form-group label="Date of Birth" label-for="input-3" invalid-feedback="Date of birth is required">
        <b-input-group>
          <b-form-input id="input-3" :state="validateUpdateDetails('dob')" v-model.lazy="$v.personal_address_update.dob.$model" disabled>
          </b-form-input>
          <b-input-group-append>
            <b-form-datepicker v-model="personal_address_update.dob" button-only right :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }" required></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->

      <b-form-group label="Date of Birth" label-for="input-3" invalid-feedback="Date of birth is required"
        class="required">
        <b-form-datepicker locale="en" id="input-3" v-model="$v.personal_address_update.dob.$model"
          :placeholder="personal_address_update.dob" :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" required></b-form-datepicker>
      </b-form-group>
      <b-form-group label="Marital Status" label-for="input-41" invalid-feedback="Marital Status is required."
        class="required">
        <b-form-select id="input-41" :options="marital_status" :state="validateUpdateDetails('marital_status')"
          v-model.lazy="$v.personal_address_update.marital_status.$model" required></b-form-select>
      </b-form-group>

      <b-form-group label="Gender" label-for="input_gender" invalid-feedback="Gender is required." class="required">
        <b-form-select id="input_gender" :options="gender" :state="validateUpdateDetails('gender')"
          v-model.lazy="$v.personal_address_update.gender.$model" required></b-form-select>
      </b-form-group>

      <b-form-group label="SSN" label-for="ssnInput" :invalid-feedback="validationErrors.ssn" class="required">
        <b-form-input id="ssnInput" :state="validateUpdateDetails('ssn_full')"
          v-model.lazy="$v.personal_address_update.ssn_full.$model" required>
        </b-form-input>
        <eye-button id="ssnInput" hideOnDefault="true" type="number"></eye-button>
      </b-form-group>

      <b-form-group v-if="has_height_weight" label="Height Feet" label-for="input-42"
        :invalid-feedback="validationErrors.height" class="required">
        <b-form-input id="input-42" :state="validateUpdateDetails('height_feet')"
          v-model.lazy="$v.personal_address_update.height_feet.$model" required></b-form-input>
      </b-form-group>

      <b-form-group v-if="has_height_weight" label="Height Inches" label-for="input-4"
        invalid-feedback="Invalid height inch.">
        <b-form-input id="input-4" :state="validateUpdateDetails('height_inches')"
          v-model.lazy="$v.personal_address_update.height_inches.$model"></b-form-input>
      </b-form-group>

      <b-form-group v-if="has_height_weight" label="Weight (lbs)" label-for="input-44"
        :invalid-feedback="validationErrors.weight" class="required">
        <b-form-input id="input-44" :state="validateUpdateDetails('weight')"
          v-model.lazy="$v.personal_address_update.weight.$model" required></b-form-input>
      </b-form-group>

    </b-modal>

    <!-- Personal Address Update Ends -->

    <!-- add address -->
    <b-modal :ok-disabled="formSubmitted" id="address_add" :centered="true" ok-title="Submit" @ok="handleSubmit"
      @hidden="hideModal()" title="Personal Address - Add" size="lg" no-close-on-backdrop>
      <b-form-group label-for="input-1" invalid-feedback="Address is required">
        <template slot="label">Address <span> *</span></template>
        <b-form-input id="input-1" :state="validateState('address1')" v-model.lazy="$v.form.address1.$model">
        </b-form-input>
        <span style="display: none" class="field_error" v-if="!$v.form.address1.required">Field is required</span>
      </b-form-group>
      <b-form-group label-for="input-12" invalid-feedback="Address is required">
        <template slot="label">Address 2</template>
        <b-form-input id="input-12" :v-model="form.address2"> </b-form-input>
      </b-form-group>

      <b-form-group label="City" label-for="input-2" invalid-feedback="City is required">
        <template slot="label">City <span> *</span></template>
        <b-form-input id="input-2" :state="validateState('city')" v-model.lazy="$v.form.city.$model"></b-form-input>
      </b-form-group>

      <b-form-group label="ZIP" label-for="input-3" invalid-feedback="Zip is required">
        <template slot="label">ZIP <span> *</span></template>
        <b-form-input id="input-3" max="5" type="number" :state="validateState('zip')"
          v-model.lazy="$v.form.zip.$model"></b-form-input>
      </b-form-group>

      <b-form-group label="State" label-for="input-4" invalid-feedback="State is required">
        <template slot="label">State <span> *</span></template>
        <b-form-select id="input-4" :options="states" :state="validateState('state')"
          v-model.lazy="$v.form.state.$model">
          <template #first>
            <b-form-select-option value="">Select State</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group label="" label-for="checkbox-bypass" invalid-feedback="State is required">
      </b-form-group>
      <b-form-group label="" label-for="checkbox-bypass" invalid-feedback="State is required">
        <template slot="label"> </template>
        <b-form-checkbox id="checkbox-bypass" v-model="form.bypass" name="checkbox-1" value="1" unchecked-value="0"
          switch>
          Bypass Address validation
        </b-form-checkbox>
      </b-form-group>

      <b-form-checkbox id="checkbox-1" checked="checked" v-model="form.is_primary" name="checkbox-1" value="1"
        unchecked-value="0">
        Set as primary
      </b-form-checkbox>
    </b-modal>
    <!-- add address ends -->
    <b-skeleton-img v-if="profile_skeleton_loader" card-img="top" aspect="3:1"></b-skeleton-img>
    <div v-if="!profile_skeleton_loader" class="profile-details-div">
      <div class="profile">
        <input @change="updatePhoto" accept=".jpg,.jpeg,.png" style="display:none" id="file_input_personal"
          type="file" />
        <div class="profile_image">
          <img :src="user.image || '/img/default_profile.52292c62.jpg'" alt="profile" class="profile_image" />
          <label for="file_input_personal" class="personal_info_update">
            <i class="fas fa-camera"></i>
          </label>
        </div>
      </div>
      <div class="person_detail">
        <h3>{{ personal_details.fname }} {{ personal_details.mname }} {{ personal_details.lname }}</h3>
        <p class="age_gender">
          {{ personal_details.age }} {{ personal_details.gender }} | {{ personal_details.marital_status }}
        </p>
        <p class="personal_email">
          {{ personal_details.email }}
        </p>
        <div class="detail">
          <ul>
            <li>DOB: {{ personal_details.dob }}</li>
            <li>PN: {{ personal_details.phone | us_phone }}</li>
          </ul>
        </div>
        <div class="personal_ssn">
          SSN: {{ personal_details.ssn }}
        </div>
      </div>
    </div>
    <div class="table_content address-status-wrap">
      <div class="address-status-inner">
        <b-skeleton-table v-if="address_skeleton_loader" :rows="2" :columns="2"
          :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
        <table v-if="address_skeleton_loader == false">
          <thead>
            <tr>
              <td>
                Address
                <a v-b-modal.address_add><i class="fas fa-plus-circle"></i></a>
              </td>
              <td style="text-align:center;">Status</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in personal_details.address_list" :key="index">
              <td>
                <p>
                  {{ item.address1 }}
                  <i v-if="item.is_usps_valid == 1" aria-hidden="true" class="fa fa-check-circle"
                    style="color: rgb(50, 205, 50)" title="Tooltips"></i>
                  <span v-else title="Tooltips"><i class="fas fa-exclamation-circle" style="color: red"></i></span>
                </p>
                {{ item.city }}
              </td>
              <td class="action_address">
                <span class="active_status" v-if="item.is_primary == 1"><i class="fas fa-check-circle"></i>
                  Active</span>
                <span class="active_status" v-else>
                  <button @click="
      openPrimaryModal(
        'address_' + item.address_id,
        item.address_id
      )
      ">
                    Set as Primary
                  </button>
                  <span class="deleteIcon" title="Delete this address"
                    @click="openPrimaryModal('deleteAddress', item.address_id)"><i class="far fa-trash-alt"
                      aria-hidden="true" style="color:#606060; font-size:20px"></i></span></span>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

    <b-modal :ok-disabled="formSubmitted" v-for="(item, index) in personal_details.address_list" @ok="setAddressPrimary"
      @hidden="hideModal()" :key="index" addressId="item.address_id" :id="'address_' + item.address_id"
      title="Are you sure?" ok-title="Confirm" :centered="true" size="sm">
      <p class="confirmation">
        Do you want to set this as your
        <b style="color: #ff7a00">Primary Address?</b>
      </p>
      <div class="current">
        <p>Current Primary Address:</p>
        <p>
          <span><b>{{ personal_details.address_list[0].address1 }}</b></span><span>{{
      personal_details.address_list[0].city
    }}
            {{ personal_details.address_list[0].state }}
          </span>
        </p>
      </div>
      <div class="new">
        <p><b>New Primary Address:</b></p>
        <p>
          <span><b>{{ item.address1 }}</b></span><span>{{ item.city }} {{ item.state }} </span>
        </p>
      </div>
    </b-modal>

    <b-modal :ok-disabled="formSubmitted" @ok="deletePrimaryAddress" :id="'deleteAddress'" title="Delete Address"
      ok-title="Confirm" :centered="true" size="lg">
      <p class="confirmation">
        Are you sure you want to delete this?
      </p>
    </b-modal>

    <b-modal :ok-disabled="formSubmitted" @ok="confirmAddressAddition" :id="'confirmPlanChange'"
      title="Plan Change Confirmation" ok-title="Confirm" :centered="true" size="lg">
      <p class="confirmation">
        {{ planConfirmationMessage }}
      </p>
    </b-modal>

    <b-modal :ok-disabled="formSubmitted" @ok="primaryAddressConfirm" :id="'primaryAddressConfirm'"
      title="Plan Change Confirmation" ok-title="Confirm" :centered="true" size="lg">
      <p class="confirmation">
        {{ planConfirmationMessage }}
      </p>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { mapGetters } from "vuex";
import helper from "../../resource/Helper";
import { required,requiredIf } from "vuelidate/lib/validators";
import EyeButton from "../common/EyeButton";
import CommonValidator from "../../resource/CommonValidator";

function intialState() {
  return {
    personal_details: [],
    person: {
      name: "",
      gmail: "",
      birth: "",
      ssn: "",
      pn: "",
      status: "",
    },
    marital_status: ["Single", "Married", "Divorced", "Separated", "Widowed"],
    gender: ["Male", "Female"],
    personal_address: [],
    personal_address_update: [],
    states: helper.getStatesList(),
    form: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      is_primary: "",
      bypass: 0,
      confirmed: false
    },
    invalid: "Address is required",
    address_skeleton_loader: true,
    profile_skeleton_loader: true,
    primary_address_id: "",
    has_height_weight: true,
    validationErrors: {
      birth_date: "Birth date is required",
      ssn: "SSN Number is required.",
      height: "Height is required.",
      weight: "Weight is required",
      phone: "Phone is required",
    },
    phone_line_type: "",
    formSubmitted: false,
    planConfirmationMessage: "",
    primayAddressConfirmation: false
  };
}
export default {
  components: {
    EyeButton,
  },
  data() {
    return intialState();
  },
 computed: {
    ...mapGetters({ user: "user" }),
  },
 async  mounted() {
    await this.getUserDetails();
  },
  methods: {
    getUserDetails() {
      let app = this;
      return axios.get("/get-user-details").then((response) => {
        app.personal_details = response.data.data;
       // Object.assign(app.personal_address_update, response.data.data);
        this.personal_address_update = {...response.data.data}
        app.has_height_weight = response.data.data.height_feet;
        app.$store.commit("setProfilePic", app.personal_details.image);
        helper.setUserDetail(app.personal_details)
        this.address_skeleton_loader = false;
        this.profile_skeleton_loader = false;
        this.$v.form.$reset();
      });
    },
    async handleSubmit(bvModalEvt) {
      let fromData = this.form;
      bvModalEvt.preventDefault();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.formSubmitted = true;
      let app = this;
      if (!fromData.bypass) {
        let verified = true;
        await axios
          .post("https://api.purenroll.com/api/v1/validate-address-usps",fromData
          )
          .then((res) => { verified = true; })
          .catch((error) => {
            if (error.response.data.status == "error") {
              helper.infoMessage(
                app,
                "error",
                error.response.data.data[0].error_message
              );
            }
            this.formSubmitted = false;
            verified = false;
          });
        if (!verified) return;
      }
      this.address_skeleton_loader = true;
      this.addAddress()
    },
    async addAddress() {
      let app = this;
      await axios
        .post("/add-member-address", this.form)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.hideModal();
            this.getUserDetails();
          }
        })
        .catch((error) => {
          if (error.response.data.statusCode == "412") {
            app.planConfirmationMessage = error.response.data.message
            this.$bvModal.show("confirmPlanChange");
          }

          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }

        })
        .finally(() => {
          this.form.confirmed = false;
          this.formSubmitted = false;
          this.address_skeleton_loader = false;
        });
    },
    confirmAddressAddition(bvModalEvt) {
      this.form.confirmed = true;
      this.handleSubmit(bvModalEvt);
    },
    validateState(name, form = "form") {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateUpdateDetails(name) {
      const { $dirty, $error } = this.$v.personal_address_update[name];
      return $dirty ? !$error : null;
    },
    resetData: function () {
      Object.assign(this.$data.form, intialState().form);
    },
    hideModal() {
      this.resetData();
      this.$v.form.$reset();
      this.$bvModal.hide("address_add");
      this.$bvModal.hide("personal_edit");
      this.$bvModal.hide("confirmPlanChange")
      this.getUserDetails();
    },
    openPrimaryModal(id, addressId) {
      this.primary_address_id = addressId;
      this.$bvModal.show(id);
    },
    primaryAddressConfirm(bvModalEvt) {
      this.primayAddressConfirmation = true;
      this.setAddressPrimary(bvModalEvt);
    },
    setAddressPrimary(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
      this.formSubmitted = true;
      axios
        .post("/set-primary-address", { address_id: this.primary_address_id, confirmed: this.primayAddressConfirmation })
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.address_skeleton_loader = true;
            this.$bvModal.hide("confirmPlanChange");
            this.$bvModal.hide("primaryAddressConfirm");
            this.$bvModal.hide("address_" + this.primary_address_id);

            this.getUserDetails();
          }
        })
        .catch((error) => {
          if (error.response.data.statusCode == "412") {
            app.planConfirmationMessage = error.response.data.message
            this.$bvModal.show("primaryAddressConfirm");
          }

          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
          this.address_skeleton_loader = false;
        })
        .finally(() => {
          this.primayAddressConfirmation = false;
          this.formSubmitted = false;
          this.address_skeleton_loader = false;
        });
    },
    deletePrimaryAddress(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
       this.formSubmitted = true;
      axios
        .delete("/delete-member-address?address_id=" + this.primary_address_id)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.address_skeleton_loader = true;
            this.$bvModal.hide("deleteAddress");
            this.getUserDetails();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
           this.address_skeleton_loader = false;
        }).finally(() => {
          this.formSubmitted = false;
          this.address_skeleton_loader = false;
        });
    },
    async updatePersonalInfo(bvModalEvt) {
      bvModalEvt.preventDefault();
      let app = this
      this.$v.personal_address_update.$touch();
      if (this.$v.personal_address_update.$anyError) {
        return;
      }
       this.formSubmitted = true;
      let updateData = this.mapPersonalData();
       this.profile_skeleton_loader = true;
      await axios
        .put("/update-personal-info", updateData)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.hideModal();

          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
          this.profile_skeleton_loader = false;
        }).finally(() => {
          this.formSubmitted = false;
          this.profile_skeleton_loader = false;
        });
    },
    async updatePhoto(evt) {
      let profilePic = document.getElementById("file_input_personal");
      if (profilePic.files.length) {
        let formData = new FormData();
        formData.append("image", profilePic.files[0]);
        this.profile_skeleton_loader = true;
        axios
          .post("/update-member-image", formData)
          .then((response) => {
            if (response.data.status == "success") {
              helper.infoMessage(
                this,
                response.data.status,
                response.data.message
              );
              this.getUserDetails();
            }
          })
          .catch((error) => {
            if (error.response.data.status == "error") {
              helper.infoMessage(this, "error", error.response.data.message);
            }
            this.profile_skeleton_loader = false;
          });
      }
    },
  getImage() {
    return (this.personal_details.image) ? this.personal_details.image : require("../../assets/images/dummy.png");
  },
    mapPersonalData() {
      return {
        email: this.personal_address_update.email,
        first_name: this.personal_address_update.fname,
        middle_name: this.personal_address_update.mname,
        last_name: this.personal_address_update.lname,
        phone: this.personal_address_update.phone,
        marital_status: this.personal_address_update.marital_status,
        birth_date: this.personal_address_update.dob,
        gender: this.personal_address_update.gender,
        ssn: this.personal_address_update.ssn_full,
        height_feet: this.personal_address_update.height_feet,
        height_inches: this.personal_address_update.height_inches,
        weight: this.personal_address_update.weight,
        phone_type: this.phone_line_type
      };
    },
  },
  validations: {
    form: {
      address1: {
        required,
        mustbeCool: function () {
          return true;
        },
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zip: {
        required,
      },
    },
    personal_address_update: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      phone: {
        requiredPhone(value) {
          if (!value.length) {
            this.validationErrors.phone = "Phone number is required.";
            return false;
          }
          if (value.length != 10) {
            this.validationErrors.phone = "Phone number should be of 10 digits.";
            return false;
          }
          return true;
        },
        async validatePhoneNumber(value) {
          if (value.length == 10) {
            let phoneNumberValidation = await CommonValidator.validatePhoneNumber(value);
            if (phoneNumberValidation.status == false) {
              this.validationErrors.phone = phoneNumberValidation.message;
            }
            this.phone_line_type = phoneNumberValidation.data
            return phoneNumberValidation.status;
          }
          return true;
        },
      },
      marital_status: {
        required,
      },
      dob: {
        required,
      },
      gender: {
        required,
      },
      ssn_full: {
        requiredSSN(value) {
          if (!value.length) {
            this.validationErrors.ssn = "SSN Number is required";
            return false;
          }
          if (value.length != 9) {
            this.validationErrors.ssn = "SSN number should be of 9 digits.";
            return false;
          }
          return true;
        },
        async validateSSN(value) {
          if (value.length == 9) {
            let ssnNumberValidation = await CommonValidator.validateSSN(value);
            if (ssnNumberValidation.status == false) {
              this.validationErrors.ssn = ssnNumberValidation.message;
            }
            return ssnNumberValidation.status;
          }
          return true;
        },
      },
      weight: {
        required: requiredIf(function (val) {
          return this.has_height_weight;
        }),
        valueCheck(val) {

          if((val == '' || val== '0') && this.has_height_weight)  { this.validationErrors.weight = 'Weight is required'; return false; }
           if((val < 1 || isNaN(val)) && this.has_height_weight) { this.validationErrors.weight = 'Invalid weight'; return false; }
           return true;
        }
      },
      height_feet: {
        required: requiredIf(function () {
          return this.has_height_weight;
        }),
        valueCheck(val) {
          if((val == '' || val== '0') && this.has_height_weight)  { this.validationErrors.height = 'Height is required'; return false; }
           if((val < 1 || isNaN(val)) && this.has_height_weight) { this.validationErrors.height = 'Invalid height'; return false; }
           return true;
        }
      },
    height_inches: {
        valueCheck(val) {
           if((val < 0 || isNaN(val) ) && this.has_height_weight) { return false; }
           return true;
        }
      },
    },
  },
};
</script>
