<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="welcome">
        <h1>Welcome</h1>
        <p>
          Register to create your online access to your member dashboard.
          (Please note online account access is not available until your start
          date or thereafter)
        </p>
      </div>

      <div class="form">
        <!-- <div class="top-button">
          <router-link :to="{ name: 'Login' }">
            <button class="login_inactive">Log In</button></router-link
          >
          <button class="sign_up_active">Register</button>
        </div> -->
        <div class="signup_form">
          <div class="signup_head">
            <h3>Register</h3>
            <span
              >To register your account, please create username and
              password.</span
            >
          </div>
          <b-form-group label="Email" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="email"
              placeholder="Enter your email"
              readonly
            ></b-form-input>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["email"] }}
            </div>
          </b-form-group>
          <b-form-group label="New Password" label-for="input-1">
            <b-form-input
              id="input-1"
              type="password"
              required
              v-model="form.password"
              placeholder="Enter your new password"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Confirm Password" label-for="input-2">
            <b-form-input
              id="input-2"
              type="password"
              required
              v-model="confirmpassword"
              placeholder="Repeat new password"
            ></b-form-input>
          </b-form-group>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
          <button @click="confirmSignUp()" :disabled="verifyLoader">
            <b-spinner v-if="verifyLoader == true" small></b-spinner>
            Submit
          </button>
          <!-- <router-link :to="{ name: 'Login' }"
            ><p>Already have an <b>Account?</b></p></router-link
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from "@/resource/Helper";
import axios from "axios";
export default {
  props: ["items"],
  data() {
    return {
      form: {
        userid: "",
        password: "",
        email_otp: "",
        phone_otp: "",
      },
      confirmpassword: "",
      email: "",
      verifyLoader: false,
      formErrorFlag: false,
      formErrors: [],
    };
  },

  methods: {
    setData() {
      this.email = this.$store.state.memberEmail;
      this.form.userid = this.$store.state.memberId;
      this.form.email_otp = this.$store.state.emailOtp;
      this.form.phone_otp = this.$store.state.phoneOtp;
      if (!this.form.email_otp || !this.form.phone_otp) {
        this.$router.push({ name: "SignUpOtp" });
      }
    },
    confirmSignUp() {
      let app = this;
      let password = app.form.password;
      app.formErrorFlag = false;
      if (app.confirmpassword == "" || password == "") {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Please enter password.";
        return;
      }
      if (app.confirmpassword != password) {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Password mismatch!";
        return;
      }
      if (Helper.validatePassword(password) == false) {
        app.formErrorFlag = true;
        app.formErrors["error"] =
          "Password should be 8 characters long and contain at least 1 uppercase,1 lowercase, number & special character.";
        return;
      }

      app.verifyLoader = true;
      const newAxios = axios.create();
      const url =
        process.env.VUE_APP_CORENROLL_SYSTEM_API_V2 + "auth/sign-up-member";
      newAxios
        .post(url, this.form)
        .then(function (response) {
          if (response.data.status == "success") {
            Helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.$router.push({
              name: "VerificationSuccess",
              params: { memberRegistered: true },
            });
            // Helper.delayRedirect("Login", 0);
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.verifyLoader = false;
        });
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style>
@import "../../assets/css/signup.css";
</style>