<template>
     <div class="content_wrapper">
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <w-9-form-sidebar/>

<div class="right_container">
                   <h3>ADDRESS INFORMATION</h3>
                <div class="form">
                    <div>
                        <label>Street Address1</label><br>
                        <input type="text" name="street_address1" v-model="formFields.street_address1.value" :readonly="formFields.street_address1.read_only == true"  value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['street_address1_message']}}</small>
                    </div>                    
                    
                    <div>
                        <label>Street Address2</label><br>
                        <input type="text" name="street_address2" v-model="formFields.street_address2.value" :readonly="formFields.street_address2.read_only == true"  value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['street_address2_message']}}</small>
                    </div>

                    <div>
                     <label>City</label><br>
                     <input type="text" name="city" v-model="formFields.city.value" :readonly="formFields.city.read_only == true"  value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['city_message']}}</small>
                    </div>
                    
                    <div>
                        <label>State</label><br>
                        <input type="text" name="state" v-model="formFields.state.value" :readonly="formFields.state.read_only == true"  value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['state_message']}}</small>
                    </div>

                    <div>
                        <label>Zip</label><br>
                        <input type="text" name="zip" v-model="formFields.zip.value" :readonly="formFields.zip.read_only == true"  value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['zip_message']}}</small>
                    </div>

                    <div>
                        <label>Requester's name and address (optional)</label><br>
                        <input type="text" name="req_name_address" v-model="formFields.req_name_address.value" :readonly="formFields.req_name_address.read_only == true"  value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['req_name_address_message']}}</small>
                    </div>
                </div> 

                   <div class="button_container">
                        <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                        <div>
                            <input type="checkbox" v-model="toggle" true-value="0" false-value="1">
                            <label for="validation_check"> Continue without validation</label>
                        </div>
                    <div class="button">
                         <button class="next" @click="saveAddressInfo">Next</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

 <style src="../../assets/css/W9_form/address_info.css" scoped>
 </style>
 <script>
import AgentInfo from "../../components/common/AgentInfo"
import W9FormSidebar from '../../components/partial/W9FormSidebar.vue'
import axios from 'axios'
import html2canvas from 'html2canvas';
export default {
    components:{ AgentInfo, W9FormSidebar  },
    data() {
        return {
            tempid: '',
            toggle:'1',
            formFields: {
                street_address1: {
                    value: '',
                    required: true,
                    read_only: false,
                },
                street_address2: {
                    value: '',
                    required: false,
                    read_only: false,
                },
                city: {
                    value: '',
                    required: true,
                    read_only: false,
                },
                state: {
                    value: '',
                    required: true,
                    read_only: false,
                },
                zip: {
                    value: '',
                    required: true,
                    read_only: false,
                },
                req_name_address: {
                    value: '',
                    required: false,
                    read_only: false,
                }
            },
            formErrorFlag: false,
            formErrors: [],
        }
    },
    mounted(){
        let app = this;
        app.tempid= window.localStorage.getItem('tempId');
        app.getAddressInfo();
    },
    methods:{
        getAddressInfo: function () {
            let app = this;
            axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-address-information-w9?', {
            params: {
                tempid: app.tempid
            }
            })
            .then(function (response) {
            let fetchedData = [];
            if (response.data.status == 'success') {
                for(var i in response.data.data.result) {
                    fetchedData.push(response.data.data.result[i]);
                }
                fetchedData.forEach(function(item) {
                    app.formFields[item.field_name].value = item.value;
                    app.formFields[item.field_name].read_only = item.read_only;
                });
            }
            })
            .catch(function (error) {
                if(error.message == 'Form completed.'){
                    window.location = '/contract-form/contract_message/completed';
                }else if(error.message == 'Agent does not exist.'){
                    window.location = '/contract-form/contract_message/not_found';
                } else {
                  window.location = '/contract-form/contract_message/error';
                }
            });
        },
        saveAddressInfo: function () {
            let app = this;
            const el = this.$refs.save;
            (async () => {
              this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'));
            })();
            app.formErrorFlag = false;
            app.formErrors    = [];
            let params = {
            tempid:            app.tempid,                
            street_address1 :  app.formFields.street_address1.value,
            street_address2 :  app.formFields.street_address2.value,
            city :             app.formFields.city.value,
            state :            app.formFields.state.value,
            zip :              app.formFields.zip.value,
            with_validation :  app.toggle,
            requester_name_address :  app.formFields.req_name_address.value,
            };
            console.log(params);
            //discard null values
            for (const key of Object.keys(params)) {
                if (params[key] === null) {
                    delete params[key];
                }
            }

            axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-address-information-w9', params)
            .then(function (response) {
                if (response.data.status == 'success') {
                    console.log("success");
                    // window.localStorage.setItem('tempId', response.data.data.tempid);
                    app.$router.push("taxpayer_id");
                    window.localStorage.setItem('address_complete', 'complete');
                }
            })
            .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                        app.formErrorFlag = true;
                        app.formErrors[item.target_element+'_message'] = item.error_message;
                    });
                }
            });
        },
        saveScreenShot (image) {
            let data = {           
                'agent_id': window.localStorage.getItem('agentId'),
                'level': '0',
                'contract_type': 'w9',
                'image': image,
                'name': 'address',
                "tempid" : this.tempid,
            };
            let config = {
              method: 'post',
              url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
              headers: { },
              data : data
            };

          axios(config)
                  .then(function (response) {
                    if (response.data.status == 'success') {
                      console.log('screenshot saved');
                    }
                  })
                  .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                  });
        },   
        navigatePrevious() {
            //Update APIs
            //Navigate to next page
            let agentid = btoa(window.localStorage.getItem('agentId'));
            this.$router.push("personal_info?id="+agentid);
            window.localStorage.setItem('personal_complete', 'incomplete');
        }, 
    }
}
</script>