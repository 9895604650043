<template>
  <div class="content_section group_information">
                <div class="group_info">
                  <h2>GROUP INFORMATION</h2>
                  <form class="" action="index.html" method="post">
                    <div class="input_box">
                      <label for="">Group Name <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Estimate number of members <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Desired Start Date <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box">
                      <label for="">Group Type <span class="red">*</span></label>
                      <div class="inputs">
                        <select class="" name="">
                          <option value=""> Group Type...</option>
                          <option value="">Employer Group</option>
                          <option value="">Trade/Professional Association</option>
                          <option value="">General Business Association</option>
                          <option value="">PEO (Professional Employer Organization)</option>
                          <option value="">Franchise Association</option>
                        </select>
                      </div>
                    </div>
                    <div class="input_box">
                      <label for="">Federal Tax ID Number (TIN/EIN) <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box">
                      <label for="">Group Industry <span class="red">*</span></label>
                      <div class="inputs">
                        <select class="" name="">
                          <option value="">Choose a State</option>
                          <option value="">Select One</option>
                          <option value="">Select Two</option>
                          <option value="">Select Three</option>
                          <option value="">Select Four</option>
                        </select>
                      </div>
                    </div>
                    <div class="buttonbox">
                      <button @click="navigatePrevious" class="previous" type="button" name="button">Previous</button>
                      <button class="save" type="button" name="button">Save</button>
                      <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
                    </div>
                  </form>
                </div>
              </div>
</template>
<script>
export default {
    name: "GroupInformation",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
