var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_section gp_contribution"
  }, [_c('div', {
    staticClass: "group_info"
  }, [_c('h2', [_vm._v("GROUP CONTRIBUTION")]), _c('form', {
    attrs: {
      "action": "index.html",
      "method": "post"
    }
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "buttonbox"
  }, [_c('button', {
    staticClass: "previous",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Previous")]), _c('button', {
    staticClass: "save",
    attrs: {
      "type": "button",
      "name": "button"
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.saveAndContinue
    }
  }, [_vm._v("Next")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box pmt_mtd"
  }, [_c('div', {
    staticClass: "con_container"
  }, [_c('span', {
    staticClass: "pay_check"
  }, [_c('input', {
    attrs: {
      "type": "radio",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Yes")])]), _c('span', {
    staticClass: "pay_check"
  }, [_c('input', {
    attrs: {
      "type": "radio",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("No")])])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "defined_contribution"
  }, [_c('h3', [_vm._v("Group Defined Contribution Type")]), _c('div', {
    staticClass: "input_box pmt_mtd"
  }, [_c('div', {
    staticClass: "con_container"
  }, [_c('span', {
    staticClass: "pay_check"
  }, [_c('input', {
    attrs: {
      "type": "radio",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Flat Dollar Amount")])]), _c('span', {
    staticClass: "pay_check"
  }, [_c('input', {
    attrs: {
      "type": "radio",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Percentage")])])])]), _c('div', {
    staticClass: "flat_amt"
  }, [_c('div', {
    staticClass: "input_box quater"
  }, [_c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])])]), _c('div', {
    staticClass: "percentage_amt"
  }, [_c('div', {
    staticClass: "input_box quater"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Member "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])]), _c('div', {
    staticClass: "input_box quater"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Member+1 "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])]), _c('div', {
    staticClass: "input_box quater"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Member + Child(ren) "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])]), _c('div', {
    staticClass: "input_box quater"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Family "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])])])]);

}]

export { render, staticRenderFns }