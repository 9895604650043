<template>
  <div class="content_section payment">
    <div class="rep_info">
      <h2>Payment Information</h2>
      <form class="" action="index.html" method="post">
        <div class="pay_type">
          <div class="select_box">
            <span class="pay_label">Pay to :</span>
            <span class="pay_check individual">
              <input type="checkbox" name="" value="">
              <label for="">Individual</label>
            </span>
            <span class="pay_check business">
              <input type="checkbox" name="" value="">
              <label for="">Business</label>
            </span>
            <p class="error_message">This is a required field</p>
          </div>
          <div class="select_box">
            <span class="pay_label">How do you want to be paid ?</span>
            <span class="pay_check check">
              <input type="checkbox" name="" value="">
              <label for="">Check</label>
            </span>
            <span class="pay_check ach">
              <input type="checkbox" name="" value="">
              <label for="">ACH</label>
            </span>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="pay_details">
          <h2>If ACH, please complete the following: <span class="red">*</span></h2>
          <div class="input_box half route">
            <label for="">Routing Number <a class="help_icon" href="#"></a></label>
            <div class="inputs">
              <input type="text" name="" value=""/>
              <p class="error_message">This is a required field</p>
            </div>
          </div>
          <div class="input_box half">
            <label for="">Bank Name<span class="red">*</span></label>
            <div class="inputs">
              <input type="text" name="" value=""/>
              <p class="error_message">This is a required field</p>
            </div>
          </div>
          <div class="input_box half">
            <label for="">Account Number<span class="red">*</span></label>
            <div class="inputs">
              <input type="text" name="" value=""/>
              <p class="error_message">This is a required field</p>
            </div>
          </div>
          <div class="input_box half">
            <label for="">Name of Bank Account<span class="red">*</span></label>
            <div class="inputs">
              <input type="text" name="" value=""/>
              <p class="error_message">This is a required field</p>
            </div>
          </div>
        </div>
        <div class="buttonbox">
          <button @click="navigatePrevious" class="previous" type="button" name="button">Previous</button>
          <button class="save" type="button" name="button">Save</button>
          <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>

export default {
    name: "PaymentInformation",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
