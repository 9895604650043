<template>

  <div class="container_contract_complete">
    <div class="thank_you">
      <div class="thankyou_image message-image">
        <img v-if="value == 'success'" src="../../assets/images/thank_you_pic.svg" alt="thank you">
        <img v-if="value != 'success'" src="../../assets/images/error.svg" alt="Error">
      </div>
      <div class="content message-text">
        <h1><br>
        </h1>
        <p>{{message}}
        </p>
      </div>
    </div>
  </div>

</template>
<script>
    export default {
        data:function(){
            return{
                message: '',
                value: '',
                contract_type:'',
                level:'',
                type:'',
            };
        },
        created(){
            this.value = this.$route.params.type;
            this.level= window.localStorage.getItem('level');
            this.contract_type= window.localStorage.getItem('contract_type');
            this.type = window.localStorage.getItem('type');
            if(this.value == 'not_found') {
                this.message = "The requested Agent Id does not exist.";
            }
            else if(this.value == 'contract_not_found') {
                this.message = "The requested Contract does not exist.";
            }else if(this.value == 'completed') {
                this.message = `The requested Agent has already completed the contract.`;
            }else if(this.value == 'success') {
                this.message = "Congratulations!! Your contract has been completed successfully.";
            }else if(this.value == 'error') {
                this.message = "Error, Please Try Again Later.";
            }

        }
    }
</script>
<style scoped>
.container_contract_complete{
    padding:10rem 0 5rem 0;
    background: transparent linear-gradient(180deg,#0cf,#1597b8 49%,#0567bc) 0 0;
    font-family:"SF UI Display Regular";
    transition: all ease 0.6s;
  }
  .container_contract_complete .thank_you{
    background:#fff;
    padding:50px 0px;
    border-radius: 10px;
    width: 760px;
    margin: auto;
    box-shadow: 0 25px 30px rgb(0 0 0 / 16%);
  }
  .thankyou_image img{
    width:100%;
  }
  .content h1{
    color:#707070;
    line-height:65px;
    font-size:18px;
    font-family:"SF UI Display Regular"
  }
   .content p{
     color:#707070;
    font-size:20px;
    font-family:"SF UI Display Regular"
  }
  .message-image{
    width: 50%;
    text-align: center;
    margin: auto;;
  }
  .message-text{
    text-align: center;
  }
  @media only screen and (max-width:991px){
    
  }
  @media only screen and (max-width:849px){
    
  }
  @media only screen and (max-width:767px){
    
    .container_contract_complete{
      padding: 10rem 15px 5rem 15px;
    }
    .container_contract_complete .thank_you{
      width: 100%;
    }
    .message-image{
      width: 75%;
    }
    .content h1{
      line-height:35px;
    }
  }
  @media only screen and (max-width:479px){
    .container_contract_complete .thank_you{
      padding:15px;
    }
    .thankyou_image img{
        max-width:240px;
      }
  }
</style>