<template>
  <div :class="showPageRecord ? 'd-flex justify-content-between align-items-center':''">
    <div class="page-record" v-if="from && to && total && showPageRecord" >
      <span>Showing {{ from }} to {{ to }} of {{ total }} results</span>
    </div>
    <div class="table-pagination" v-if="items.total_records > perPage">
      <b-pagination
        v-if="items.total_records > 0"
        v-model="items.current_page"
        :total-rows="items.total_records"
        :per-page="perPage"
        align="right"
        prev-text="Prev"
        next-text="Next"
        first-text="First"
        last-text="Last"
        @change="handlePageChange"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import Helper from "@/resource/Helper";
import axios from "axios";
export default {
  name: "Pagination",
  props: {
    rows: {
      required: true,
    },
    filters: {
      default() {
        return {};
      },
    },
    showPageRecord:{
      type:Boolean,
      default:true
    }
  },

  data() {
    return {
      items: [],
      filterParams: {},
    };
  },
  methods: {
    handlePageChange(page) {
      let url, pageSize;
      pageSize = this.filters.page_size ? this.filters.page_size : this.perPage;
      url = `${this.items.path}?page_size=${pageSize}&page=${page}`;
      this.getData(url);
    },
    async getData(url) {
      if (this.filterQuery) {
        url = `${url}&${this.filterQuery}`;
      }
      await axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.$emit("update:rows", data);
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => {
        });
    },
  },
  mounted() {
    this.$root.$on("searchEvent", (data) => {
      this.filterParams = data;
      this.handlePageChange(1);
    });
  },
  computed: {
    perPage() {
      return this.items.per_page ? this.items.per_page : 10;
    },
    filterQuery() {
      return Helper.objToUrlParams(this.filterParams);
    },
    from() {
      return this.items.record_range ? this.items.record_range[0] : "";
    },
    to() {
      return this.items.record_range ? this.items.record_range[1] : "";
    },
    total() {
      return this.items.total_records ? this.items.total_records : "";
    }
  },
  created() {
    this.items = this.rows;
    this.filterParams = this.filters;
  },
  watch: {
    rows(value) {
      this.items = value;
    },
    filters(value) {
      this.filterParams = value;
    },
  },
};
</script>

<style >
.page-link {
  border: none !important;
  color: #778699 !important;
}
/* .page-item:last-child .page-link{
color:#29bcff !important;
} */
.page-item.active .page-link {
  background-color: unset !important;
  color: #29bcff !important;
  border-bottom: 3px solid #29bcff !important;
}
.page-item .page-link:focus {
  box-shadow: unset !important;
}

.pagination ul li {
  font-size: 13px;
}

.table-pagination {
  margin-top: -20px;
}

.pagination {
  margin-top: 30px !important;
}

@media (max-width: 499px) {
  .pagination {
    display: inline;
  }
}
</style>
<style scoped>
.page-record {
  font-size: 14px;
}
</style>