<template>
    <div class="content_wrapper" ref="save">
         <agent-info></agent-info>
        <div class="contract_content">
            <w-9-form-sidebar/>

            <div class="right_container">
                   
                   <h3>Specific Instruction</h3>
                   <div class="description">
                        <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-1 variant="info" style="text-align:left;font-size:18px;">Form Instructions
            <!-- <strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:auto;"> 
                    <h4>Line 1</h4>
                    <p>You must enter one of the following on this line; <b>do not</b> leave this line blank. The name
                        should match the name on your tax return.</p>
    
                    <p>If this Form W-9 is for a joint account (other than an account maintained by a foreign financial
                        institution (FFI)), list first, and then circle, the name of the person or entity whose number you
                        entered in Part I of Form W-9. If you are providing Form W-9 to an FFI to document a joint account,
                        each holder of the account that is a U.S. person must provide a Form W-9.  </p>
    
                    <ol type="a">
                        <li><b>Individual.</b> Generally, enter the name shown on your tax return. If you have changed your
                            last name without informing the Social Security Administration (SSA) of the name change, enter
                            your first name, the last name as shown on your social security card, and your new last
                            name.<br>
                            <span style="color:#0594b8"><b>Note: ITIN applicant:</b> Enter your individual name as it was entered on your Form W-7
                            application, line 1a. This should also be the same as the name you entered on the Form
                            1040/1040A/1040EZ you filed with your application.</span></li>
    
                        <li><b>Sole proprietor or single-member LLC.</b> Enter your individual name as shown on your
                            1040/1040A/1040EZ on line 1. You may enter your business, trade, or “doing business as” (DBA)
                            name on line 2.</li>
    
                        <li><b>Partnership, LLC that is not a single-member LLC, C corporation, or S corporation.</b> Enter
                            the entity's name as shown on the entity's tax return on line 1 and any business, trade, or DBA
                            name on line 2.</li>
    
                        <li><b>Other entities.</b> Enter your name as shown on required U.S. federal tax documents on line
                            1. This name should match the name shown on the charter or other legal document creating the
                            entity. You may enter any business, trade, or DBA name on line 2.</li>
    
                        <li><b>Disregarded entity.</b> For U.S. federal tax purposes, an entity that is disregarded as an
                            entity separate from its owner is treated as a “disregarded entity.” See Regulations section
                            301.7701-2(c)(2)(iii). Enter the owner's name on line 1. The name of the entity entered on line
                            1 should never be a disregarded entity. The name on line 1 should be the name shown on the
                            income tax return on which the income should be reported. For example, if a foreign LLC that is
                            treated as a disregarded entity for U.S. federal tax purposes has a single owner that is a U.S.
                            person, the U.S. owner's name is required to be provided on line 1. If the direct owner of the
                            entity is also a disregarded entity, enter the first owner that is not disregarded for federal
                            tax purposes. Enter the disregarded entity's name on line 2, “Business name/disregarded entity
                            name.” If the owner of the disregarded entity is a foreign person, the owner must complete an
                            appropriate Form W-8 instead of a Form W-9. This is the case even if the foreign person has a
                            U.S. TIN.</li>
    
                    </ol>
    
                    <h4>Line 2</h4>
                    <p>If you have a business name, trade name, DBA name, or disregarded entity name, you may enter it on
                        line 2.</p>
                    <h4>Line 3</h4>
                    <p>Check the appropriate box on line 3 for the U.S. federal tax classification of the person whose
                        name is entered on line 1. Check only one box on line 3.</p>
                  

                    <table>
                        <tr>
                            <th>IF the entity/person on line 1 is <br>a(n) . . .</th>
                            <th>THEN check the box for . . .</th>
                        </tr>
    
                        <tr>
    
                            <td>
                                <ul>
                                    <li>Corporation</li>
                                </ul>
                            </td>
    
                            <td>Corporation</td>
                        </tr>
    
                        <tr>
                            <td>
                                <ul>
                                    <li>Individual</li>
                                    <li>Sole proprietorship, or</li>
                                    <li>Single-member limited liability company (LLC) owned by an individual and disregarded
                                        for U.S. federal tax purposes.</li>
                                </ul>
                            </td>
                            <td>
                                Individual/sole proprietor or single-member LLC
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>LLC treated as a partnership for U.S. federal tax purposes,</li>
                                    <li>LLC that has filed Form 8832 or 2553 to be taxed as a corporation, or</li>
                                    <li>LLC that is disregarded as an entity separate from its owner but the owner is
                                        another LLC that is not disregarded for U.S. federal tax purposes.</li>
                                </ul>
                            </td>
                            <td>Limited liability company and enter the appropriate tax classification. (P= Partnership; C=
                                C corporation; or S= S corporation)</td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Partnership</li>
                                </ul>
                            </td>
                            <td>Partnership</td>
                        </tr>
    
    
                        <tr>
                            <td>
                                <ul>
                                    <li>Trust/estate</li>
                                </ul>
                            </td>
                            <td>Trust/estate</td>
                        </tr>
    
    
                    </table>
    
    
    
                    <h4>Line 4, Exemptions</h4>
                    <p>If you are exempt from backup withholding and/or FATCA reporting, enter in the appropriate space
                        on line 4 any code(s) that may apply to you.</p>
    
                    <b>Exempt payee code.</b>
                    <ul>
                        <li>Generally, individuals (including sole proprietors) are not exempt from backup withholding.</li>
                        <li>Except as provided below, corporations are exempt from backup withholding for certain payments,
                            including interest and dividends.</li>
                        <li>Corporations are not exempt from backup withholding for payments made in settlement of payment
                            card or third party network transactions.</li>
                        <li>Corporations are not exempt from backup withholding with respect to attorneys’ fees or gross
                            proceeds paid to attorneys, and corporations that provide medical or health care services are
                            not exempt with respect to payments reportable on Form 1099-MISC.</li>
                    </ul>
    
                    <p>The following codes identify payees that are exempt from backup withholding. Enter the appropriate
                        code in the space in line 4.
                    <ol>
                        <li>-An organization exempt from tax under section 501(a), any IRA, or a custodial account under
                            section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)</li>
                        <li>-The United States or any of its agencies or instrumentalities</li>
                        <li>-A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political
                            subdivisions or instrumentalities</li>
                        <li>-A foreign government or any of its political subdivisions, agencies, or instrumentalities</li>
                        <li>-A corporation</li>
                        <li>-A dealer in securities or commodities required to register in the United States, the District
                            of Columbia, or a U.S. commonwealth or possession</li>
                        <li>A futures commission merchant registered with the Commodity Futures Trading Commission</li>
                        <li>-A real estate investment trust</li>
                        <li>-An entity registered at all times during the tax year under the Investment Company Act of 1940
                        </li>
                        <li>-A common trust fund operated by a bank under section 584(a)</li>
                        <li>-A financial institution</li>
                        <li>-A middleman known in the investment community as a nominee or custodian</li>
                        <li>-A trust exempt from tax under section 664 or described in section 4947</li>
                    </ol>
                    </p>
    
                    <p>The following chart shows types of payments that may be exempt
                        from backup withholding. The chart applies to the exempt payees listed
                        above, 1 through 13.
                    <table>
                        <tr>
                            <th>IF the payment is for . . .</th>
                            <th>THEN the payment is exempt
                                for . . .</th>
                        </tr>
                        <tr>
                            <td>Interest and dividend payments</td>
                            <td>All exempt payees except
                                for 7</td>
                        </tr>
                        <tr>
                            <td>Broker transactions</td>
                            <td>Exempt payees 1 through 4 and 6
                                through 11 and all C corporations.
                                S corporations must not enter an
                                exempt payee code because they
                                are exempt only for sales of
                                noncovered securities acquired
                                prior to 2012. </td>
                        </tr>
                        <tr>
                            <td>Barter exchange transactions and
                                patronage dividends</td>
                            <td>Exempt payees 1 through 4</td>
                        </tr>
                        <tr>
                            <td>Payments over $600 required to be
                                reported and direct sales over
                                $5,000<sup>1</sup></td>
                            <td>Generally, exempt payees
                                1 through 5<sup>2</sup></td>
                        </tr>
                        <tr>
                            <td>Payments made in settlement of
                                payment card or third party network
                                transactions </td>
                            <td>Exempt payees 1 through 4</td>
                        </tr>
                    </table>
    
                    <sup>1</sup>See Form 1099-MISC, Miscellaneous Income, and its instructions.
                    <sup>2</sup>However, the following payments made to a corporation and
                    reportable on Form 1099-MISC are not exempt from backup
                    withholding: medical and health care payments, attorneys’ fees, gross
                    proceeds paid to an attorney reportable under section 6045(f), and
                    payments for services paid by a federal executive agency.
                    </p>
                    <p><b>Exemption from FATCA reporting code.</b>The following codes identify
                        payees that are exempt from reporting under FATCA. These codes
                        apply to persons submitting this form for accounts maintained outside
                        of the United States by certain foreign financial institutions. Therefore, if
                        you are only submitting this form for an account you hold in the United
                        States, you may leave this field blank. Consult with the person
                        requesting this form if you are uncertain if the financial institution is
                        subject to these requirements. A requester may indicate that a code is
                        not required by providing you with a Form W-9 with “Not Applicable” (or
                        any similar indication) written or printed on the line for a FATCA
                        exemption code.</p>
                    <ol type="A">
                        <li>-An organization exempt from tax under section 501(a) or any
                            individual retirement plan as defined in section 7701(a)(37) </li>
                        <li>-The United States or any of its agencies or instrumentalities</li>
                        <li>-A state, the District of Columbia, a U.S. commonwealth or
                            possession, or any of their political subdivisions or instrumentalities </li>
                        <li>-A corporation the stock of which is regularly traded on one or
                            more established securities markets, as described in Regulations
                            section 1.1472-1(c)(1)(i) </li>
                        <li> -A corporation that is a member of the same expanded affiliated
                            group as a corporation described in Regulations section 1.1472-1(c)(1)(i)</li>
                        <li> -A dealer in securities, commodities, or derivative financial
                            instruments (including notional principal contracts, futures, forwards,
                            and options) that is registered as such under the laws of the United
                            States or any state</li>
                        <li>-A real estate investment trust
                        </li>
                        <li> -A regulated investment company as defined in section 851 or an
                            entity registered at all times during the tax year under the Investment
                            Company Act of 1940</li>
                        <li> -A common trust fund as defined in section 584(a)</li>
                        <li> -A bank as defined in section 581</li>
                        <li> -A broker</li>
                        <li>-A trust exempt from tax under section 664 or described in section
                            4947(a)(1)
                        </li>
                        <li>-A tax exempt trust under a section 403(b) plan or section 457(g)
                            plan</li>
                    </ol>
             
                    <p style="color:#0594b8"><b>Note:</b>You may wish to consult with the financial institution requesting
                        this form to determine whether the FATCA code and/or exempt payee
                        code should be completed.</p>
    
                    <h4>Line 5</h4>
                    <p>Enter your address (number, street, and apartment or suite number).
                        This is where the requester of this Form W-9 will mail your information
                        returns. If this address differs from the one the requester already has on
                        file, write NEW at the top. If a new address is provided, there is still a
                        chance the old address will be used until the payor changes your
                        address in their records.</p>
    
                    <h4>Line 6</h4>
                    <p>Enter your city, state, and ZIP code.</p>
         </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

     <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-2 variant="info" style="text-align:left;font-size:18px;">Part I. Taxpayer Identification Number (TIN)
            <!-- <strong v-if="isNotVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" >
        <b-card-body>
            <b-card-text style="height:190px;overflow-y:scroll;">
                    <h2>Part I. Taxpayer Identification Number (TIN)</h2>
    
                    <p><b>Enter your TIN in the appropriate box. </b> If you are a resident alien and
                        you do not have and are not eligible to get an SSN, your TIN is your IRS
                        individual taxpayer identification number (ITIN). Enter it in the social
                        security number box. If you do not have an ITIN, see How to get a TIN
                        below.</p>
    
                    <p>If you are a sole proprietor and you have an EIN, you may enter either
                        your SSN or EIN. </p>
    
                    <p>If you are a single-member LLC that is disregarded as an entity
                        separate from its owner, enter the owner’s SSN (or EIN, if the owner has
                        one). Do not enter the disregarded entity’s EIN. If the LLC is classified as
                        a corporation or partnership, enter the entity’s EIN.</p>
    
                    <p style="color:#0594b8"><b>Note:</b>See What Name and Number To Give the Requester, later, for
                        further clarification of name and TIN combinations.</p><br>
    
                    <p><b>How to get a TIN.</b> If you do not have a TIN, apply for one immediately.
                        To apply for an SSN, get Form SS-5, Application for a Social Security
                        Card, from your local SSA office or get this form online at <a href="www.SSA.gov">
                            <i>www.SSA.gov.</i></a> You may also get this form by calling 1-800-772-1213.
                            Use Form W-7, Application for IRS Individual Taxpayer Identification
                            Number, to apply for an ITIN, or Form SS-4, Application for Employer
                            Identification Number, to apply for an EIN. You can apply for an EIN
                            online by accessing the IRS website at <a
                                href="www.irs.gov/Businesses"><i>www.irs.gov/Businesses</i></a> clicking on Employer Identification
                            Number (EIN) under Starting a
                            Business. Go to <a href=" www.irs.gov/OrderForms"> <i>www.irs.gov/OrderForms</i></a> to
                            place an order and have Form W-7 and/or SS-4 mailed to you within 10
                            business days.</p>
    
                    <p> If you are asked to complete Form W-9 but do not have a TIN, apply
                        for a TIN and write “Applied For” in the space for the TIN, sign and date
                        the form, and give it to the requester. For interest and dividend
                        payments, and certain payments made with respect to readily tradable
                        instruments, generally you will have 60 days to get a TIN and give it to
                        the requester before you are subject to backup withholding on
                        payments. The 60-day rule does not apply to other types of payments.
                        You will be subject to backup withholding on all such payments until
                        you provide your TIN to the requester.</p>
                    <p style="color:#0594b8"> <b>Note:</b> Entering “Applied For” means that you have already applied for a
                        TIN or that you intend to apply for one soon.</p>
                    <p><b>Caution:</b> A disregarded U.S. entity that has a foreign owner must use
                        the appropriate Form W-8.
                    </p>
                    </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 
    
      <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-3 variant="info" style="text-align:left;font-size:18px;">Part II. Certification
            <!-- <strong v-if="isNotVisible==true" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:scroll;">
                    <h2>Part II. Certification</h2>
    
                    <p>To establish to the withholding agent that you are a U.S. person, or
                        resident alien, sign Form W-9. You may be requested to sign by the
                        withholding agent even if item 1, 4, or 5 below indicates otherwise.</p>
    
                    <p>For a joint account, only the person whose TIN is shown in Part I
                        should sign (when required). In the case of a disregarded entity, the
                        person identified on line 1 must sign. Exempt payees, see <i>Exempt payee
                            code</i>, earlier.</p>
                    <p><b>Signature requirements.</b> Complete the certification as indicated in
                        items 1 through 5 below.</p>
               
                    <ol>
                        <li> <span>Interest, dividend, and barter exchange accounts opened
                            before 1984 and broker accounts considered active during 1983.</span>
                           You must give your correct TIN, but you do not have to sign the
                            certification.</li>
    
                            <li>
                                <span>Interest, dividend, broker, and barter exchange accounts
    opened after 1983 and broker accounts considered inactive during
    1983. </span>You must sign the certification or backup withholding will apply. If
        you are subject to backup withholding and you are merely providing
        your correct TIN to the requester, you must cross out item 2 in the
        certification before signing the form.
                            </li>
                            <li><span> Real estate transactions.
                            </span>You must sign the certification. You may
                                    cross out item 2 of the certification.
                            </li>
                            <li><span>Other payments.</span>
                                You must give your correct TIN, but you do not
                                    have to sign the certification unless you have been notified that you
                                    have previously given an incorrect TIN. “Other payments” include
                                    payments made in the course of the requester’s trade or business for
                                    rents, royalties, goods (other than bills for merchandise), medical and
                                    health care services (including payments to corporations), payments to
                                    a nonemployee for services, payments made in settlement of payment
                                    card and third party network transactions, payments to certain fishing
                                    boat crew members and fishermen, and gross proceeds paid to
                                    attorneys (including payments to corporations).
                            </li>
                            <li><span>Mortgage interest paid by you, acquisition or abandonment of
                                secured property, cancellation of debt, qualified tuition program
                                payments (under section 529), ABLE accounts (under section 529A),
                                IRA, Coverdell ESA, Archer MSA or HSA contributions or
                                distributions, and pension distributions.</span> You must give your correct
                                    TIN, but you do not have to sign the certification.</li>
                    </ol>
                     </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 
                    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-4 variant="info" style="text-align:left;font-size:18px;">Name and Number To Give the Requester
            <!-- <strong v-if="isNotVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" >
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:auto;">
    
                    <h2>What Name and Number To Give the Requester</h2>
                    <table>
                        <tr>
                            <th>For this type of account:</th>
                            <th>Give name and SSN of:</th>
                        </tr>
                        <tr>
                            <td>1. Individual</td>
                            <td> The Individual</td>
                        </tr>
                        <tr>
                            <td>2. Two or more individuals (joint
                                account) other than an account
                                maintained by an FFI</td>
                            <td>The actual owner of the account or, if
                                combined funds, the first individual on
                                the account<sup>1</sup></td>
                        </tr>
                        <tr>
                            <td>3.  Two or more U.S. persons
                                (joint account maintained by an FFI)</td>
                            <td>Each holder of the account</td>
                        </tr>
                        <tr>
                            <td>4. Custodial account of a minor
                                (Uniform Gift to Minors Act)</td>
                                <td>The minor <sup>2</sup></td>
                        </tr>
                        <tr>
                            <td>5. a.
                                The usual revocable savings trust
                                    (grantor is also trustee) 
                            </td>
                            <td>The grantor-trustee <sup>1</sup></td>
                        </tr>
                        <tr>
                            <td>b. So-called trust account that is not
                                a legal or valid trust under state law</td>
                                <td>The actual owner <sup>1</sup></td>
                        </tr>
                        <tr>
                            <td>6. Sole proprietorship or disregarded
                                entity owned by an individual</td>
                                <td>The owner <sup>3</sup></td>
                        </tr>
                        <tr>
                            <td>7. Grantor trust filing under Optional
                                Form 1099 Filing Method 1 (see
                                Regulations section 1.671-4(b)(2)(i)
                                (A))</td>
                                <td>The grantor*</td>
                        </tr>
                        <tr>
                            <th>For this type of account:</th>
                            <th>Give name and EIN of:</th>
                        </tr>
                        <tr>
                            <td>8. Disregarded entity not owned by an
                                individua</td>
                            <td>The owner</td>
                        </tr>
                        <tr>
                            <td>9. A valid trust, estate, or pension trust</td>
                            <td>Legal entity <sup>4</sup></td>
                        </tr>
                        <tr>
                            <td>10. Corporation or LLC electing
                                corporate status on Form 8832 or
                                Form 2553</td>
                            <td>The corporation</td>
                        </tr>
                        <tr>
                            <td>11. Association, club, religious,
                                charitable, educational, or other taxexempt organization</td>
                            <td>The organization</td>
                        </tr>
                        <tr>
                            <td>12. Partnership or multi-member LLC</td>
                            <td>The partnership</td>
                        </tr>
                        <tr>
                            <td>13. A broker or registered nominee</td>
                            <td>The broker or nominee</td>
                        </tr>
                    </table>
                
                    <table>
                        <tr>
                            <th>For this type of account:</th>
                            <th>Give name and EIN of:</th>
                        </tr>
                        <tr>
                            <td>14. Account with the Department of
                                Agriculture in the name of a public
                                entity (such as a state or local
                                government, school district, or
                                prison) that receives agricultural
                                program payments</td>
                            <td>The public entity</td>
                        </tr>
                        <tr>
                            <td>15. Grantor trust filing under the Form
                                1041 Filing Method or the Optional
                                Form 1099 Filing Method 2 (see
                                Regulations section 1.671-4(b)(2)(i)(B))</td>
                            <td>The trust</td>
                        </tr>
                    </table>
                    <p><sup>1</sup> List first and circle the name of the person whose number you furnish.
                        If only one person on a joint account has an SSN, that person’s number
                        must be furnished.
                        </p>
    
                    <p><sup>2</sup> Circle the minor’s name and furnish the minor’s SSN.</p>
    
                    <p><sup>3</sup>You must show your individual name and you may also enter your
                        business or DBA name on the “Business name/disregarded entity”
                        name line. You may use either your SSN or EIN (if you have one), but the
                        IRS encourages you to use your SSN.</p>
    
                    <p><sup>4</sup>List first and circle the name of the trust, estate, or pension trust. (Do
                        not furnish the TIN of the personal representative or trustee unless the
                        legal entity itself is not designated in the account title.) Also see <i>Special
                        rules for partnerships</i>, earlier.</p>
    
                        <p style="color:#0594b8"><b>*Note:</b>  The grantor also must provide a Form W-9 to trustee of trust</p>
    
                        <p style="color:#0594b8"><b>Note:</b>  If no name is circled when more than one name is listed, the
                            number will be considered to be that of the first name listed.</p>
                            </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 
    
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-5 variant="info" style="text-align:left;font-size:18px;">Secure Tax Records
            <!-- <strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" >
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:auto;">
                            <h2>Secure Your Tax Records From Identity Theft</h2>
                        <p>Identity theft occurs when someone uses your personal information
                            such as your name, SSN, or other identifying information, without your
                            permission, to commit fraud or other crimes. An identity thief may use
                            your SSN to get a job or may file a tax return using your SSN to receive
                            a refund.</p>
    
                            <p> To reduce your risk:
                                <ul>
                                    <li> Protect your SSN,</li>
                                    <li> Ensure your employer is protecting your SSN, and</li>
                                    <li>Be careful when choosing a tax preparer</li>
                                </ul>
                            </p>
    
                            <p>If your tax records are affected by identity theft and you receive a
                                notice from the IRS, respond right away to the name and phone number
                                printed on the IRS notice or letter.</p>
    
                                <p>If your tax records are not currently affected by identity theft but you
                                    think you are at risk due to a lost or stolen purse or wallet, questionable
                                    credit card activity or credit report, contact the IRS Identity Theft Hotline
                                    at 1-800-908-4490 or submit Form 14039.
                                    </p>
    
                                    <p>For more information, see Pub. 5027, Identity Theft Information for
                                        Taxpayers.</p>
    
                                        <p>Victims of identity theft who are experiencing economic harm or a
                                            systemic problem, or are seeking help in resolving tax problems that
                                            have not been resolved through normal channels, may be eligible for
                                            Taxpayer Advocate Service (TAS) assistance. You can reach TAS by
                                            calling the TAS toll-free case intake line at 1-877-777-4778 or TTY/TDD
                                            1-800-829-4059.</p>
    
                                            <h4>Protect yourself from suspicious emails or phishing schemes. </h4>
                                            <p>Phishing is the creation and use of email and websites designed to
                                                mimic legitimate business emails and websites. The most common act
                                                is sending an email to a user falsely claiming to be an established
                                                legitimate enterprise in an attempt to scam the user into surrendering
                                                private information that will be used for identity theft.</p>
    
                
                <p>The IRS does not initiate contacts with taxpayers via emails. Also, the
                    IRS does not request personal detailed information through email or ask
                    taxpayers for the PIN numbers, passwords, or similar secret access
                    information for their credit card, bank, or other financial accounts.</p>
    
                    <p>If you receive an unsolicited email claiming to be from the IRS,
                        forward this message to <a href=" phishing@irs.gov"><i>phishing@irs.gov</i></a>. You may also report misuse
                        of the IRS name, logo, or other IRS property to the Treasury Inspector
                        General for Tax Administration (TIGTA) at 1-800-366-4484. You can
                        forward suspicious emails to the Federal Trade Commission at
                        <a href="spam@uce.gov"><i>spam@uce.gov</i></a> or report them at <a href="www.ftc.gov/complaint"><i>www.ftc.gov/complaint</i></a>. You can
                        contact the FTC at <a href="www.ftc.gov/idtheft"><i>www.ftc.gov/idtheft</i></a> or 877-IDTHEFT (877-438-4338).
                        If you have been the victim of identity theft, see <a href="www.IdentityTheft.gov"><i>www.IdentityTheft.gov</i></a>
                        and Pub. 5027.</p>
    
                        <p>Visit <a href="www.irs.gov/IdentityTheft"><i>www.irs.gov/IdentityTheft</i></a> to learn more about identity theft and
                            how to reduce your risk.</p>
         </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-6 variant="info" style="text-align:left;font-size:18px;">Penalties
            <!-- <strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" >
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:auto;">

                            <h2>Privacy Act Notice</h2>
                            <p>Section 6109 of the Internal Revenue Code requires you to provide your
                                correct TIN to persons (including federal agencies) who are required to
                                file information returns with the IRS to report interest, dividends, or
                                certain other income paid to you; mortgage interest you paid; the
                                acquisition or abandonment of secured property; the cancellation of
                                debt; or contributions you made to an IRA, Archer MSA, or HSA. The
                                person collecting this form uses the information on the form to file
                                information returns with the IRS, reporting the above information.
                                Routine uses of this information include giving it to the Department of
                                Justice for civil and criminal litigation and to cities, states, the District of
                                Columbia, and U.S. commonwealths and possessions for use in
                                administering their laws. The information also may be disclosed to other
                                countries under a treaty, to federal and state agencies to enforce civil
                                and criminal laws, or to federal law enforcement and intelligence
                                agencies to combat terrorism. You must provide your TIN whether or
                                not you are required to file a tax return. Under section 3406, payers
                                must generally withhold a percentage of taxable interest, dividend, and
                                certain other payments to a payee who does not give a TIN to the payer.
                                Certain penalties may also apply for providing false or fraudulent
                                information.</p>
                                 </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 
                   </div>
                  
                   <div class="button_container">
                    <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                    <div class="button">
                        <button class="save" @click="saveSpecificInstruction">Save</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<style src="../../assets/css/W9_form/specific.css" scoped>
 </style>
 <script>
import AgentInfo from "../../components/common/AgentInfo";
import W9FormSidebar from '../../components/partial/W9FormSidebar.vue';
import html2canvas from 'html2canvas';
import axios from 'axios';
export default {
    components:{ AgentInfo, W9FormSidebar  },
    data() {
        return {
            tempid:''
        }
    },
    mounted() {
        let app = this;
        app.tempid= window.localStorage.getItem('tempId');
    },
    methods:{
        saveSpecificInstruction: function () {
            let app = this;
            const el = app.$refs.save;
            (async () => {
            app.saveScreenShot((await html2canvas(el)).toDataURL('image/png'));
            })();

            let params = {
                tempid : app.tempid
            };
            //discard null values
            for (const key of Object.keys(params)) {
                if (params[key] === null) {
                    delete params[key];
                }
            }

            axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-w9', params)
            .then(function (response) {
                if (response.data.status == 'success') {
                    console.log("success");
                    app.generatePdf();
                    // window.localStorage.setItem('tempId', response.data.data.tempid);
                    // app.$router.push("general_instruction");
                        window.localStorage.setItem('specific_complete', 'complete');
                        window.localStorage.clear();
                    
                } else {
                    window.location = '/contract-form/contract_message/error';                    
                }
            })
            .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                        app.formErrorFlag = true;
                        app.formErrors[item.target_element+'_message'] = item.error_message;
                    });
                }
            });
        },
        navigatePrevious() {
          this.$router.push("general_instruction");
          window.localStorage.setItem('general_complete', 'incomplete');
        },
        saveScreenShot (image) {
            let data = {           
                'agent_id': window.localStorage.getItem('agentId'),
                'level': '0',
                'contract_type': 'w9',
                'image': image,
                'name': 'specific',
                "tempid" : this.tempid,
            };
            let config = {
              method: 'post',
              url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
              headers: { },
              data : data
            };

          axios(config)
                  .then(function (response) {
                    if (response.data.status == 'success') {
                      console.log('screenshot saved');
                    }
                  })
                  .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                  });
        }, 
        generatePdf(){
            let app = this;
            var url;
            var baseUrl = window.location.origin;
            console.log(baseUrl);
            url = '/contractTemplate/w9/'+app.tempid;
            window.location= url;
        }
    }
}
</script>