var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-xl-10"
  }, [false ? _c('div', {
    staticClass: "notice"
  }, [_c('h2', [_vm._v("Open Enrollment Plan renewal Alert")]), _c('p', [_vm._v(" Your plan renews Jan 1, 2024, you can keep your existing plan or choose to change to another plan. Deadline to change is 12/10/2023. If you do not choose a new plan, your current plan will renew at the renewal rate. ")]), _c('ul', [_c('dashboard-enrollment-current-plan', {
    attrs: {
      "current-plan": _vm.currentPlan
    },
    on: {
      "update:currentPlan": function ($event) {
        _vm.currentPlan = $event;
      },
      "update:current-plan": function ($event) {
        _vm.currentPlan = $event;
      }
    }
  }), _c('dashboard-enrollment-renew-plan', {
    attrs: {
      "current-plan": _vm.currentPlan
    },
    on: {
      "update:currentPlan": function ($event) {
        _vm.currentPlan = $event;
      },
      "update:current-plan": function ($event) {
        _vm.currentPlan = $event;
      },
      "onSwitchedPlan": function ($event) {
        return _vm.getCurrentNewPlan();
      }
    }
  })], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }