var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "activate_form",
      "centered": true,
      "title": "Plan Re-activation",
      "no-close-on-backdrop": ""
    },
    on: {
      "hide": _vm.resetFormFields
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          staticClass: "float-right",
          attrs: {
            "variant": "secondary",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              return _vm.resetFormFields();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          staticClass: "float-right",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": _vm.reActivatePlan
          }
        }, [_vm.formSubmitted == true ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm._v(" Submit ")], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('strong', [_vm._v(" Plan Detail:")]), _c('span', {
    staticClass: "text-secondary"
  }, [_vm._v(" " + _vm._s(_vm.plan_detail) + " ")])]), _c('InputTextArea', {
    attrs: {
      "rules": {
        required: true
      },
      "label": "Re-activation Reason",
      "label-for": "activate-reason",
      "vmodel": _vm.form.reason,
      "input-class": "shadow-none",
      "vObject": _vm.$v.form.reason
    },
    on: {
      "update:vmodel": function ($event) {
        return _vm.$set(_vm.form, "reason", $event);
      }
    }
  }), _c('div', {
    staticClass: "signature"
  }, [_c('div', {
    staticClass: "signature_title"
  }, [_c('p', [_vm._v(" Application Signature (Required) "), _c('span', [_vm._v("(Sign Name using your mouse or touch screen in the box below)")])])]), _c('signature-pad', {
    on: {
      "sign": _vm.getSign
    }
  }), _vm.signError ? _c('div', {
    staticClass: "invalid-feedback",
    staticStyle: {
      "display": "block"
    }
  }, [_vm._v(" Sign is required. ")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }