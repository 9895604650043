var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "alert-popup1",
      "centered": "",
      "title": "Your Current Plan",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "alert-popup"
  }, [_c('p', {
    staticClass: "mb-4 text-center"
  }, [_vm._v("Do you want to confirm below plans?")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Plans")]), _c('th', [_vm._v("Renewal Rate")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.currentPlan.web_display_name))]), _c('td', [_vm._v(_vm._s(_vm._f("currency")(_vm.currentPlan.price)))])])])])]), _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "accept-btn",
    on: {
      "click": _vm.keepPlan
    }
  }, [_vm._v(" " + _vm._s(_vm.isKeep ? "Accepting..." : "Accept") + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }