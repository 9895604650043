var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "footer"
    }
  }, [_c('div', {
    staticClass: "footer-new"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "about"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'DashboardNew'
      }
    }
  }, [_c('img', {
    staticClass: "footer_logo",
    attrs: {
      "src": require("@/assets/images/corenroll-logo.png"),
      "alt": "logo"
    }
  })]), _vm._m(0), _c('br'), _c('span', [_vm._v("© Copyright " + _vm._s(new Date().getFullYear()) + ". All Rights Reserved")])], 1)]), _vm._m(1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" If you have some technical difficulties Go to: "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "http://tickets.purenroll.com/"
    }
  }, [_vm._v(" Issues Dashboard")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "download"
  }, [_c('span', [_vm._v("Download The Member App")]), _c('div', {
    staticClass: "app_download"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://apps.apple.com/us/app/corenroll-member/id1539841827"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/applestore.png"),
      "alt": "apple",
      "width": "162",
      "height": "52"
    }
  })]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://play.google.com/store/apps/details?id=com.neura.corenroll_member"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/playstore.png"),
      "alt": "apple",
      "width": "162",
      "height": "50"
    }
  })])])])]);

}]

export { render, staticRenderFns }