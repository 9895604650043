var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "body-main-row"
  }, [_c('div', {
    staticClass: "member-body-content-wrap add-request"
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('div', {}, [_c('button', {
    staticClass: "btn-new-request"
  }, [_c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.new_request",
      modifiers: {
        "new_request": true
      }
    }]
  }, [_vm._v("Add New Request "), _c('i', {
    staticClass: "fas fa-plus-circle ml-1"
  })])])]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('AddRequestModal', {
    attrs: {
      "policyIds": _vm.policyIds,
      "getAll": _vm.getAll
    }
  }), _c('AddRequestTable', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function ($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": []
    },
    on: {
      "update:rows": function ($event) {
        _vm.rows = $event;
      }
    }
  })], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }