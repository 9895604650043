var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page twoVerification-wrap"
  }, [_c('div', {
    staticClass: "main_page twoVerification"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "verification-detail two_OTP_verification text-center"
  }, [_c('h2', [_vm._v("Account Verification Code")]), _vm._m(1), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Enter OTP ")]), _c('v-otp-input', {
    attrs: {
      "inputClasses": "otp-input",
      "numInputs": 4,
      "separator": "",
      "shouldAutoFocus": true,
      "is-input-num": true
    },
    on: {
      "on-complete": _vm.handleEmailOtp
    }
  })], 1), _c('div', {
    staticClass: "action-btn"
  }, [_c('button', {
    staticClass: "back-btn",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("Back")]), _c('button', {
    staticClass: "next-btn",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Verify Code")])])]), _c('a', {
    staticClass: "resendOTP-code",
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": _vm.resendOtp
    }
  }, [_vm._v(" Resend Code ")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome"
  }, [_c('h1', [_vm._v("OTP Verification")]), _c('p', [_vm._v(" Confirm your verification code that we've sent to verify your identity. "), _c('br')])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Enter the verification OTP Code to registered in "), _c('br'), _vm._v(" Member Dashboard "), _c('br'), _vm._v(" We've send you a "), _c('b', [_vm._v("4 digit verification code")]), _vm._v(" to verify your account. "), _c('br')]);

}]

export { render, staticRenderFns }