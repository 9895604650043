<template>

<b-modal :ok-disabled="formSubmitted"  id="new_request" :centered="true" ok-title="Submit"
              @ok="handleSubmit" @hidden="hideModal()" title="Add New Request" size="lg" no-close-on-backdrop>
              <b-form-group label="Member-Policy" label-for="input-4" invalid-feedback="Member-Policy is required" class="pt-0">
                <template slot="label">Member-Policy<span> *</span></template>
                <b-form-select id="input-4" :options="policyIds" :state="validateState('policy_id')"
                  v-model.lazy="$v.form.policy_id.$model">
                  <template #first>
                    <b-form-select-option value="">Select Member-Policy</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group label-for="input-1" invalid-feedback="Information is required">
                <template slot="label">Information<span> *</span></template>
                <b-form-textarea id="input-1" :state="validateState('information')"
                  v-model.lazy="$v.form.information.$model"></b-form-textarea>
                <span style="display: none" class="field_error" v-if="!$v.form.information.required">Field is
                  required</span>
              </b-form-group>
              
            </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import helper from "../../resource/Helper";
import { required } from "vuelidate/lib/validators";


function intialState() {
  return {   
    form: {
      type:'member_card',
      information: "",
      policy_id: "",    
    },    
    formSubmitted: false,
    
  };
}
export default {

  data() {
    return intialState();
  },
  props:{
    policyIds:{
        required
    },
    getAll:Function
  },
  computed: {
    ...mapGetters({ user: "user" }),
  },
  
  methods: {    
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.formSubmitted = true;
      this.addRequest()
    },
    async addRequest() {
      let app = this;
      let loader = this.$loading.show();
      await axios
        .post("request/store", this.form)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.getAll()
            this.hideModal();
            // this.getUserDetails();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        })
        .finally(() => {
          this.formSubmitted = false;
          loader.hide()
        });
    },
    validateState(name, form = "form") {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetData: function () {
      Object.assign(this.$data.form, intialState().form);
    },
    hideModal() {
      this.resetData();
      this.$v.form.$reset();
      this.$bvModal.hide("new_request");
    },
 
   
  },
  validations: {
    form: {
      information: {
        required,
      },
      policy_id: {
        required,
      },
     
    },
  },
};
</script>