var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page twoVerification-wrap"
  }, [_c('div', {
    staticClass: "main_page verification-success-block"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "verification-detail two_OTP_verification text-center"
  }, [_vm._m(1), _c('h2', [_vm._v("Verification Success")]), _vm._m(2), _c('div', {
    staticClass: "action-btn"
  }, [_c('button', {
    staticClass: "main-primary-btn",
    on: {
      "click": _vm.loginLink
    }
  }, [_vm._v("Login")])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome"
  }, [_c('h1', [_vm._v("Member Verified")]), _c('p', [_vm._v(" Congratulations, Your verification to member dashboard is success. ")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "verification-icon"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/images/checkmark.svg"),
      "alt": "Verification Icon"
    }
  })]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Your verification to member dashboard is success "), _c('br'), _vm._v(" now you can login to your account ")]);

}]

export { render, staticRenderFns }