<template>
  <b-form-group :class="formGroupClass">
    <template v-if="label">
      <label :for="`label-${labelFor || validationLabel}`">
        <span v-html="computedLabel" />
      </label>
    </template>
    <b-form-textarea
      :id="`label-${labelFor || validationLabel}`"
      :class="[
        vObject && vObject.$error ? 'has-error' : '',
        'form-control',
        inputClass,
      ]"
      v-model="computedInputData"
      @blur="handleBlur"
      :placeholder="computedPlaceholder"
      rows="3"
    />
    <template v-if="vObject && vObject.$error">
      <span class="error-text">{{ computedErrorMessage }}</span>
    </template>
  </b-form-group>
</template>

<script>
import ErrorMessage from "./error_message";
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    labelFor: {
      type: String,
      required: false,
    },
    formGroupClass: {
      default: "",
    },
    inputType: {
      default: "text",
    },
    validationLabel: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    rules: {
      type: Object,
      required: false,
    },
    vObject: {
      required: false,
    },
    vmodel: {
      required: true,
    },
  },
  computed: {
    computedPlaceholder() {
      if (!this.placeholder) {
        return `Enter ${this.label}`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules && this.rules.required) {
        return this.label + "<span class='input-required'> * </span>";
      }
      return this.label ? this.label : "";
    },
    computedInputData: {
      get: function() {
        return this.vmodel;
      },
      set: function(value) {
        if (this.vObject) {
          this.vObject.$touch();
        }
        this.$emit("update:vmodel", value ? value : "");
      },
    },
    computedErrorMessage() {
      let errorMessage = new ErrorMessage(
        this.vObject,
        this.label || this.validationLabel
      ).generateMessage();
      return errorMessage[0];
    },
  },
  methods: {
    handleBlur() {
      if (this.vObject) {
        this.vObject.$touch();
      }
    },
  },
};
</script>

<style>
.input-required {
  color: rgb(255, 0, 0) !important;
}
.error-text {
  display: block;
  margin-top: 4px;
  color: #dc3545;
  font-size: 14px;
}
.has-error {
  border: 1px solid #dc3545 !important;
}
</style>
