/*eslint-disable */
import axios from 'axios';
import store from "../store/store"
import helper from "../resource/Helper"

export default {
    apiUrl: function () {
      return process.env.VUE_APP_CORENROLL_SYSTEM_MEMBER;

      /*  switch (window.location.hostname) {
            case 'localhost':
                return 'http://qa-api.corenroll.com/api.member/v1';
            case 'qa-dashboard.purenroll.com':
                return 'http://qa-api.corenroll.com/api.member/v1';
            case 'dashboard.purenroll.com':
                return 'https://api.purenroll.com/api.member/v1';
            default:
                return 'http://qa-api.corenroll.com/api.member/v1';
        } */
    },
    config: function () {
        axios.defaults.baseURL = this.apiUrl()
        store.commit('setAccessToken', store.state.accessToken);
        axios.interceptors.response.use(response => response,
            error => {
               
                // eslint-disable-next-line
                if (error.response == undefined && error.message == "Network Error") {
                    // helper.infoMessage(app,'error', error.message);
                }

                let excludedPath = [
                  "/auth/login",
                  "/auth/authenticate",
                  "/auth/authenticate-code",
                  "/auth/authenticate-device-token",
                  "/auth/reset-password",
                  "auth/reset-password-check",
                  "/auth/reset-password-code"
                ];
                if (
                  (!excludedPath.includes(error.response.config.url) &&
                    error.response.status == 401) ||
                  error.response.status == 418
                ) {
                     helper.forceLogout();
                }
                  /* if (
                  error.response.status == 418 ||
                  (error.response.status == 401 &&
                    store.state.authenticated == true) ||
                  (typeof store.state.authenticated !== "boolean" &&
                    store.state.authenticated != "") ||
                  (store.state.accessToken == "" &&
                    store.state.authenticated == true) ||
                 (error.response.statusText == "Unauthorized" &&  store.state.authenticated == true)
                ) {
                  helper.forceLogout();
                } */
                  return Promise.reject(error);
            }
        );
    }
}