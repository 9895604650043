<template>
  <div class="rep_new dashboard-card dashboard-card-lt mt-4">
    <div class="rep_info">
      <h3>Rep Information</h3>
      <div class="rep_detail">
        <div class="profile_detail">
          <div class="profile">
            <img :src="getImage()" alt="profile" />
          </div>
          <div class="rep_profile">
            <h3>{{ rep_details.agent_fname }} {{ rep_details.agent_lname }} </h3>
            <div class="detail">
              <ul>
                <li class="agent-code">{{ rep_details.agent_code }}</li>

                <li><a href="mailto:"><i class="fa fa-envelope"></i>&nbsp;&nbsp;{{ rep_details.agent_email }}</a></li>
                <li><a href="tel:"><i class="fa fa-phone-alt"></i>&nbsp;&nbsp; {{ rep_details.agent_phone | us_phone }} </a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="rep_message">
              <h3>Messages</h3>
             <div class="column">
            <div class="row" v-for="(item,index) in rep_message" :key="index">
             <div class="icon_item">
              <div class="icon">
                <img src="../assets/images/document_icon.png" />
              </div>
              <div class="rep_message_item">
                <p>{{item}}</p>
                <small><span>Admin</span> - Announcement</small>
              </div>
              </div>
              <div class="test"></div>
            </div>
          </div>
            </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
export default {
  data() {
    return {
      rep_details: [],
    };
  },
  mounted() {
    this.getRepDetails();
  },
  methods: {
    getRepDetails() {
      let app = this;
      return axios.get("/get-rep-details").then((response) => {
        app.rep_details = response.data.data;
      });
    },
    getImage() {
      return this.rep_details.agent_image != "" && this.rep_details.agent_image != undefined
        ? this.rep_details.agent_image
        : require("../../assets/images/dummy.png");
    },
  formattedPhone(phone) {
    debugger;
      if (phone) {
        return Helper.formatPhoneNumber(phone);
      }
      return "N/A";
    },
  },
};
</script>

<style scoped>
.rep_profile {
  object-fit: contain !important;
}
</style>