var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    ref: "save",
    staticClass: "contract_content"
  }, [_c('w-9-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_c('h3', [_vm._v("PERSONAL INFORMATION")]), _c('div', {
    staticClass: "form"
  }, [_c('div', [_c('label', [_vm._v("Name (as shown on your income tax return)")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.name.value,
      expression: "formFields.name.value"
    }],
    attrs: {
      "type": "text",
      "name": "name",
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.name.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.name, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['name_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Business name/disregarded entity name(if different from above)")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.business_name.value,
      expression: "formFields.business_name.value"
    }],
    attrs: {
      "type": "text",
      "name": "business_name",
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.business_name.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.business_name, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['business_name_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Federal tax classification of the person (check one)")]), _c('br'), _c('div', {
    staticClass: "option"
  }, [_c('div', [_c('input', {
    attrs: {
      "type": "radio",
      "name": "radio",
      "value": "individual"
    },
    domProps: {
      "checked": _vm.formFields.radio.value == 'individual'
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }), _c('label', [_vm._v("Individual/sole proprietor or single-member LLC")])]), _c('div', [_c('input', {
    attrs: {
      "type": "radio",
      "name": "radio",
      "value": "c_corporation"
    },
    domProps: {
      "checked": _vm.formFields.radio.value == 'c_corporation'
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }), _c('label', [_vm._v("C Corporation")])]), _c('div', [_c('input', {
    attrs: {
      "type": "radio",
      "name": "radio",
      "value": "s_corporation"
    },
    domProps: {
      "checked": _vm.formFields.radio.value == 's_corporation'
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }), _c('label', [_vm._v("S Corporation")])]), _c('div', [_c('input', {
    attrs: {
      "type": "radio",
      "name": "radio",
      "value": "partnership"
    },
    domProps: {
      "checked": _vm.formFields.radio.value == 'partnership'
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }), _c('label', [_vm._v("Partnership")])]), _c('div', [_c('input', {
    attrs: {
      "type": "radio",
      "name": "radio",
      "value": "trust"
    },
    domProps: {
      "checked": _vm.formFields.radio.value == 'trust'
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }), _c('label', [_vm._v("Trust/estate")])]), _c('div', [_c('input', {
    attrs: {
      "type": "radio",
      "name": "radio",
      "value": "limited_liability"
    },
    domProps: {
      "checked": _vm.formFields.radio.value == 'limited_liability'
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }), _c('label', [_vm._v("Limited liability company")])]), _c('div', [_c('input', {
    attrs: {
      "type": "radio",
      "name": "radio",
      "value": "other"
    },
    domProps: {
      "checked": _vm.formFields.radio.value == 'other'
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }), _c('label', [_vm._v("Other(see instructions)")])])]), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['federal_tax_message']))]) : _vm._e(), _vm._m(0)]), _c('div', [_c('label', [_vm._v("Exempt payee code (if any)")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.payee_code.value,
      expression: "formFields.payee_code.value"
    }],
    attrs: {
      "type": "text",
      "name": "payee_code",
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.payee_code.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.payee_code, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['payee_code_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Exemption from FATCA reporting code (if any)")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.fatca_code.value,
      expression: "formFields.fatca_code.value"
    }],
    attrs: {
      "type": "text",
      "name": "fatca_code",
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.fatca_code.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.fatca_code, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['fatca_code_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("List account number(s) (optional)")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.acc_number.value,
      expression: "formFields.acc_number.value"
    }],
    attrs: {
      "type": "text",
      "name": "acc_number",
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.acc_number.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.acc_number, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['acc_number_message']))]) : _vm._e()])]), _c('div', {
    staticClass: "button_container"
  }, [_c('div'), _c('div'), _c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "next",
    on: {
      "click": _vm.savePersonalInfo
    }
  }, [_vm._v("Next")])])])])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "note"
  }, [_c('span', [_c('b', [_vm._v("Note: ")]), _vm._v("(Check the appropriate box in the line above for the tax classification of the single-member owner. Do not check LLC if the LLC is classified as a single-member LLC that is disregarded from the owner unless the owner of the LLC is another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member LLC that is disregarded from the owner should check the appropriate box for the tax classification of its owner.)")])]);

}]

export { render, staticRenderFns }