<template>
    <div class="left_container">
        <ul>
            <li class="personal" v-bind:class="[this.$route.name == 'Personal' ? 'form-active' : '', personal == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/person.png">
                    Personal Information </li>
            <li class="address" v-bind:class="[this.$route.name == 'Address' ? 'form-active' : '', address == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/house.png">Address Information</li>
            <li class="taxpayer" v-bind:class="[this.$route.name == 'TaxpayerId' ? 'form-active' : '', taxpayer == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/certificate.png">Taxpayer ID Number (TIN)</li>
            <li class="certification" v-bind:class="[this.$route.name == 'Certification' ? 'form-active' : '', certification == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/certificate.png">Certification</li>
            <li class="general" v-bind:class="[this.$route.name == 'GeneralInstruction' ? 'form-active' : '', general == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/instruction.png">General Instruction</li>
            <li class="specific" v-bind:class="[this.$route.name == 'SpecificInstruction' ? 'form-active' : '', specific == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/instruction.png">Specific Instruction</li>
        </ul>
    </div>

</template>

<script>
export default {
    name: "W9FormSidebar",
      data(){
        return{
            personal: 'incomplete',
            address: 'incomplete',
            taxpayer: 'incomplete',
            certification: 'incomplete',
            general: 'incomplete',
            specific: 'incomplete',
            sidebar: '',
            level: '',
            items : [], 
        };
    },
  mounted(){
        let app = this;
        app.personal = window.localStorage.getItem('personal_complete');
        app.address = window.localStorage.getItem('address_complete');
        app.taxpayer = window.localStorage.getItem('taxpayer_complete');
        app.certification = window.localStorage.getItem('certification_complete');
        app.general = window.localStorage.getItem('general_complete');
        app.specific = window.localStorage.getItem('specific_complete');
        app.sidebar = window.localStorage.getItem('type');
        
    }
}
</script>

<style scoped>
.left_container{
    background: url(/img/welcome.617bd874.png) no-repeat;
    width: 30%;
    padding: 3rem 0;
    font-size: 20px;
    border-radius: 0px;
    background-position: center;
    box-shadow: 0 25px 30px rgb(0 0 0 / 16%);
    background-size: cover;
    border-radius: 10px 0 0 10px;
    transition: all ease 0.6s;
}
.left_container ul{
    /* padding: 0 25px; */

}
.left_container ul li{
    /* list-style:none;
    border:2px solid #fff;
    border-radius: 30px;
    padding:10px;
    margin:25px 40px;
    color:#fff;
    line-height: normal !important; */
    /* align-items: center; */
    border-bottom: 1px solid rgb(255 255 255 / 23%);
    padding: 18px 10px;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
}
.left_container ul li:last-child{
    border-bottom: 0px;
}
.form-active{
    position: relative;
}
.form-active::after{
    content: '';
    position: absolute;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px solid #fff;
    right: 0px;
}
.form-filled{
    background: rgb(51 51 51 / 59%);
    color: #bbb !important;
}
.left_container ul li img{
   
    width:20px;
    margin:0 10px 0px 0px;
    padding-left: 3px;
}
@media only screen and (max-width:1280px){

    

}
@media only screen and (max-width:991px){
    .left_container{
        width: 100%;
        padding: 0px 0px;
        border-radius: 10px;
        margin-bottom: 15px;
        overflow: hidden;
    }
    .left_container ul{
        margin-bottom: 0;
    }
    .form-active::after{
        display: none;
    }
}
@media(max-width: 768px){
    
}
</style>