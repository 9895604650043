<template>
  <b-modal id="alert-popup1" centered title="Your Current Plan" hide-footer>
    <div class="alert-popup">
      <p class="mb-4 text-center">Do you want to confirm below plans?</p>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Plans</th>
              <th>Renewal Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ currentPlan.web_display_name }}</td>
              <td>{{ currentPlan.price | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button class="accept-btn" @click="keepPlan">
          {{ isKeep ? "Accepting..." : "Accept" }}
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Api from "@/resource/MemberApi";
export default {
  name: "DashboardEnrollmentCurrentPlanModal",
  props: {
    currentPlan: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    isKeep: false,
  }),
  methods: {
    keepPlan() {
      let url = `v2/keep-plan`;
      let data = {
        current_plan_policy_id: this.currentPlan.plan_policy_id,
      };
      this.isKeep = true;
      Api.put(url, data)
        .then((res) => {
          this.$notify({
            type: "success",
            title: "Keep Plan",
            text: res.data.message,
          });
          this.$bvModal.hide("alert-popup1");
        })
        .catch((err) => {
          this.$notify({
            type: "error",
            title: "Keep Plan",
            text: "Something went wrong",
          });
        })
        .finally(() => {
          this.isKeep = false;
        });
    },
  },
};
</script>