var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    ref: "save",
    staticClass: "contract_content"
  }, [_c('w-9-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_c('h3', [_vm._v("ADDRESS INFORMATION")]), _c('div', {
    staticClass: "form"
  }, [_c('div', [_c('label', [_vm._v("Street Address1")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.street_address1.value,
      expression: "formFields.street_address1.value"
    }],
    attrs: {
      "type": "text",
      "name": "street_address1",
      "readonly": _vm.formFields.street_address1.read_only == true,
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.street_address1.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.street_address1, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['street_address1_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Street Address2")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.street_address2.value,
      expression: "formFields.street_address2.value"
    }],
    attrs: {
      "type": "text",
      "name": "street_address2",
      "readonly": _vm.formFields.street_address2.read_only == true,
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.street_address2.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.street_address2, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['street_address2_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("City")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.city.value,
      expression: "formFields.city.value"
    }],
    attrs: {
      "type": "text",
      "name": "city",
      "readonly": _vm.formFields.city.read_only == true,
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.city.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.city, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['city_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("State")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.state.value,
      expression: "formFields.state.value"
    }],
    attrs: {
      "type": "text",
      "name": "state",
      "readonly": _vm.formFields.state.read_only == true,
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.state.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.state, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['state_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Zip")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.zip.value,
      expression: "formFields.zip.value"
    }],
    attrs: {
      "type": "text",
      "name": "zip",
      "readonly": _vm.formFields.zip.read_only == true,
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.zip.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.zip, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['zip_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Requester's name and address (optional)")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.req_name_address.value,
      expression: "formFields.req_name_address.value"
    }],
    attrs: {
      "type": "text",
      "name": "req_name_address",
      "readonly": _vm.formFields.req_name_address.read_only == true,
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.req_name_address.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.req_name_address, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['req_name_address_message']))]) : _vm._e()])]), _c('div', {
    staticClass: "button_container"
  }, [_c('button', {
    staticClass: "back",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Back")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.toggle,
      expression: "toggle"
    }],
    attrs: {
      "type": "checkbox",
      "true-value": "0",
      "false-value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.toggle) ? _vm._i(_vm.toggle, null) > -1 : _vm._q(_vm.toggle, "0")
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.toggle,
            $$el = $event.target,
            $$c = $$el.checked ? "0" : "1";

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.toggle = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.toggle = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.toggle = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "validation_check"
    }
  }, [_vm._v(" Continue without validation")])]), _c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "next",
    on: {
      "click": _vm.saveAddressInfo
    }
  }, [_vm._v("Next")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }