import { render, staticRenderFns } from "./MessagePage.vue?vue&type=template&id=5ec61949&scoped=true&"
import script from "./MessagePage.vue?vue&type=script&lang=js&"
export * from "./MessagePage.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/review_rating/review_content.css?vue&type=style&index=0&id=5ec61949&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ec61949",
  null
  
)

export default component.exports