var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page"
  }, [_c('div', {
    staticClass: "main_page"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "send_code"
  }, [_c('h3', [_vm._v("Choose where you would like to receive the verification code?")]), _c('b-form-group', [_c('b-form-radio', {
    attrs: {
      "name": "radios"
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }, [_vm._v("SMS (09123456790)")]), _c('b-form-radio', {
    attrs: {
      "name": "radios"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }, [_vm._v("Email (sc**@htstream.com)")]), _c('b-form-radio', {
    attrs: {
      "name": "radios"
    },
    model: {
      value: _vm.both,
      callback: function ($$v) {
        _vm.both = $$v;
      },
      expression: "both"
    }
  }, [_vm._v("Both (SMS & Email)")])], 1), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e(), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.sendForgotPassword();
      }
    }
  }, [_vm.verifyLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "forgot_welcome"
  }, [_c('h1', [_vm._v("Send Code")]), _c('p', [_vm._v(" Choose where you would like to receive the code ")])]);

}]

export { render, staticRenderFns }