<template>

            <div class="messages_new">
              <div class="message_dropdown">
                <!-- <div style="width:130px;vertical-align:middle;margin-right:30px;">
                  <b-form-select v-model="selected" :options="view_options" class="mb-3" value-field="item" text-field="name" size="sm" style="font-size:17px;font-family:SF UI display Medium;color:#323c47;"></b-form-select>
                </div> -->
              </div>
              <div class="message_table_new">
                <div>
                  <b-card no-body>
                    <b-skeleton-table
                      v-if="message_loader"
                      :rows="5"
                      :columns="2"
                      :table-props="{ bordered: true, striped: true }"
                    ></b-skeleton-table>
                    <b-tabs pills card vertical v-if="!message_loader">
                      <b-tab
                        v-for="(item, index) in rows.records"
                        :key="index"
                        @click="'knowledge_modal_' + item.id"
                      >
                        <template #title>
                          <div class="list-icon">
                            <img
                              src="@/assets/images/message-icon.svg"
                              alt=""
                            />
                          </div>
                          <div class="list-sub">
                            <h3>{{ item.title }}</h3>
                            <p>{{ formatDate(item.created_at) }}</p>
                          </div>
                        </template>

                        <b-card-text
                          :id="'knowledge_modal_' + item.id"
                          class="message-details-div"
                        >
                          <b-skeleton-table
                            v-if="message_loader"
                            :rows="2"
                            :columns="1"
                            :table-props="{ bordered: true, striped: true }"
                          ></b-skeleton-table>
        <div class="rcv-msgcontent">
          <div class="rcv-msg-sender">
            <div class="rcv-snder-icon">
             <img :src="$dummyImage"  alt="" class="msg-userIcon" />
              <div class="rcv-sender-details">
                <h6>{{item.created_user?.rep_full_name}}</h6>
                <p>
                  <i class="far fa-calendar-alt"></i>
                  {{
                    item.published_date
                  }}
                </p>
              </div>
            </div>
          </div>
          <h5>Title: {{ item.title }}</h5>
          <hr />
          <div class="ql-snow">
            <div class="ql-editor">
              <p v-html="item.description" />
            </div>
          </div>
       <div v-if="item.files && item.files.length > 0">
        <h6>Attachments:</h6>
        <ul style="list-style: none">
          <li v-for="file in item.files" :key="file.id">
            <a :href="file.path" target="__blank" download>
              <i class="fa fa-file"></i>
              {{ file.filename }}
            </a>
          </li>
        </ul>
      </div>
        </div>
                        </b-card-text></b-tab>
                    </b-tabs>
                    <pagination :rows.sync="rows" :showPageRecord="false"/>
        <!-- <div class="table-pagination">
          <b-pagination
            v-if="records.total_records > 0"
            v-model="records.current_page"
            :total-rows="records.total_records"
            :per-page="10"
            align="right"
            prev-text="Prev"
            next-text="Next"
            first-text="First"
            last-text="Last">
          </b-pagination>
        </div> -->
                  </b-card>
                </div>
              </div>
            </div>
         
</template>

<script>
/*eslint-disable */
import axios from "axios";
import helper from "@/resource/Helper";
import { mapGetters } from "vuex";
import Pagination from "../../components/Pagination.vue";


export default {
    data() {
        return {
            knowledge_feeds: [],
            knowledge_feeds_all: [],
            selected: "All",
            view_options: [
                { item: "All", name: "All" },
                { item: "Archive", name: "Archive" },
                { item: "Active", name: "Active" },
            ],
            message_loader: true,
            rows: []
        };
    },
    computed: {
        ...mapGetters(["activePlanId"]),
    },
    created() {
        this.getKnowledgeCenterList();
    },
    methods: {
        getKnowledgeCenterList() {
            return axios
                .get("/knowledge-feed/list?user_related_feed=1")
                .then((response) => {
                this.rows = response.data;
                this.knowledge_feeds = response.data.records;
                this.knowledge_feeds_all = response.data.records;
                this.message_loader = false;
            })
                .error((err) => { });
        },
        formatDate(date) {
            return helper.formatDate(date);
        },
        downloadFile(file) {
            window.open(file);
        },
    },
    components: { Pagination }
};
</script>
<style>
@import "../../assets/css/view_messages.css";
.message_body >>> h2 {
  font-size: 20px !important;
}
.message_body >>> h2 strong {
  font-size: 20px !important;
}
.message_body >>> h3 strong {
  font-size: 20px !important;
}
.attchement_details .icon_item {
  width: 100%;
}
.attchement_details .icon_item .resource_item {
  width: 100%;
  margin-top: 1%;
  margin-left: 2%;
}
.attchement_details {
  margin-top: 4%;
}
</style>
<style scoped>
.member-body-content-wrap.member_dashboard {
  min-width: calc(100% - 200px);
}
@media (max-width: 992px) {
  .member-body-content-wrap.member_dashboard {
    min-width: calc(100% - 45px);
  }
}
.attchement_details .row {
  background: #f1efef !important;
}
.contents {
  padding: 0 !important;
  min-height: calc(100vh - 305px);
}
.dashboard-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px 25px;
  border: none;
  box-shadow: 0px 2px 6px #21252914;
}
.message_dropdown h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.view-knowledge-center-page .tabs.row.no-gutters .tab-content .card-body {
  padding: 25px;
}
.view-knowledge-center-page .tabs.row.no-gutters .tab-content .card-body {
  padding: 25px;
}
.table-pagination .b-pagination {
  justify-content: left !important;
}

</style>
