<template>
  <div class="initial_page twoVerification-wrap">
    <div class="main_page twoVerification">
      <div class="welcome">
        <h1>OTP Verification</h1>
        <p>
          Confirm your verification code that we've sent to verify your identity. <br />
         <!-- Email and Phone to verify your identity. -->
        </p>
      </div>
      <div class="form">
        <div class="verification-detail two_OTP_verification text-center">
          <h2>Account Verification Code</h2>
          <p>
            Enter the verification OTP Code to registered in <br />
            Member Dashboard
            <br />
            We've send you a <b>4 digit verification code</b> to verify your account. <br />
            <!-- <b>email & phone</b> to verify your account. -->
          </p>
          <form @submit.prevent="submit">
            <div class="form-group">
              <label for="">
                Enter OTP
                <!--<span class="identity-detail">
                  {{ maskedEmail }}
                </span> -->
              </label>
              <v-otp-input
                inputClasses="otp-input"
                :numInputs="4"
                separator=""
                :shouldAutoFocus="true"
                :is-input-num="true"
                @on-complete="handleEmailOtp"
              />
            </div>
           <!-- <div class="form-group mt-4">
              <label for="">
                Phone
                <span class="identity-detail">
                  {{ maskedPhone }}
                </span>
              </label>

              <v-otp-input
                inputClasses="otp-input"
                :numInputs="4"
                separator=""
                :is-input-num="true"
                @on-complete="handlePhoneOtp"
              />
            </div> -->
            <div class="action-btn">
              <button @click="goBack" class="back-btn">Back</button>
              <button type="submit" class="next-btn">Verify Code</button>
            </div>
          </form>
          <a
            href="javascript:void(0);"
            class="resendOTP-code"
            @click="resendOtp"
          >
            Resend Code
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
import axios from "axios";

export default {
  name: "SignUpOtp",
  data: () => {
    return {
      form: {
        email_otp: "",
        phone_otp: "",
        userid: "",
      },
      memberPhone: "",
      memberEmail: "",
      errors: {},
    };
  },
  computed: {
    maskedEmail() {
      return Helper.maskEmail(this.memberEmail);
    },
    maskedPhone() {
      return Helper.maskPhone(this.memberPhone);
    },
  },
  methods: {
    resendOtp() {
      let url = "auth/validate-registration-email-phone";
      axios
        .post(url, {
          phone: this.$store.state.memberPhone,
          email: this.$store.state.memberEmail,
          userid: this.$store.state.memberId,
        })
        .then((res) => {
          Helper.infoMessage(this, res.data.status, res.data.message);
        })
        .catch((err) => {
          Helper.infoMessage(
            this,
            err.response.data.status,
            err.response.data.message
          );
        });
    },
    submit() {
      let url = "auth/validate-registration-otp";
      this.form.phone_otp = this.form.email_otp
      axios
        .post(url, this.form)
        .then((res) => {
          Helper.infoMessage(this, res.data.status, res.data.message);
          this.$store.commit("setEmailOtp", this.form.email_otp);
          this.$store.commit("setPhoneOtp", this.form.email_otp);
          this.$router.push({ name: "SignUpContinue" });
        })
        .catch((err) => {
          Helper.infoMessage(
            this,
            err.response.data.status,
            err.response.data.message
          );
        });
    },
    goBack() {
      this.$store.commit("setOtpSend", false);
      this.$router.push({ name: "SignUpVerification" });
    },
    setData() {
      this.form.userid = this.$store.state.memberId;
      this.memberPhone = this.$store.state.memberPhone;
      this.memberEmail = this.$store.state.memberEmail;
      if (!this.memberPhone || !this.memberEmail) {
        this.$router.push({ name: "SignUp" });
      }
      if (!this.$store.state.otpSend) {
        this.$router.push({ name: "SignUpVerification" });
      }
    },
    handleEmailOtp(value) {
      if (value.length == 4) {
        this.form.email_otp = parseInt(value);
      }
    },
    handlePhoneOtp(value) {
      if (value.length == 4) {
        this.form.phone_otp = parseInt(value);
      }
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style scoped>
@import "../../assets/css/verification.css";
</style>