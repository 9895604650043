var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page twoVerification-wrap"
  }, [_c('div', {
    staticClass: "main_page twoVerification"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "verification-detail text-center"
  }, [_vm._m(1), _c('h2', [_vm._v("Two Way Verification Details")]), _vm._m(2), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "registration-input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Phone")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phone,
      expression: "form.phone"
    }],
    staticClass: "registration-input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.form, "phone", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "action-btn"
  }, [_c('button', {
    staticClass: "back-btn",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("Back")]), _c('button', {
    staticClass: "next-btn",
    attrs: {
      "type": "submit"
    }
  }, [_vm.formLoader ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Send Code")], 1)])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome"
  }, [_c('h1', [_vm._v("Send Code")]), _c('p', [_vm._v("Recieving Verification Codes")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "verification-icon"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/images/verification.svg"),
      "alt": "Verification Icon"
    }
  })]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" We will send verification code in following details,"), _c('br'), _vm._v(" please verify individually with respective"), _c('br'), _vm._v(" OTP Codes. ")]);

}]

export { render, staticRenderFns }