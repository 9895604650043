<template>
  <div class="left_container">
        <!-- <div class="arrow-left"></div> -->
        <ul>
            <li class="personal" v-bind:class="[this.$route.name == 'ContractPersonalInfo' ? 'form-active' : '', personal == 'complete' ? 'form-filled' : '']" ><img src="../../assets/img/person.png">Personal Information </li>
            <li class="contact" v-bind:class="[this.$route.name == 'ContractContactInfo' ? 'form-active' : '' , contact == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/contact.png"> Contact Information</li>
            <li class="address" v-bind:class="[this.$route.name == 'ContractAddressInfo' ? 'form-active' : '', address == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/house.png"> Address Information</li>
            <li class="billing" v-bind:class="[this.$route.name == 'ContractBillingInfo' ? 'form-active' : '', billing == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/credit-card.png"> Billing Information</li>
            <li class="sheet" v-bind:class="[this.$route.name == 'ContractCommissionSheet' ? 'form-active' : '', sheet == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/sheet.png"> Master Commission Sheet</li>
            <li class="agreement" v-bind:class="[this.$route.name == 'ContractAgreement' ? 'form-active' : '', agreement == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/pen-tip.png">  Agreement & Signature</li>
            <!-- <li class="fee" v-bind:class="[this.$route.name == 'ContractFeeStructure' ? 'form-active' : '', fee == 'complete' ? 'form-filled' : '']"><img src="../../assets/img/pen-tip.png"> Confirmation & Signature</li> -->
        </ul>
    </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "ContractFormSidebar",
  data(){
        return{
            personal: 'incomplete',
            contact: 'incomplete',
            address: 'incomplete',
            billing: 'incomplete',
            agreement: 'incomplete',
            sheet: 'incomplete',
            fee: 'incomplete',
            sidebar: '',
            level: '',
            items : [], 
        };
    },
  mounted(){
        let app = this;
        app.personal = window.localStorage.getItem('personal_complete');
        app.contact = window.localStorage.getItem('contact_complete');
        app.address = window.localStorage.getItem('address_complete');
        app.billing = window.localStorage.getItem('billing_complete');
        app.agreement = window.localStorage.getItem('agreement_complete');
        app.sheet = window.localStorage.getItem('sheet_complete');
        app.fee = window.localStorage.getItem('fee_complete');
        app.sidebar = window.localStorage.getItem('type');
        
    }
};

</script>
<style scoped>
.left_container{
    background: url(/img/welcome.617bd874.png) no-repeat;
    width: 30%;
    padding: 3rem 0;
    font-size: 20px;
    border-radius: 0px;
    background-position: center;
    box-shadow: 0 25px 30px rgb(0 0 0 / 16%);
    background-size: cover;
    border-radius: 10px 0 0 10px;
    transition: all ease 0.6s;
}
.left_container ul{
    /* padding: 0 25px; */

}
.left_container ul li{
    /* list-style:none;
    border:2px solid #fff;
    border-radius: 30px;
    padding:10px;
    margin:25px 40px;
    color:#fff;
    line-height: normal !important; */
    /* align-items: center; */
    border-bottom: 1px solid rgb(255 255 255 / 23%);
    padding: 18px 10px;
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
}
.left_container ul li:last-child{
    border-bottom: 0px;
}
.form-active{
    position: relative;
}
.form-active::after{
    content: '';
    position: absolute;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px solid #fff;
    right: 0px;
}
.form-filled{
    background: rgb(51 51 51 / 59%);
    color: #bbb !important;
}
.left_container ul li img{
   
    width:20px;
    margin:0 10px 0px 0px;
    padding-left: 3px;
}
@media only screen and (max-width:1280px){

    

}
@media only screen and (max-width:991px){
    .left_container{
        width: 100%;
        padding: 0px 0px;
        border-radius: 10px;
        margin-bottom: 15px;
        overflow: hidden;
    }
    .left_container ul{
        margin-bottom: 0;
    }
    .form-active::after{
        display: none;
    }
}
@media(max-width: 768px){
    
}

</style>
