var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('VueSignaturePad', {
    ref: "signaturePad",
    attrs: {
      "id": "signature",
      "width": "100%",
      "height": "150px",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        },
        onEnd: _vm.onEnd
      }
    }
  }), _c('button', {
    staticClass: "clear",
    on: {
      "click": _vm.clear
    }
  }, [_vm._v("Clear")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }