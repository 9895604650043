var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page"
  }, [_c('div', {
    staticClass: "main_page"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "signup_form"
  }, [_vm._m(1), _c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "text",
      "required": "",
      "placeholder": "Enter your email",
      "readonly": ""
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["email"]) + " ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "New Password",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "password",
      "required": "",
      "placeholder": "Enter your new password"
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Confirm Password",
      "label-for": "input-2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-2",
      "type": "password",
      "required": "",
      "placeholder": "Repeat new password"
    },
    model: {
      value: _vm.confirmpassword,
      callback: function ($$v) {
        _vm.confirmpassword = $$v;
      },
      expression: "confirmpassword"
    }
  })], 1), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e(), _c('button', {
    attrs: {
      "disabled": _vm.verifyLoader
    },
    on: {
      "click": function ($event) {
        return _vm.confirmSignUp();
      }
    }
  }, [_vm.verifyLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome"
  }, [_c('h1', [_vm._v("Welcome")]), _c('p', [_vm._v(" Register to create your online access to your member dashboard. (Please note online account access is not available until your start date or thereafter) ")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signup_head"
  }, [_c('h3', [_vm._v("Register")]), _c('span', [_vm._v("To register your account, please create username and password.")])]);

}]

export { render, staticRenderFns }