<template>
    <section class="container-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="review-option-site-title">
                        <h1>We are constantly striving to improve and value your input, <br> please select from the 3 options below to submit your feedback.
</h1>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="rewiew-option-block">
                        <router-link to="reviewform?owner=members">
                            <div class="img-icons">
                                <img src="../../assets/images/review-images/icons/member.svg" alt="">
                            </div>
                            <h3>Member / Employee <br> Feedback</h3>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="rewiew-option-block">
                        <router-link to="reviewform?owner=employers">
                            <div class="img-icons">
                                <img src="../../assets/images/review-images/icons/group.svg" alt="">
                            </div>
                            <h3>Employers / Groups <br> Feedback</h3>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="rewiew-option-block">
                        <router-link to="reviewform?owner=brokers">
                            <div class="img-icons">
                                <img src="../../assets/images/review-images/icons/broker.svg" alt="">
                            </div>
                            <h3>Broker / Reps <br> Feedback</h3>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ReviewOption"
}
</script>
<style src="../../assets/css/review_rating/review_content.css" scoped>

</style>

