<template>
  <div class="initial_page twoVerification-wrap">
    <div class="main_page twoVerification">
      <div class="welcome">
        <h1>Send Code</h1>
        <p>Recieving Verification Codes</p>
      </div>
      <div class="form">
        <div class="verification-detail text-center">
          <div class="verification-icon">
            <img
              src="@/assets/images/verification.svg"
              alt="Verification Icon"
              class="img-fluid"
            />
          </div>
          <h2>Two Way Verification Details</h2>
          <p>
            We will send verification code in following details,<br />
            please verify individually with respective<br />
            OTP Codes.
          </p>
          <form @submit.prevent="submit">
            <div class="form-group">
              <label for="">Email</label>
              <input
                type="text"
                class="registration-input"
                v-model="form.email"
              />
            </div>
            <div class="form-group">
              <label for="">Phone</label>
              <input
                type="text"
                class="registration-input"
                v-model="form.phone"
              />
            </div>
            <div class="action-btn">
              <button @click="goBack" class="back-btn">Back</button>
              <button type="submit" class="next-btn">
                 <b-spinner v-if="formLoader" small></b-spinner>
                Send Code</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
import axios from "axios";
export default {
  name: "SignUpVerification",
  data: () => {
    return {
      form: {
        phone: "",
        email: "",
        userid: "",
      },
      errors: {},
      formLoader: false
    };
  },
  methods: {
    goBack() {
      this.$store.commit("setMemberEmail", "");
      this.$store.commit("setMemberId", "");
      this.$store.commit("setMemberPhone", "");
      this.$router.push({ name: "SignUp" });
    },
    setData() {
      this.form.userid = this.$store.state.memberId;
      this.form.phone = this.$store.state.memberPhone;
      this.form.email = this.$store.state.memberEmail;
      if (!this.form.phone || !this.form.email) {
        this.$router.push({ name: "SignUp" });
      }
    },
    submit() {
      this.formLoader = true;
      let url = "auth/validate-registration-email-phone";
      axios
        .post(url, this.form)
        .then((res) => {
          this.$store.commit("setOtpSend", true);
          this.$store.commit("setMemberPhone", this.form.phone);
          this.$store.commit("setMemberEmail", this.form.email);
          Helper.infoMessage(this, res.data.status, res.data.message);
          this.$router.push({ name: "SignUpOtp" });
        })
        .catch((err) => {
          Helper.infoMessage(
            this,
            err.response.data.status,
            err.response.data.message
          );
        }).finally(()=> {
          this.formLoader = false;
        });
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style scoped>
@import "../../assets/css/verification.css";
</style>