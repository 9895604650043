<template>
  <span class="toggleSSN" @click="toggleIcon">
    <span :id="'eyeIconSlash_'+this.id" style="display:none"> <i class="far fa-eye-slash"></i> </span>
    <span :id="'eyeIcon_'+this.id"> <i class="far fa-eye"></i> </span>
  </span>

</template>

<script>
/* eslint-disable */
export default {
  name: "EyeButton",
  props: ['id', 'type','hideOnDefault'],
  data() {
    return {
      eyeButton: true,
      currentType: '',
    };
  },
  mounted() {
    if(this.hideOnDefault) {
      this.toggleIcon()
    }
  },
  methods: {
    toggleIcon() {
      this.eyeButton = this.eyeButton ? false : true;
      if (this.eyeButton) {
        document.getElementById("eyeIcon_"+this.id).style.display = "block";
        document.getElementById("eyeIconSlash_"+this.id).style.display = "none";
        this.currentType = this.type
      } else {
        document.getElementById("eyeIcon_"+this.id).style.display = "none";
        document.getElementById("eyeIconSlash_"+this.id).style.display = "block";
        this.currentType = 'password'
      }
      
      document.getElementById(this.id).type = this.currentType;
    },
  },
};
</script>
<style scoped>
.toggleSSN {
  position: absolute;
  right:15px;
  margin-top: -3.9%;
}

</style>