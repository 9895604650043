<template>
    <div class="content_wrapper">
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <w-9-form-sidebar/>

            <div class="right_container">
                   <h3>Part II | Certification</h3>
                   <div class="description">
                    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-1 variant="info" style="text-align:left;font-size:18px;">Certification Instructions<strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong></b-button>
      </b-card-header>
      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" v-model="isVisible">
        <b-card-body>

          <b-card-text>
                    <div class="certify_agreement">
                        
                    <p>Under penalties of perjury, I certify that:<br><br>
                        <ol>
                            <li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number
                                to be issued to me); and</li>
                            <li>I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have
                                not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a
                                result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no
                                longer subject to backup withholding; and</li>
                            <li>I am a U.S. citizen or other U.S. person (defined below); and</li>
                            <li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is
                                correct.</li>
                        </ol>
                        <strong>Certification Instructions:</strong> You must cross out item 2 above if you have been notified by the
                        IRS that you are currently subject to backup withholding because you have failed to report all interest and
                        dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid,
                        acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement
                        arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the
                        certification, but you must provide your correct TIN. See the instructions for Part II, later.
                        </p>
                    </div>
                    </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info" style="text-align:left;font-size:18px;">Signature
             <strong v-if="isNotVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong></b-button>
      </b-card-header>
      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel" v-model="isNotVisible">
        <b-card-body>
         
          <b-card-text>  
                    <div class="agreement_signature">
                         <span class="sign_container">
                            <span class="signature_block">
                              <span class="sign">
                                Signature of U.S. person<br>
                                  <canvas id="rep_signature" class="signature"></canvas>
                                  <button class="clear" type="button" name="button" @click="clear">Clear</button>                              
                                  <small class="error_message" v-if="formErrorFlag == true">{{formErrors['signature_of_us_person_message']}}</small>
                                </span>
                                  <br>
                              <span>
                                Date<br><input type="text" name="date" v-model="formFields.date.value" :readonly="formFields.date.read_only == true" value="" required>
                              </span>
                                <small class="error_message" v-if="formErrorFlag == true">{{formErrors['date_message']}}</small>
                            </span>   
                          </span>
                              </div>
   </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>   
                </div>

                   <div class="button_container">
                    <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                    <div class="button">
                       <button class="next" @click="saveCertification">Next</button>
                </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import SignaturePad from "signature_pad";
import W9FormSidebar from '../../components/partial/W9FormSidebar.vue'
import AgentInfo from "../../components/common/AgentInfo"
import axios from 'axios'
import html2canvas from 'html2canvas';
import moment from 'moment';
export default {
  name: "MyComponent",
  components: { W9FormSidebar, AgentInfo },
    data(){
      return {
        tempid: '',
        isVisible:false,
        isNotVisible:true,
        signaturePad:null,
        formErrorFlag: false,
        formErrors: [],
        formFields: {
          signature: {
              value: '',
              required: true,
              read_only: false,
          },
          date: {
              value: '',
              required: false,
              read_only: true,
          }
        },
      };
    },
    mounted(){
      let app = this;      
      app.tempid= window.localStorage.getItem('tempId');

      let canvas = document.querySelector('#rep_signature');
      var ratio =  Math.max(window.devicePixelRatio || 0, 0)
      canvas.width = canvas.offsetWidth * ratio
      canvas.height = canvas.offsetHeight * ratio
      canvas.getContext("2d").scale(ratio, ratio)
      app.signaturePad = new SignaturePad(canvas, {
        backgroundColor: "rgba(255, 255, 255, 0)",
        onEnd: () => {
          app.signaturePad.toDataURL();
        }
      }); 
      
      // var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      app.formFields.date.value = moment(new Date()).format("MM/DD/YYYY"); 
    },
    methods: {
      clear() {
        let app = this;
        app.signaturePad.clear();
        app.signatureError = false;
        console.log('cleared');
      },
      saveCertification: function () {
        let app = this;
        app.formErrorFlag = false;
        app.formErrors    = [];
        if(app.signaturePad.isEmpty()){
          app.formErrorFlag = true;
          app.formErrors['signature_of_us_person_message'] = "The signature field is required";
          return false;
        }
        const el = this.$refs.save;
        (async () => {
        this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'));
        })();
        let params = {
          tempid:            app.tempid,                
          signature_of_us_person : app.signaturePad.toDataURL('image/png'),
          date :  app.formatDate(app.formFields.date.value),
        };
        //discard null values
        for (const key of Object.keys(params)) {
            if (params[key] === null) {
                delete params[key];
            }
        }

        axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-certificate-information-w9', params)
        .then(function (response) {
            if (response.data.status == 'success') {
                console.log("success");
                // window.localStorage.setItem('tempId', response.data.data.tempid);
                app.$router.push("general_instruction");
                window.localStorage.setItem('certification_complete', 'complete');
            } else {
              window.location = '/contract-form/contract_message/error';
            }
        })
        .catch(function (error) {
            console.log(error.response.data.data);
            if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                    app.formErrorFlag = true;
                    app.formErrors[item.target_element+'_message'] = item.error_message;
                });
            }
        });
      },
        saveScreenShot (image) {
            let data = {           
                'agent_id': window.localStorage.getItem('agentId'),
                'level': '0',
                'contract_type': 'w9',
                'image': image,
                'name': 'certification',
                "tempid" : this.tempid
            };
            let config = {
              method: 'post',
              url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
              headers: { },
              data : data
            };

          axios(config)
                  .then(function (response) {
                    if (response.data.status == 'success') {
                      console.log('screenshot saved');
                    }
                  })
                  .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                  });
        },     
      navigatePrevious() {
        this.$router.push("taxpayer_id");
        window.localStorage.setItem('taxpayer_complete', 'incomplete');
      },
      formatDate(dateString) {
          var return_date = '';
          return_date = moment(dateString).format("MM/DD/YYYY");
          return return_date;
      }
    },
}
</script>
<style src="../../assets/css/W9_form/certification.css" scoped>
 </style>
