<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="forgot_welcome">
        <h1>Forgot your Password?</h1>
        <p>
          Don't Worry! we've got your back. Just follow the instructions and
          you'll be good :)
        </p>
      </div>
      <div class="form">
        <div class="reset_form">
          <h3>Reset Password</h3>
          <b-form-group label="Email" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="email"
              placeholder="Enter your email"
            ></b-form-input>
          </b-form-group>

          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
          <button @click="sendForgotPassword()">
            <b-spinner v-if="verifyLoader == true" small></b-spinner>
            Request OTP Code
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "../resource/Helper";
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
    };
  },
  methods: {
    sendForgotPassword() {
      let app = this;
      this.formErrorFlag = false;
      if (this.email == "" || helper.validateEmail(this.email) == false) {
        this.formErrors["error"] = "Please enter valid email address.";
        this.formErrorFlag = true;
        return;
      }
      app.verifyLoader = true;
      axios
        .post("/auth/reset-password", {
          data: app.email,
          option: "email"
        })
        .then(function (response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.$store.commit("setMemberEmail", app.email);
            helper.delayRedirect("VerifyPasswordChangeOTP",1)
            app.verifyLoader = false;
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.verifyLoader = false;
        });
    },
  },
};
</script>

<style>
@import "../assets/css/forgot_password.css";
</style>