<template>
  <li>
    <strong class="plan-renew-title">Plan Renewal Options:</strong>
    <div class="col-md-5">
      <multiselect
        :placeholder="placeholder"
        v-model="selectedPlan"
        label="formatted_plan_name"
        track-by="pid"
        :options="plans"
        :internal-search="false"
        :close-on-select="true"
        :clear-on-select="false"
        @search-change="searchHandler"
        @open="openPlans"
      >
      </multiselect>
    </div>
    <a
      href="javascript:void(0);"
      v-if="selectedPlan"
      v-b-modal.alert-popup2
      class="notice-btn plan-renew-btn"
      >Change to plan at renewal</a
    >
    <dashboard-enrollment-renew-plan-modal
      v-if="selectedPlan"
      :current-plan="currentPlan"
      :new-plan.sync="selectedPlan"
    />
  </li>
</template>
<script>
import { mapGetters } from "vuex";
import Api from "@/resource/MemberApi";
import DashboardEnrollmentRenewPlanModal from "./DashboardEnrollmentRenewPlanModal.vue";
export default {
  components: { DashboardEnrollmentRenewPlanModal },
  name: "DashboardEnrollmentRenewPlan",
  props: {
    currentPlan: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    selectedPlan: null,
    plans: [],
    placeholder: "Choose Plan",
  }),
  computed: {
    ...mapGetters({ memberId: "memberId" }),
  },
  methods: {
    openPlans() {
      this.placeholder = "Loading plans..";
      this.getMajorMedicalPlans();
    },
    searchHandler(query) {
      if (query.length > 3) {
        this.getMajorMedicalPlans(query);
      }
    },
    getMajorMedicalPlans(query) {
      let url = `v2/get-major-medical-plans/${this.memberId}`;
      if (query) {
        url = `${url}?query=${query}`;
      }  
      Api.get(url)
        .then((res) => {
          this.plans = res.data.data;
          this.placeholder = "Choose Plan"
        })
        .catch(() => {
          this.plans = [];
        });
    },
  },
  created() {
    this.getMajorMedicalPlans();
  },
};
</script>
<style scoped>
.plan-renew-title,
.plan-renew-btn {
  margin-top: 8px;
}
</style>