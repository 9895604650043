var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_section registeration"
  }, [_c('div', {
    staticClass: "rep_info"
  }, [_c('h2', [_vm._v("Representative Information")]), _c('form', {
    attrs: {
      "action": "index.html",
      "method": "post"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "buttonbox"
  }, [_c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.saveAndContinue
    }
  }, [_vm._v("Next")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Upline Rep ID "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]);

}]

export { render, staticRenderFns }