<template>
  <div class="content_section repid">
    <div class="group_info">
      <h2>BROKER INFORMATION</h2>
      <form class="" action="index.html" method="post">
        <div class="input_box">
          <label for="">Representative ID <span class="red">*</span></label>
          <div class="inputs">
            <input type="text" name="" value=""/>
          </div>
        </div>
        <div class="buttonbox">
          <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
    name: "BrokerInformation",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
        console.log(this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
