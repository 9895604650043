<template>
  <div class="terminate_plan">
    <div class="contents">
      <welcome-content></welcome-content>

      <h3>Active Plans</h3>

      <div class="active_plans">
        <div class="active">
          <div class="table_content">
            <table>
              <thead>
                <tr>
                  <td style="text-align: left">Plan</td>
                  <td>Status</td>
                  <td>Effective Date</td>
                </tr>
              </thead>

              <tbody v-for="(item, index) in plans" :key="index">
                <tr v-for="(obj, index) in item.plan_list" :key="index">
                  <td style=" font-family: SF UI Display Bold; text-align: left">
                    {{ obj.plan }}
                  </td>
                  <td style="color: rgb(50, 205, 50)">{{ obj.status }}</td>
                  <td>{{ obj.effective_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="selected_plan">
          <div class="table_content">
            <table>
              <thead>
                <tr>
                  <td style="text-align: left">Plan</td>
                  <td>Status</td>
                  <td>Effective Date</td>
                  <td>Tier</td>
                  <td>Monthly Premium</td>
                  <td style="text-align: center">Action</td>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in plan_detail" :key="index">
                  <td style=" font-family: SF UI Display Bold; text-align: left">
                    {{ item.plan }}
                  </td>
                  <td style="color: rgb(50, 205, 50)">{{ item.status }}</td>
                  <td>{{ item.effective_date }}</td>
                  <td>{{ item.tier }}</td>
                  <td>{{ item.premium }}</td>
                  <td style="text-align: center">
                    <div class="buttons">
                      <button class="terminate">Terminate</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="terminate_all">
            <p class="terminate_text">
              You can request to cancel all the plans instead of individually
            </p>
            <router-link :to="{ name: 'TerminateForm' }"
              ><button>Terminate All</button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeContent from "../components/WelcomeContent";
export default {
  name: "MyComponent",
  components: { WelcomeContent },
  data: function () {
    return {
      plans: [
        {
          plan_list: [
            {
              plan: "Solstice EPO Vision",
              status: "Active",
              effective_date: "09-01-2020",
            },
            {
              plan: "Solstice EPO Vision",
              status: "Active",
              effective_date: "09-01-2020",
            },
            {
              plan: "Solstice EPO Vision",
              status: "Active",
              effective_date: "09-01-2020",
            },
            {
              plan: "Solstice EPO Vision",
              status: "Active",
              effective_date: "09-01-2020",
            },
          ],
        },
        {
          plan_list: [
            {
              plan: "Solstice EPO Vision",
              status: "Active",
              effective_date: "09-01-2020",
            },
          ],
        },
        {
          plan_list: [
            {
              plan: "Solstice EPO Vision",
              status: "Active",
              effective_date: "09-01-2020",
            },
          ],
        },
      ],
      plan_detail: [
        {
          plan: "Solstice EPO Vision",
          status: "Active",
          effective_date: "09-01-2020",
          tier: "IC",
          premium: "$1458.50",
        },
        {
          plan: "Solstice EPO Vision",
          status: "Active",
          effective_date: "09-01-2020",
          tier: "IC",
          premium: "$1458.50",
        },
        {
          plan: "Solstice EPO Vision",
          status: "Active",
          effective_date: "09-01-2020",
          tier: "IC",
          premium: "$1458.50",
        },
        {
          plan: "Solstice EPO Vision",
          status: "Active",
          effective_date: "09-01-2020",
          tier: "IC",
          premium: "$1458.50",
        },
      ],
    };
  },
};
</script>
<style>
@import "../assets/css/terminate_plan.css";
</style>