var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('button', {
    staticClass: "btn",
    on: {
      "click": _vm.openModal
    }
  }, [_vm._v("Payment History")]), _c('payment-history-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }