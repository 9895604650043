<template>
  <div id="repNav">
    <div class="logo">
     <a href="#"> <img src="../assets/corenroll-logo.png" alt="corenroll-logo"></a>
    </div>

  </div>
</template>
<script>
export default {
      name: "Header.vue",
    }
</script>
