<template>
    <header>
        <div class="header-logo">
            <img src="../../assets/images/review-images/icons/corenroll-logo.png">
        </div>
    </header>
</template>
<script>
export default {
    name: "reviewHeader"
}
</script>
<style scoped>
    header{
        padding: 15px 0;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 99;
        background: rgba(0,0,0,.3);
    }
    .header-logo{
        width: 160px;
        height: 60px;
        margin: auto;
    }
</style>
