var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row body-main-row"
  }, [_c('div', {
    staticClass: "col-md-10 member-body-content-wrap member_dashboard view-knowledge-center-page"
  }, [_c('div', {
    staticClass: "view-message"
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('h3', {
    staticStyle: {
      "vertical-align": "text-bottom"
    }
  }, [_vm._v("Knowledge Center")]), _c('b-tabs', {
    attrs: {
      "card": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "From Admin"
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 0;
      }
    }
  }, [_c('b-card-text', [_c('ViewKnowledgeAdminVue')], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "From Rep"
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 1;
      }
    }
  }, [_c('b-card-text', [_c('ViewKnowledgeRepVue')], 1)], 1)], 1)], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }