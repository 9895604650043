var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "footer"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "copyright"
  }, [_c('span', [_vm._v("© Copyright " + _vm._s(new Date().getFullYear()) + ". All Rights Reserved")])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "about"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "footer_logo",
    attrs: {
      "src": require("../../assets/images/corenroll-logo.png"),
      "alt": "logo"
    }
  })]), _c('p', [_vm._v(" If you have some technical difficulties Go to:"), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "http://tickets.purenroll.com/"
    }
  }, [_vm._v(" Issues Dashboard")])])]), _c('div', {
    staticClass: "download"
  }, [_c('span', [_vm._v("Download The Member App")]), _c('div', {
    staticClass: "app_download"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://apps.apple.com/us/app/corenroll-member/id1539841827"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/app-store.png"),
      "alt": "apple",
      "width": "162",
      "height": "52"
    }
  })]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://play.google.com/store/apps/details?id=com.neura.corenroll_member"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/play-store.png"),
      "alt": "apple",
      "width": "162",
      "height": "50"
    }
  })])]), _c('span', [_vm._v("Click the Appropriate App")])])]);

}]

export { render, staticRenderFns }