<template>
     <div class="content_wrapper">
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <w-9-form-sidebar/>

            <div class="right_container">
                   
                   <h3>General Instruction</h3>
                   <div class="description">
                        <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-1 variant="info" style="text-align:left;font-size:18px;">Introduction
            <strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong>
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>

             <b-card-text>
                            <p>
                            Section references are to the Internal Revenue Code unless otherwise noted.
                            <b>Future developments.</b> For the latest information about developments related to Form W-9 and its
                            instructions, such as legislation enacted after they were published, go to www.irs.gov/FormW9.</p>
                           </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-2 variant="info" style="text-align:left;font-size:18px;">Purpose of Form
            <!-- <strong v-if="isNotVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" >
        <b-card-body>
            <b-card-text style="height:190px;overflow-y:scroll;">
                            <h2>Purpose of Form</h2>
                            <p>An individual or entity (Form W-9 requester) who is required to file an information return with the IRS
                            must obtain your correct taxpayer identification number (TIN) which may be your social security number
                            (SSN), individual taxpayer identification number (ITIN), adoption taxpayer identification number (ATIN),
                            or employer identification number (EIN), to report on an information return the amount paid to you, or
                            other amount reportable on an information return. Examples of information returns include, but are not
                            limited to, the following.</p>
                            <ul>
                                <li> Form 1099-INT (interest earned or paid)</li>
                                <li>Form 1099-DIV (dividends, including those from stocks or mutual funds)
                                </li>
                                <li>Form 1099-MISC (various types of income, prizes, awards, or gross proceeds)
                                </li>
                                <li>Form 1099-B (stock or mutual fund sales and certain other transactions by brokers)
                                </li>
                                <li>Form 1099-S (proceeds from real estate transactions)
                                </li>
                                <li>Form 1099-K (merchant card and third party network transactions)
                                </li>
                                <li>Form 1098 (home mortgage interest), 1098-E (student loan interest), 1098-T (tuition)
                                </li>
                                <li>Form 1099-C (canceled debt)
                                </li>
                                <li>Form 1099-A (acquisition or abandonment of secured property)
                                </li>
                            </ul>
                            <p>Use Form W-9 only if you are a U.S. person (including a resident alien), to provide your correct
                                TIN.</p>
                               <p> <i>If you do not return Form W-9 to the requester with a TIN, you might be subject to backup
                                    withholding. See What is backup withholding, later.</i></p>
                            <p>By signing the filled-out form, you:
                            <ol>
                                <li>Certify that the TIN you are giving is correct (or you are waiting for a number to be issued),
                                </li>
                                <li>Certify that you are not subject to backup withholding, or</li>
                                <li>Claim exemption from backup withholding if you are a U.S. exempt payee. If applicable, you are
                                    also certifying that as a U.S. person, your allocable share of any partnership income from a
                                    U.S. trade or business is not subject to the withholding tax on foreign partners' share of
                                    effectively connected income, and</li>
                                <li>Certify that FATCA code(s) entered on this form (if any) indicating that you are exempt from the
                                    FATCA reporting, is correct. See What is FATCA reporting, later, for further information.</li>
                            </ol>
                            <span style="color:#0594b8"><b>Note:</b> If you are a U.S. person and a requester gives you a form other than Form W-9 to request
                            your TIN, you must use the requester’s form if it is substantially similar to this Form W-9. </span><br>
                            <b>Definition of a U.S. person.</b> For federal tax purposes, you are considered a U.S. person if you
                            are:
                            <ul>
                                <li>An individual who is a U.S. citizen or U.S. resident alien;</li>
                                <li>A partnership, corporation, company, or association created or organized in the United States or
                                    under the laws of the United States;</li>
                                <li>An estate (other than a foreign estate); or</li>
                                <li>A domestic trust (as defined in Regulations section 301.7701-7).</li>
                            </ul>
                            <b>Special rules for partnerships.</b>
                            Partnerships that conduct a trade or business in the United States are generally required to pay a
                            withholding tax under section 1446 on any foreign partners’ share of effectively connected taxable
                            income from such business. Further, in certain cases where a Form W-9 has not been received, the rules
                            under section 1446 require a partnership to presume that a partner is a foreign person, and pay the
                            section 1446 withholding tax. Therefore, if you are a U.S. person that is a partner in a partnership
                            conducting a trade or business in the United States, provide Form W-9 to the partnership to establish
                            your U.S. status and avoid section 1446 withholding on your share of partnership income.</p>
                            <p>In the cases below, the following person must give Form W-9 to the partnership for purposes of
                                establishing its U.S. status and avoiding withholding on its allocable share of net income from the
                                partnership conducting a trade or business in the United States.</p>
                            <ul>
                                <li>In the case of a disregarded entity with a U.S. owner, the U.S. owner of the disregarded entity
                                    and not the entity;</li>
                                <li>In the case of a grantor trust with a U.S. grantor or other U.S. owner, generally, the U.S.
                                    grantor or other U.S. owner of the grantor trust and not the trust; and</li>
                                <li>In the case of a U.S. trust (other than a grantor trust), the U.S. trust (other than a grantor
                                    trust) and not the beneficiaries of the trust.
                                </li>
                            </ul>
            
                           <p> <b>Foreign person.</b> If you are a foreign person or the U.S. branch of a foreign bank that has elected
                            to be treated as a U.S. person, do not use Form W-9. Instead, use the appropriate Form W-8 or Form 8233
                            (see Pub. 515, Withholding of Tax on Nonresident Aliens and Foreign Entities).</p>
            
                           <p> <b>Nonresident alien who becomes a resident alien.</b>Generally, only a nonresident alien individual may
                            use the terms of a tax treaty to reduce or eliminate U.S. tax on certain types of income. However, most
                            tax treaties contain a provision known as a “saving clause.” Exceptions specified in the saving clause
                            may permit an exemption from tax to continue for certain types of income even after the payee has
                            otherwise become a U.S. resident alien for tax purposes.</p>
                            
            
                            <p> If you are a U.S. resident alien who is relying on an exception contained in the saving clause of a
                                tax treaty to claim an exemption from U.S. tax on certain types of income, you must attach a
                                statement to Form W-9 that specifies the following five items.
                            <ol>
                                <li>The treaty country. Generally, this must be the same treaty under which you claimed exemption
                                    from tax as a nonresident alien.</li>
                                <li>The treaty article addressing the income.</li>
                                <li>The article number (or location) in the tax treaty that contains the saving clause and its
                                    exceptions.</li>
                                <li>The type and amount of income that qualifies for the exemption from tax.</li>
                                <li>Sufficient facts to justify the exemption from tax under the terms of the treaty article.</li>
                            </ol>
                            </p>

            
                            <p><b>Example.</b>Article 20 of the U.S.-China income tax treaty allows an exemption from tax for
                                scholarship income received by a Chinese student temporarily present in the United States. Under
                                U.S. law, this student will become a resident alien for tax purposes if his or her stay in the
                                United States exceeds 5 calendar years. However, paragraph 2 of the first Protocol to the U.S.-China
                                treaty (dated April 30, 1984) allows the provisions of Article 20 to continue to apply even after
                                the Chinese student becomes a resident alien of the United States. A Chinese student who qualifies
                                for this exception (under paragraph 2 of the first protocol) and is relying on this exception to
                                claim an exemption from tax on his or her scholarship or fellowship income would attach to Form W-9
                                a statement that includes the information described above to support that exemption.</p>
            
                            <p>If you are a nonresident alien or a foreign entity, give the requester the appropriate completed Form
                                W-8 or Form 8233.</p>
                                </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

            <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-3 variant="info" style="text-align:left;font-size:18px;">Backup Withholding
            <!-- <strong v-if="isNotVisible==true" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:scroll;">
                            <h2>Backup Withholding</h2>
                            <p><b>What is backup withholding?</b>Persons making certain payments to you must under certain
                                conditions withhold and pay to the IRS 24% of such payments. This is called “backup withholding.”
                                Payments that may be subject to backup withholding include interest, tax-exempt interest, dividends,
                                broker and barter exchange transactions, rents, royalties, nonemployee pay, payments made in
                                settlement of payment card and third party network transactions, and certain payments from fishing
                                boat operators. Real estate transactions are not subject to backup withholding.</p>
            
                            <p>You will not be subject to backup withholding on payments you receive if you give the requester your
                                correct TIN, make the proper certifications, and report all your taxable interest and dividends on
                                your tax return.<br>
                                <b>Payments you receive will be subject to backup withholding if:</b>
                            <ol>
                                <li>You do not furnish your TIN to the requester,</li>
                                <li>You do not certify your TIN when required (see the instructions for Part II for details),</li>
                                <li>The IRS tells the requester that you furnished an incorrect TIN,</li>
                                <li>The IRS tells you that you are subject to backup withholding because you did not report all your
                                    interest and dividends on your tax return (for reportable interest and dividends only), or</li>
                                <li>You do not certify to the requester that you are not subject to backup withholding under 4 above
                                    (for reportable interest and dividend accounts opened after 1983 only).</li>
                            </ol>
            
                            </p>
            
                            <p>Certain payees and payments are exempt from backup withholding. See Exempt payee code, later, and the
                                separate Instructions for the Requester of Form W-9 for more information.</p>
            
                            <p>Also see <i>Special rules for partnerships,</i> earlier.</p>
                             </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 
            
            <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-4 variant="info" style="text-align:left;font-size:18px;">FATCA Reporting
            <!-- <strong v-if="isNotVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" >
        <b-card-body>

             <b-card-text>
                            <h2>What is FATCA Reporting?</h2>
                            <p>The Foreign Account Tax Compliance Act (FATCA) requires a participating foreign financial
                                institution to report all United States account holders that are specified United States persons.
                                Certain payees are exempt from FATCA reporting. See Exemption from FATCA reporting code, later, and
                                the Instructions for the Requester of Form W-9 for more information.</p>
            </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-5 variant="info" style="text-align:left;font-size:18px;">Update Information
            <!-- <strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" >
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:auto;">
                            <h2>Updating Your Information</h2>
                            <p>You must provide updated information to any person to whom you claimed to be an exempt payee if
                                you are no longer an exempt payee and anticipate receiving reportable payments in the future from
                                this person. For example, you may need to provide updated information if you are a C corporation
                                that elects to be an S corporation, or if you no longer are tax exempt. In addition, you must
                                furnish a new Form W-9 if the name or TIN changes for the account; for example, if the grantor of a
                                grantor trust dies.</p>
             </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="" role="tab">
          
        <b-button block v-b-toggle.accordion-6 variant="info" style="text-align:left;font-size:18px;">Penalties
            <!-- <strong v-if="isVisible" aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >-</strong>
           <strong v-else aria-hidden="true" class="ml-auto" style="position:absolute;right:20px;" >+</strong> -->
           </b-button>
      </b-card-header>
      <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel" >
        <b-card-body>

             <b-card-text style="height:190px;overflow-y:auto;">
                            <h2>Penalties</h2>
                            <p><b>Failure to furnish TIN.</b> If you fail to furnish your correct TIN to a requester, you are
                                subject to a penalty of $50 for each such failure unless your failure is due to reasonable cause and
                                not to willful neglect.</p>
            
                            <p><b>Civil penalty for false information with respect to withholding.</b>If you make a false
                                statement with no reasonable basis that results in no backup withholding, you are subject to a $500 </p>   
                     
                            <p><b>Criminal penalty for falsifying information.</b> Willfully falsifying certifications or
                                affirmations may subject you to criminal penalties including fines and/or imprisonment.</p>
            
                            <p><b>Misuse of TINs.</b>If the requester discloses or uses TINs in violation of federal law, the
                                requester may be subject to civil and criminal penalties.</p>
                    </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card> 

                   </div>
                  
                   <div class="button_container">
                    <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                    <div class="button">
                     <button class="next" @click="saveGeneralInstruction">Next</button>
                </div>
                </div>

            </div>
        </div>
    </div>
</template>
<style src="../../assets/css/W9_form/general.css" scoped>
 </style>
 <script>
import AgentInfo from "../../components/common/AgentInfo";
import W9FormSidebar from '../../components/partial/W9FormSidebar.vue'
import html2canvas from 'html2canvas';
import axios from "axios";
export default {
    components:{ AgentInfo, W9FormSidebar },
    data(){
      return {
          tempid:'',
          isVisible:false,
          isNotVisible:true
      }
    },
    mounted() {
        let app = this;
        app.tempid= window.localStorage.getItem('tempId');
    },
    methods:{
        navigatePrevious() {
          this.$router.push("certification");
          window.localStorage.setItem('certification_complete', 'incomplete');
        },
        saveGeneralInstruction: function () {
            let app = this;
            const el = this.$refs.save;
            (async () => {
            this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'));
            })();

            let params = {
                tempid : app.tempid
            };
            //discard null values
            for (const key of Object.keys(params)) {
                if (params[key] === null) {
                    delete params[key];
                }
            }

            axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-general-information-w9', params)
            .then(function (response) {
                if (response.data.status == 'success') {
                    console.log("success");
                    // window.localStorage.setItem('tempId', response.data.data.id);
                    app.$router.push("specific_instruction");
                    window.localStorage.setItem('general_complete', 'complete');
                } else {
                  window.location = '/contract-form/contract_message/error';
                } 
            })
            .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                        app.formErrorFlag = true;
                        app.formErrors[item.target_element+'_message'] = item.error_message;
                    });
                }
            });
        },
        saveScreenShot (image) {
            let data = {           
                'agent_id': window.localStorage.getItem('agentId'),
                'level': '0',
                'contract_type': 'w9',
                'image': image,
                'name': 'general',
                "tempid" : this.tempid,
            };
            let config = {
              method: 'post',
              url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
              headers: { },
              data : data
            };

          axios(config)
                  .then(function (response) {
                    if (response.data.status == 'success') {
                      console.log('screenshot saved');
                    }
                  })
                  .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                  });
        }, 
    }
}
</script>