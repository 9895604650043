<template>
  <div class="content_section business">
    <div class="rep_info">
      <h2>Business Information</h2>
      <form class="" action="index.html" method="post">
        <div class="input_box half">
          <label for="">Business Tax ID <span class="red">*</span></label>
          <div class="inputs">
            <input type="text" name="" value=""/>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="input_box half">
          <label for="">Business Name<span class="red">*</span></label>
          <div class="inputs">
            <input type="text" name="" value=""/>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="input_box">
          <label for="">Business Street Address <span class="red">*</span></label>
          <div class="inputs">
            <input type="text" name="" value=""/>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="input_box half">
          <label for="">Apt/Suite/Other</label>
          <div class="inputs">
            <input type="text" name="" value=""/>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="input_box half">
          <label for="">Business City<span class="red">*</span></label>
          <div class="inputs">
            <input type="text" name="" value=""/>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="input_box half">
          <label for="">Business State<span class="red">*</span></label>
          <div class="inputs">
            <input type="text" name="" value=""/>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="input_box half">
          <label for="">Business Zip Code<span class="red">*</span></label>
          <div class="inputs">
            <input type="text" name="" value=""/>
            <p class="error_message">This is a required field</p>
          </div>
        </div>
        <div class="buttonbox">
          <button @click="navigatePrevious" class="previous" type="button" name="button">Previous</button>
          <button class="save" type="button" name="button">Save</button>
          <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
    name: "BusinessInformation",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
