var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "re_wrapper group_register"
  }, [_c('div', {
    staticClass: "bg_overlay"
  }, [_c('div', {
    staticClass: "re_container"
  }, [_c('h1', [_vm._v("Group Registration")]), _c('div', {
    staticClass: "cnt_wrapper"
  }, [_vm.tabName ? _c('div', {
    staticClass: "reg_container"
  }, [_c('group-menu-nav'), _c('router-view', {
    attrs: {
      "navdata": _vm.group_nav_array
    },
    on: {
      "comEnvNavigation": _vm.comEnvNavigationFn,
      "comEnvNavigationBack": _vm.comEnvNavigationBackFn
    }
  })], 1) : _vm._e()])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }