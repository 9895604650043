var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('vue-confirm-dialog', {
    staticClass: "member-app-confirm-dialog"
  }), _vm.MemberHeader ? _c('div', {}, [_c('Header')], 1) : _vm._e(), _vm.defaultHeader || _vm.repHeader ? _c('div', {}, [_c('RepRegistrationHeader')], 1) : _vm._e(), _vm.reviewHeader ? _c('div', [_c('review-header')], 1) : _vm._e(), _c('div', {
    staticClass: "member-body-wrap"
  }, [_c('router-view')], 1), _vm.contractFooter ? _c('div', {}, [_c('ContractFooter')], 1) : _vm._e(), _vm.defaultFooter ? _c('div', [_c('Footer')], 1) : _vm._e(), _vm.reviewFooter ? _c('div', [_c('review-footer')], 1) : _vm._e(), _c('notifications'), _c('app-download-modal', {
    attrs: {
      "appUrl": _vm.appDownloadUrl
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }