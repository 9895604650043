var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "container-wrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "rewiew-option-block"
  }, [_c('router-link', {
    attrs: {
      "to": "reviewform?owner=members"
    }
  }, [_c('div', {
    staticClass: "img-icons"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/review-images/icons/member.svg"),
      "alt": ""
    }
  })]), _c('h3', [_vm._v("Member / Employee "), _c('br'), _vm._v(" Feedback")])])], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "rewiew-option-block"
  }, [_c('router-link', {
    attrs: {
      "to": "reviewform?owner=employers"
    }
  }, [_c('div', {
    staticClass: "img-icons"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/review-images/icons/group.svg"),
      "alt": ""
    }
  })]), _c('h3', [_vm._v("Employers / Groups "), _c('br'), _vm._v(" Feedback")])])], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "rewiew-option-block"
  }, [_c('router-link', {
    attrs: {
      "to": "reviewform?owner=brokers"
    }
  }, [_c('div', {
    staticClass: "img-icons"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/review-images/icons/broker.svg"),
      "alt": ""
    }
  })]), _c('h3', [_vm._v("Broker / Reps "), _c('br'), _vm._v(" Feedback")])])], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "review-option-site-title"
  }, [_c('h1', [_vm._v("We are constantly striving to improve and value your input, "), _c('br'), _vm._v(" please select from the 3 options below to submit your feedback. ")])])]);

}]

export { render, staticRenderFns }