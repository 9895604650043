var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "alert-popup2",
      "centered": "",
      "title": "Plan Renewal Options",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "alert-popup"
  }, [_c('p', {
    staticClass: "mb-4 text-center"
  }, [_vm._v(" Do you want to confirm current plan to new plan? ")]), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Current Plans")]), _c('th', [_vm._v("New Plan")]), _c('th', [_vm._v("Effective Date")]), _c('th', [_vm._v("Rate")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.currentPlan.web_display_name))]), _c('td', [_vm._v(_vm._s(_vm.newPlan.web_display_name))]), _c('td', [_vm._v(_vm._s(_vm.date))]), _c('td', [_vm._v(_vm._s(_vm._f("currency")(_vm.newPlan.price)))])])])])]), _c('div', [_c('p', [_vm._v(" Application Signature (Required) "), _c('span', [_vm._v("(Sign Name using your mouse or touch screen in the box below)")])]), _c('signature-pad', {
    on: {
      "sign": _vm.onEndSign
    }
  })], 1)]), _c('br'), _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    class: _vm.sign ? 'accept-btn' : 'accept-btn disable-btn',
    attrs: {
      "disabled": _vm.sign ? false : true
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.switchPlan.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isSwitch ? "Switching Plan.." : "Switch Plan") + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }