<template>
  <div>
    <div v-if="remaningSecond">
      <div class="progress">
        <div
          class="progress-bar bg-danger"
          role="progressbar"
          :style="{ width: remainingPercentage + '%' }"
          aria-valuenow="60"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <i>Please wait until {{ remaningSecond }} sec, for next request.</i>
    </div>
    <span v-else class="resend_otp" @click="sendOtpCode">
      {{ title }}
    </span>
  </div>
</template>

<script>
/* eslint-disable */
import Cookies from "js-cookie";
export default {
  name: "OTPTimer",
  props: {
    expirySecond: {
      type: Number,
      required: true,
      default: "0",
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      remaningSecond: 0,
      remainingPercentage: 100,
    };
  },
  mounted() {
    //if (!Cookies.get("otp_wait_time_end")) {
      this.setTimer();
    //}
  },
  destroyed() {
    Cookies.remove("otp_wait_time_end");
  },
  methods: {
    setTimer() {
      if (!this.getCookieDate()) {
        this.setCookie();
      }
      this.setOTPInterval();
    },
    setCookie() {
      Cookies.set(
        "otp_logged_time",
        this.getTimeStamp(this.expirySecond).getTime(),
        {
          expires: this.getTimeStamp(this.expirySecond),
          secure: false,
        }
      );
    },
    getCookieDate() {
      return Cookies.get("otp_logged_time");
    },
    setOTPInterval() {
      let interval = setInterval(() => {
        let remaining = Math.round(
          (this.getCookieDate() - new Date().getTime()) / 1000
        );
        this.remaningSecond = isNaN(remaining) ? 0 : remaining;
        this.remainingPercentage =(this.remaningSecond / this.expirySecond) * 100;
        
        if (!this.remaningSecond) {
          Cookies.set("otp_wait_time_end", 1,{expires: this.getTimeStamp(600),secure: false});
          clearInterval(interval);
        }
      }, 1000);
    },
    sendOtpCode() {
      this.$emit("sendOTP");
      Cookies.remove("otp_wait_time_end");
      this.setTimer();
    },
    getTimeStamp(expirySecond) {
      return new Date(new Date().getTime() + expirySecond * 1000);
    },
  },
};
</script>
<style scoped>
.resend_otp {
  cursor: pointer;
  color: "blue";
  text-decoration: underline;
}
</style>
