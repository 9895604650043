var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.remaningSecond ? _c('div', [_c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress-bar bg-danger",
    style: {
      width: _vm.remainingPercentage + '%'
    },
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "60",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  })]), _c('i', [_vm._v("Please wait until " + _vm._s(_vm.remaningSecond) + " sec, for next request.")])]) : _c('span', {
    staticClass: "resend_otp",
    on: {
      "click": _vm.sendOtpCode
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }