<template>
  <div class="content_section contact_info">
                <div class="rep_info">
                  <h2>ContactInformation</h2>
                  <form class="" action="index.html" method="post">
                    <div class="input_box">
                      <label for="">Mobile Phone Number<span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                        <p class="error_message">This is a required field</p>
                      </div>
                    </div>
                    <div class="input_box">
                      <label for="">Select Time Zone <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                        <p class="error_message">This is a required field</p>
                      </div>
                    </div>
                    <div class="input_box">
                      <label for="">Email <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                        <p class="error_message">This is a required field</p>
                      </div>
                    </div>
                    <div class="input_box">
                      <label for="">Phone No</label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                        <p class="error_message">This is a required field</p>
                      </div>
                    </div>
                    <div class="input_box">
                      <label for="">Fax</label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                        <p class="error_message">This is a required field</p>
                      </div>
                    </div>
                    <div class="buttonbox">
                      <button @click="navigatePrevious" class="previous" type="button" name="button">Previous</button>
                      <button class="save" type="button" name="button">Save</button>
                      <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
                    </div>
                  </form>
                </div>
              </div>
</template>
<script>
export default {
    name: "ContactInformation",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
