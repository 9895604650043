<template>
    <div class="agent_container">
    <div class="agent_information">
        <div class="agent_name">
            Contract Name:<strong>{{computedContractName}}</strong>
        </div>
        <div class="agent_name">
            Agent Name:<strong>{{agentName}}</strong>
        </div>
        <div class="agent_code">
            Agent Code:<strong>{{agentCode}}</strong>
        </div>
    </div>
    </div>
</template>
<script>

    import axios from 'axios';
    export default{
        data: function(){
            return{
                agentId: '',
                agentName:'',
                agentCode: '',
                contractType: '',
            }
        },
        mounted(){
            let app = this;
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('id')) {
              let urlagentId = urlParams.get('id');
              app.agentId = atob(urlagentId);
              
            } else {
                app.agentId = window.localStorage.getItem('agentId');
            }
            app.getAgentInfo();
            app.contractType = window.localStorage.getItem('type');
        },
        methods:{
            getAgentInfo: function () {
              let app = this;
              console.log(app.agentId);
              axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-rep-information?', {
                params: {
                    agentid: app.agentId,
                }
              })
              .then(function (response) {
                  console.log(response);
                if (response.data.status == 'success') {
                    
                    app.agentName = response.data.data.result.agent_fname+' '+response.data.data.result.agent_lname;
                    app.agentCode = response.data.data.result.agent_code;
                    
                }
              })
              .catch(function (error) {
                  console.log(error);
              });
            }    
        },
        /**
         * show contract type name from url type for null value
         */
        computed:{
            
            computedContractType() {
                const contractType = this.$route.query.type;
                if(!contractType && localStorage.getItem('headerType')){
                    return localStorage.getItem('headerType');
                }
                return contractType ? contractType : 'N/A';
            },
            computedContractName() { // camelCase -> Title Case
                return this.computedContractType
                    // .replace(/[A-Z]/g, ' $&').split(' ')
                    // .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase()).join(' ');
            }
        }
    };
</script>
<style scoped>
.agent_container{
    width:100%;
    max-width:1170px;
    margin:0 auto;
    font-size:14px;
}
.agent_information{
    text-align: right;
    padding: 1rem 0 2rem;
}
.agent_name{
    border-right: 1px solid #fff;
}
.agent_name, .agent_code{
    color: #fff;
    display:inline;
    font-weight: 100;
    font-size: 16px;
    padding: 0 10px;
}
.agent_name strong, .agent_code strong{
    padding-left: 5px;
}
@media only screen and (max-width:991px){
    .agent_container{
        display:flex;
        justify-content: center;
    }
    .agent_information{
        float:none;
    }
}
@media only screen and (max-width:600px){
    .agent_information{
        flex-direction: column;
        padding:15px;
    }
    .agent_name{
        width:100%;
        margin:0;
    }
    .agent_code{
          width:100%;
          margin:15px 0 0 0;
    }
    .agent_name{
        border-right: 0px;
    }
    .agent_name, .agent_code{
        display: block;
        text-align: center;
    }
}
</style>