var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    ref: "save",
    staticClass: "contract_content"
  }, [_c('contract-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_c('h3', [_vm._v("Contact Information")]), _c('div', {
    staticClass: "form"
  }, [_c('div', [_c('label', [_vm._v("Business or Corporate Name")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.business_name.value,
      expression: "formFields.business_name.value"
    }],
    attrs: {
      "type": "text",
      "name": "business_name",
      "value": "",
      "readonly": _vm.formFields.business_name.read_only == true
    },
    domProps: {
      "value": _vm.formFields.business_name.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.business_name, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['business_name_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Email")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.email.value,
      expression: "formFields.email.value"
    }],
    attrs: {
      "type": "text",
      "name": "email",
      "value": "",
      "readonly": _vm.formFields.email.read_only == true
    },
    domProps: {
      "value": _vm.formFields.email.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.email, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['email_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Telephone Number")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.telephone.value,
      expression: "formFields.telephone.value"
    }],
    attrs: {
      "type": "text",
      "name": "telephone",
      "value": "",
      "readonly": _vm.formFields.telephone.read_only == true
    },
    domProps: {
      "value": _vm.formFields.telephone.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.telephone, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['telephone_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Cell Phone Number")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.cell_phone.value,
      expression: "formFields.cell_phone.value"
    }],
    attrs: {
      "type": "text",
      "name": "cell_phone",
      "value": "",
      "readonly": _vm.formFields.cell_phone.read_only == true
    },
    domProps: {
      "value": _vm.formFields.cell_phone.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.cell_phone, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['cell_phone_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Fax Number")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.fax.value,
      expression: "formFields.fax.value"
    }],
    attrs: {
      "type": "text",
      "name": "fax",
      "value": "",
      "readonly": _vm.formFields.fax.read_only == true
    },
    domProps: {
      "value": _vm.formFields.fax.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.fax, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['fax_message']))]) : _vm._e()])]), _c('div', {
    staticClass: "button_container"
  }, [_c('button', {
    staticClass: "back",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Back")]), _c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.saveContactInfo
    }
  }, [_vm._v("Next")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }