var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "top-content"
  }, [_c('h2', [_vm._v("Welcome, " + _vm._s(_vm.personal_details.fname) + "!")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'MemberDashboard'
      }
    }
  }, [_c('small', [_vm._v("Member Dashboard")])]), _c('div', {
    staticClass: "right-top"
  }, [_c('p', [_c('span', [_vm._v("IP Address: ")]), _vm._v(_vm._s(_vm.personal_details.ip_address))]), _c('p', [_c('span', [_vm._v("Date: ")]), _vm._v(_vm._s(_vm.login_time))]), _c('p', [_vm.login_time != '' ? _c('span', [_vm._v("Last login: " + _vm._s(_vm.last_login))]) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }