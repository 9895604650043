<template>
  <div class="message_center_new dashboard-card mt-4">
    <div class="message_head">
      <h3>Knowledge Center</h3>
      <router-link :to="{ name: 'ViewKnowledge' }"
        ><small>View All</small></router-link
      >
    </div>
    <b-skeleton-table
      v-if="skeletonLoader"
      :rows="5"
      :columns="2"
      :table-props="{ bordered: true, striped: true }"
    ></b-skeleton-table>
    <div class="dashboard-msg-body" v-if="!skeletonLoader">
      <div class="msg-list" v-for="(item, index) in knowledge_feeds" :key="index">
        <div class="message">
          <p>{{ formatTitle(item.title)  }}</p>
          <small>{{ formatDate(item.published_date) }}</small>
        </div>
        <button
          @click="$bvModal.show('knowledge_modal_' + item.id)"
          class="read"
        >
          Read More
        </button>
      </div>
    </div>
    <!-- <a class="view" href="#">View All</a> -->

    <b-modal
      v-for="(item, index) in knowledge_feeds"
      :key="index"
      :title="item.title"
      :id="'knowledge_modal_' + item.id"
      class="message_modal new_one"
      :centered="true"
      :hide-footer="true"
      size="lg"
    >
      <div class="modal-body">
        <div class="rcv-msgcontent">
          <div class="rcv-msg-sender">
            <div class="rcv-snder-icon">
             <img :src="item?.created_user?.agent_profile_image ?? $dummyImage"  alt="" class="msg-userIcon" />
              <div class="rcv-sender-details">
                <h6>{{ item?.created_user?.rep_full_name ?? 'ADMIN' }}</h6>
                <p>
                  <i class="far fa-calendar-alt"></i>
                  {{
                    item.published_date | date_format("YYYY-MM-DD", "MM/DD/YYYY", item.published_date)
                  }}
                </p>
              </div>
            </div>
          </div>
          <h5>Title: {{ item.title }}</h5>
          <hr />
          <div class="ql-snow">
            <div class="ql-editor">
              <p v-html="item.description" />
            </div>
          </div>
       <div v-if="item.file && item.file.length > 0">
        <h6>Attachments:</h6>
        <ul style="list-style: none">
          <li v-for="file in item.file" :key="file.id">
            <a :href="file.path" target="__blank" download>
              <i class="fa fa-file"></i>
              {{ file.filename }}
            </a>
          </li>
        </ul>
      </div>
        </div>

       
      </div>
      <div class="modal-footer"></div>
    </b-modal>
  </div>
</template>

<script>

import axios from "axios";
import helper from "../../resource/Helper"
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      knowledge_feeds: [],
      skeletonLoader: true,
    };
  },
  computed:{
    ...mapGetters([
      'activePlanId'
    ])
  },
  mounted() {
    this.getKnowledgeFeed();
  },
  methods: {
    getKnowledgeFeed() {
      return axios
        .get("/knowledge-feed/merged-feed?user_related_feed=1")
        .then((response) => {
          this.knowledge_feeds = response.data.data;
          this.skeletonLoader = false;
        })
        .error((err) => {});
    },
    formatDate(date) {
      return helper.formatDate(date);
    },
    formatTitle(title) {
      return title.length > 30 ? title.substr(0, 30)+'...' : title;
    },
    downloadFile(file) {
      window.open( process.env.VUE_APP_PURENROLL_KNOWLEDGE_CENTER_FILE+file);
    }
  },
};
</script>
<style scoped>
.message_center {
  margin-top: 20px;
}
.message_modal {
  color: #323c47;
}
.resource_item {
  margin-top: 6px;
  padding-left: 12px;
}
.attchement_details .row {
  background: #f1efef;
}
.attchement_details {
  padding-top: 12px;
  border-top: 1px solid grey;
  margin-top: 20px;
}

.message_body >>> h2 {
  font-size: 20px !important;
}
.message_body >>> h2 strong {
  font-size: 20px !important;
}
.message_body >>> h3 strong {
  font-size: 20px !important;
}

/* .message_center .column {
  overflow: hidden;
  overflow-y: auto;
  height: 479px;
}
.message_center .column::-webkit-scrollbar {
  width: 6px;
}
.message_center .column::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.message_center .column::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
} */
@media only screen and (max-width: 1290px) {
  .message_center {
    margin-top: 30px;
  }
}
 .modal-body {
    padding-top: 0px !important;
  }
  .modal-header {
    padding-bottom: 0px !important;
  }
</style>
