import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import Helper from "@/resource/Helper"

Vue.use(Vuex);
const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    authenticated: false,
    memberEmail: "",
    accessToken: "",
    memberId: "",
    profilePic: "",
    deviceId: "",
    groupType: "",
    memberPhone: "",
    otpSend: false,
    emailOtp: "",
    phoneOtp: "",
    OTPCode: "",
    user: null,
    activePlanId:[],
    activePolicyId:[]
  },
  mutations: {
    setUserData(state, data) {
      state.user = data;
    },
    setAuthentication(state, status) {
      state.authenticated = status;
    },
    setMemberEmail(state, email) {
      state.memberEmail = email;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
      axios.defaults.headers["Authorization"] =
        token != "" ? "Bearer " + token : "";
    },
    setMemberId(state, memberId) {
      state.memberId = memberId;
    },
    setProfilePic(state, profilePic) {
      state.profilePic = profilePic;
    },
    setDeviceId(state, deviceId) {
      state.deviceId = deviceId;
    },
    setGroupType(state, groupType) {
      state.groupType = groupType;
    },
    setMemberPhone(state, data) {
      state.memberPhone = data
    },
    setOtpSend(state, data) {
      state.otpSend = data
    },
    setEmailOtp(state, data) {
      state.emailOtp = data
    },
    setPhoneOtp(state, data) {
      state.phoneOtp = data
    },
    setOtpCode(state, data) {
      state.OTPCode = data
    },
    setActivePlanId(state,data){
      state.activePlanId = data
    },
    setActivePolicyId(state,data){
      state.activePolicyId = data
    }
    
  },
  getters: {
    // count: (state) => {
    //   return state.count;
    // },
    count: state => state.count,
    memberPhone: state => state.memberPhone,
    memberId: state => state.memberId,
    accessToken: state => state.accessToken,
    user: state => state.user,
    groupType: state => state.groupType,
    activePlanId:state =>state.activePlanId,
    activePolicyId: state =>state.activePolicyId,
    memberEmail: state=> state.memberEmail,
    authenticated:state=>state.authenticated
  },
  actions: {
    async loadUserDetail({ commit }) {
      await axios.get('/get-user-details')
      .then((res)=>{
        const data = res.data.data
        Helper.setUserDetail(data)
        commit('setGroupType',data.employer_type)
      })
      .catch(()=>{
        commit('setUserData', null);
        commit('setGroupType',null)
      })
    }
  },
});
export default store;
