var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper document-view-details"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row body-main-row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-8 member_dashboard"
  }, [_vm.skeletonLoader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 15,
      "columns": 1,
      "table-props": {
        striped: true
      }
    }
  }) : _vm._e(), !_vm.skeletonLoader ? _c('div', {
    staticClass: "document-view-body",
    staticStyle: {
      "margin-top": "9%"
    }
  }, [_vm.isComplete ? _c('div', {
    staticStyle: {
      "padding": "16%"
    }
  }, [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.alreadySubmitted ? "Signature has already been submitted" : "Signature submitted successfully."))])]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('p', [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Go to Dashboard ")])])])], 1) : _vm._e(), !_vm.isPolicyActive ? _c('div', {
    staticStyle: {
      "padding": "16%"
    }
  }, [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.inactiveMessage))])])]) : _vm._e(), !_vm.isComplete && _vm.isPolicyActive ? _c('div', [_c('div', {
    staticClass: "directory-block signatureData"
  }, [_c('h2', [_vm._v(_vm._s(_vm.data.user_info.name))]), _c('figure', [_vm._v("Effective Date: " + _vm._s(_vm.data.enrollment_information.effective_date))])]), _c('div', {
    staticClass: "signatureTerms"
  }, [_c('p', [_vm._v("This is to verfiy your email " + _vm._s(_vm.data.user_info.email) + " to proceed further. We will notify your further through this email. Please validate by your signature below for confirmation.")])]), _vm._m(0), _c('div', {
    staticClass: "new-rate-details"
  }, [_c('ul', [_c('li', [_vm._v(" Enrollment #: "), _c('strong', [_vm._v(_vm._s(_vm.data.enrollment_information.policy_id))])]), _c('li', [_vm._v(" Tier: "), _c('strong', [_vm._v(_vm._s(_vm.data.tier))])])])]), _vm._m(1), _c('div', {
    staticClass: "new-rate-details"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Enrollment Information")]), _c('ul', [_c('li', [_c('strong', [_vm._v("System ID Number:")]), _vm._v(" " + _vm._s(_vm.data.enrollment_information.policy_id))]), _c('li', [_c('strong', [_vm._v("Enrollment Date:")]), _vm._v(" " + _vm._s(_vm.data.enrollment_information.enrollment_date))]), _c('li', [_c('strong', [_vm._v("Effective Date:")]), _vm._v(" " + _vm._s(_vm.data.enrollment_information.effective_date))]), _c('li', [_c('strong', [_vm._v("Group Name :")]), _vm._v(" " + _vm._s(_vm.data.enrollment_information.group_name))])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Enrolled In")]), _c('ul', _vm._l(_vm.data.plan_list, function (item, index) {
    return _c('li', {
      key: index
    }, [_vm._v(_vm._s(item) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Primary Applicant")]), _c('ul', [_c('li', [_c('strong', [_vm._v(" " + _vm._s(_vm.data.user_info.name))])]), _c('li', [_c('strong', [_vm._v("Age:")]), _vm._v(" " + _vm._s(_vm.data.user_info.age))]), _c('li', [_c('strong', [_vm._v("Employer/Group:")]), _vm._v(_vm._s(_vm.data.enrollment_information.group_name))]), _c('li', [_c('strong', [_vm._v("Phone:")]), _vm._v(_vm._s(_vm.data.user_info.phone))]), _c('li', [_c('strong', [_vm._v("Email:")]), _vm._v(" " + _vm._s(_vm.data.user_info.email))])])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Address")]), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.data.user_info.address1))]), _c('li', [_vm._v(_vm._s(_vm.data.user_info.address2))])])])]), !_vm.data.user_info.is_employer_type ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Payment Info")]), _c('ul', [_c('li', [_c('strong', [_vm._v("Membership Processing Fee: ")]), _vm._v(_vm._s(_vm.data.payment_info.enrollment_fee))]), _c('li', [_c('strong', [_vm._v("First Month Total: ")]), _vm._v(_vm._s(_vm.data.payment_info.first_month))]), _vm.data.payment_info.payment_method == 'cc' ? _c('li', [_c('strong', [_vm._v("Payment Service Fee ")]), _vm._v(_vm._s(_vm.data.payment_info.cc_fee))]) : _vm._e(), _c('li', [_c('strong', [_vm._v("Recurring Month Total: ")]), _vm._v(" " + _vm._s(_vm.data.payment_info.recurring_amount))]), _c('li', [_c('strong', [_vm._v("Payment Method: ")]), _vm._v(" " + _vm._s(_vm.data.payment_info.payment_method))])])])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "policy-Info mb-4"
  }, [_c('h4', [_vm._v("Representative")]), _c('ul', [_c('li', [_c('strong', [_vm._v(_vm._s(_vm.data.rep.agent_name))])]), _c('li', [_vm._v(_vm._s(_vm.data.rep.agent_code))]), _c('li', [_vm._v(_vm._s(_vm.data.rep.agent_email))]), _c('li', [_vm._v(_vm._s(_vm.data.rep.agent_phone))])])])])])]), _vm.carrierSpecialMessage ? _c('div', {
    staticClass: "carrier-message-container mb-3"
  }, [_vm._v(" " + _vm._s(_vm.carrierSpecialMessage) + " ")]) : _vm._e(), _c('div', {
    staticClass: "signatureTerms"
  }, [_c('h2', [_vm._v("Terms & Conditions")]), _vm._m(2), _c('label', {
    staticClass: "checkbox-inline"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.is_term_agree,
      expression: "is_term_agree"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.is_term_agree) ? _vm._i(_vm.is_term_agree, null) > -1 : _vm.is_term_agree
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.is_term_agree,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.is_term_agree = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.is_term_agree = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.is_term_agree = $$c;
        }
      }
    }
  }), _vm._v(" I Agree Terms & Conditions ")])]), _c('div', {
    staticClass: "signature"
  }, [_vm._m(3), _c('signature-pad', {
    ref: "signpad",
    on: {
      "sign": _vm.getSign
    }
  }), _vm.isSignRequired ? _c('div', {
    staticClass: "invalid-feedback",
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "tabindex": "-1",
      "role": "alert",
      "aria-live": "assertive",
      "aria-atomic": "true"
    }
  }, [_vm._v(" Sign is required. ")]) : _vm._e(), _vm._m(4), _c('br'), _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "submitSignature",
    attrs: {
      "type": "button",
      "disabled": !_vm.is_term_agree
    },
    on: {
      "click": function ($event) {
        return _vm.submitSign();
      }
    }
  }, [_vm.signatureLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit Signature ")], 1)])], 1)]) : _vm._e()]) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Sign in and Complete your Membership Enrollment")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('strong', [_vm._v("Policy Information")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', [_c('li', [_vm._v("The member shall agree that the Plan is final as written and will be administrated as such.")]), _c('li', [_vm._v("The member shall agree that all plan and eligibility changes must be approved by the Plan Supervisor.")]), _c('li', [_vm._v("The member shall agree to be drafted on the payment date noted in the confirmation email monthly.")]), _c('li', [_vm._v("The member shall submit a request for termination in advance of the premium draft corresponding to the termination date. All terminations will be processed effective the last day of the month.")]), _c('li', [_vm._v("The member shall make up any failed payment within 30 days of the scheduled draft date. Failure to remit payment within this time frame will result in plan termination.")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signature_title"
  }, [_c('p', [_c('b', [_vm._v("Application Signature (Required): ")]), _c('span', [_vm._v("(Sign Name using your mouse or touch screen in the box below)")])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "osf"
  }, [_c('em', [_vm._v("With signature your enrollment will be complete.")])]);

}]

export { render, staticRenderFns }