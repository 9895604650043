<template>
  <div class="dashboard-card dashboard-card-lt mt-4 pt-3  new-req-tbl">
    <div class="table_content table_add-req table-responsive mt-2">
      <table>
        <thead>
          <tr>
            <td style="width: 10%;">System ID</td>
            <td style="width: 15%;">Type</td>
            <td style="width: 50%; word-break: break-all;">Information</td>
            <td style="width: 15%;">Status</td>
            <td style="width: 15%;">Created At</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in rows.records" :key="index">
            <td style="font-family: SF UI Display Medium">{{ item.policy_id }}</td>
            <td style="font-family: SF UI Display Medium">{{ item.type_name }}</td>
            <td>
              <div class="member-card-container p-0">
                {{ item.information }}
              </div>
            </td>
            <td :class="getStatusClass(item.status_name)">  
              {{ item.status_name }}
            </td>
            <td>{{ item.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      required: true,
    },
  },
  methods: {
    getStatusClass(status) {
      switch (status.toLowerCase()) {
        case 'rejected':
          return 'reject_status';
        case 'in progress':
          return 'progress_status';
        case 'pending':
          return 'pending_status';
        case 'completed':
          return 'completed_status';
        default:
          return '';
      }
    },
  },
};
</script>

<style>
.member-card-container{
  max-height: 200px;
  overflow-y: auto;
  /* padding: 10px; */
}
.new-req-tbl .table_content table tr td {
  font-size: 14px;
  padding: 1rem;
}
.new-req-tbl .table_content table thead tr td {
  font-weight: 600;
}
.new-req-tbl .table_content table thead {
  background-color: #00ccff11;
  border-radius: 4px;
}
.new-req-tbl .table_content tbody tr td:first-child {
  font-weight: 400;
}
.reject_status {
  color: red !important;
  font-weight: 700;
  text-transform: uppercase;
}
.pending_status {
  color: orange !important;
  font-weight: 700;
  text-transform: uppercase;
}
.progress_status {
  font-weight: 700;
  color: #0385cf;
  text-transform: uppercase;
}
.completed_status {
  font-weight: 700;
  text-transform: uppercase;
  color: green;
}
</style>
