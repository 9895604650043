var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "container-wrapper"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8 offset-md-2"
  }, [_c('div', {
    staticClass: "message-wrap white-wrap text-center"
  }, [_vm._m(0), _c('h1', [_vm._v("Thank you for your review in "), _c('br'), _vm._v(" " + _vm._s(_vm.ownerTitle))]), _vm._m(1)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "message-image"
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/review-images/thankyou.svg"),
      "alt": ""
    }
  })]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v("We have received your Feedback. "), _c('br'), _vm._v(" We are working for better experience on the system.")]);

}]

export { render, staticRenderFns }