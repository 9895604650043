var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "toggleSSN",
    on: {
      "click": _vm.toggleIcon
    }
  }, [_c('span', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": 'eyeIconSlash_' + this.id
    }
  }, [_c('i', {
    staticClass: "far fa-eye-slash"
  })]), _c('span', {
    attrs: {
      "id": 'eyeIcon_' + this.id
    }
  }, [_c('i', {
    staticClass: "far fa-eye"
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }