var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    class: _vm.formGroupClass
  }, [_vm.label ? [_c('label', {
    attrs: {
      "for": `label-${_vm.labelFor || _vm.validationLabel}`
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.computedLabel)
    }
  })])] : _vm._e(), _c('b-form-textarea', {
    class: [_vm.vObject && _vm.vObject.$error ? 'has-error' : '', 'form-control', _vm.inputClass],
    attrs: {
      "id": `label-${_vm.labelFor || _vm.validationLabel}`,
      "placeholder": _vm.computedPlaceholder,
      "rows": "3"
    },
    on: {
      "blur": _vm.handleBlur
    },
    model: {
      value: _vm.computedInputData,
      callback: function ($$v) {
        _vm.computedInputData = $$v;
      },
      expression: "computedInputData"
    }
  }), _vm.vObject && _vm.vObject.$error ? [_c('span', {
    staticClass: "error-text"
  }, [_vm._v(_vm._s(_vm.computedErrorMessage))])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }