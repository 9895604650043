<template>
     <div class="content_wrapper" >
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <contract-form-sidebar/>

            <div class="right_container">
                   <h3>Billing Information</h3>
                   <div class="form">
                       <div>
                           <label>Pay to (check one)</label><br>
                           <div class="option">
                               <div class="individual">
                           <input type="radio" name="radio" value="individual" :checked="formFields.radio.value=='individual'" :readonly="formFields.radio.read_only == true">
                           <label>Individual</label>
                        </div>
                        <div class="business">
                           <input type="radio" name="radio" value="business" :checked="formFields.radio.value == 'business'" :readonly="formFields.radio.read_only == true">
                           <label>Business</label>
                        </div>
                    </div>
                     
                       </div>

                       <div>
                        <label>Paid By</label><br>
                        <input type="text" name="paid" value=""  v-model="formFields.paid.value" :readonly="formFields.paid.read_only == true">
                        <small class="error_message" v-if="formErrorFlag == true">{{formErrors['paid_message']}}</small>
                    </div>
                    <!-- <div class="form" v-if="formFields.paid.value != 'check'"> -->
                       <span class="applicable">ACH Information (if applicable)</span>
                    <div>
                        <label>Routing Number</label><br>

                        <input type="text" name="routing" value="" v-model="formFields.routing.value" :readonly="formFields.routing.read_only == true">
                         <small class="error_message" v-if="formErrorFlag == true">{{formErrors['routing_message']}}</small>

                    </div>

                    <div>
                        <label>Account Number</label><br>
                        <input type="text" name="account_number" v-model="formFields.account_number.value" value="" :readonly="formFields.account_number.read_only == true">
                         <small class="error_message" v-if="formErrorFlag == true">{{formErrors['account_number_message']}}</small>

                    </div>

                    <div>
                        <label>Bank Name</label><br>

                        <input type="text" name="bank_name" v-model="formFields.bank_name.value" value="" :readonly="formFields.bank_name.read_only == true">
                         <small class="error_message"  v-if="formErrorFlag == true">{{formErrors['bank_name_message']}}</small>
                    </div>
                    <div>
                        <label>Account Name</label><br>
                        <input type="text" name="account_name" v-model="formFields.account_name.value" value="" :readonly="formFields.account_name.read_only == true">
                         <small class="error_message"  v-if="formErrorFlag == true">{{formErrors['account_name_message']}}</small>

                    </div>
                    
                    <!-- </div> -->
                   </div> 

                   <div class="button_container">
                   <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                      <div class="button">
                    <!-- <a href="#"><button class="save">Save</button></a> -->

                    <button class="next"  @click="saveBillingInfo">Next</button>
                </div>
                </div>

            </div>
        </div>
    </div>  

</template>

<style src="../../assets/css/contract_form/billing_info.css" scoped>

</style>

<script>
import ContractFormSidebar from '../partial/ContractFormSidebar.vue';
import axios from 'axios';
 import AgentInfo from "../common/AgentInfo.vue"
 import html2canvas from 'html2canvas';
    export default{
        components:{AgentInfo, ContractFormSidebar},
        data: function(){
            return{
              tempId: '',
              formFields :{
                radio: {
                    value: '',
                    read_only: true,
                    required: false,
                },
                paid: {
                    value: '',
                    read_only: true,
                    required: false,
                },
                routing: {
                    value: '',
                    read_only: true,
                    required: false,
                },
                account_name: {
                    value: '',
                    read_only: true,
                    required: false,
                },
                bank_name: {
                    value: '',
                    read_only: true,
                    required: false,
                },
                account_number: {
                    value: '',
                    read_only: true,
                    required: false,
                }
              },
              formErrorFlag: false,
              formErrors: [],
            }
        },
        mounted(){
            let app = this;
            app.tempId = window.localStorage.getItem('tempId');
            app.fetchInfo();
        },
        methods:{
          fetchInfo: function () {
            let app = this;
            axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-form-data-billing-info', {
                params: {
                    tempid: app.tempId,
                }
            })
            .then(function (response) {
                console.log(response);
              let fetchedData = [];
              if (response.data.status == 'success') {
                  for(var i in response.data.data.result) {
                      fetchedData.push(response.data.data.result[i]);
                  }
                  console.log(fetchedData);
                  // app.tempId = response.data.tempid;
                  fetchedData.forEach(function(item) {
                    console.log(item);
                      app.formFields[item.field_name].value = item.value;
                      app.formFields[item.field_name].read_only = item.read_only;
                  });
              }
            })
            .catch(function (error) {
                if(error.response.data.message == 'Form completed.'){
                      window.location = '/contract-form/contract_message/completed';
                  }else if(error.response.data.message == 'Agent does not exist.'){
                      window.location = '/contract-form/contract_message/not_found';
                  } else {
                    window.location = '/contract-form/contract_message/error';
                  }
            });

          },
            
          saveBillingInfo: function () {
              const el = this.$refs.save;
            (async () => {
                this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'billing');
            })();
              let app = this;
              app.formErrorFlag = false;
              app.formErrors    = [];
              let params = {
                tempid: app.tempId,
                routing_number:   app.formFields.routing.value,
                account_name:     app.formFields.account_name.value,
                bank_name:        app.formFields.bank_name.value,
                account_number:    app.formFields.account_number.value,
                pay_to:    app.formFields.radio.value,
                paid_by:    app.formFields.paid.value,
              };
              //discard null values
              for (const key of Object.keys(params)) {
                if (params[key] === null) {
                    delete params[key];
                }
              }

              axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-billing-information', params)
              .then(function (response) {
                  if (response.data.status == 'success') {
                    console.log("success");
                    app.$router.push("commission_sheet");
                    window.localStorage.setItem('billing_complete', 'complete');
                  }
              })
              .catch(function (error) {
                  console.log(error.response.data.data);
                  if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                        app.formErrorFlag = true;
                        app.formErrors[item.target_element+'_message'] = item.error_message;
                    });
                  }
              });
          },
          navigatePrevious() {
              //Update APIs
              //Navigate to next page
              this.$router.push("address_info");
              window.localStorage.setItem('address_complete', 'incomplete');
           
          },
          saveScreenShot (image, name) {
            
            let data = {
                "agent_id" : window.localStorage.getItem('agentId'),
                "contract_type" : window.localStorage.getItem('contractType'),
                "image" : image,
                "name" : name,
                "tempid" : this.tempId,
            };
            let config = {
                method: 'post',
                url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
                headers: { },
                data : data
            };

            axios(config)
                .then(function (response) {
                    if (response.data.status == 'success') {
                        console.log('screenshot saved');
                    }
                })
                .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                });
          },
          scrollTop(){
              window.scrollTo(0,0);
          }
      }
    };
</script>