var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('strong', [_vm._v("Your Current Plan:")]), _c('span', {
    staticClass: "mx-4"
  }, [_vm._v(" " + _vm._s(_vm.currentPlan.formatted_plan_name))]), _c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.alert-popup1",
      modifiers: {
        "alert-popup1": true
      }
    }],
    staticClass: "notice-btn",
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_vm._v("Keep Current Plan At Renewal")]), _c('dashboard-enrollment-current-plan-modal', {
    attrs: {
      "current-plan": _vm.currentPlan
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }