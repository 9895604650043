var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "changePassword",
      "centered": true,
      "title": "Change Password",
      "hide-footer": true,
      "size": "md",
      "no-close-on-backdrop": ""
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "form form_change"
  }, [_c('div', {
    staticClass: "input_form"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Current Password",
      "label-for": "input_changepassword_1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input_changepassword_1",
      "type": "text",
      "required": "",
      "placeholder": "Current Password"
    },
    model: {
      value: _vm.currentpassword,
      callback: function ($$v) {
        _vm.currentpassword = $$v;
      },
      expression: "currentpassword"
    }
  }), _c('eye-button', {
    attrs: {
      "id": "input_changepassword_1",
      "hideOnDefault": "true",
      "type": "text"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["password"]) + " ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "New Password",
      "label-for": "input_changepassword_2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input_changepassword_2",
      "type": "text",
      "required": "",
      "placeholder": "Enter your new password"
    },
    model: {
      value: _vm.newpassword,
      callback: function ($$v) {
        _vm.newpassword = $$v;
      },
      expression: "newpassword"
    }
  }), _c('eye-button', {
    attrs: {
      "id": "input_changepassword_2",
      "hideOnDefault": "true",
      "type": "text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Confirm Password",
      "label-for": "input_changepassword_3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input_changepassword_3",
      "type": "text",
      "required": "",
      "placeholder": "Repeat new password"
    },
    model: {
      value: _vm.confirmpassword,
      callback: function ($$v) {
        _vm.confirmpassword = $$v;
      },
      expression: "confirmpassword"
    }
  }), _c('eye-button', {
    attrs: {
      "id": "input_changepassword_3",
      "hideOnDefault": "true",
      "type": "text"
    }
  })], 1), _c('a', {}, [_c('button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.loginLoader
    },
    on: {
      "click": function ($event) {
        return _vm.changePassword();
      }
    }
  }, [_vm.loginLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)]), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "modal-footer"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }