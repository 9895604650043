var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "personal_info_new dashboard-card"
  }, [_c('div', {
    staticClass: "personal_head"
  }, [_c('h3', [_vm._v("Personal Info")]), _c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.personal_edit",
      modifiers: {
        "personal_edit": true
      }
    }]
  }, [_c('i', {
    staticClass: "fas fa-edit"
  })])]), _c('b-modal', {
    attrs: {
      "ok-disabled": _vm.formSubmitted,
      "id": "personal_edit",
      "centered": true,
      "ok-title": "Submit",
      "title": "Personal Information",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.updatePersonalInfo
    }
  }, [_c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Email",
      "label-for": "input-123",
      "invalid-feedback": "Email  is required"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-123",
      "disabled": ""
    },
    model: {
      value: _vm.personal_address_update.email,
      callback: function ($$v) {
        _vm.$set(_vm.personal_address_update, "email", $$v);
      },
      expression: "personal_address_update.email"
    }
  })], 1), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "First Name",
      "label-for": "input_1_fname",
      "invalid-feedback": "First Name is required"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input_1_fname",
      "state": _vm.validateUpdateDetails('fname')
    },
    model: {
      value: _vm.$v.personal_address_update.fname.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.fname, "$model", $$v);
      },
      expression: "$v.personal_address_update.fname.$model"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Middle Name",
      "label-for": "input_1_middle_name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input_1_middle_name",
      "required": ""
    },
    model: {
      value: _vm.personal_address_update.mname,
      callback: function ($$v) {
        _vm.$set(_vm.personal_address_update, "mname", $$v);
      },
      expression: "personal_address_update.mname"
    }
  })], 1), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Last Name",
      "label-for": "input_1_lastname",
      "invalid-feedback": "Last Name is required"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input_1_lastname",
      "state": _vm.validateUpdateDetails('lname'),
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.lname.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.lname, "$model", $$v);
      },
      expression: "$v.personal_address_update.lname.$model"
    }
  })], 1), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Phone Number",
      "label-for": "input-2",
      "invalid-feedback": _vm.validationErrors.phone
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-2",
      "state": _vm.validateUpdateDetails('phone'),
      "type": "number",
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.phone.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.phone, "$model", $$v);
      },
      expression: "$v.personal_address_update.phone.$model"
    }
  })], 1), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Date of Birth",
      "label-for": "input-3",
      "invalid-feedback": "Date of birth is required"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "locale": "en",
      "id": "input-3",
      "placeholder": _vm.personal_address_update.dob,
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.dob.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.dob, "$model", $$v);
      },
      expression: "$v.personal_address_update.dob.$model"
    }
  })], 1), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Marital Status",
      "label-for": "input-41",
      "invalid-feedback": "Marital Status is required."
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "input-41",
      "options": _vm.marital_status,
      "state": _vm.validateUpdateDetails('marital_status'),
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.marital_status.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.marital_status, "$model", $$v);
      },
      expression: "$v.personal_address_update.marital_status.$model"
    }
  })], 1), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Gender",
      "label-for": "input_gender",
      "invalid-feedback": "Gender is required."
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "input_gender",
      "options": _vm.gender,
      "state": _vm.validateUpdateDetails('gender'),
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.gender.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.gender, "$model", $$v);
      },
      expression: "$v.personal_address_update.gender.$model"
    }
  })], 1), _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "SSN",
      "label-for": "ssnInput",
      "invalid-feedback": _vm.validationErrors.ssn
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "ssnInput",
      "state": _vm.validateUpdateDetails('ssn_full'),
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.ssn_full.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.ssn_full, "$model", $$v);
      },
      expression: "$v.personal_address_update.ssn_full.$model"
    }
  }), _c('eye-button', {
    attrs: {
      "id": "ssnInput",
      "hideOnDefault": "true",
      "type": "number"
    }
  })], 1), _vm.has_height_weight ? _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Height Feet",
      "label-for": "input-42",
      "invalid-feedback": _vm.validationErrors.height
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-42",
      "state": _vm.validateUpdateDetails('height_feet'),
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.height_feet.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.height_feet, "$model", $$v);
      },
      expression: "$v.personal_address_update.height_feet.$model"
    }
  })], 1) : _vm._e(), _vm.has_height_weight ? _c('b-form-group', {
    attrs: {
      "label": "Height Inches",
      "label-for": "input-4",
      "invalid-feedback": "Invalid height inch."
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-4",
      "state": _vm.validateUpdateDetails('height_inches')
    },
    model: {
      value: _vm.$v.personal_address_update.height_inches.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.height_inches, "$model", $$v);
      },
      expression: "$v.personal_address_update.height_inches.$model"
    }
  })], 1) : _vm._e(), _vm.has_height_weight ? _c('b-form-group', {
    staticClass: "required",
    attrs: {
      "label": "Weight (lbs)",
      "label-for": "input-44",
      "invalid-feedback": _vm.validationErrors.weight
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-44",
      "state": _vm.validateUpdateDetails('weight'),
      "required": ""
    },
    model: {
      value: _vm.$v.personal_address_update.weight.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.personal_address_update.weight, "$model", $$v);
      },
      expression: "$v.personal_address_update.weight.$model"
    }
  })], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "ok-disabled": _vm.formSubmitted,
      "id": "address_add",
      "centered": true,
      "ok-title": "Submit",
      "title": "Personal Address - Add",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": _vm.handleSubmit,
      "hidden": function ($event) {
        return _vm.hideModal();
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "input-1",
      "invalid-feedback": "Address is required"
    }
  }, [_c('template', {
    slot: "label"
  }, [_vm._v("Address "), _c('span', [_vm._v(" *")])]), _c('b-form-input', {
    attrs: {
      "id": "input-1",
      "state": _vm.validateState('address1')
    },
    model: {
      value: _vm.$v.form.address1.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.address1, "$model", $$v);
      },
      expression: "$v.form.address1.$model"
    }
  }), !_vm.$v.form.address1.required ? _c('span', {
    staticClass: "field_error",
    staticStyle: {
      "display": "none"
    }
  }, [_vm._v("Field is required")]) : _vm._e()], 2), _c('b-form-group', {
    attrs: {
      "label-for": "input-12",
      "invalid-feedback": "Address is required"
    }
  }, [_c('template', {
    slot: "label"
  }, [_vm._v("Address 2")]), _c('b-form-input', {
    attrs: {
      "id": "input-12",
      "v-model": _vm.form.address2
    }
  })], 2), _c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "input-2",
      "invalid-feedback": "City is required"
    }
  }, [_c('template', {
    slot: "label"
  }, [_vm._v("City "), _c('span', [_vm._v(" *")])]), _c('b-form-input', {
    attrs: {
      "id": "input-2",
      "state": _vm.validateState('city')
    },
    model: {
      value: _vm.$v.form.city.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.city, "$model", $$v);
      },
      expression: "$v.form.city.$model"
    }
  })], 2), _c('b-form-group', {
    attrs: {
      "label": "ZIP",
      "label-for": "input-3",
      "invalid-feedback": "Zip is required"
    }
  }, [_c('template', {
    slot: "label"
  }, [_vm._v("ZIP "), _c('span', [_vm._v(" *")])]), _c('b-form-input', {
    attrs: {
      "id": "input-3",
      "max": "5",
      "type": "number",
      "state": _vm.validateState('zip')
    },
    model: {
      value: _vm.$v.form.zip.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.zip, "$model", $$v);
      },
      expression: "$v.form.zip.$model"
    }
  })], 2), _c('b-form-group', {
    attrs: {
      "label": "State",
      "label-for": "input-4",
      "invalid-feedback": "State is required"
    }
  }, [_c('template', {
    slot: "label"
  }, [_vm._v("State "), _c('span', [_vm._v(" *")])]), _c('b-form-select', {
    attrs: {
      "id": "input-4",
      "options": _vm.states,
      "state": _vm.validateState('state')
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("Select State")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.$v.form.state.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.state, "$model", $$v);
      },
      expression: "$v.form.state.$model"
    }
  })], 2), _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "checkbox-bypass",
      "invalid-feedback": "State is required"
    }
  }), _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "checkbox-bypass",
      "invalid-feedback": "State is required"
    }
  }, [_c('template', {
    slot: "label"
  }), _c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-bypass",
      "name": "checkbox-1",
      "value": "1",
      "unchecked-value": "0",
      "switch": ""
    },
    model: {
      value: _vm.form.bypass,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bypass", $$v);
      },
      expression: "form.bypass"
    }
  }, [_vm._v(" Bypass Address validation ")])], 2), _c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-1",
      "checked": "checked",
      "name": "checkbox-1",
      "value": "1",
      "unchecked-value": "0"
    },
    model: {
      value: _vm.form.is_primary,
      callback: function ($$v) {
        _vm.$set(_vm.form, "is_primary", $$v);
      },
      expression: "form.is_primary"
    }
  }, [_vm._v(" Set as primary ")])], 1), _vm.profile_skeleton_loader ? _c('b-skeleton-img', {
    attrs: {
      "card-img": "top",
      "aspect": "3:1"
    }
  }) : _vm._e(), !_vm.profile_skeleton_loader ? _c('div', {
    staticClass: "profile-details-div"
  }, [_c('div', {
    staticClass: "profile"
  }, [_c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "accept": ".jpg,.jpeg,.png",
      "id": "file_input_personal",
      "type": "file"
    },
    on: {
      "change": _vm.updatePhoto
    }
  }), _c('div', {
    staticClass: "profile_image"
  }, [_c('img', {
    staticClass: "profile_image",
    attrs: {
      "src": _vm.user.image || '/img/default_profile.52292c62.jpg',
      "alt": "profile"
    }
  }), _vm._m(0)])]), _c('div', {
    staticClass: "person_detail"
  }, [_c('h3', [_vm._v(_vm._s(_vm.personal_details.fname) + " " + _vm._s(_vm.personal_details.mname) + " " + _vm._s(_vm.personal_details.lname))]), _c('p', {
    staticClass: "age_gender"
  }, [_vm._v(" " + _vm._s(_vm.personal_details.age) + " " + _vm._s(_vm.personal_details.gender) + " | " + _vm._s(_vm.personal_details.marital_status) + " ")]), _c('p', {
    staticClass: "personal_email"
  }, [_vm._v(" " + _vm._s(_vm.personal_details.email) + " ")]), _c('div', {
    staticClass: "detail"
  }, [_c('ul', [_c('li', [_vm._v("DOB: " + _vm._s(_vm.personal_details.dob))]), _c('li', [_vm._v("PN: " + _vm._s(_vm._f("us_phone")(_vm.personal_details.phone)))])])]), _c('div', {
    staticClass: "personal_ssn"
  }, [_vm._v(" SSN: " + _vm._s(_vm.personal_details.ssn) + " ")])])]) : _vm._e(), _c('div', {
    staticClass: "table_content address-status-wrap"
  }, [_c('div', {
    staticClass: "address-status-inner"
  }, [_vm.address_skeleton_loader ? _c('b-skeleton-table', {
    attrs: {
      "rows": 2,
      "columns": 2,
      "table-props": {
        bordered: true,
        striped: true
      }
    }
  }) : _vm._e(), _vm.address_skeleton_loader == false ? _c('table', [_c('thead', [_c('tr', [_c('td', [_vm._v(" Address "), _c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.address_add",
      modifiers: {
        "address_add": true
      }
    }]
  }, [_c('i', {
    staticClass: "fas fa-plus-circle"
  })])]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Status")])])]), _c('tbody', _vm._l(_vm.personal_details.address_list, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('p', [_vm._v(" " + _vm._s(item.address1) + " "), item.is_usps_valid == 1 ? _c('i', {
      staticClass: "fa fa-check-circle",
      staticStyle: {
        "color": "rgb(50, 205, 50)"
      },
      attrs: {
        "aria-hidden": "true",
        "title": "Tooltips"
      }
    }) : _c('span', {
      attrs: {
        "title": "Tooltips"
      }
    }, [_c('i', {
      staticClass: "fas fa-exclamation-circle",
      staticStyle: {
        "color": "red"
      }
    })])]), _vm._v(" " + _vm._s(item.city) + " ")]), _c('td', {
      staticClass: "action_address"
    }, [item.is_primary == 1 ? _c('span', {
      staticClass: "active_status"
    }, [_c('i', {
      staticClass: "fas fa-check-circle"
    }), _vm._v(" Active")]) : _c('span', {
      staticClass: "active_status"
    }, [_c('button', {
      on: {
        "click": function ($event) {
          return _vm.openPrimaryModal('address_' + item.address_id, item.address_id);
        }
      }
    }, [_vm._v(" Set as Primary ")]), _c('span', {
      staticClass: "deleteIcon",
      attrs: {
        "title": "Delete this address"
      },
      on: {
        "click": function ($event) {
          return _vm.openPrimaryModal('deleteAddress', item.address_id);
        }
      }
    }, [_c('i', {
      staticClass: "far fa-trash-alt",
      staticStyle: {
        "color": "#606060",
        "font-size": "20px"
      },
      attrs: {
        "aria-hidden": "true"
      }
    })])])])]);
  }), 0)]) : _vm._e()], 1)]), _vm._l(_vm.personal_details.address_list, function (item, index) {
    return _c('b-modal', {
      key: index,
      attrs: {
        "ok-disabled": _vm.formSubmitted,
        "addressId": "item.address_id",
        "id": 'address_' + item.address_id,
        "title": "Are you sure?",
        "ok-title": "Confirm",
        "centered": true,
        "size": "sm"
      },
      on: {
        "ok": _vm.setAddressPrimary,
        "hidden": function ($event) {
          return _vm.hideModal();
        }
      }
    }, [_c('p', {
      staticClass: "confirmation"
    }, [_vm._v(" Do you want to set this as your "), _c('b', {
      staticStyle: {
        "color": "#ff7a00"
      }
    }, [_vm._v("Primary Address?")])]), _c('div', {
      staticClass: "current"
    }, [_c('p', [_vm._v("Current Primary Address:")]), _c('p', [_c('span', [_c('b', [_vm._v(_vm._s(_vm.personal_details.address_list[0].address1))])]), _c('span', [_vm._v(_vm._s(_vm.personal_details.address_list[0].city) + " " + _vm._s(_vm.personal_details.address_list[0].state) + " ")])])]), _c('div', {
      staticClass: "new"
    }, [_c('p', [_c('b', [_vm._v("New Primary Address:")])]), _c('p', [_c('span', [_c('b', [_vm._v(_vm._s(item.address1))])]), _c('span', [_vm._v(_vm._s(item.city) + " " + _vm._s(item.state) + " ")])])])]);
  }), _c('b-modal', {
    attrs: {
      "ok-disabled": _vm.formSubmitted,
      "id": 'deleteAddress',
      "title": "Delete Address",
      "ok-title": "Confirm",
      "centered": true,
      "size": "lg"
    },
    on: {
      "ok": _vm.deletePrimaryAddress
    }
  }, [_c('p', {
    staticClass: "confirmation"
  }, [_vm._v(" Are you sure you want to delete this? ")])]), _c('b-modal', {
    attrs: {
      "ok-disabled": _vm.formSubmitted,
      "id": 'confirmPlanChange',
      "title": "Plan Change Confirmation",
      "ok-title": "Confirm",
      "centered": true,
      "size": "lg"
    },
    on: {
      "ok": _vm.confirmAddressAddition
    }
  }, [_c('p', {
    staticClass: "confirmation"
  }, [_vm._v(" " + _vm._s(_vm.planConfirmationMessage) + " ")])]), _c('b-modal', {
    attrs: {
      "ok-disabled": _vm.formSubmitted,
      "id": 'primaryAddressConfirm',
      "title": "Plan Change Confirmation",
      "ok-title": "Confirm",
      "centered": true,
      "size": "lg"
    },
    on: {
      "ok": _vm.primaryAddressConfirm
    }
  }, [_c('p', {
    staticClass: "confirmation"
  }, [_vm._v(" " + _vm._s(_vm.planConfirmationMessage) + " ")])])], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "personal_info_update",
    attrs: {
      "for": "file_input_personal"
    }
  }, [_c('i', {
    staticClass: "fas fa-camera"
  })]);

}]

export { render, staticRenderFns }