<template>
  <b-modal
    id="payment-history-modal"
    :centered="true"
    title="Payment History"
    sm
  >
    <div class="container">
      <form @submit.prevent="downloadPaymentHistory">
        <div class="row mb-2">
          <div class="col-12">
            <label for="">Policy Id</label>
            <multiselect

              v-model="$v.policy_id.$model"
              :options="getActivePolicyId()"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"

              placeholder="Choose Policy Id"
              :class="getClassName('policy_id','')"
            ></multiselect>
            <span class="error_message" v-show="$v.policy_id.$error">{{getErrMessage('Policy Id')}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="">From</label>
            <date-picker
              class="w-100"
              type="date"

              v-model="$v.from_date.$model"
              placeholder="Start Date"
              :append-to-body="appendDatepicker"
              :input-class="getClassName('from_date','mx-input')"
            ></date-picker>
            <span class="error_message" v-if="$v.from_date.$error">{{getErrMessage('From Date')}}</span>


          </div>
          <div class="col-md-6">
            <label for="">To</label>
            <date-picker
              class="w-100"

              v-model="$v.to_date.$model"
              type="date"
              placeholder="End Date"
              :append-to-body="appendDatepicker"
              :input-class="getClassName('to_date','mx-input')"
            ></date-picker>
            <span class="error_message" v-if="$v.to_date.$error ">{{getErrMessage('To Date')}}</span>
          </div>
        </div>
        <div class="row justify-content-end payment-download">
          <div class="col-sm-4">
            <button type="submit" class="btn dwn-btn" >
              {{ isDownload ? "Downloading..." : "Download" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import moment from "moment";

import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators'

export default {
  data: () => {
    return {
      show: false,
      from_date: "",
      policy_id: "",
      to_date: "",
      variants: ["close-btn"],
      appendDatepicker: false,
      isDownload: false
    };
  },

  validations:{
    policy_id:{required},
    from_date:{required},
    to_date:{required}
  },
  computed: {
    ...mapGetters([
      'activePolicyId'
    ])
  },
  methods: {
    downloadPaymentHistory() {

      this.$v.$touch();
      if(this.$v.$invalid){
        return false;
      }
      this.isDownload = true;
      let path = `/get-policy-payment-history?policy_id=${
        this.policy_id
      }&from_date=${this.formattedDate(
        this.from_date
      )}&to_date=${this.formattedDate(this.to_date)}`;
      return axios
        .get(path)
        .then((res) => {
          if(res.data.data.file_url){
            window.open(res.data.data.file_url, '_blank');
          }
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isDownload = false;
        });
    },

    formattedDate(dt) {
      return moment(dt).format("YYYY-MM-DD");
    },

    getActivePolicyId(){
      return this.activePolicyId ? this.activePolicyId :[];

    },

    getErrMessage(field = 'This'){
      return `${field} is required`;
    },
    getClassName(key ,append = ""){
      let className = '';
      if(this.$v[key].$error){
        className =  append +' '+'invalid-input-block';
      }else{
        className = append +' '+'valid-input-block';
      }
      return className;
    }
  },
  watch: {
    rowId: function () {
      this.from_date = "";
      this.to_date = "";
    },
  },
};
</script>


<style scoped>
.error_message{
  color:#dc3545;
}
</style>
<style>
#payment-history-modal .modal-body {
  padding-top: 0;
}
#payment-history-modal .modal-footer {
  display: none;
}
.mx-datepicker-main .mx-calendar-header button {
  outline: none;
}
.multiselect__element .multiselect__option--highlight {
  background: #29bcff;
}
.multiselect__element
  .multiselect__option--selected.multiselect__option--highlight {
  background: #29bcff;
  color: #35495e;
}

.invalid-input-block > .multiselect__tags {
  border-color: #dc3545 !important;
}

.mx-input-wrapper > .invalid-input-block{
  border-color: #dc3545 !important;
}

.valid-input-block > .multiselect__tags {
  border-color: #ced4da !important;
}

.mx-input-wrapper > .valid-input-block{
  border-color: #ced4da !important;
}
</style>

<style scoped>
.payment-download {
  margin: 20px 0;
}
.dwn-btn {
  background-color: #29bcff;
  border: 1px solid #29bcff;
  color: #fff;
  float: right;
}
.btn:hover {
  color: #fff;
}
</style>
