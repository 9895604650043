var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    ref: "save",
    staticClass: "contract_content"
  }, [_c('contract-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_c('h3', [_vm._v("MASTER COMMISSION SHEET - OF " + _vm._s(_vm.headerType))]), _vm.items.length > 0 ? _c('div', {
    staticClass: "master-commission"
  }, [_c('table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "commission-sheet"
    }
  }, [_c('tr', [_c('th', [_vm._v("Supplemental and Lifestyle Plans ")]), _c('th', [_vm._v("TIER")]), 1 <= _vm.level ? _c('th', [_vm._v("Level 1")]) : _vm._e(), 2 <= _vm.level ? _c('th', [_vm._v("Level 2")]) : _vm._e(), 3 <= _vm.level ? _c('th', [_vm._v("Level 3")]) : _vm._e(), 4 <= _vm.level ? _c('th', [_vm._v("Level 4")]) : _vm._e(), 5 <= _vm.level ? _c('th', [_vm._v("Level 5")]) : _vm._e(), 6 <= _vm.level ? _c('th', [_vm._v("Level 6")]) : _vm._e(), 7 <= _vm.level ? _c('th', [_vm._v("Level 7")]) : _vm._e(), 8 <= _vm.level ? _c('th', [_vm._v("Level 8")]) : _vm._e(), 9 <= _vm.level ? _c('th', [_vm._v("Level 9")]) : _vm._e()]), _vm._l(_vm.items, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.plan_name))]), _c('td', [_vm._v(_vm._s(item.tier_name))]), item.l1 ? _c('td', [_vm._v(_vm._s(item.l1))]) : _vm._e(), item.l2 ? _c('td', [_vm._v(_vm._s(item.l2))]) : _vm._e(), item.l3 ? _c('td', [_vm._v(_vm._s(item.l3))]) : _vm._e(), item.l4 ? _c('td', [_vm._v(_vm._s(item.l4))]) : _vm._e(), item.l5 ? _c('td', [_vm._v(_vm._s(item.l5))]) : _vm._e(), item.l6 ? _c('td', [_vm._v(_vm._s(item.l6))]) : _vm._e(), item.l7 ? _c('td', [_vm._v(_vm._s(item.l7))]) : _vm._e(), item.l8 ? _c('td', [_vm._v(_vm._s(item.l8))]) : _vm._e(), item.l9 ? _c('td', [_vm._v(_vm._s(item.l9))]) : _vm._e()]);
  })], 2)]) : _c('div', {
    staticClass: "master-commission"
  }, [_c('table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "commission-sheet"
    }
  }, _vm._l(_vm.responseContract, function (value, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(value.plan_name || _vm.capitalizeFirstLetter(value.contract_item_display_name)))])]), value.configuration && value.configuration.hasOwnProperty('Lifeline Bronze') && value.configuration.hasOwnProperty('Lifeline Silver, Gold & Platinum') ? _c('td', [_c('table', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('thead', _vm._l(value.configuration, function (configValue, configKey) {
      return _c('tr', {
        key: 'header-' + configKey
      }, [_c('th', [_c('strong', [_vm._v(_vm._s(configKey))])]), _c('th', [_c('table', {
        staticStyle: {
          "width": "100%"
        }
      }, [_c('thead', _vm._l(configValue, function (configVal, configKey, index) {
        return _c('tr', {
          key: 'tier-header-' + configKey
        }, [index === 0 ? [_vm._m(0, true), _vm._l(configVal, function (tierVall, tierrKey) {
          return _c('th', {
            key: 'tier-header-' + tierrKey
          }, [_c('strong', [_vm._v(" " + _vm._s(tierrKey) + " ")])]);
        })] : _vm._e()], 2);
      }), 0), _c('tbody', _vm._l(configValue, function (configVal, configKey) {
        return _c('tr', {
          key: 'data-' + configKey
        }, [_c('td', [_c('strong', [_vm._v(_vm._s(configKey))])]), _vm._l(configVal, function (conval, conKey) {
          return _c('td', {
            key: 'data-' + conKey
          }, [_c('strong', [_vm._v(_vm._s(conval))])]);
        })], 2);
      }), 0)])])]);
    }), 0)])]) : value.configuration ? _c('td', [_c('table', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('thead', [_c('tr', _vm._l(value.configuration, function (configValue, configKey) {
      return _c('th', {
        key: 'header-' + configKey
      }, [_c('strong', [_vm._v(_vm._s(configKey))])]);
    }), 0)]), _c('tbody', [_c('tr', _vm._l(value.configuration, function (configValue, configKey) {
      return _c('td', {
        key: 'data-' + configKey
      }, [configValue == '0.0%' ? _c('strong', [_vm._v(_vm._s('Paid By Up-Line'))]) : _c('strong', [_vm._v(_vm._s(configValue))])]);
    }), 0)])])]) : _c('td', [value.commission == '0.00' ? _c('strong', [_vm._v(_vm._s('Paid By Up-Line'))]) : value.commission == '' || value.commission == null ? _c('strong', [_vm._v(_vm._s('N/A'))]) : _c('strong', [_vm._v(_vm._s(value.commission))])])]);
  }), 0)]), _c('div', {
    staticClass: "button_container"
  }, [_c('button', {
    staticClass: "back",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.navigatePrevious();
      }
    }
  }, [_vm._v("Back")]), _c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "next",
    on: {
      "click": function ($event) {
        return _vm.saveMasterInfo();
      }
    }
  }, [_vm._v("Next")])])])])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('th', [_c('strong', [_vm._v("Tier")])]);

}]

export { render, staticRenderFns }