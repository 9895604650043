<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="forgot_welcome">
        <h1>Forgot your Password?</h1>
        <p>
         Enter the OTP code you received in your email or phone.
        </p>
      </div>
      <div class="form">
        <div class="reset_form">
          <h3>Verify OTP</h3>
          <b-form-group label="Enter OTP"  label-for="input-2">
            <b-input-group id="input-2">
            <div id="otp">
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  required
                  v-model="otp.one"
                  placeholder="0"
                ></b-form-input>
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  required
                  v-model="otp.two"
                  placeholder="0"
                ></b-form-input>
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  required
                  v-model="otp.three"
                  placeholder="0"
                ></b-form-input>
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  required
                  v-model="otp.four"
                  placeholder="0"
                ></b-form-input>
              </div>
            </b-input-group>
          </b-form-group>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
           <OTPTimer title="Not received? Click to resend" @sendOTP=sendOtpCode :expirySecond=60 />
              <button @click="verifyToken" :disabled="verifyLoader" >
                <b-spinner v-if="verifyLoader == true" small></b-spinner>
               Verify OTP
              </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "../resource/Helper"
import axios from 'axios'
import OTPTimer from '@/components/common/OTPTimer';
export default {
  components: {
    OTPTimer
  },
  data() {
      return {
      otp: {
        one: "",
        two: "",
        three: "",
        four: "",
      },
      email: '',
      enterEmail:true,
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
      errorClass: "",
    }
  },
  methods: {
    verifyToken() {
      let app = this;
      app.errorClass = "";
      app.formErrorFlag = false;
      let code = parseInt(
        app.otp.one + app.otp.two + app.otp.three + app.otp.four
      );
      if (code == "NaN" || code.toString().length < 4) {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Enter valid code.";
        app.errorClass = "highlight_otp";
        return;
      }
      app.verifyLoader = true;
      axios
        .post("auth/reset-password-check", {
          data: this.$store.state.memberEmail,
          option: "email",
          reset_code: code
        })
        .then(function (response) {
          if (response.data.status == "success") {
            app.$store.commit('setOtpCode',code)
            helper.infoMessage(app,response.data.status,response.data.message)
            helper.delayRedirect('PasswordChange',0)
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.verifyLoader = false;
        });
    },
    sendOtpCode() {
      let app = this;
      app.verifyLoader = true;
      axios
        .post("/auth/reset-password", {
          data: app.$store.state.memberEmail,
          option: "email"
        })
        .then(function (response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
          }
        })
        .finally(function (error) {
          app.verifyLoader = false;
        });
    },
  }
};
</script>

<style scoped>
@import "../assets/css/forgot_password.css";
.highlight_otp {
  border: 2px solid red !important;
}
</style>