var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "terminate_plan"
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('welcome-content'), _c('h3', [_vm._v("Active Plans")]), _c('div', {
    staticClass: "active_plans"
  }, [_c('div', {
    staticClass: "active"
  }, [_c('div', {
    staticClass: "table_content"
  }, [_c('table', [_vm._m(0), _vm._l(_vm.plans, function (item, index) {
    return _c('tbody', {
      key: index
    }, _vm._l(item.plan_list, function (obj, index) {
      return _c('tr', {
        key: index
      }, [_c('td', {
        staticStyle: {
          "font-family": "SF UI Display Bold",
          "text-align": "left"
        }
      }, [_vm._v(" " + _vm._s(obj.plan) + " ")]), _c('td', {
        staticStyle: {
          "color": "rgb(50, 205, 50)"
        }
      }, [_vm._v(_vm._s(obj.status))]), _c('td', [_vm._v(_vm._s(obj.effective_date))])]);
    }), 0);
  })], 2)])]), _c('div', {
    staticClass: "selected_plan"
  }, [_c('div', {
    staticClass: "table_content"
  }, [_c('table', [_vm._m(1), _c('tbody', _vm._l(_vm.plan_detail, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "font-family": "SF UI Display Bold",
        "text-align": "left"
      }
    }, [_vm._v(" " + _vm._s(item.plan) + " ")]), _c('td', {
      staticStyle: {
        "color": "rgb(50, 205, 50)"
      }
    }, [_vm._v(_vm._s(item.status))]), _c('td', [_vm._v(_vm._s(item.effective_date))]), _c('td', [_vm._v(_vm._s(item.tier))]), _c('td', [_vm._v(_vm._s(item.premium))]), _vm._m(2, true)]);
  }), 0)])]), _c('div', {
    staticClass: "terminate_all"
  }, [_c('p', {
    staticClass: "terminate_text"
  }, [_vm._v(" You can request to cancel all the plans instead of individually ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'TerminateForm'
      }
    }
  }, [_c('button', [_vm._v("Terminate All")])])], 1)])])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v("Plan")]), _c('td', [_vm._v("Status")]), _c('td', [_vm._v("Effective Date")])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    }
  }, [_vm._v("Plan")]), _c('td', [_vm._v("Status")]), _c('td', [_vm._v("Effective Date")]), _c('td', [_vm._v("Tier")]), _c('td', [_vm._v("Monthly Premium")]), _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Action")])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('button', {
    staticClass: "terminate"
  }, [_vm._v("Terminate")])])]);

}]

export { render, staticRenderFns }