<template>
  <div class="content_section gp_payment">
                <div class="group_info">
                  <h2>PAYMENT INFORMATION</h2>
                  <form class="" action="index.html" method="post">
                    <div class="input_box pmt_mtd">
                      <span class="pay_label">Payment Method<span class="red">*</span></span>
                      <span class="pay_check">
                        <input type="radio" name="" value="">
                        <label for="">EFT/ACH</label>
                      </span>
                    </div>
                    <div class="input_box half">
                      <label for="">First Name</label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Last Name</label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Street Address <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Apt/Suite/Other <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">City <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half">
                      <label for="">State<span class="red">*</span></label>
                      <div class="inputs">
                        <select class="" name="">
                          <option value="">Choose a State</option>
                          <option value="">Select One</option>
                          <option value="">Select Two</option>
                          <option value="">Select Three</option>
                          <option value="">Select Four</option>
                        </select>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Zip Code  <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Email  <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Mobile Phone Number  <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Fax  <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box checkbox">
                      <div class="inputs">
                        <input type="checkbox" name="" value=""/>
                      </div>
                      <label for="">Continue without Address Validation</label>
                    </div>

                    <div class="buttonbox">
                      <button @click="navigatePrevious" class="previous" type="button" name="button">Previous</button>
                      <button class="save" type="button" name="button">Save</button>
                      <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
                    </div>
                  </form>
                </div>
              </div>
</template>
<script>
export default {
    name: "PaymentInformation",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
