var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page"
  }, [_c('div', {
    staticClass: "main_page"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "top-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('button', {
    staticClass: "login_inactive"
  }, [_vm._v("Log In")])]), _c('button', {
    staticClass: "sign_up_active"
  }, [_vm._v("Register")])], 1), _c('div', {
    staticClass: "signup_form"
  }, [_vm._m(1), _c('b-form-group', {
    attrs: {
      "label": "Last Name",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "text",
      "required": "",
      "placeholder": "Enter your lastname"
    },
    model: {
      value: _vm.lastname,
      callback: function ($$v) {
        _vm.lastname = $$v;
      },
      expression: "lastname"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["lastname"]) + " ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Last 4 Digits of SSN",
      "label-for": "input-2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-2",
      "type": "text",
      "maxlength": "4",
      "required": "",
      "placeholder": "0123",
      "oninput": "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');"
    },
    model: {
      value: _vm.cssn4,
      callback: function ($$v) {
        _vm.cssn4 = $$v;
      },
      expression: "cssn4"
    }
  }), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["cssn"]) + " ")]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Date of Birth",
      "label-for": "input-3"
    }
  }, [_c('div', {
    staticClass: "date_of_birth"
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.months
    },
    model: {
      value: _vm.selected_month,
      callback: function ($$v) {
        _vm.selected_month = $$v;
      },
      expression: "selected_month"
    }
  }), _c('b-form-select', {
    attrs: {
      "options": _vm.days
    },
    model: {
      value: _vm.selected_day,
      callback: function ($$v) {
        _vm.selected_day = $$v;
      },
      expression: "selected_day"
    }
  }), _c('b-form-select', {
    attrs: {
      "options": _vm.years
    },
    model: {
      value: _vm.selected_year,
      callback: function ($$v) {
        _vm.selected_year = $$v;
      },
      expression: "selected_year"
    }
  })], 1), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["dob"]) + " ")]) : _vm._e()]), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e(), _c('button', {
    attrs: {
      "disabled": _vm.verifyLoader
    },
    on: {
      "click": _vm.verifyMember
    }
  }, [_vm.verifyLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Continue ")], 1), _c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('p', [_vm._v("Already have an "), _c('b', [_vm._v("Account?")])])])], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "welcome"
  }, [_c('h1', [_vm._v("Welcome")]), _c('p', [_vm._v(" Register to create your online access to your member dashboard. (Please note online account access is not available until your start date or thereafter) ")])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signup_head"
  }, [_c('h3', [_vm._v("Register")]), _c('span', [_vm._v("To confirm and register your account, please complete the fields below.")])]);

}]

export { render, staticRenderFns }