import Vue from 'vue';
import axios from 'axios';
//import Helper from '../includes/Helper';

const otherUrl = axios.create({
  baseURL: "",
});
export default class Api {

    static get(url) {
        return new Promise((resolve, reject) => {
            return otherUrl
              .get(url)
              .then((response) => {
                Api.resolve(response, resolve, reject);
              })
              .catch((error) => {
                Api.reject(error, reject);
              });
        });
    }
    static post(url, data) {
        return new Promise((resolve, reject) => {
            return otherUrl
              .post(url, data)
              .then((response) => {
                Api.resolve(response, resolve, reject);
              })
              .catch((error) => {
                Api.reject(error, reject);
              });
        });
    }

    static resolve(response, resolve, reject) {
            if (response.data.status == 'success') {
                resolve(response);
            } else if (response.data.status === 'error') {
               // Api.alert('error', response.data.message);
                reject(response);
            } else {
               // Api.alert('error', 'Failed!');
                reject(response);
            }
       //
    }

    static reject(error, reject) {
       //Api.alert('error', error.message);
        reject(error);
    }

    static alert(status, message) {
        switch (status) {
            case 'success':
                Vue.notify({
                    group: 'alert',
                    title: 'Success!',
                    type: 'success',
                    text: message
                });
                break;
            case 'error':
                Vue.notify({
                    group: 'alert',
                    title: 'Error!',
                    type: 'error',
                    text: message,
                });
                break;
            case 'warning':
                Vue.notify({
                    group: 'alert',
                    title: 'Warning!',
                    type: 'warn',
                    text: message
                });
                break;
            case 'info':
                Vue.notify({
                    group: 'alert',
                    title: 'Info!',
                    text: message
                });
                break;
        }
    }
}
