<template>

  <div class="message_center_new dashboard-card mt-4">
    <div class="message_head">
      <h3>Message Center</h3>
      <router-link v-if="messages.total_records > 2" :to="{ name: 'ViewMessage' }"><small>View All</small></router-link>
    </div>
    <b-skeleton-table v-if="skeletonLoader" :rows="5" :columns="2" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
    <div class="dashboard-msg-body" v-if="!skeletonLoader">

      <div class="msg-list" v-for="(item, index) in messages.records" :key="index">
        <div class="message">
          <p>{{ item.subject }}</p>
          <small>{{
                    item.date_created | date_format("YYYY-MM-DD", "MM/DD/YYYY", item.date_created)
                  }}</small>
        </div>
        <button @click="openModal('message_' + item.mid,item.mid)" class="read">
          Read More
        </button>
      </div>
    </div>
    <!-- <a class="view" href="#">View All</a> -->

    <b-modal v-for="(item, index) in messages.records" :key="index" :title="item.subject" :id="'message_' + item.mid" class="message_modal" :centered="true" :hide-footer="true" size="lg">
      <div class="modal-body">
        <div class="rcv-msgcontent">
          <div class="rcv-msg-sender">
            <div class="rcv-snder-icon">
             <img :src="item.sender_details?.image ?? $dummyImage"  alt="" class="msg-userIcon" />
              <div class="rcv-sender-details">
                <h6>{{item.sender_details?.name}}</h6>
                <p>
                  <i class="far fa-calendar-alt"></i>
                  {{
                    item.date_created | date_format("YYYY-MM-DD", "MM/DD/YYYY", item.date_created)
                  }}
                </p>
              </div>
            </div>
          </div>
          <h5>Title: {{ item.subject }}</h5>
          <hr />
          <div class="ql-snow">
            <div class="ql-editor">
              <p v-html="item.message" />
            </div>
          </div>
       <div v-if="item.attachments && item.attachments.length > 0">
        <h6>Attachments:</h6>
        <ul style="list-style: none">
          <li v-for="(attachment, attachmentindex) in item.attachments" :key="attachmentindex">
            <a :href="attachment.file_url" target="__blank" download>
              <i class="fa fa-file"></i>
              {{ attachment.title }}
            </a>
          </li>
        </ul>
      </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </b-modal>
  </div>
</template>

<script>

import axios from "axios";
export default {

  data() {
    return {
      messages: [],
      skeletonLoader: true,
    };
  },
  mounted() {
    this.getMessage();
  },
  methods: {
    getMessage() {
      let app = this;
      return axios
        .get((process.env.VUE_APP_CORENROLL_SYSTEM_API_V2+"get-received-message-list?page=1&page_size=6"))
        .then((response) => {
          app.messages = response.data;
          this.skeletonLoader = false;
        });
    },
    openModal(id,mid) {
      this.$bvModal.show(id);
      axios.put(process.env.VUE_APP_CORENROLL_SYSTEM_API_V2+"update-message-seen-status?mid=" + mid);
    },
    downloadFile(fileUrl) {
      window.open(fileUrl, "_blank");
    },
  },
};
</script>
<style scoped>
.message_modal {
  color: #323c47;
}
.resource_item {
  margin-top: 6px;
  padding-left: 12px;
}
.attchement_details .row {
  background: #f1efef;
}
.attchement_details {
  padding-top: 12px;
  border-top: 1px solid grey;
  margin-top: 20px;
}

.message_body >>> h2 {
  font-size: 20px !important;
}
.message_body >>> h2 strong {
  font-size: 20px !important;
}
.message_body >>> h3 strong {
  font-size: 20px !important;
}

/* .message_center .column {
  overflow: hidden;
  overflow-y: auto;
  height: 479px;
}
.message_center .column::-webkit-scrollbar {
  width: 6px;
}
.message_center .column::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.message_center .column::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
} */
 .modal-body {
    padding-top: 0px !important;
  }
  .modal-header {
    padding-bottom: 0px !important;
  }
</style>
