<template>
  <div class="container-fluid">
    <div class="row body-main-row">
      <div class="member_dashboard member-body-content-wrap">
        <div class="member-referral-wrap">
          <div class="contents">
            <div class="member-referral">
              <div class="member-referral-header">
                <h1>Member Referral</h1>
                <button class="btn btn-mr" @click="sendReferralModel()">
                  Send referral
                </button>
                <p v-if="info" class="mr-disclaimer-text">
                  {{ info }}
                </p>
                <b-modal
                  id="sendReferral"
                  :centered="true"
                  title="Send Referral Email"
                  no-close-on-backdrop
                  @ok="handleSubmit"
                  size="lg"
                >
            
                  
                  <div class="row title-email-wrapper">
    <div class="col-sm-6">
        <b-form-group
                    invalid-feedback="Name is required"
                    label="Name"
                    label-for="name-field"
                    class="name-field required"
                  >
                    <b-form-input
                      id="title-1"
                      :state="validateState('name')"
                      v-model.lazy="$v.form.name.$model"
                      required
                    ></b-form-input>
                  </b-form-group>
    </div>
                    <div class="col-sm-6">
                      <b-form-group
                        label="Email"
                        label-for="mr-email"
                        class="required"
                        :invalid-feedback="emailError"
                      >
                        <b-form-input
                          id="mr-email"
                          :state="validateState('email')"
                          v-model.lazy="$v.form.email.$model"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <b-form-group
                    :invalid-feedback="messageRequired"
                    label="Message"
                    label-for="mr-message"
                    class="mr-message"
                  >
                    <ckeditor
                      v-model="form.message"
                      class="is-invalid"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>
                  </b-form-group>
                </b-modal>
              </div>

              <div class="new-list-table">
                <div class="table-content">
                  <div class="list-table-header">
                    <div class="list-header name">Name</div>
                    <div class="list-header major-medical">Major Medical</div>
                    <div class="list-header amount">Amount</div>
                    <div class="list-header date">Date</div>
                    <div class="list-header status">Status</div>
                    <div class="list-header action">Action</div>
                  </div>

                  <div class="list-table-body">
                    <div
                      class="list-row"
                      v-for="(item, index) in items.records"
                      :key="index"
                    >
                      <div class="list-body name">{{ item.name }}</div>
                      <div class="list-body major-medical">
                        {{ item.is_major_medical ? "Yes" : "No" }}
                      </div>
                      <div class="list-body amount">{{ "$" + item.incentive }}</div>
                      <div class="list-body date">
                        Referral Date:
                        {{ item.created_date }}
                        <br>
                        Enrolled Date:
                        {{ item.enrolled_date }}
                        Redeemed Date:
                        {{ item.reedem_date }}
                        </div>
                      <div class="list-body status">
                        {{ item.status_text}}
                      </div>
                      <div
                        class="list-body action redeemed"
                        v-if="item.is_redeemed"
                      >
                        <button class="btn-redeemed">
                          <i class="fas fa-check-circle"></i> Redeemed
                        </button>
                      </div>
                      <!--<div
                        class="list-body action redeem"
                        v-else-if="item.is_complete && item.is_major_medical">
                        <button
                          class="btn-redeem"
                          @click="redeemActionModal(item.id)">
                          Redeem
                        </button>
                      </div> -->
                      <div
                        class="list-body action redeem"
                        v-else-if="!item.is_complete">
                        <button class="mr-resend-btn" @click="resendActionModal(item.id)">Resend</button>
                      </div>
                      <div class="list-body action redeem" v-else></div>
                    </div>
                    <div class="list-row" v-if="!items.total_records">
                      <div class="list-body no-record">
                        <span>No records found</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="overflow-auto">
                <!-- Use text in props -->
                <b-pagination
                  v-model="pagination.current"
                  :total-rows="pagination.total"
                  first-text="First"
                  prev-text="Prev"
                  next-text="Next"
                  last-text="Last"
                  :per-page="pagination.perPage"
                  style="justify-content: center; margin-top: 20px"
                  :page-click="referrerList"
                  :bvEvent="referrerList"
                  @change="referrerList"
                ></b-pagination>
              </div>
            </div>
            <b-modal
              id="redeemAction"
              :centered="true"
              title="Confirm Your Redeem"
              @ok="claimReward"
              no-close-on-backdrop
            >
              <b-form-group
                label="Redeem your member referral reward."
                label-for="title-1"
                class=""
              >
              </b-form-group>
            </b-modal>

            <b-modal
              id="resendAction"
              :centered="true"
              title="Referral Link"
              no-close-on-backdrop
              @ok="resendReferral"
            >
              <b-form-group
                label="Resend referral link"
                label-for="title-1"
                class=""
              >
              </b-form-group>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { required, email } from "vuelidate/lib/validators";
import CommonValidator from "../resource/CommonValidator";
import helper from "../resource/Helper";
import axios from "axios";

export default {
  name: "MemberReferral",
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
         //removePlugins: ['image'],
        height: "500px",
        rows: 8,
      },
      form: {
        name: "",
        title: "",
        message: "",
        email: "",
      },
      messageRequired: "",
      emailError: "Email is required",
      isFormValid: true,
      items: [],
      info: "",
      currentId: "",
      pagination: {
        current: 1,
        total: 0,
        perPage: 5,
      },
    };
  },
  mounted() {
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          '|',
          '|',
          'undo',
          'redo'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'en'
    },
    this.referrerList();
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("sendReferral");
      this.$bvModal.hide("resendAction");
      this.$bvModal.hide("redeemAction");
    },
    sendReferralModel() {
      this.$bvModal.show("sendReferral");
    },
    resendActionModal(id) {
      this.currentId = id;
      this.$bvModal.show("resendAction");
    },
    redeemActionModal(id) {
      this.currentId = id;
      this.$bvModal.show("redeemAction");
    },
    referrerList(page) {
      axios.get("/get-referral-list?page=" + page).then((response) => {
        this.pagination.total = response.data.total_records;
        this.pagination.current = response.data.current_page;
        this.items = response.data;
        this.info = response.data.info;
      });
    },
    claimReward() {
      let app = this;
      axios
        .post("/reedem-reward", { id: this.currentId })
        .then((response) => {
          if (response.data.status == "success") {
            this.referrerList();
            this.hideModal();
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        });
    },
    resendReferral() {
      let app = this;
      axios
        .post("/resend-referral-list", { id: this.currentId })
        .then((response) => {
          if (response.data.status == "success") {
            this.hideModal();
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        });
    },
    async handleSubmit(bvModalEvt) {
      let fromData = this.form;
      let app = this;
      bvModalEvt.preventDefault();
      this.$v.form.$touch();
      console.log(this.$v.form)
      if (this.$v.form.$anyError) {
        return;
      }
      await axios
        .post("/send-referral-url", fromData)
        .then((response) => {
          if (response.data.status == "success") {
            this.referrerList();
            this.hideModal();
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        });
    },
    validateState(name, form = "form") {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    hideModal() {
      [this.form.name, this.form.title, this.form.email, this.form.message] = [
        "",
      ];
      this.$v.form.$reset();
      this.$bvModal.hide("sendReferral");
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
     /* title: {
        required,
      },*/
      email: {
        required,
        email,
        /*async validateEmail(value) {
          let statusEmail = false;
          if (helper.validateEmail(value)) {
           let status =  await CommonValidator.validateEmail(value);
          if(!status) this.emailError = 'Email is invalid - Validated using Neverbounce API.';
           statusEmail =  status;
          }
          else {
            if(value !='') this.emailError = 'Please enter valid email';
            }
             this.isFormValid = Boolean(status)
            return status
        },*/
      },
     /* message: {
        required,
        async validateMessage(value) {
          if (value == "") {
            this.messageRequired = "Message is required";
          } else {
            this.messageRequired = "";
          }
          return (this.isFormValid = Boolean(value));
        },
      }, */
    },
  },
};
</script>

<style>
@import "../assets/css/new_table.css";

.member-referral {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 40px;
}

.member-referral h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.mr-disclaimer-text {
  margin: 0 0 30px 0;
  padding: 10px 15px;
  font-size: 13px;
  background-color: #ff7a00;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
}
.btn-mr {
  background: #00ccff !important;
  color: white !important;
  float: right;
  margin-top: -55px;
  font-size: 12px !important;

  padding: 8px 15px!important;
  font-weight: 700!important;
  padding-bottom: 5px;
  border-radius: 3px!important;
  border: 1px solid #29bcff!important;
  letter-spacing: 0.7px;
}
.btn-mr:hover {
  background: #00bbe9 !important;
}
#sendReferral .modal-body .form-group {
  display: inline-block;
}
#sendReferral .mr-message {
  width: 100%;
}
#sendReferral .mr-message input {
  min-height: 100px;
}

#sendReferral .mr-message {
  width: 100%;
}
#sendReferral .mr-message input {
  min-height: 100px;
}
#redeemAction .modal-header h5 {
  padding-left: 10px;
}
#redeemAction .form-group {
  width: 100%;
}
#redeemAction .form-group label {
  font-size: 16px;
}
#sendReferral .name-field {
  width: 100%;
}

@media (max-width: 380px) {
  .btn-mr {
    float: none !important;
    margin-top: 0px !important;
    margin-bottom: 20px;
  }
  .member-referral h1 {
    margin-bottom: 15px;
  }
}
</style>
<style>
.ck-blurred {
  height: 150px !important;
}
.ck-focused {
  height: 150px !important;
}
.no-record span {
  margin: 0px auto;
}
</style>
<style>
.page-item.active .page-link {
  background: #00ccff !important;
}
.page-link {
  padding: 10px 15px !important;
}
.page-item button {
  width: 50px !important;
  height: 100% !important;
  border: none !important;
  border-radius: 0 !important;
}
@media only screen and (max-width: 760px) {
  .page-link {
    padding: 5px 10px !important;
  }
  .page-item button {
    width: 40px !important;
  }
}
.title-email-wrapper {
  background-color: white !important;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.title-email-wrapper .form-group {
  width: 100%;
}
.ck-content ol li {
  list-style: auto !important;
}
.ck-content ul li {
  list-style: disc !important;
}
.ck-content ol,.ck-content ul {
  margin-left: 14px !important;
}
.status {
  width: 20%;
  min-width: 20%;
}
.pagination .active {
  width: unset;
}
.pagination li .page-link {
  color: #6c757d;
  font-size: 13px!important;
  pointer-events: all;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dee2e6!important;
  width: unset!important;
  padding: 12px 20px!important;
}
@media (max-width: 768px) {
  .pagination li .page-link {
    padding: 10px 12px!important;
  }
}
@media (max-width: 400px) {
  .pagination li .page-link {
    padding: 8px 10px!important;
  }
}
</style>
