<template>
  <div class="banks_cont_new dashboard-card dashboard-card-lt">
    <div class="">
      <h3>
        Banking Information
        <a v-b-modal.bank_add><i class="fas fa-plus-circle"></i></a>
      </h3>
      <div class="table_content">
        <b-skeleton-table v-if="bank_list_skeletion" :rows="3" :columns="5" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
        <table v-if="!bank_list_skeletion">
          <thead>
            <tr>
              <td>A/C Name</td>
              <td>Bank Name</td>
              <td>Routing Number</td>
              <td>A/C Number</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in bank_info" :key="index">
              <td style="font-family: SF UI Display Medium">
                {{ item.bank_accountname }}
              </td>
              <td>{{ item.bank_name }}</td>
              <td>{{ item.bank_routing }}</td>
              <td>{{ item.bank_account_number }}</td>
              <td style="color: rgb(50, 205, 50)" v-if="item.status == 'ACTIVE'">
                {{ item.status }}
              </td>
              <td v-else-if="item.status == 'INACTIVE'">
                <button @click="showPrimaryModel('bank_primary_' + item.bank_id, item.bank_id)">Set as Primary</button>
                <a style="" @click="showDeleteModal(item.bank_id)"><i class="far fa-trash-alt" aria-hidden="true" style="color:#606060; font-size: 18px;align-items:center; margin-left: 10px;" title="Delete Info"></i></a>

              </td>
              <td v-else>
                {{ item.status }}
              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <b-modal :ok-disabled="formSubmitted" title="Are you sure?" ok-title="Confirm" :centered="true" size="lg" v-for="(item, index) in bank_info" @ok="setBankPrimary" @hidden="hideModal()" :key="index" :id="'bank_primary_' + item.bank_id">
        <p class="confirmation">
          Do you want to set this as your
          <b style="color: #ff7a00">Primary Banking Information?</b>
        </p>
        <div class="current_info">
          <p><b>Current Primary Banking Information</b></p>
          <div class="table_content">
            <table>
              <thead>
                <tr>
                  <td>A/C Name</td>
                  <td>Bank Name</td>
                  <td>Routing Number</td>
                  <td>A/C Number</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ bank_info[0].bank_accountname}}</td>
                  <td>{{ bank_info[0].bank_name}}</td>
                  <td>{{ bank_info[0].bank_routing}}</td>
                  <td>{{ bank_info[0].bank_account_number}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="new_info">
          <p><b>New Primary Banking Information</b></p>
          <div class="table_content">
            <table>
              <thead>
                <tr>
                  <td>A/C Name</td>
                  <td>Bank Name</td>
                  <td>Routing Number</td>
                  <td>A/C Number</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{item.bank_accountname}}</td>
                  <td>{{item.bank_name}}</td>
                  <td>{{item.bank_routing}}</td>
                  <td>{{item.bank_account_number}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <span style="color:red;font-style:italic">Note: By setting Primary Account - you agree that this bank information will be used for all upcoming transactions.</span>
        </div>
      </b-modal>

      <b-modal :ok-disabled="formSubmitted" @ok="addBank" @hidden="hideModal()" id="bank_add" :centered="true" ok-title="Submit" title="Banking Information - Add" size="lg" no-close-on-backdrop>
        <b-form-group label="Account Holder Name" label-for="input-1" invalid-feedback="Account name is required." class="required">
          <b-form-input :state="validateState('account_name')" v-model="$v.bank_add_form.account_name.$model" id="input-1" required></b-form-input>
        </b-form-group>
        <b-form-group label="Routing Number" label-for="input-2" :invalid-feedback="validationErrors.routing_number" class="required">
          <b-form-input @blur="$v.bank_add_form.routing_number.$touch" debounce="15000000" type="number" :state="validateState('routing_number')" v-model.lazy="$v.bank_add_form.routing_number.$model" id="input-2" required></b-form-input>
        </b-form-group>
        <b-form-group label="Bank Name" label-for="input-3" invalid-feedback="Bank name is required." class="required">
          <b-form-input  :state="validateState('bank_name')" v-model.lazy="$v.bank_add_form.bank_name.$model" id="input-3" required></b-form-input>
        </b-form-group>
        <b-form-group label="Account Number" label-for="input-4" :invalid-feedback="validationErrors.account_number" class="required">
          <b-form-input @blur="$v.bank_add_form.account_number.$touch" type="number" :state="validateState('account_number')" v-model.lazy="$v.bank_add_form.account_number.$model" id="input-4" required></b-form-input>
        </b-form-group>

        <b-form-group label="Account Type" label-for="input-5" invalid-feedback="Account type is required." class="required">
          <b-form-select :state="validateState('account_type')" v-model.lazy="$v.bank_add_form.account_type.$model" id="input-5" :options="account_type" required>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Account Holder Type" label-for="input-7" invalid-feedback="Account holder type is required." class="required">
          <b-form-select :state="validateState('account_holder_type')" v-model.lazy="$v.bank_add_form.account_holder_type.$model" id="input-7" :options="account_holder_type" required>
          </b-form-select>
        </b-form-group>
        <b-form-checkbox id="checkbox-1" checked="checked" v-model="bank_add_form.is_primary" name="checkbox-1" value="1" unchecked-value="0">
          Set as primary
        </b-form-checkbox>
        <div class="signature">
          <div class="signature_title">
            <p>
              Application Signature (Required)
              <span>(Sign Name using your mouse or touch screen in the box
                below)</span>
            </p>
          </div>
          <signature-pad @sign="getSign"></signature-pad>
          <div tabindex="-1" v-if="!$v.bank_add_form.sign.required && $v.bank_add_form.sign.$error" role="alert" aria-live="assertive" aria-atomic="true" style="display:block" class="invalid-feedback">Sign is required.</div>
        </div>
      </b-modal>

   <b-modal :ok-disabled="formSubmitted" @ok="deleteBank" :id="'deleteBankDetails'" title="Delete Bank" ok-title="Confirm" :centered="true" size="lg">
        <p class="confirmation">
          Are you sure you want to delete this bank details?
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import SignaturePad from "../../components/SignaturePad";
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import helper from "../../resource/Helper";
import CommonValidator from "../../resource/CommonValidator";
function intialState() {
  return {
    bank_info: [],
    account_type: [
      { value: '', text: "Select account type", disabled: false },
      { value: "Saving", text: "Saving", disabled: false },
      { value: "Checking", text: "Checking", disabled: false },
    ],
    account_holder_type: [
      { value: '', text: "Select account holder type", disabled: true },
      { value: "Individual", text: "Individual", disabled: false },
      { value: "Company", text: "Company", disabled: false },
    ],
    selected_account: null,
    bank_add_form: {
      account_name: "",
      routing_number: "",
      bank_name: "",
      account_number: "",
      account_type: "",
      account_holder_type: "",
      is_primary: 1,
      sign: "",
    },
    bank_list_skeletion: true,
    primary_bank_id: "",
    validationErrors: {
      routing_number: "Routing Number is required",
      account_number:""
    },
    formSubmitted : false
  };
}
export default {
  components: {
    SignaturePad,
  },
  data() {
    return intialState();
  },
  async mounted() {
    this.bankingDetails();
  },
  methods: {
    bankingDetails() {
      let app = this;
      return axios.get("/get-bank-information").then((response) => {
        app.bank_info = response.data.data;
        app.bank_list_skeletion = false;
      });
    },
    validateState(name, form) {
      const { $dirty, $error } = this.$v.bank_add_form[name];
      return $dirty ? !$error : null;
    },
    async addBank(bvModalEvt) {
      let formData = this.bank_add_form;
      let app = this;
      bvModalEvt.preventDefault();
      this.$v.bank_add_form.$touch();
      if (this.$v.bank_add_form.$anyError) {
        return;
      }
      this.formSubmitted = true;
      this.bank_list_skeletion = true;
      await axios
        .post("/add-banking-information", formData)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.hideModal();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        }).finally(() => { this.formSubmitted = false;})
    },
    getSign(sign = "") {
      this.bank_add_form.sign = sign;
    },
    resetData: function () {
      Object.assign(this.$data.bank_add_form, intialState().bank_add_form);
    },
    hideModal() {
      this.resetData();
      this.$v.bank_add_form.$reset();
      this.$bvModal.hide("bank_add");
      this.bankingDetails();
    },
    showPrimaryModel(id, bankId) {
      this.primary_bank_id = bankId;
      this.$bvModal.show(id);
    },
    setBankPrimary(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
      this.formSubmitted = true
      axios
        .post("/set-primary-bank", { bank_id: this.primary_bank_id })
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.bank_list_skeletion = true;
            this.$bvModal.hide("bank_primary_" + this.primary_bank_id);
            this.bankingDetails();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        }).finally(() => { this.formSubmitted = false;})
    },
  showDeleteModal(bank_id) {
      this.primary_bank_id = bank_id;
      this.$bvModal.show("deleteBankDetails");
    },
  async deleteBank(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
      this.formSubmitted = true;
      await axios
        .delete("/delete-bank-details?bank_id=" + this.primary_bank_id)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.$bvModal.hide("deleteBankDetails");
            this.bank_list_skeletion = true;
            this.bankingDetails();
          }
        })
        .catch((error) => {
          app.$bvModal.hide("deleteBankDetails");
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        }).finally(() => { this.formSubmitted = false;})
    },
  },
  validations: {
    bank_add_form: {
      account_name: { required },
      routing_number: {
        required(value) {
          if(!value.length) { this.validationErrors.routing_number  = 'Routing Number is required'; return false; }
          if(value.length != 9) { this.validationErrors.routing_number  = 'Routing Number should be of 9 digits.'; return false }
          return true;
        },
        async validateRoutingNumber(value) {
          if(value.length == 9) {
              let routingNumberValidation = await CommonValidator.validateRoutingNumber(value);
              if(routingNumberValidation.status == false) {
                  this.validationErrors.routing_number = routingNumberValidation.message;
              }
              this.bank_add_form.bank_name = routingNumberValidation.data
              return routingNumberValidation.status;
          }

          return true
        },
      },
      bank_name: { required },
      account_number: { 
        required(value){
          if(!value.length) { this.validationErrors.account_number  = 'Account Number is required'; return false; }
          if(value.length < 4 || value.length > 17) {
             this.validationErrors.account_number  = 'Account Number should be between 4 to 17 digits.'; 
             return false 
            }
          return true;
        }
       },
      account_type: { required },
      account_holder_type: { required },
      is_primary: 0,
      sign: { required },
    },
  },
};
</script>

<style scoped>
.modal-body .form-group .form-control:disabled {
    background-color: #cacaca;
}

</style>