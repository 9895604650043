var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('strong', {
    staticClass: "plan-renew-title"
  }, [_vm._v("Plan Renewal Options:")]), _c('div', {
    staticClass: "col-md-5"
  }, [_c('multiselect', {
    attrs: {
      "placeholder": _vm.placeholder,
      "label": "formatted_plan_name",
      "track-by": "pid",
      "options": _vm.plans,
      "internal-search": false,
      "close-on-select": true,
      "clear-on-select": false
    },
    on: {
      "search-change": _vm.searchHandler,
      "open": _vm.openPlans
    },
    model: {
      value: _vm.selectedPlan,
      callback: function ($$v) {
        _vm.selectedPlan = $$v;
      },
      expression: "selectedPlan"
    }
  })], 1), _vm.selectedPlan ? _c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.alert-popup2",
      modifiers: {
        "alert-popup2": true
      }
    }],
    staticClass: "notice-btn plan-renew-btn",
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_vm._v("Change to plan at renewal")]) : _vm._e(), _vm.selectedPlan ? _c('dashboard-enrollment-renew-plan-modal', {
    attrs: {
      "current-plan": _vm.currentPlan,
      "new-plan": _vm.selectedPlan
    },
    on: {
      "update:newPlan": function ($event) {
        _vm.selectedPlan = $event;
      },
      "update:new-plan": function ($event) {
        _vm.selectedPlan = $event;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }