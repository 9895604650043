import axios from "axios";
import router from "../router/index";
import store from "../store/store";
import Vue from "vue";
import moment from "moment"
import dummyImage from "../assets/images/dummy.png"
export default class Helper {
  static showSpinner() {
    //Show loading. This is the only way to make it appear before the app is mounted.
    let spinnerWrapper = document.createElement("div");
    spinnerWrapper.className = "spinner-wrapper";
    let spinner = document.createElement("div");
    spinner.className = "spinner";
    spinnerWrapper.appendChild(spinner);
    let spinnerStyle = document.createElement("style");
    spinnerStyle.textContent = `.spinner {
          width: 40px;
          height: 40px;
          background-color: #333;
        
          margin: 100px auto;
          -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
          animation: sk-rotateplane 1.2s infinite ease-in-out;
        }
        
        @-webkit-keyframes sk-rotateplane {
          0% { -webkit-transform: perspective(120px) }
          50% { -webkit-transform: perspective(120px) rotateY(180deg) }
          100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
        }
        
        @keyframes sk-rotateplane {
          0% { 
            transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
          } 50% { 
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
          } 100% { 
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
          }
        }`;
    spinnerWrapper.appendChild(spinnerStyle);
    let appDiv = document.getElementById("app");
    appDiv.appendChild(spinnerWrapper);
  }

  static hideSpinner() {
    let div = document.getElementsByClassName("spinner-wrapper");
    div[0].remove();
  }

  static validateEmail(email) {
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+|(?:\.[a-zA-Z0-9-]+)$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  }
  static validatePassword(password) {
    return new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    ).test(password); // eslint-disable-line
  }
  static logout() {
    axios.get("/logout");
    this.clearToken();
    router.push({ name: "Login" });
  }
  static forceLogout() {
    this.clearToken();
    router.push({ name: "Login" });
  }

  static clearToken() {
    store.commit("setAuthentication", false);
    store.commit("setAccessToken", "");
    store.commit("setMemberEmail", "");
    store.commit("setMemberId", "");
  }

  static setUserDetail(data) {
    let lastLogin = moment(data.last_login_date).format("MMM DD, YYYY | LT");
    let setImage = data.image ?? dummyImage
    let setPhone = this.formatPhoneNumber(data.phone);
    let newName = data.mname
      ? `${data.fname} ${data.mname} ${data.lname}`
      : `${data.fname} ${data.lname}`;
    const {
      firstName = data.fname,
      name = newName,
      email,
      phone = setPhone,
      image = setImage,
      last_login = lastLogin,
      ip =data.ip_address
    } = data;
    const newData = { firstName, name, email, phone, image, last_login, ip }
    store.commit("setUserData", newData)
  }
  static setLoginDetails(data) { 
      if(store.getters.authenticated==false){
        router.push({ name: "LoginSave" });   
      }else{
        router.push({ name: "DashboardNew" })
      } 
      store.commit("setAuthentication", true);
      store.commit("setAccessToken", data.access_token);
      store.commit("setMemberId", data.member.member_id);
      store.commit("setOtpCode", "");    
    
  }

  static redirect(url) {
    window.location = window.location.hostname + url;
  }
  static infoMessage(
    thisObj,
    variant = "success",
    message = "This is message"
  ) {
    thisObj.$root.$bvToast.toast(
      message.charAt(0).toUpperCase() + message.slice(1),
      {
        title:
          variant != ""
            ? variant.charAt(0).toUpperCase() + variant.slice(1)
            : "",
        variant: variant == "error" ? "danger" : variant,
        solid: true,
      }
    );
  }

  static alert(status = "success", message = "This is test") {
    switch (status) {
      case "success":
        Vue.notify({
          group: "alert",
          title: "Success!",
          type: "success",
          text: message,
        });
        break;
      case "error":
        Vue.notify({
          group: "alert",
          title: "Error!",
          type: "error",
          text: message,
        });
        break;
      case "warning":
        Vue.notify({
          group: "alert",
          title: "Warning!",
          type: "warn",
          text: message,
        });
        break;
      case "info":
        Vue.notify({
          group: "alert",
          title: "Info!",
          text: message,
        });
        break;
    }
  }

  static delayRedirect(routeName, delaySec = 0, data = []) {
    setTimeout(() => {
      router.push({
        name: routeName,
        params: {
          items: data,
        },
      });
    }, delaySec * 1000);
  }
  static validateDate(date) {
    return new Date(date) != "Invalid Date";
  }
  static downloadFile(fileUrl) {
    window.location = fileUrl;
  }

  static getStatesList() {
    return {
      AK: "Alaska",
      AL: "Alabama",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District of Columbia",
      FL: "Florida",
      GA: "Georgia",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "NEW Hampshire",
      NJ: "NEW Jersey",
      NM: "NEW Mexico",
      NY: "NEW York",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    };
  }

  static getRelationList() {
    return {
      Spouse: "Spouse",
      "Uncle or Aunt": "Uncle or Aunt",
      "Nephew or Niece": "Nephew or Niece",
      "Brother or Sister": "Brother or Sister",
      Child: "Child",
      "Ex Spouse": "Ex Spouse",
      Mother: "Mother",
      Father: "Father",
      "Life Partner": "Life Partner",
      Trust: "Trust",
      Friend: "Friend",
      Other: "Other",
    };
  }

  static formatDate(date) {
    let dateObj = new Date(date)
    return dateObj.toLocaleDateString("default", { // you can use undefined as first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  static maskEmail = (email) => {
    const [name, domain] = email.split('@');
    const { length: len } = name;
    const maskedName = name[0] + '***' + name[len - 1];
    return maskedName + '@' + domain;
  }

  static maskPhone = (phone, maskNo = 3) => {
    const lastChar = phone.substring(phone.length - maskNo)
    return `*******${lastChar}`
  }

  static resetRegisterMemberAuth = () => {
    store.commit("setOtpSend", false);
    store.commit("setEmailOtp", "");
    store.commit("setPhoneOtp", "");
    store.commit("setMemberId", "");
    store.commit("setMemberPhone", "");
  }

  static formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

    /**
    * convert object params to url
    */
     static objToUrlParams(obj) {
      return Object.keys(obj)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key] ? obj[key] : ''))
        .join("&");
    }
}
