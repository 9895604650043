var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rep_new dashboard-card dashboard-card-lt mt-4"
  }, [_c('div', {
    staticClass: "rep_info"
  }, [_c('h3', [_vm._v("Rep Information")]), _c('div', {
    staticClass: "rep_detail"
  }, [_c('div', {
    staticClass: "profile_detail"
  }, [_c('div', {
    staticClass: "profile"
  }, [_c('img', {
    attrs: {
      "src": _vm.getImage(),
      "alt": "profile"
    }
  })]), _c('div', {
    staticClass: "rep_profile"
  }, [_c('h3', [_vm._v(_vm._s(_vm.rep_details.agent_fname) + " " + _vm._s(_vm.rep_details.agent_lname) + " ")]), _c('div', {
    staticClass: "detail"
  }, [_c('ul', [_c('li', {
    staticClass: "agent-code"
  }, [_vm._v(_vm._s(_vm.rep_details.agent_code))]), _c('li', [_c('a', {
    attrs: {
      "href": "mailto:"
    }
  }, [_c('i', {
    staticClass: "fa fa-envelope"
  }), _vm._v("  " + _vm._s(_vm.rep_details.agent_email))])]), _c('li', [_c('a', {
    attrs: {
      "href": "tel:"
    }
  }, [_c('i', {
    staticClass: "fa fa-phone-alt"
  }), _vm._v("   " + _vm._s(_vm._f("us_phone")(_vm.rep_details.agent_phone)) + " ")])])])])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }