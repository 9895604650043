import { render, staticRenderFns } from "./Agreement.vue?vue&type=template&id=6be0f056&scoped=true&"
import script from "./Agreement.vue?vue&type=script&lang=js&"
export * from "./Agreement.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/contract_form/agreement.css?vue&type=style&index=0&id=6be0f056&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6be0f056",
  null
  
)

export default component.exports