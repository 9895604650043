var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "left_container"
  }, [_c('ul', [_c('li', {
    staticClass: "personal",
    class: [this.$route.name == 'Personal' ? 'form-active' : '', _vm.personal == 'complete' ? 'form-filled' : '']
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/img/person.png")
    }
  }), _vm._v(" Personal Information ")]), _c('li', {
    staticClass: "address",
    class: [this.$route.name == 'Address' ? 'form-active' : '', _vm.address == 'complete' ? 'form-filled' : '']
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/img/house.png")
    }
  }), _vm._v("Address Information")]), _c('li', {
    staticClass: "taxpayer",
    class: [this.$route.name == 'TaxpayerId' ? 'form-active' : '', _vm.taxpayer == 'complete' ? 'form-filled' : '']
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/img/certificate.png")
    }
  }), _vm._v("Taxpayer ID Number (TIN)")]), _c('li', {
    staticClass: "certification",
    class: [this.$route.name == 'Certification' ? 'form-active' : '', _vm.certification == 'complete' ? 'form-filled' : '']
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/img/certificate.png")
    }
  }), _vm._v("Certification")]), _c('li', {
    staticClass: "general",
    class: [this.$route.name == 'GeneralInstruction' ? 'form-active' : '', _vm.general == 'complete' ? 'form-filled' : '']
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/img/instruction.png")
    }
  }), _vm._v("General Instruction")]), _c('li', {
    staticClass: "specific",
    class: [this.$route.name == 'SpecificInstruction' ? 'form-active' : '', _vm.specific == 'complete' ? 'form-filled' : '']
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/img/instruction.png")
    }
  }), _vm._v("Specific Instruction")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }