var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_section payment"
  }, [_c('div', {
    staticClass: "rep_info"
  }, [_c('h2', [_vm._v("Payment Information")]), _c('form', {
    attrs: {
      "action": "index.html",
      "method": "post"
    }
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "buttonbox"
  }, [_c('button', {
    staticClass: "previous",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Previous")]), _c('button', {
    staticClass: "save",
    attrs: {
      "type": "button",
      "name": "button"
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.saveAndContinue
    }
  }, [_vm._v("Next")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pay_type"
  }, [_c('div', {
    staticClass: "select_box"
  }, [_c('span', {
    staticClass: "pay_label"
  }, [_vm._v("Pay to :")]), _c('span', {
    staticClass: "pay_check individual"
  }, [_c('input', {
    attrs: {
      "type": "checkbox",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Individual")])]), _c('span', {
    staticClass: "pay_check business"
  }, [_c('input', {
    attrs: {
      "type": "checkbox",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Business")])]), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])]), _c('div', {
    staticClass: "select_box"
  }, [_c('span', {
    staticClass: "pay_label"
  }, [_vm._v("How do you want to be paid ?")]), _c('span', {
    staticClass: "pay_check check"
  }, [_c('input', {
    attrs: {
      "type": "checkbox",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Check")])]), _c('span', {
    staticClass: "pay_check ach"
  }, [_c('input', {
    attrs: {
      "type": "checkbox",
      "name": "",
      "value": ""
    }
  }), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("ACH")])]), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pay_details"
  }, [_c('h2', [_vm._v("If ACH, please complete the following: "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input_box half route"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Routing Number "), _c('a', {
    staticClass: "help_icon",
    attrs: {
      "href": "#"
    }
  })]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]), _c('div', {
    staticClass: "input_box half"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Bank Name"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]), _c('div', {
    staticClass: "input_box half"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Account Number"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])]), _c('div', {
    staticClass: "input_box half"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Name of Bank Account"), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  }), _c('p', {
    staticClass: "error_message"
  }, [_vm._v("This is a required field")])])])]);

}]

export { render, staticRenderFns }