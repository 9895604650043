var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container_contract_complete"
  }, [_c('div', {
    staticClass: "thank_you"
  }, [_c('div', {
    staticClass: "thankyou_image message-image"
  }, [_vm.value == 'success' ? _c('img', {
    attrs: {
      "src": require("../../assets/images/thank_you_pic.svg"),
      "alt": "thank you"
    }
  }) : _vm._e(), _vm.value != 'success' ? _c('img', {
    attrs: {
      "src": require("../../assets/images/error.svg"),
      "alt": "Error"
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "content message-text"
  }, [_vm._m(0), _c('p', [_vm._v(_vm._s(_vm.message) + " ")])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', [_c('br')]);

}]

export { render, staticRenderFns }