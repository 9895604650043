<template>
  <div>
    <VueSignaturePad
      id="signature"
      width="100%"
      height="150px"
      ref="signaturePad"
      :options="{
        onBegin: () => {
          $refs.signaturePad.resizeCanvas();
        },
        onEnd,
      }"
    />

    <button class="clear" @click="clear">Clear</button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
   props: ['sign'],
  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
       this.$emit('sign','')
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.$emit('sign',data)
    },
  },
};
</script>

<style>
#signature {
  cursor: crosshair;
  border: 1px solid #dcdcdc;
  background: #fff;
}
.clear {
  background: #fff;
  float: right;
  margin: 5px 5px 25px 0;
  padding: 5px 15px;
  font-size: 12px;
  border: 1px solid #757575;
  border-radius: 6px;
  color: #666;
  margin: 5px;
  /* font-weight:bold; */
}
.clear:focus {
  outline: none;
}
</style>
