/**
 * simple class for generating error message based on vuelidate
 * check validatior object params to generate message
 * need to add entries to rules Object to support more validators
 */

const rules = {
  required :`field is required`,
  email: 'field must be valid email',
}

export default class ErrorMessage {
  constructor(vObject, field){
    this.field = field;
    this.vObject = vObject;
  }

  generateMessage(){
    const ruleParams = this.getKeys(this.vObject);
    // console.log(ruleParams , this.field , this.vObject);
    if(!ruleParams.length){
      return [];
    }
    let messageArray = [];
    ruleParams.forEach((param)=>{
      if(this.vObject[param] === false){
        messageArray.push( `The ${this.field} ${rules[param]}`);
      }
    })
    return messageArray;
  }

  getKeys(obj){
    if(!obj || !obj.$params){
      return [];
    } 
    return Object.keys(obj.$params);
  }

}