<template>
  <div class="content_section contacts">
                <div class="group_info">
                  <h2>GROUP CONTACT INFORMATION</h2>
                  <form class="" action="index.html" method="post">
                    <div class="input_box half">
                      <label for="">Group Contact First Name <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Group Contact Last Name <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Group changed Contact Title <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Email <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Mobile Phone Number  <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="input_box half ">
                      <label for="">Fax <span class="red">*</span></label>
                      <div class="inputs">
                        <input type="text" name="" value=""/>
                      </div>
                    </div>
                    <div class="buttonbox">
                      <button @click="navigatePrevious" class="previous" type="button" name="button">Previous</button>
                      <button class="save" type="button" name="button">Save</button>
                      <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
                    </div>
                  </form>
                </div>
              </div>
</template>
<script>
export default {
    name: "Contact",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
