<template>
<div class="w9_form_container">
    <vue-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="false"
            :preview-modal="false"
            filename="test.pdf"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="1280px"
            :htmlToPdfOptions="htmlToPdfOptions"
            @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)"
            ref="html2Pdf"
    >
    <section slot="pdf-content">
    <header>
        <section class="heading pdf-item">
            <div class="left">
                <p>Form <strong>W-9</strong><br>
                    (Rev. (October 2018)<br>
                    Department of the Treasury <br>Internal Revenue Service</p>
            </div>

            <div class="center">
                <h1>Request for Taxpayer <br>
                    Identification Number and Certification</h1>

                <strong> Go to <a href="www.irs.gov/FormW9"><i>www.iris.gov/FormW9</i></a> for instructions and the
                    latest information. </strong>
            </div>

            <div class="right">
                <strong>Give Form to the <br>requester. Do not<br> send to the IRS.</strong>
            </div>
        </section>
    </header>
    <section class="main pdf-item">
            <div class="form">
                <form class="w9contract-form" id="w9contract-form">
                        <div class="input-form">
                            <p class="side-text"><b>Print or Type.<br></b> See <b>Specific Instructions</b> on page 3.</p>
                            <label><b>1</b> Name (as shown on your income tax return). Name is required on this line; do not leave this
                                line blank.</label><br>
                            <input type="text" v-model="name" id="name" value="" name="name" required> <br>

                            <label><b>2</b> Business name/disregarded entity name, if different from above</label><br>
                            <input type="text" v-model="business_name" id="business" name="business" value="" required>
                                        <span><b>3</b> Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only <b>one</b> of the
                                            following seven boxes. </span><br>
                                <div class="option">
                                    <input type="radio" v-model="federal_tax" id="Individual" name="fed_tax_classification" value="individual">
                                    <label for="Individual">Individual/sole proprietor or <br> single-member LLC</label>
                                            
                                            
                                    <input type="radio" v-model="federal_tax" id="C_Corporation" name="fed_tax_classification" value="c_corporation">
                                    <label for="C_Corporation">C Corporation</label>
                                            
                                            
                                    <input type="radio" v-model="federal_tax" id="S_Corporation" name="fed_tax_classification" value="s_corporation">
                                    <label for="S_Corporation">S Corporation</label>
                                            
                                    <input type="radio" v-model="federal_tax" id="Partnership" name="fed_tax_classification" value="partnership">
                                    <label for="Partnership">Partnership </label>
                                            
                                    <input type="radio" v-model="federal_tax" id="Trust" name="fed_tax_classification" value="trust">
                                    <label for="Trust">Trust/estate</label>
                                                
                                    
                                </div>
                                <div>
                                <input type="radio" v-model="federal_tax" id="LLC" name="fed_tax_classification" value="limited_liability">
                                    <label for="LLC">Limited liability company. Enter the tax classification (C=C corporation, S=S
                                        corporation, P=Partnership)<input type="text" id="tax-class" name="tax-class"
                                            value=""></label>
                                </div>
                                <br><strong>Note:</strong> Check the appropriate box in the line above for
                                    the tax classification of the single-member owner. Do not check LLC if the LLC is classified as
                                    a single-member LLC that is disregarded from the owner unless the owner of the LLC is another
                                    LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member
                                    LLC that is disregarded from the owner should check the appropriate box for the tax
                                    classification of its owner.<br>

                                    <input type="radio" v-model="federal_tax" id="Other" name="fed_tax_classification" value="other">
                                    <label for="Other">Other (see instructions)</label><input type="text" id="other" name="other"
                                            value="">

                                <div class="html2pdf__page-break"/>

                                <div class="code">
                                    <p><b>4</b> Exemptions (codes apply only to certain entities, not individuals;
                                        see instructions on page 3): </p><br>

                                    <label>Exempt payee code (if any)</label><input type="text" v-model="exempt_payee_code" id="payee" name="payee" value=""><br>

                                    <label>Exemption from FATCA reporting 
                                        code (if any)</label><input type="text" v-model="exempt_fatca_reporting" id="reporting" name="reporting" value=""><br>
                                    <small>(Applies to accounts maintained outside the U.S.)</small>
                                </div>
                            <div class="divide-2">
                                <div class="address">
                                    <label><b>5</b> Address (number, street, and apt. or suite no.) See instructions.</label><br>
                                    <input type="text" v-model="street_address1" id="address" name="address" value=""><br>

                                    <label><b>6</b> City, state, and ZIP code </label><br>
                                    <input type="text" v-model="fullAddress" id="city" name="city" value=""><br>

                                
                                </div>
                                <div class="requester">
                                    <label>Requester’s name and address (optional)</label><br>
                                    <input type="text" v-model="requester_name_address" name="requester" id="requester" value="">
                                </div>

                            </div>
                            <label><b>7</b> List account number(s) here (optional)</label><br>
                                    <input type="text" v-model="list_account_numbers" id="account" name="account" value="">
                        
                        </div>

                    <div class="html2pdf__page-break"/>
                    <div class="html2pdf__page-break"/>

                    <div class="part-1">
                        <div class="text">
                            <h5><strong>Part I</strong> Taxpayer Identification Number (TIN)</h5>

                            <p>Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid
                                backup withholding. For individuals, this is generally your social security number (SSN). However, for a
                                resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For
                                other entities, it is your employer identification number (EIN). If you do not have a number, see How to
                                get a TIN, later.</p>
                            <p><b>Note:</b>If the account is in more than one name, see the instructions for line 1. Also see What Name
                                and Number To Give the Requester for guidelines on whose number to enter.</p>
                        </div>
                        <div class="number">
                            <label><b>Social security number</b></label><br>
                            <input type="text" v-model="social_security" id="security" name="security" value=""><br>
                            <b>or</b><br>
                            <label><b>Employer identification number</b></label><br>
                            <input type="text" v-model="employer_identification_number" id="identification" name="identification" value="">
                        </div>
                    </div>
                </form>
            </div>
            <section class="part-2 pdf-item">
                <h5><strong>Part II</strong> Certification</h5>
                <p>Under penalties of perjury, I certify that:<br>
                <ol>
                    <li>The number shown on this form is my correct taxpayer identification number (or I am waiting for a number
                        to be issued to me); and</li>
                    <li>I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have
                        not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a
                        result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no
                        longer subject to backup withholding; and</li>
                    <li>I am a U.S. citizen or other U.S. person (defined below); and</li>
                    <li>The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is
                        correct.</li>
                </ol>
                <strong>Certification Instructions.</strong> You must cross out item 2 above if you have been notified by the
                IRS that you are currently subject to backup withholding because you have failed to report all interest and
                dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid,
                acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement
                arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the
                certification, but you must provide your correct TIN. See the instructions for Part II, later.
                </p>
            </section>
            <section class="verification pdf-item">
                <div class="title">
                <h3>Sign<br>
                    Here</h3>
                </div>
                <p class="signature"><strong>Signature of<br> U.S. person</strong><img width="336" height="151" :src="signature_of_us_person"/>
                <p class="date"><strong>Date</strong> <input type="text" v-model="date" id="date" name="date" value=""></p>
            </section>
            <section class="description pdf-item">
                
                <section class="first-page pdf-item">
                
                    <div class="left-side">
                        <h1>General Instructions</h1>
                        Section references are to the Internal Revenue Code unless otherwise noted.
                        <b>Future developments.</b> For the latest information about developments related to Form W-9 and its
                        instructions, such as legislation enacted after they were published, go to www.irs.gov/FormW9.
                        <h2>Purpose of Form</h2>
                        An individual or entity (Form W-9 requester) who is required to file an information return with the IRS
                        must obtain your correct taxpayer identification number (TIN) which may be your social security number
                        (SSN), individual taxpayer identification number (ITIN), adoption taxpayer identification number (ATIN),
                        or employer identification number (EIN), to report on an information return the amount paid to you, or
                        other amount reportable on an information return. Examples of information returns include, but are not
                        limited to, the following.
                        <ul>
                            <li> Form 1099-INT (interest earned or paid)</li>
                        </ul>
                    </div>
                    <div class="right-side">
                        <ul>
                            <li>Form 1099-DIV (dividends, including those from stocks or mutual funds)
                            </li>
                            <li>Form 1099-MISC (various types of income, prizes, awards, or gross proceeds)
                            </li>
                            <li>Form 1099-B (stock or mutual fund sales and certain other transactions by brokers)
                            </li>
                            <li>Form 1099-S (proceeds from real estate transactions)
                            </li>
                            <li>Form 1099-K (merchant card and third party network transactions)
                            </li>
                            <li>Form 1098 (home mortgage interest), 1098-E (student loan interest), 1098-T (tuition)
                            </li>
                            <li>Form 1099-C (canceled debt)
                            </li>
                            <li>Form 1099-A (acquisition or abandonment of secured property)
                            </li>
                        </ul>
                        <p class="indent">Use Form W-9 only if you are a U.S. person (including a resident alien), to provide your correct
                            TIN.</p>
                        <p class="indent"> <i>If you do not return Form W-9 to the requester with a TIN, you might be subject to backup
                                withholding. See What is backup withholding, later.</i></p>
                    </div>

                </section>

                <div class="html2pdf__page-break"/>
                <section class="page pdf-item">
                    <p class="right-head">Form W-9 (Rev. 10-2018)</p>
                    <p class="page-number">Page<span>2</span></p>
                </section>
                <section class="second-page pdf-item">
                    <div class="left-side">
                        <p class="indent">By signing the filled-out form, you:
                        <ol>
                            <li>Certify that the TIN you are giving is correct (or you are waiting for a number to be issued),
                            </li>
                            <li>Certify that you are not subject to backup withholding, or</li>
                            <li>Claim exemption from backup withholding if you are a U.S. exempt payee. If applicable, you are
                                also certifying that as a U.S. person, your allocable share of any partnership income from a
                                U.S. trade or business is not subject to the withholding tax on foreign partners' share of
                                effectively connected income, and</li>
                            <li>Certify that FATCA code(s) entered on this form (if any) indicating that you are exempt from the
                                FATCA reporting, is correct. See What is FATCA reporting, later, for further information.</li>
                        </ol>
                        <b>Note:</b> If you are a U.S. person and a requester gives you a form other than Form W-9 to request
                        your TIN, you must use the requester’s form if it is substantially similar to this Form W-9.<br>
                        <b>Definition of a U.S. person.</b> For federal tax purposes, you are considered a U.S. person if you
                        are:
                        <ul>
                            <li>An individual who is a U.S. citizen or U.S. resident alien;</li>
                            <li>A partnership, corporation, company, or association created or organized in the United States or
                                under the laws of the United States;</li>
                            <li>An estate (other than a foreign estate); or</li>
                            <li>A domestic trust (as defined in Regulations section 301.7701-7).</li>
                        </ul>
                        <b>Special rules for partnerships.</b>
                        Partnerships that conduct a trade or business in the United States are generally required to pay a
                        withholding tax under section 1446 on any foreign partners’ share of effectively connected taxable
                        income from such business. Further, in certain cases where a Form W-9 has not been received, the rules
                        under section 1446 require a partnership to presume that a partner is a foreign person, and pay the
                        section 1446 withholding tax. Therefore, if you are a U.S. person that is a partner in a partnership
                        conducting a trade or business in the United States, provide Form W-9 to the partnership to establish
                        your U.S. status and avoid section 1446 withholding on your share of partnership income.</p>
                        <p class="indent">In the cases below, the following person must give Form W-9 to the partnership for purposes of
                            establishing its U.S. status and avoiding withholding on its allocable share of net income from the
                            partnership conducting a trade or business in the United States.
                        <ul>
                            <li>In the case of a disregarded entity with a U.S. owner, the U.S. owner of the disregarded entity
                                and not the entity;</li>
                            <li>In the case of a grantor trust with a U.S. grantor or other U.S. owner, generally, the U.S.
                                grantor or other U.S. owner of the grantor trust and not the trust; and</li>
                            <li>In the case of a U.S. trust (other than a grantor trust), the U.S. trust (other than a grantor
                                trust) and not the beneficiaries of the trust.
                            </li>
                        </ul>

                    <p> <b>Foreign person.</b> If you are a foreign person or the U.S. branch of a foreign bank that has elected
                        to be treated as a U.S. person, do not use Form W-9. Instead, use the appropriate Form W-8 or Form 8233
                        (see Pub. 515, Withholding of Tax on Nonresident Aliens and Foreign Entities).</p>

                        <b>Nonresident alien who becomes a resident alien.</b>Generally, only a nonresident alien individual may
                        use the terms of a tax treaty to reduce or eliminate U.S. tax on certain types of income. However, most
                        tax treaties contain a provision known as a “saving clause.” Exceptions specified in the saving clause
                        may permit an exemption from tax to continue for certain types of income even after the payee has
                        otherwise become a U.S. resident alien for tax purposes.
                        

                        <p class="indent"> If you are a U.S. resident alien who is relying on an exception contained in the saving clause of a
                            tax treaty to claim an exemption from U.S. tax on certain types of income, you must attach a
                            statement to Form W-9 that specifies the following five items.
                        <ol>
                            <li>The treaty country. Generally, this must be the same treaty under which you claimed exemption
                                from tax as a nonresident alien.</li>
                            <li>The treaty article addressing the income.</li>
                            <li>The article number (or location) in the tax treaty that contains the saving clause and its
                                exceptions.</li>
                            <li>The type and amount of income that qualifies for the exemption from tax.</li>
                            <li>Sufficient facts to justify the exemption from tax under the terms of the treaty article.</li>
                        </ol>
                        </p>
                    </div>

                    <div class="right-side">
                        <p class="indent"><b>Example.</b>Article 20 of the U.S.-China income tax treaty allows an exemption from tax for
                            scholarship income received by a Chinese student temporarily present in the United States. Under
                            U.S. law, this student will become a resident alien for tax purposes if his or her stay in the
                            United States exceeds 5 calendar years. However, paragraph 2 of the first Protocol to the U.S.-China
                            treaty (dated April 30, 1984) allows the provisions of Article 20 to continue to apply even after
                            the Chinese student becomes a resident alien of the United States. A Chinese student who qualifies
                            for this exception (under paragraph 2 of the first protocol) and is relying on this exception to
                            claim an exemption from tax on his or her scholarship or fellowship income would attach to Form W-9
                            a statement that includes the information described above to support that exemption.</p>

                        <p class="indent">If you are a nonresident alien or a foreign entity, give the requester the appropriate completed Form
                            W-8 or Form 8233.</p>

                        <h2>Backup Withholding</h2>
                        <p><b>What is backup withholding?</b>Persons making certain payments to you must under certain
                            conditions withhold and pay to the IRS 24% of such payments. This is called “backup withholding.”
                            Payments that may be subject to backup withholding include interest, tax-exempt interest, dividends,
                            broker and barter exchange transactions, rents, royalties, nonemployee pay, payments made in
                            settlement of payment card and third party network transactions, and certain payments from fishing
                            boat operators. Real estate transactions are not subject to backup withholding.</p>

                        <p class="indent">You will not be subject to backup withholding on payments you receive if you give the requester your
                            correct TIN, make the proper certifications, and report all your taxable interest and dividends on
                            your tax return.<br>
                            <b>Payments you receive will be subject to backup withholding if:</b>
                        <ol>
                            <li>You do not furnish your TIN to the requester,</li>
                            <li>You do not certify your TIN when required (see the instructions for Part II for details),</li>
                            <li>The IRS tells the requester that you furnished an incorrect TIN,</li>
                            <li>The IRS tells you that you are subject to backup withholding because you did not report all your
                                interest and dividends on your tax return (for reportable interest and dividends only), or</li>
                            <li>You do not certify to the requester that you are not subject to backup withholding under 4 above
                                (for reportable interest and dividend accounts opened after 1983 only).</li>
                        </ol>

                        </p>

                        <p class="indent">Certain payees and payments are exempt from backup withholding. See Exempt payee code, later, and the
                            separate Instructions for the Requester of Form W-9 for more information.</p>

                        <p class="indent">Also see <i>Special rules for partnerships,</i> earlier.</p>

                        <h2>What is FATCA Reporting?</h2>
                        <p>The Foreign Account Tax Compliance Act (FATCA) requires a participating foreign financial
                            institution to report all United States account holders that are specified United States persons.
                            Certain payees are exempt from FATCA reporting. See Exemption from FATCA reporting code, later, and
                            the Instructions for the Requester of Form W-9 for more information.</p>

                        <h2>Updating Your Information</h2>
                        <p>You must provide updated information to any person to whom you claimed to be an exempt payee if
                            you are no longer an exempt payee and anticipate receiving reportable payments in the future from
                            this person. For example, you may need to provide updated information if you are a C corporation
                            that elects to be an S corporation, or if you no longer are tax exempt. In addition, you must
                            furnish a new Form W-9 if the name or TIN changes for the account; for example, if the grantor of a
                            grantor trust dies.</p>

                        <h2>Penalties</h2>
                        <p><b>Failure to furnish TIN.</b> If you fail to furnish your correct TIN to a requester, you are
                            subject to a penalty of $50 for each such failure unless your failure is due to reasonable cause and
                            not to willful neglect.</p>

                        <p><b>Civil penalty for false information with respect to withholding.</b>If you make a false
                            statement with no reasonable basis that results in no backup withholding, you are subject to a $500
                            penalty.</p>
                    </div>

                </section>
                <section class="page pdf-item">
                    <p class="right-head">Form W-9 (Rev. 10-2018)</p>
                    <p class="page-number">Page<span>3</span></p>
                </section>
                <section class="third-page pdf-item">

                    <div class="left-side">
                        <p><b>Criminal penalty for falsifying information.</b> Willfully falsifying certifications or
                            affirmations may subject you to criminal penalties including fines and/or imprisonment.</p>

                        <p><b>Misuse of TINs.</b>If the requester discloses or uses TINs in violation of federal law, the
                            requester may be subject to civil and criminal penalties.</p>

                        <h1>Specific Instructions</h1>
                        <h4>Line 1</h4>
                        <p>You must enter one of the following on this line; <b>do not</b> leave this line blank. The name
                            should match the name on your tax return.</p>

                        <p class="indent">If this Form W-9 is for a joint account (other than an account maintained by a foreign financial
                            institution (FFI)), list first, and then circle, the name of the person or entity whose number you
                            entered in Part I of Form W-9. If you are providing Form W-9 to an FFI to document a joint account,
                            each holder of the account that is a U.S. person must provide a Form W-9.

                        <ol type="a">
                            <li><b>Individual.</b> Generally, enter the name shown on your tax return. If you have changed your
                                last name without informing the Social Security Administration (SSA) of the name change, enter
                                your first name, the last name as shown on your social security card, and your new last
                                name.<br>
                                <b>Note: ITIN applicant:</b> Enter your individual name as it was entered on your Form W-7
                                application, line 1a. This should also be the same as the name you entered on the Form
                                1040/1040A/1040EZ you filed with your application.</li>

                            <li><b>Sole proprietor or single-member LLC.</b> Enter your individual name as shown on your
                                1040/1040A/1040EZ on line 1. You may enter your business, trade, or “doing business as” (DBA)
                                name on line 2.</li>

                            <li><b>Partnership, LLC that is not a single-member LLC, C corporation, or S corporation.</b> Enter
                                the entity's name as shown on the entity's tax return on line 1 and any business, trade, or DBA
                                name on line 2.</li>

                            <li><b>Other entities.</b> Enter your name as shown on required U.S. federal tax documents on line
                                1. This name should match the name shown on the charter or other legal document creating the
                                entity. You may enter any business, trade, or DBA name on line 2.</li>

                            <li><b>Disregarded entity.</b> For U.S. federal tax purposes, an entity that is disregarded as an
                                entity separate from its owner is treated as a “disregarded entity.” See Regulations section
                                301.7701-2(c)(2)(iii). Enter the owner's name on line 1. The name of the entity entered on line
                                1 should never be a disregarded entity. The name on line 1 should be the name shown on the
                                income tax return on which the income should be reported. For example, if a foreign LLC that is
                                treated as a disregarded entity for U.S. federal tax purposes has a single owner that is a U.S.
                                person, the U.S. owner's name is required to be provided on line 1. If the direct owner of the
                                entity is also a disregarded entity, enter the first owner that is not disregarded for federal
                                tax purposes. Enter the disregarded entity's name on line 2, “Business name/disregarded entity
                                name.” If the owner of the disregarded entity is a foreign person, the owner must complete an
                                appropriate Form W-8 instead of a Form W-9. This is the case even if the foreign person has a
                                U.S. TIN.</li>

                        </ol>

                        <h4>Line 2</h4>
                        <p>If you have a business name, trade name, DBA name, or disregarded entity name, you may enter it on
                            line 2.</p>
                        <h4>Line 3</h4>
                        <p>Check the appropriate box on line 3 for the U.S. federal tax classification of the person whose
                            name is entered on line 1. Check only one box on line 3.</p>
                        
                    </div>

                    <div class="right-side">
                        <table>
                            <tr>
                                <th>IF the entity/person on line 1 is <br>a(n) . . .</th>
                                <th>THEN check the box for . . .</th>
                            </tr>

                            <tr>

                                <td>
                                    <ul>
                                        <li>Corporation</li>
                                    </ul>
                                </td>

                                <td>Corporation</td>
                            </tr>

                            <tr>
                                <td>
                                    <ul>
                                        <li>Individual</li>
                                        <li>Sole proprietorship, or</li>
                                        <li>Single-member limited liability company (LLC) owned by an individual and disregarded
                                            for U.S. federal tax purposes.</li>
                                    </ul>
                                </td>
                                <td>
                                    Individual/sole proprietor or single-member LLC
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>LLC treated as a partnership for U.S. federal tax purposes,</li>
                                        <li>LLC that has filed Form 8832 or 2553 to be taxed as a corporation, or</li>
                                        <li>LLC that is disregarded as an entity separate from its owner but the owner is
                                            another LLC that is not disregarded for U.S. federal tax purposes.</li>
                                    </ul>
                                </td>
                                <td>Limited liability company and enter the appropriate tax classification. (P= Partnership; C=
                                    C corporation; or S= S corporation)</td>
                            </tr>
                            <tr>
                                <td>
                                    <ul>
                                        <li>Partnership</li>
                                    </ul>
                                </td>
                                <td>Partnership</td>
                            </tr>


                            <tr>
                                <td>
                                    <ul>
                                        <li>Trust/estate</li>
                                    </ul>
                                </td>
                                <td>Trust/estate</td>
                            </tr>


                        </table>



                        <h4>Line 4, Exemptions</h4>
                        <p>If you are exempt from backup withholding and/or FATCA reporting, enter in the appropriate space
                            on line 4 any code(s) that may apply to you.</p>

                        <b>Exempt payee code.</b>
                        <ul>
                            <li>Generally, individuals (including sole proprietors) are not exempt from backup withholding.</li>
                            <li>Except as provided below, corporations are exempt from backup withholding for certain payments,
                                including interest and dividends.</li>
                            <li>Corporations are not exempt from backup withholding for payments made in settlement of payment
                                card or third party network transactions.</li>
                            <li>Corporations are not exempt from backup withholding with respect to attorneys’ fees or gross
                                proceeds paid to attorneys, and corporations that provide medical or health care services are
                                not exempt with respect to payments reportable on Form 1099-MISC.</li>
                        </ul>

                        <p class="indent">The following codes identify payees that are exempt from backup withholding. Enter the appropriate
                            code in the space in line 4.
                        <ol>
                            <li>-An organization exempt from tax under section 501(a), any IRA, or a custodial account under
                                section 403(b)(7) if the account satisfies the requirements of section 401(f)(2)</li>
                            <li>-The United States or any of its agencies or instrumentalities</li>
                            <li>-A state, the District of Columbia, a U.S. commonwealth or possession, or any of their political
                                subdivisions or instrumentalities</li>
                            <li>-A foreign government or any of its political subdivisions, agencies, or instrumentalities</li>
                            <li>-A corporation</li>
                            <li>-A dealer in securities or commodities required to register in the United States, the District
                                of Columbia, or a U.S. commonwealth or possession</li>
                            <li>A futures commission merchant registered with the Commodity Futures Trading Commission</li>
                            <li>-A real estate investment trust</li>
                            <li>-An entity registered at all times during the tax year under the Investment Company Act of 1940
                            </li>
                            <li>-A common trust fund operated by a bank under section 584(a)</li>
                            <li>-A financial institution</li>
                            <li>-A middleman known in the investment community as a nominee or custodian</li>
                            <li>-A trust exempt from tax under section 664 or described in section 4947</li>
                        </ol>
                        </p>
                    </div>
                </section>
                <section class="page pdf-item">
                    <p class="right-head">Form W-9 (Rev. 10-2018)</p>
                    <p class="page-number">Page<span>4</span></p>
                </section>
                <section class="fourth-page pdf-item">

                    <div class="left-side">

                        <p class="indent">The following chart shows types of payments that may be exempt
                            from backup withholding. The chart applies to the exempt payees listed
                            above, 1 through 13.
                        <table>
                            <tr>
                                <th>IF the payment is for . . .</th>
                                <th>THEN the payment is exempt
                                    for . . .</th>
                            </tr>
                            <tr>
                                <td>Interest and dividend payments</td>
                                <td>All exempt payees except
                                    for 7</td>
                            </tr>
                            <tr>
                                <td>Broker transactions</td>
                                <td>Exempt payees 1 through 4 and 6
                                    through 11 and all C corporations.
                                    S corporations must not enter an
                                    exempt payee code because they
                                    are exempt only for sales of
                                    noncovered securities acquired
                                    prior to 2012. </td>
                            </tr>
                            <tr>
                                <td>Barter exchange transactions and
                                    patronage dividends</td>
                                <td>Exempt payees 1 through 4</td>
                            </tr>
                            <tr>
                                <td>Payments over $600 required to be
                                    reported and direct sales over
                                    $5,000<sup>1</sup></td>
                                <td>Generally, exempt payees
                                    1 through 5<sup>2</sup></td>
                            </tr>
                            <tr>
                                <td>Payments made in settlement of
                                    payment card or third party network
                                    transactions </td>
                                <td>Exempt payees 1 through 4</td>
                            </tr>
                        </table>

                        <sup>1</sup>See Form 1099-MISC, Miscellaneous Income, and its instructions.
                        <sup>2</sup>However, the following payments made to a corporation and
                        reportable on Form 1099-MISC are not exempt from backup
                        withholding: medical and health care payments, attorneys’ fees, gross
                        proceeds paid to an attorney reportable under section 6045(f), and
                        payments for services paid by a federal executive agency.
                        </p>
                        <p><b>Exemption from FATCA reporting code.</b>The following codes identify
                            payees that are exempt from reporting under FATCA. These codes
                            apply to persons submitting this form for accounts maintained outside
                            of the United States by certain foreign financial institutions. Therefore, if
                            you are only submitting this form for an account you hold in the United
                            States, you may leave this field blank. Consult with the person
                            requesting this form if you are uncertain if the financial institution is
                            subject to these requirements. A requester may indicate that a code is
                            not required by providing you with a Form W-9 with “Not Applicable” (or
                            any similar indication) written or printed on the line for a FATCA
                            exemption code.</p>
                        <ol type="A">
                            <li>-An organization exempt from tax under section 501(a) or any
                                individual retirement plan as defined in section 7701(a)(37) </li>
                            <li>-The United States or any of its agencies or instrumentalities</li>
                            <li>-A state, the District of Columbia, a U.S. commonwealth or
                                possession, or any of their political subdivisions or instrumentalities </li>
                            <li>-A corporation the stock of which is regularly traded on one or
                                more established securities markets, as described in Regulations
                                section 1.1472-1(c)(1)(i) </li>
                            <li> -A corporation that is a member of the same expanded affiliated
                                group as a corporation described in Regulations section 1.1472-1(c)(1)(i)</li>
                            <li> -A dealer in securities, commodities, or derivative financial
                                instruments (including notional principal contracts, futures, forwards,
                                and options) that is registered as such under the laws of the United
                                States or any state</li>
                            <li>-A real estate investment trust
                            </li>
                            <li> -A regulated investment company as defined in section 851 or an
                                entity registered at all times during the tax year under the Investment
                                Company Act of 1940</li>
                            <li> -A common trust fund as defined in section 584(a)</li>
                            <li> -A bank as defined in section 581</li>
                            <li> -A broker</li>
                            <li>-A trust exempt from tax under section 664 or described in section
                                4947(a)(1)
                            </li>
                            <li>-A tax exempt trust under a section 403(b) plan or section 457(g)
                                plan</li>
                        </ol>
                    </div>

                    <div class="right-side">
                        <p><b>Note:</b>You may wish to consult with the financial institution requesting
                            this form to determine whether the FATCA code and/or exempt payee
                            code should be completed.</p>

                        <h4>Line 5</h4>
                        <p>Enter your address (number, street, and apartment or suite number).
                            This is where the requester of this Form W-9 will mail your information
                            returns. If this address differs from the one the requester already has on
                            file, write NEW at the top. If a new address is provided, there is still a
                            chance the old address will be used until the payor changes your
                            address in their records.</p>

                        <h4>Line 6</h4>
                        <p>Enter your city, state, and ZIP code.</p>

                        <h2>Part I. Taxpayer Identification Number (TIN)</h2>

                        <p><b>Enter your TIN in the appropriate box. </b> If you are a resident alien and
                            you do not have and are not eligible to get an SSN, your TIN is your IRS
                            individual taxpayer identification number (ITIN). Enter it in the social
                            security number box. If you do not have an ITIN, see How to get a TIN
                            below.</p>

                        <p class="indent">If you are a sole proprietor and you have an EIN, you may enter either
                            your SSN or EIN. </p>

                        <p class="indent">If you are a single-member LLC that is disregarded as an entity
                            separate from its owner, enter the owner’s SSN (or EIN, if the owner has
                            one). Do not enter the disregarded entity’s EIN. If the LLC is classified as
                            a corporation or partnership, enter the entity’s EIN.</p>

                        <p><b>Note:</b>See What Name and Number To Give the Requester, later, for
                            further clarification of name and TIN combinations.</p><br>

                        <p><b>How to get a TIN.</b> If you do not have a TIN, apply for one immediately.
                            To apply for an SSN, get Form SS-5, Application for a Social Security
                            Card, from your local SSA office or get this form online at <a href="www.SSA.gov">
                                <i>www.SSA.gov.</i></a> You may also get this form by calling 1-800-772-1213.
                                Use Form W-7, Application for IRS Individual Taxpayer Identification
                                Number, to apply for an ITIN, or Form SS-4, Application for Employer
                                Identification Number, to apply for an EIN. You can apply for an EIN
                                online by accessing the IRS website at <a
                                    href="www.irs.gov/Businesses"><i>www.irs.gov/Businesses</i></a> clicking on Employer Identification
                                Number (EIN) under Starting a
                                Business. Go to <a href=" www.irs.gov/OrderForms"> <i>www.irs.gov/OrderForms</i></a> to
                                place an order and have Form W-7 and/or SS-4 mailed to you within 10
                                business days.</p>

                        <p class="indent"> If you are asked to complete Form W-9 but do not have a TIN, apply
                            for a TIN and write “Applied For” in the space for the TIN, sign and date
                            the form, and give it to the requester. For interest and dividend
                            payments, and certain payments made with respect to readily tradable
                            instruments, generally you will have 60 days to get a TIN and give it to
                            the requester before you are subject to backup withholding on
                            payments. The 60-day rule does not apply to other types of payments.
                            You will be subject to backup withholding on all such payments until
                            you provide your TIN to the requester.</p>
                        <p><b>Note:</b> Entering “Applied For” means that you have already applied for a
                            TIN or that you intend to apply for one soon.</p>
                        <p><b>Caution:</b> A disregarded U.S. entity that has a foreign owner must use
                            the appropriate Form W-8.
                        </p>

                        <h2>Part II. Certification</h2>

                        <p>To establish to the withholding agent that you are a U.S. person, or
                            resident alien, sign Form W-9. You may be requested to sign by the
                            withholding agent even if item 1, 4, or 5 below indicates otherwise.</p>

                        <p class="indent">For a joint account, only the person whose TIN is shown in Part I
                            should sign (when required). In the case of a disregarded entity, the
                            person identified on line 1 must sign. Exempt payees, see <i>Exempt payee
                                code</i>, earlier.</p>
                        <p><b>Signature requirements.</b> Complete the certification as indicated in
                            items 1 through 5 below.</p>
                    </div>
                </section>
                <div class="html2pdf__page-break"/>
                <section class="page pdf-item">
                    <p class="right-head">Form W-9 (Rev. 10-2018)</p>
                    <p class="page-number">Page<span>5</span></p>
                </section>
                <section class="fifth-page pdf-item">
                    <div class="left-side">
                        <ol>
                            <li> <span>Interest, dividend, and barter exchange accounts opened
                                before 1984 and broker accounts considered active during 1983.</span>
                            You must give your correct TIN, but you do not have to sign the
                                certification.</li>

                                <li>
                                    <span>Interest, dividend, broker, and barter exchange accounts
                                    opened after 1983 and broker accounts considered inactive during
                                    1983. </span>You must sign the certification or backup withholding will apply. If
                                        you are subject to backup withholding and you are merely providing
                                        your correct TIN to the requester, you must cross out item 2 in the
                                        certification before signing the form.
                                </li>
                                <li><span> Real estate transactions.
                                </span>You must sign the certification. You may
                                        cross out item 2 of the certification.
                                </li>
                                <li><span>Other payments.</span>
                                    You must give your correct TIN, but you do not
                                        have to sign the certification unless you have been notified that you
                                        have previously given an incorrect TIN. “Other payments” include
                                        payments made in the course of the requester’s trade or business for
                                        rents, royalties, goods (other than bills for merchandise), medical and
                                        health care services (including payments to corporations), payments to
                                        a nonemployee for services, payments made in settlement of payment
                                        card and third party network transactions, payments to certain fishing
                                        boat crew members and fishermen, and gross proceeds paid to
                                        attorneys (including payments to corporations).
                                </li>
                                <li><span>Mortgage interest paid by you, acquisition or abandonment of
                                    secured property, cancellation of debt, qualified tuition program
                                    payments (under section 529), ABLE accounts (under section 529A),
                                    IRA, Coverdell ESA, Archer MSA or HSA contributions or
                                    distributions, and pension distributions.</span> You must give your correct
                                        TIN, but you do not have to sign the certification.</li>
                        </ol>

                        <h2>What Name and Number To Give the Requester</h2>
                        <table>
                            <tr>
                                <th>For this type of account:</th>
                                <th>Give name and SSN of:</th>
                            </tr>
                            <tr>
                                <td>1. Individual</td>
                                <td> The Individual</td>
                            </tr>
                            <tr>
                                <td>2. Two or more individuals (joint
                                    account) other than an account
                                    maintained by an FFI</td>
                                <td>The actual owner of the account or, if
                                    combined funds, the first individual on
                                    the account<sup>1</sup></td>
                            </tr>
                            <tr>
                                <td>3.  Two or more U.S. persons
                                    (joint account maintained by an FFI)</td>
                                <td>Each holder of the account</td>
                            </tr>
                            <tr>
                                <td>4. Custodial account of a minor
                                    (Uniform Gift to Minors Act)</td>
                                    <td>The minor <sup>2</sup></td>
                            </tr>
                            <tr>
                                <td>5. a.
                                    The usual revocable savings trust
                                        (grantor is also trustee) 
                                </td>
                                <td>The grantor-trustee <sup>1</sup></td>
                            </tr>
                            <tr>
                                <td> b. So-called trust account that is not
                                    a legal or valid trust under state law</td>
                                    <td>The actual owner <sup>1</sup></td>
                            </tr>
                            <tr>
                                <td>6. Sole proprietorship or disregarded
                                    entity owned by an individual</td>
                                    <td>The owner <sup>3</sup></td>
                            </tr>
                            <tr>
                                <td>7. Grantor trust filing under Optional
                                    Form 1099 Filing Method 1 (see
                                    Regulations section 1.671-4(b)(2)(i)
                                    (A))</td>
                                    <td>The grantor*</td>
                            </tr>
                            <tr>
                                <th>For this type of account:</th>
                                <th>Give name and EIN of:</th>
                            </tr>
                            <tr>
                                <td>8. Disregarded entity not owned by an
                                    individua</td>
                                <td>The owner</td>
                            </tr>
                            <tr>
                                <td>9. A valid trust, estate, or pension trust</td>
                                <td>Legal entity <sup>4</sup></td>
                            </tr>
                            <tr>
                                <td>10. Corporation or LLC electing
                                    corporate status on Form 8832 or
                                    Form 2553</td>
                                <td>The corporation</td>
                            </tr>
                            <tr>
                                <td>11. Association, club, religious,
                                    charitable, educational, or other taxexempt organization</td>
                                <td>The organization</td>
                            </tr>
                            <tr>
                                <td>12. Partnership or multi-member LLC</td>
                                <td>The partnership</td>
                            </tr>
                            <tr>
                                <td>13. A broker or registered nominee</td>
                                <td>The broker or nominee</td>
                            </tr>
                        </table>
                    </div>

                    <div class="right-side">
                        <table>
                            <tr>
                                <th>For this type of account:</th>
                                <th>Give name and EIN of:</th>
                            </tr>
                            <tr>
                                <td>14. Account with the Department of
                                    Agriculture in the name of a public
                                    entity (such as a state or local
                                    government, school district, or
                                    prison) that receives agricultural
                                    program payments</td>
                                <td>The public entity</td>
                            </tr>
                            <tr>
                                <td>15. Grantor trust filing under the Form
                                    1041 Filing Method or the Optional
                                    Form 1099 Filing Method 2 (see
                                    Regulations section 1.671-4(b)(2)(i)(B))</td>
                                <td>The trust</td>
                            </tr>
                        </table>
                        <p><sup>1</sup> List first and circle the name of the person whose number you furnish.
                            If only one person on a joint account has an SSN, that person’s number
                            must be furnished.
                            </p>

                        <p><sup>2</sup> Circle the minor’s name and furnish the minor’s SSN.</p>

                        <p><sup>3</sup>You must show your individual name and you may also enter your
                            business or DBA name on the “Business name/disregarded entity”
                            name line. You may use either your SSN or EIN (if you have one), but the
                            IRS encourages you to use your SSN.</p>

                        <p><sup>4</sup>List first and circle the name of the trust, estate, or pension trust. (Do
                            not furnish the TIN of the personal representative or trustee unless the
                            legal entity itself is not designated in the account title.) Also see <i>Special
                            rules for partnerships</i>, earlier.</p>

                            <p><b>*Note:</b>  The grantor also must provide a Form W-9 to trustee of trust</p>

                            <p><b>Note:</b>  If no name is circled when more than one name is listed, the
                                number will be considered to be that of the first name listed.</p>

                                <h2>Secure Your Tax Records From Identity Theft</h2>
                            <p>Identity theft occurs when someone uses your personal information
                                such as your name, SSN, or other identifying information, without your
                                permission, to commit fraud or other crimes. An identity thief may use
                                your SSN to get a job or may file a tax return using your SSN to receive
                                a refund.</p>

                                <p class="indent"> To reduce your risk:
                                    <ul>
                                        <li> Protect your SSN,</li>
                                        <li> Ensure your employer is protecting your SSN, and</li>
                                        <li>Be careful when choosing a tax preparer</li>
                                    </ul>
                                </p>

                                <p class="indent">If your tax records are affected by identity theft and you receive a
                                    notice from the IRS, respond right away to the name and phone number
                                    printed on the IRS notice or letter.</p>

                                    <p class="indent">If your tax records are not currently affected by identity theft but you
                                        think you are at risk due to a lost or stolen purse or wallet, questionable
                                        credit card activity or credit report, contact the IRS Identity Theft Hotline
                                        at 1-800-908-4490 or submit Form 14039.
                                        </p>

                                        <p class="indent">For more information, see Pub. 5027, Identity Theft Information for
                                            Taxpayers.</p>

                                            <p class="indent">Victims of identity theft who are experiencing economic harm or a
                                                systemic problem, or are seeking help in resolving tax problems that
                                                have not been resolved through normal channels, may be eligible for
                                                Taxpayer Advocate Service (TAS) assistance. You can reach TAS by
                                                calling the TAS toll-free case intake line at 1-877-777-4778 or TTY/TDD
                                                1-800-829-4059.</p>

                                                <h4>Protect yourself from suspicious emails or phishing schemes. </h4>
                                                <p>Phishing is the creation and use of email and websites designed to
                                                    mimic legitimate business emails and websites. The most common act
                                                    is sending an email to a user falsely claiming to be an established
                                                    legitimate enterprise in an attempt to scam the user into surrendering
                                                    private information that will be used for identity theft.</p>

                    </div>
                </section>
                <div class="html2pdf__page-break"/>
                <section class="page pdf-item">
                    <p class="right-head">Form W-9 (Rev. 10-2018)</p>
                    <p class="page-number">Page<span>6</span></p>
                </section>
                <section class="sixth-page pdf-item">
                    <div class="left-side">
                    <p class="indent">The IRS does not initiate contacts with taxpayers via emails. Also, the
                        IRS does not request personal detailed information through email or ask
                        taxpayers for the PIN numbers, passwords, or similar secret access
                        information for their credit card, bank, or other financial accounts.</p>

                        <p class="indent">If you receive an unsolicited email claiming to be from the IRS,
                            forward this message to <a href=" phishing@irs.gov"><i>phishing@irs.gov</i></a>. You may also report misuse
                            of the IRS name, logo, or other IRS property to the Treasury Inspector
                            General for Tax Administration (TIGTA) at 1-800-366-4484. You can
                            forward suspicious emails to the Federal Trade Commission at
                            <a href="spam@uce.gov"><i>spam@uce.gov</i></a> or report them at <a href="www.ftc.gov/complaint"><i>www.ftc.gov/complaint</i></a>. You can
                            contact the FTC at <a href="www.ftc.gov/idtheft"><i>www.ftc.gov/idtheft</i></a> or 877-IDTHEFT (877-438-4338).
                            If you have been the victim of identity theft, see <a href="www.IdentityTheft.gov"><i>www.IdentityTheft.gov</i></a>
                            and Pub. 5027.</p>

                            <p class="indent">Visit <a href="www.irs.gov/IdentityTheft"><i>www.irs.gov/IdentityTheft</i></a> to learn more about identity theft and
                                how to reduce your risk.</p>

                            </div>
                            <div class="right-side">
                                <h2>Privacy Act Notice</h2>
                                <p>Section 6109 of the Internal Revenue Code requires you to provide your
                                    correct TIN to persons (including federal agencies) who are required to
                                    file information returns with the IRS to report interest, dividends, or
                                    certain other income paid to you; mortgage interest you paid; the
                                    acquisition or abandonment of secured property; the cancellation of
                                    debt; or contributions you made to an IRA, Archer MSA, or HSA. The
                                    person collecting this form uses the information on the form to file
                                    information returns with the IRS, reporting the above information.
                                    Routine uses of this information include giving it to the Department of
                                    Justice for civil and criminal litigation and to cities, states, the District of
                                    Columbia, and U.S. commonwealths and possessions for use in
                                    administering their laws. The information also may be disclosed to other
                                    countries under a treaty, to federal and state agencies to enforce civil
                                    and criminal laws, or to federal law enforcement and intelligence
                                    agencies to combat terrorism. You must provide your TIN whether or
                                    not you are required to file a tax return. Under section 3406, payers
                                    must generally withhold a percentage of taxable interest, dividend, and
                                    certain other payments to a payee who does not give a TIN to the payer.
                                    Certain penalties may also apply for providing false or fraudulent
                                    information.</p>
                            </div>
                </section>
            </section>
    </section>
    </section>
    </vue-html2pdf>
</div>

</template>

<script>
    import axios from 'axios';
    import VueHtml2pdf from 'vue-html2pdf'

    export default {
        name: "TestPdf",
        components: {VueHtml2pdf},
        data() {
            return {
                tempId: '',
                type:'',
                tax_id:'', 
                agentid:'', 
                name:'',
                business_name:'',
                federal_tax:'',
                exempt_payee_code:'',
                exempt_fatca_reporting:'',
                list_account_numbers:'',
                street_address1:'',
                street_address2:'',
                city:'',
                state:'',
                zip:'',
                requester_name_address:'',
                social_security:'',
                employer_identification_number:'',
                pay_to:'',
                signature_of_us_person:'',
                date:'',
                is_complete:'', 
                completed_upto:'', 
                screenshot:'',
                items: [],
                comItems: [],
                level: '2',
                isGenerating: false,
                htmlToPdfOptions: {
                    margin: 0.5,
                    filename: '',
                    enableLinks: true,
                    image: {
                        type: 'jpeg',
                        quality: 0.98,
                    },
                    html2canvas: {
                        scale: 0.5,
                        scrollX: 0,
                        scrollY: 0,
                        width: 1280,
                        useCORS: true
                    },
                    jsPDF: {
                        unit: 'in',
                        format: 'a4',
                        orientation: 'portrait',
                    }
                },
            };
        },
        mounted() {
            let app = this;
            app.tempId= app.$route.params.id;
            app.getContractData();
        },
        computed:{
            fullAddress:{
                get() {
                    return this.city+','+this.state+','+this.zip;
                }
            }
        },
        methods: {
            async beforeDownload ({ html2pdf, options, pdfContent }) {
                var tempId = this.tempId;
                var agentId = this.agentid;
                html2pdf().set(options).from(pdfContent).toPdf().output('datauristring').then(function (pdfAsString) {

                    let data = new FormData();
                    data.append('pdf',pdfAsString);
                    data.append('id',tempId);
                    data.append('agent_id',agentId);
                    data.append('type','w9');
                    data.append('level','0');
                    axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-pdf', data)
                    // axios.post('http://local.coreapi/api/v1/save-contract-pdf', data)
                        .then(function (response) {
                            if (response.data.status == 'success') {
                                console.log("success");
                            }
                            window.location = '/contract-form/contract_message/success';
                        })
                        .catch(function (error) {
                            console.log(error.response.data.data);
                            if (error.response.data.status == 'error') {
                            error.response.data.data.forEach(function(item) {

                            });
                            }
                        });
                });

            },
            hasDownloaded(blob) {
                this.isGenerating = false;
            },
            generate() {
                this.isGenerating = true;
                this.htmlToPdfOptions.filename = `anclevel2.pdf`;
                this.$refs.html2Pdf.generatePdf()
            },
            getContractData() {
                let app = this;
                axios.get(process.env.VUE_APP_CORENROLL_SYSTEM + '/get-contract-data-w9?', {
                    params: {
                        tempid: app.tempId,
                    }
                })
                    .then(function (response) {
                        app.tax_id = response.data.data.result.tax_id; 
                        app.agentid = response.data.data.result.agentid; 
                        app.name = response.data.data.result.name;
                        app.business_name = response.data.data.result.business_name;
                        app.federal_tax = response.data.data.result.federal_tax;
                        app.exempt_payee_code = response.data.data.result.exempt_payee_code;
                        app.exempt_fatca_reporting = response.data.data.result.exempt_fatca_reporting;
                        app.list_account_numbers = response.data.data.result.list_account_numbers;
                        app.street_address1 = response.data.data.result.street_address1;
                        app.street_address2 = response.data.data.result.street_address2;
                        app.city = response.data.data.result.city;
                        app.state = response.data.data.result.state;
                        app.zip = response.data.data.result.zip;
                        app.requester_name_address = response.data.data.result.requester_name_address;
                        app.social_security = response.data.data.result.social_security;
                        app.employer_identification_number = response.data.data.result.employer_identification_number;
                        app.pay_to = response.data.data.result.pay_to;
                        app.signature_of_us_person = response.data.data.result.signature_of_us_person;
                        app.date = response.data.data.result.date;
                        app.is_complete = response.data.data.result.is_complete; 
                        app.completed_upto = response.data.data.result.completed_upto; 
                        app.screenshot = response.data.data.result.screenshot;
                        app.generate();
                    })
                    .catch(function (error) {
                        console.log(error)
                        console.log('failed to generate pdf')
                    });
            },
        }
    };
</script>
<style
        src="../../assets/css/contract_template/w-9.css"
        scoped
></style>