<template>
  <div class="content_section gp_contribution">
                <div class="group_info">
                  <h2>GROUP CONTRIBUTION</h2>
                  <form class="" action="index.html" method="post">
                    <div class="input_box pmt_mtd">
                      <div class="con_container">
                        <span class="pay_check">
                          <input type="radio" name="" value="">
                          <label for="">Yes</label>
                        </span>
                        <span class="pay_check">
                          <input type="radio" name="" value="">
                          <label for="">No</label>
                        </span>
                      </div>
                    </div>
                    <div class="defined_contribution">
                      <h3>Group Defined Contribution Type</h3>
                      <div class="input_box pmt_mtd">
                        <div class="con_container">
                          <span class="pay_check">
                            <input type="radio" name="" value="">
                            <label for="">Flat Dollar Amount</label>
                          </span>
                          <span class="pay_check">
                            <input type="radio" name="" value="">
                            <label for="">Percentage</label>
                          </span>
                        </div>
                      </div>
                      <div class="flat_amt">
                        <div class="input_box quater ">
                          <div class="inputs">
                            <input type="text" name="" value=""/>
                          </div>
                        </div>
                      </div>
                      <div class="percentage_amt">
                        <div class="input_box quater ">
                          <label for="">Member <span class="red">*</span></label>
                          <div class="inputs">
                            <input type="text" name="" value=""/>
                          </div>
                        </div>
                        <div class="input_box quater ">
                          <label for="">Member+1 <span class="red">*</span></label>
                          <div class="inputs">
                            <input type="text" name="" value=""/>
                          </div>
                        </div>
                        <div class="input_box quater ">
                          <label for="">Member + Child(ren) <span class="red">*</span></label>
                          <div class="inputs">
                            <input type="text" name="" value=""/>
                          </div>
                        </div>
                        <div class="input_box quater ">
                          <label for="">Family <span class="red">*</span></label>
                          <div class="inputs">
                            <input type="text" name="" value=""/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="buttonbox">
                      <button @click="navigatePrevious" class="previous" type="button" name="button">Previous</button>
                      <button class="save" type="button" name="button">Save</button>
                      <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
                    </div>
                  </form>
                </div>
              </div>
</template>
<script>
export default {
    name: "GroupContribution",
    props: {
      navdata: {
        type: Array
      }
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
