var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "initial_page"
  }, [_c('div', {
    staticClass: "main_page"
  }, [_vm._m(0), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "reset_form"
  }, [_c('h3', [_vm._v("Reset Password")]), _c('b-form-group', {
    attrs: {
      "label": "New Password",
      "label-for": "input-1"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-1",
      "type": "text",
      "required": "",
      "placeholder": "Enter your new password"
    },
    model: {
      value: _vm.newpassword,
      callback: function ($$v) {
        _vm.newpassword = $$v;
      },
      expression: "newpassword"
    }
  }), _c('eye-button', {
    attrs: {
      "id": "input-1",
      "hideOnDefault": "true",
      "type": "text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Confirm Password",
      "label-for": "input-2"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "input-2",
      "type": "text",
      "required": "",
      "placeholder": "Repeat new password"
    },
    model: {
      value: _vm.confirmpassword,
      callback: function ($$v) {
        _vm.confirmpassword = $$v;
      },
      expression: "confirmpassword"
    }
  }), _c('eye-button', {
    attrs: {
      "id": "input-2",
      "hideOnDefault": "true",
      "type": "text"
    }
  })], 1), _vm.formErrorFlag == true ? _c('div', {
    staticClass: "error_message"
  }, [_vm._v(" " + _vm._s(_vm.formErrors["error"]) + " ")]) : _vm._e(), _c('button', {
    attrs: {
      "disabled": _vm.verifyLoader
    },
    on: {
      "click": _vm.changePassword
    }
  }, [_vm.verifyLoader == true ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Change Password ")], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "forgot_welcome"
  }, [_c('h1', [_vm._v("Forgot your Password?")]), _c('p', [_vm._v(" Don't Worry! we've got your back. Just follow the instructions and you'll be good :) ")])]);

}]

export { render, staticRenderFns }