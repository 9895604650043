var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    ref: "save",
    staticClass: "contract_content"
  }, [_c('w-9-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_vm._m(0), _c('div', {
    staticClass: "description"
  }, [_c('p', [_vm._v("Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.")]), _c('div', {
    staticClass: "form"
  }, [_c('div', [_c('label', [_vm._v("Social security number")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.ssn.value,
      expression: "formFields.ssn.value"
    }],
    attrs: {
      "type": "text",
      "name": "ssn",
      "readonly": _vm.formFields.ssn.read_only == true,
      "value": "",
      "maxlength": "9"
    },
    domProps: {
      "value": _vm.formFields.ssn.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.ssn, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['social_security_message']))]) : _vm._e()]), _c('div', [_c('label', [_vm._v("Employer identification number")]), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.employer_id.value,
      expression: "formFields.employer_id.value"
    }],
    attrs: {
      "type": "text",
      "name": "employer_id",
      "readonly": _vm.formFields.employer_id.read_only == true,
      "value": ""
    },
    domProps: {
      "value": _vm.formFields.employer_id.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.employer_id, "value", $event.target.value);
      }
    }
  }), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['employer_identification_number_message']))]) : _vm._e()])]), _vm._m(1)]), _c('div', {
    staticClass: "button_container"
  }, [_c('button', {
    staticClass: "back",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Back")]), _c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "next",
    on: {
      "click": _vm.saveTaxPayerInfo
    }
  }, [_vm._v("Next")])])])])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', [_c('span', [_vm._v("PART I")]), _c('br'), _vm._v("TAXPAYER IDENTIFICATION NUMBER")]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "color": "#0594b8"
    }
  }, [_c('b', [_vm._v("Note:")]), _vm._v(" If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give the Requester for guidelines on whose number to enter.")]);

}]

export { render, staticRenderFns }