<template>
  <b-modal id="alert-popup2" centered title="Plan Renewal Options" hide-footer>
    <div class="alert-popup">
      <p class="mb-4 text-center">
        Do you want to confirm current plan to new plan?
      </p>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Current Plans</th>
              <th>New Plan</th>
              <th>Effective Date</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ currentPlan.web_display_name }}</td>
              <td>{{ newPlan.web_display_name }}</td>
              <td>{{ date }}</td>
              <td>{{ newPlan.price | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
         <p>
              Application Signature (Required)
              <span>(Sign Name using your mouse or touch screen in the box
                below)</span>
            </p>
        <signature-pad @sign="onEndSign" />
      </div>
    </div>
    <br/>
    <div class="text-center">
      <button
        :class="sign ? 'accept-btn' : 'accept-btn disable-btn'"
        @click.prevent="switchPlan"
        :disabled="sign ? false : true"
      >
        {{ isSwitch ? "Switching Plan.." : "Switch Plan" }}
      </button>
    </div>
  </b-modal>
</template>
<script>
import moment from "moment";
import Api from "@/resource/MemberApi";
import SignaturePad from "../../SignaturePad.vue";
export default {
  components: { SignaturePad },
  name: "DashboardEnrollmentRenewPlanModal",
  props: {
    currentPlan: {
      required: true,
      type: Object,
    },
    newPlan: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    date: moment("01/01/2022").format("MM/DD/Y"),
    isSwitch: false,
    sign: "",
  }),
  methods: {
    onEndSign(val) {
      this.sign = val;
    },
    switchPlan() {
      let url = `v2/switch-plan`;
      let data = {
        current_plan_policy_id: this.currentPlan.plan_policy_id,
        current_plan_id: this.currentPlan.pid,
        plan_id: this.newPlan.pid,
        plan_pricing_id: this.newPlan.plan_pricing_id,
        sign: this.sign,
      };
      this.isSwitch = true;
      Api.put(url, data)
        .then((res) => {
          this.$notify({
            type: "success",
            title: "Switch Plan",
            text: res.data.message,
          });
          this.$bvModal.hide("alert-popup2");
          this.$emit("update:newPlan", null);
          this.$parent.$emit("onSwitchedPlan");
          this.sign = ""
        })
        .catch((err) => {
          let message;
          if (err.response.status == 422) {
            message = err.response.data.errors[0];
          } else {
            message = err.response.data.message;
          }
          this.$notify({
            type: "error",
            title: "Switch Plan",
            text: message,
          });
        })
        .finally(() => {
          this.isSwitch = false;
        });
    },
  },
};
</script>
<style scoped>
.disable-btn {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0 !important;
  background-color: #ffffff !important;
  border: 1 px solid #ccc !important;
  outline: none;
}
</style>