var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row body-main-row"
  }, [_c('div', {
    staticClass: "dashboard-new"
  }, [_c('div', {
    staticClass: "col-md-10 member-body-content-wrap pt-3"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-2 vertical-center"
  }, [_c('div', {
    staticClass: "user-title mb-3"
  }, [_c('h2', [_vm._v(" Welcome "), _c('strong', [_vm._v(_vm._s(_vm.user.firstName))])]), _c('p', [_vm._v("Member Dashboard")])])]), _c('div', {
    staticClass: "col-xl-10 col-md-12 btn-content"
  }, [_c('div', {
    staticClass: "text-center"
  }), _c('div', {
    staticClass: "benefitstore-dash d-flex"
  }, [_vm._m(0), _c('div', {
    staticClass: "benefitstore-content"
  }, [_c('h2', [_vm._v("The Benefit Store")]), _c('button', {
    staticClass: "dashboard-button",
    on: {
      "click": _vm.benefitStoreUrl
    }
  }, [_vm._v(" Add New Plans ")])])]), _c('div', {
    staticClass: "referal-btn d-flex"
  }, [_c('img', {
    staticClass: "refer-img",
    attrs: {
      "src": require("@/assets/images/referal-icon.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "ref-btn",
    on: {
      "click": _vm.memberReferral
    }
  }, [_c('span', {
    staticClass: "mt-1 mb-0"
  }, [_vm._v("Referral Reward Program")])])])]), _c('dashboard-enrollment-plan', {
    on: {
      "onSwitchedPlanGetProducts": _vm.getProductDetails
    }
  }), _c('div', {
    staticClass: "col-xl-8 col-lg-12"
  }, [_c('div', {
    staticClass: "dashboard-product-details"
  }, [_c('product-details', {
    ref: "productDetail",
    on: {
      "setPlanType": _vm.isMajorMedical
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('message-center')], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('resource')], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('knowledge-center')], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('beneficiary')], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('employer')], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('dependent')], 1)])]), _c('div', {
    staticClass: "col-xl-4 col-lg-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-12 col-lg-6 col-md-6 col-sm-12 dashboard-personal"
  }, [_c('personal-info')], 1), _c('div', {
    staticClass: "col-xl-12 col-lg-6 col-md-6 col-sm-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 dashboard-benefit-store"
  }, [_c('benefit-store')], 1), _c('div', {
    staticClass: "col-12 dashboard-rep-info"
  }, [_c('rep-info')], 1)])])])])], 1)]), _c('div', {
    staticClass: "col-md-12 member-body-content-wrap member-body-content-wrap-bottom"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', [_vm.employer_type != 'employer' ? _c('banking-info') : _vm._e()], 1)]), _c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', [_vm.employer_type != 'employer' ? _c('billing-invoice2') : _vm._e()], 1)])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "benefitstore-image"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/b-store.svg"),
      "alt": ""
    }
  })]);

}]

export { render, staticRenderFns }