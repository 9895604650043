<template>
  <div class="container-fluid">
    <div class="row body-main-row">
      <div class="col-md-10 member-body-content-wrap plan">
        <div class="row contents">
          
          <div class="col-12">
            <h3>Plan Details</h3>
          </div>

          <div class="first col-12">
            <div class="row inner-row">

              <div class="col-xl-8 col-lg-12 plan_details mb-4">
                <div class="dashboard-card">
                  
                  <div class="table_content">
                    <b-skeleton-table v-if="policy_details_loader" :rows="4" :columns="4" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                    <table class="" v-if="!policy_details_loader">
                      <thead>
                        <tr>
                          <td style="text-align: left">Plan</td>
                          <td>Status</td>
                          <td>Effective Date</td>
                          <td>Term Date</td>
                          <td>Amount</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in policy_details.plans" :key="index">
                          <td :class="item.status == 'ACTIVE' ? '' : 'plan_not_active'" style="font-family:SF UI display Bold; text-align: left" v-html="item.plan_name.replace('\n', '<br/>')">
                          </td>
                          <td style="color: rgb(50, 205, 50); font-weight: bold;">{{ item.status }}</td>
                          <td>{{ item.plan_effective_date }}</td>
                          <td>{{ item.plan_term_date }}</td>
                          <td>{{ "$" + item.price }}</td>
                        </tr>
                        <tr>

                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            Tier: <span>{{ policy_details.tier }}</span>
                          </td>

                          <td class="res_none"></td>
                          <td class="res_none"><span v-if="policy_details.billing_type == 'cc'">Payment Service Fee: ${{policy_details.cc_fee}}</span> </td>
                          <td class="res_none">
                            Total: 
                          </td>
                          <td class="res_none" style="color: #ff7a00; text-align: center">
                            {{ "$" + policy_details.plan_total }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" v-if="this.employer_type != 'employer'">
                            Billing Method:
                            <span>{{ policy_details.billing_type }}</span>
                          </td>
                          <td  v-if="this.employer_type != 'employer'">
                            Bill Date:
                            <span>{{ policy_details.bill_date }}</span>
                          </td>
                          <td>
                            Enrollement Date:
                            <span>{{ policy_details.enrollment_date }}</span>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <br>
                    
                    <!-- <table v-if="policy_details.billing_type == 'eft'">
                    <thead>
                      <tr>
                        <td>A/C Name</td>
                        <td>Bank Name</td>
                        <td>Routing Number</td>
                        <td>A/C Number</td>
                        <td>Type</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="show_default_bank">
                        <td style="font-family: SF UI Display Medium">
                          {{ policy_details.default_bank_account.bank_accountname }}
                        </td>
                        <td>{{ policy_details.default_bank_account.bank_name }}</td>
                        <td>{{ policy_details.default_bank_account.bank_routing }}</td>
                        <td>{{ policy_details.default_bank_account.bank_account_number }}</td>
                        <td>
                          <b>Default</b>
                        </td>
                      </tr>
                      <tr v-if="!show_default_bank">
                        <td style="font-family: SF UI Display Medium">
                          {{ policy_details.recurring_bank_account.bank_accountname }}
                        </td>
                        <td>{{ policy_details.recurring_bank_account.bank_name }}</td>
                        <td>{{ policy_details.recurring_bank_account.bank_routing }}</td>
                        <td>{{ policy_details.recurring_bank_account.bank_account_number }}</td>
                        <td>
                          <b>Recurring</b>
                        </td>
                      </tr>
                    </tbody>
                    </table> -->
                  </div>

                  <div class="planPayment-via" v-if="policy_details.billing_type == 'eft'">
                      <h3>Payment Via</h3>
                      <div class="row p-0" v-if="policy_details.billing_type == 'eft'">
                        <div class="col-xxl-5 col-xl-6 col-md-6 mb-3" v-if="policy_details.show_default_bank">
                          <div class="paymentBlock">
                            <span class="ac-name">
                              {{ policy_details.default_bank_account.bank_accountname }}
                            </span>
                            <h4>
                              {{ policy_details.default_bank_account.bank_name }}
                            </h4>
                            <div class="row p-0">
                              <div class="col-md-6">
                                <label>A/C Number</label>
                                <span>
                                  {{ policy_details.default_bank_account.bank_account_number }}
                                </span>
                              </div>
                              <div class="col-md-6">
                                <label>Routing Number</label>
                                <span>
                                  {{ policy_details.default_bank_account.bank_routing }}
                                </span>
                              </div>
                            </div>
                            <span class="type">Default</span>
                          </div>
                        </div>

                        <div class="col-xxl-5 col-xl-6 col-md-6 mb-3" v-if="policy_details.show_recurring_bank">
                          <div class="paymentBlock">
                            <span class="ac-name">
                              {{ policy_details.recurring_bank_account.bank_accountname }}
                            </span>
                            <h4>
                              {{ policy_details.recurring_bank_account.bank_name }}
                            </h4>
                            <div class="row p-0">
                              <div class="col-md-6">
                                <label>A/C Number</label>
                                <span>
                                  {{ policy_details.recurring_bank_account.bank_account_number }}
                                </span>
                              </div>
                              <div class="col-md-6">
                                <label>Routing Number</label>
                                <span>
                                  {{ policy_details.recurring_bank_account.bank_routing }}
                                </span>
                              </div>
                            </div>
                            <span class="type">Recurring</span>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12 resource_new mb-4" v-if="show_hide.resource">
                <div class="dashboard-card">
                  <h3>Resources</h3>
                  <b-skeleton-table v-if="resources_loader" :rows="4" :columns="1" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                  <div v-if="!resources_loader" class="resources-list-div">
                    <div class="resources-list-div-inner">
                      <div class="resources-list" v-for="(item, index) in  resources.files" :key="index">
                        <div class="icon_item" @click="downloadFile(item.url)">
                          <div class="icon">
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                              viewBox="0 0 482.14 482.14" style="enable-background:new 0 0 482.14 482.14;" xml:space="preserve">
                              <g>
                              <path d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69
                                c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z"/>
                              <path d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394
                                c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z"/>
                              <path d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404
                                c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223
                                c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796
                                c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646
                                c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811
                                c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748
                                c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233
                                c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z
                                M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282
                                c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222
                                C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42
                                v19.875H314.892z"/>
                              </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                            </svg>
                          </div>
                          <div class="resource_item">
                            <p>{{ item.filename }}</p>
                            <small><span></span>{{item.header}}</small>
                          </div>
                        </div>
                        <div style="cursor:pointer" @click="downloadFile(item.url)" class="test"><i class="fas fa-download"></i></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="second col-xl-6 col-lg-7 col-md-6 col-sm-12 mb-4">
                <div class="dependent_info dashboard-card">
                  <h3>Dependent Information</h3>
                  <div class="table_content">
                    <b-skeleton-table v-if="policy_details_loader" :rows="4" :columns="1" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                    <table v-if="!policy_details_loader">
                      <thead>
                        <tr>
                          <td>Name</td>
                          <td>Gender</td>
                          <td>Relation</td>
                          <td>DOB</td>
                          <td>SSN</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in policy_details.dependent_details" :key="index">
                          <td style="font-family:SF UI display Medium;">
                            {{ item.first_name }} {{ item.last_name }}
                          </td>
                          <td>{{ item.gender }}</td>
                          <td>{{ item.relation }}</td>
                          <td>{{ item.birthdate }}</td>
                          <td>{{ item.ssn }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              
              <div class="second col-xl-6 col-lg-12 col-md-6 col-sm-12 mb-4">

                <!-- <div class="payment_history" v-if="show_hide.payment_history"> -->

                <div class="payment_history"  v-if="payment_history.length">
                  <div class="dashboard-card">
                    <h3>Payment History</h3>
                    <div class="table_content">
                      <b-skeleton-table v-if="payment_history_loader" :rows="4" :columns="1" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
                      <table v-if="!payment_history_loader">
                    
                        <thead>
                          <tr>
                            <td>Payment ID</td>
                            <td>Payment Date</td>
                            <td>Paid Through Date</td>
                            <td>Method</td>
                            <td>Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in payment_history" :key="index">
                            <td style="font-family:SF UI display Medium;">
                              {{ item.payment_id }}
                            </td>
                            <td>{{ item.payment_date }}</td>
                            <td>{{ item.payment_paid_through_date }}</td>
                            <td>{{ item.payment_method }}</td>
                            <td>{{ "$" + item.payment_amount }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
export default {
  data: function () {
    return {
      employer_type : this.$store.state.groupType,
      policyID: "",
      policy_details: [],
      resources: [],
      payment_history: [],
      resources_loader : true,
      policy_details_loader : true,
      payment_history_loader : true,
      //show_default_bank: true,
      show_hide : {
        payment_history: true,
        resource: true,
        dependent: true,
      }
    };
  },
  mounted() {
    this.policyId = this.$route.params.policyID;
    this.getPolicyDetails(this.$route.params.policyID);
    this.getPaymentHistory(this.$route.params.policyID);
    this.getPolicyResource(this.$route.params.policyID);
  },
  methods: {
    getPolicyDetails(policyID) {
      let app = this;
      axios.get("/get-policy-details/" + policyID).then((response) => {
        app.policy_details = response.data.data;
        //let effectiveDate = new Date(app.policy_details.effective_date);
        //let currentDate = new Date();
        //app.show_default_bank = effectiveDate >= currentDate
        this.show_hide.dependent = response.data.data.dependent_details.length
        this.policy_details_loader = false
      });
    },
    getPaymentHistory(policyID) {
      let app = this;
      if(this.employer_type =='employer') { app.show_hide.payment_history = false; return; }
      axios.get("/get-payment-history/" + policyID).then((response) => {
        app.payment_history = response.data.data;
        this.show_hide.payment_history = response.data.data.length
        this.payment_history_loader = false
      });
    },
    getPolicyResource(policyID) {
      let app = this;
      axios.get("get-resources?policy_id=" + policyID).then((response) => {
        app.resources = response.data.data;
        this.show_hide.resource = response.data.data.files.length
        this.resources_loader = false
      });
    },
    downloadFile(fileUrl) {
      window.open(fileUrl, "_blank");
    },
  },
};
</script>

<style>
@import "../assets/css/plan_detail.css";
.plan_not_active ~ td,
.plan_not_active {
  color: red !important;
}
.icon_item {
  cursor: pointer;
}
</style>

<style scoped>
.member-body-content-wrap.plan {
  min-width: calc(100% - 200px);
}
.member-body-content-wrap.plan .row {
  background: transparent;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.member-body-content-wrap.plan .inner-row {
  padding: 0px;
  margin-left: -15px;
  margin-right: -15px;
}
@media (max-width: 992px) {
  .member-body-content-wrap.plan {
    min-width: calc(100% - 45px);
  }
}
.member-body-content-wrap.plan .inner-row .plan_details .table_content  {
  overflow-X: auto;
}
.dependent_info .table_content  {
  overflow-x: auto;
}
.member-body-content-wrap.plan .inner-row .plan_details .table_content table {
  width: 100%;
  margin-bottom: 10px;
}
.dependent_info table {
  min-width: 480px;
  margin-bottom: 10px;
}
.contents {
  padding: 0!important;
  min-height: calc(100vh - 305px);
}
.dashboard-card {
    background-color: #fff;
    border-radius: 20px;
    padding: 30px 25px;
    border: none;
    box-shadow: 0px 2px 6px #21252914;
}
.plan h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.member-body-content-wrap.plan .inner-row .plan_details .table_content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.member-body-content-wrap.plan .inner-row .plan_details .table_content::-webkit-scrollbar-track {
    background: #fff;
    
}
.member-body-content-wrap.plan .inner-row .plan_details .table_content::-webkit-scrollbar-thumb {
    background: #d6d6d6;
    border-radius: 2px;
}
.member-body-content-wrap.plan .inner-row .plan_details .table_content::-webkit-scrollbar-thumb:hover {
    background: #8f8b8b80;
}
.dependent_info .table_content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.dependent_info .table_content::-webkit-scrollbar-track {
    background: #fff;
    
}
.dependent_info .table_content::-webkit-scrollbar-thumb {
    background: #d6d6d6;
    border-radius: 2px;
}
.dependent_info .table_content::-webkit-scrollbar-thumb:hover {
    background: #8f8b8b80;
}
.planPayment-via h3{
  font-size: 16px;
  font-weight: 600;
}
.paymentBlock{
  padding: 1rem;
  border: 1px dashed #ddd;
  border-radius: 15px;
  background: #f9f9f9;
  position: relative;
}
.paymentBlock .ac-name, .paymentBlock label{
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}
.paymentBlock h4{
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 1.5rem;
}
.paymentBlock span{
  display: block;
}
.paymentBlock .type{
  font-size: 13px;
    background: #29bcff;
    position: absolute;
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    top: 10px;
    right: 10px;
}
@media(max-width: 420px){
  .planPayment-via{
    margin-top: 1rem;
  }
}
@media(max-width: 400px){
  .paymentBlock .type{
    position: inherit;
    width: 100%;
    text-align: center;
    top: 0;
    right: 0;
    margin-top: 10px;
  }
}
</style>