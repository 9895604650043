<template>
    <section class="container-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="message-wrap white-wrap text-center">
                        <div class="message-image">
                            <img src="../../assets/images/review-images/thankyou.svg" class="img-fluid" alt="">
                        </div>
                        <h1>Thank you for your review in <br> {{ ownerTitle }}</h1>
                        <p>We have received your Feedback. <br> We are working for better experience on the system.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "MessagePage",
    data: function () {
        return {
            owner: '',
            ownerTitle: '',
        }
    },
    mounted() {
        this.owner = this.$route.query.owner;
        this.setOwner();
    },
    methods: {
        setOwner: function () {
            if (this.owner == 'members') {
                this.ownerTitle = 'Member / Employee';
            } else if(this.owner == 'employers') {
                this.ownerTitle = 'Employers / Groups';
            } else if(this.owner == 'brokers') {
                this.ownerTitle = 'Broker / Reps';
            } else {
                this.ownerTitle = 'Leave a Review';
            }
        },
    }
}
</script>
<style src="../../assets/css/review_rating/review_content.css" scoped>

</style>
