<template>
  <div class="initial_page twoVerification-wrap">
    <div class="main_page verificaiton-success-block">
      <div class="welcome">
        <h1>Success</h1>
        <p>Congratulations, We have recovered you email <strong>{{ email }}</strong>.</p>
      </div>
      <div class="form">
        <div class="verification-detail two_OTP_verification text-center">
          <div class="verification-icon">
            <img
              src="@/assets/images/checkmark.svg"
              alt="Verification Icon"
              class="img-fluid"
            />
          </div>
          <h2>{{ email }}</h2>
          <div class="action-btn">
            <button class="main-primary-btn" @click="loginLink">Login</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
export default {
  name: "RecoverEmail",
  data: () => {
    return {
      email: "",
    };
  },
  methods: {
    loginLink() {
      Helper.resetRegisterMemberAuth();
      this.$router.push({
        name: "Login",
        params: { tempEmail: this.email },
      });
    },
  },
  created() {
    this.email = this.$route.params.tempEmail;
    if (!this.email) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
<style scoped>
@import "../../assets/css/verification.css";
</style>