var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "agent_container"
  }, [_c('div', {
    staticClass: "agent_information"
  }, [_c('div', {
    staticClass: "agent_name"
  }, [_vm._v(" Contract Name:"), _c('strong', [_vm._v(_vm._s(_vm.computedContractName))])]), _c('div', {
    staticClass: "agent_name"
  }, [_vm._v(" Agent Name:"), _c('strong', [_vm._v(_vm._s(_vm.agentName))])]), _c('div', {
    staticClass: "agent_code"
  }, [_vm._v(" Agent Code:"), _c('strong', [_vm._v(_vm._s(_vm.agentCode))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }