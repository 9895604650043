<template>
  <div class="dependent_container_new dashboard-card dashboard-card-lt mt-4">
    <div class="">
      <h3>
        Dependent Information
      </h3>
      <div class="table_content">
        <b-skeleton-table v-if="dependent_list_skeletion" :rows="3" :columns="3" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
        <table v-if="!dependent_list_skeletion">
          <thead>
            <tr>
              <td>Name</td>
              <td>Relation</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in dependent_info" :key="index">
              <td style="font-family: SF UI Display Medium">{{ item.first_name }} {{ item.last_name }}</td>
              <td>{{ item.relationship }}</td>
              <td><a @click="showEditModel(item.dependent_id)"> <i class="fas fa-edit"></i></a>
                <a v-if="item.is_deletable" style="" @click="showDeleteModal(item.dependent_id)"><i class="fa fa-trash" aria-hidden="true" style="color:red; font-size:18px; margin-left: 10px;"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal :ok-disabled="formSubmitted" id="dependent_add" :centered="true" ok-title="Submit" @ok="saveDependent" title="Dependent Information" size="lg">
        <b-form-group label="First Name" label-for="dep_input-1" invalid-feedback="First name is required." class="required">
          <b-form-input :state="validateState('first_name')" v-model="$v.dependent_info_individual.first_name.$model" id="dep_input-1" required></b-form-input>
        </b-form-group>

        <b-form-group label="Middle Name" label-for="dep_input-2">
          <b-form-input v-model="dependent_info_individual.middle_name" id="dep_input-2"></b-form-input>
        </b-form-group>

        <b-form-group label="Last Name" label-for="input-3" invalid-feedback="Last name is required." class="required">
          <b-form-input :state="validateState('last_name')" v-model="$v.dependent_info_individual.last_name.$model" id="dep_input-3" required></b-form-input>
        </b-form-group>

        <b-form-group label="Gender" label-for="dep_input-7" invalid-feedback="Gender is required." class="required">
          <b-form-select :state="validateState('gender')" v-model="dependent_info_individual.gender" id="dep_input-7" :options="gender" required>
          </b-form-select>
        </b-form-group>

      <b-form-group label="SSN" label-for="dep_input_34" :invalid-feedback="validationErrors.ssn" class="required">
          <b-form-input type="text" :state="validateState('ssn')" v-model="$v.dependent_info_individual.ssn.$model" id="dep_input_34" required></b-form-input>
          <eye-button id="dep_input_34" hideOnDefault='true' type="number"></eye-button>
        </b-form-group>

        <b-form-group label="Date of Birth" label-for="input-3" :invalid-feedback="validationErrors.birth_date" class="required">
          <b-form-datepicker locale="en" id="input-3" v-model="$v.dependent_info_individual.birth_date.$model" :placeholder="dependent_info_individual.birth_date" :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }" required></b-form-datepicker>
        </b-form-group>

    
        <b-form-group v-if="has_height_weight" label="Height Feet" label-for="input-421" :invalid-feedback="validationErrors.height" class="required">
          <b-form-input type="number" id="input-421" :state="validateState('height')" v-model.lazy="$v.dependent_info_individual.height.$model" required></b-form-input>
        </b-form-group>

        <b-form-group v-if="has_height_weight" label="Height Inches" label-for="input-4" invalid-feedback="Invalid height inch.">
          <b-form-input type="number" id="input-4" :state="validateState('height_inches')" v-model.lazy="$v.dependent_info_individual.height_inches.$model"></b-form-input>
        </b-form-group>

        <b-form-group v-if="has_height_weight" label="Weight (lbs)" label-for="input-44" :invalid-feedback="validationErrors.weight" class="required">
          <b-form-input type="number" id="input-44" :state="validateState('weight')" v-model.lazy="$v.dependent_info_individual.weight.$model" required></b-form-input>
        </b-form-group>

      </b-modal>
      <b-modal :ok-disabled="formSubmitted" @ok="deleteDependent" :id="'deleteDependent'" title="Delete Dependent" ok-title="Confirm" :centered="true" size="lg">
        <p class="confirmation">
          Are you sure you want to delete this?
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import helper from "../../resource/Helper";
import EyeButton from "../common/EyeButton";
import { required, requiredIf } from "vuelidate/lib/validators";
import CommonValidator from "../../resource/CommonValidator";
export default {
  components: {
    EyeButton,
  },
  data() {
    return {
      selected_gender: null,
      dependent_info: [],
      dependent_info_individual: { height_feet: "" },
      gender: [
        { value: "Male", text: "Male" },
        { value: "Female", text: "Female" },
      ],
      dependent_list_skeletion: true,
      validationErrors: {
        birth_date: "Birth date is required",
        ssn: "SSN Number is required.",
        height: "Height is required.",
        weight: "Weight is required",
      },
      dependent_delete_id: "",
      has_height_weight: true,
      formSubmitted: false
    };
  },
  mounted() {
    this.getDependentDetails();
  },
  methods: {
    getDependentDetails() {
      let app = this;
      return axios.get("/get-dependents").then((response) => {
        app.dependent_info = response.data.data;
        app.dependent_list_skeletion = false;
      });
    },
    hideModal() {
      this.$bvModal.hide("dependent_add");
      this.getDependentDetails();
    },
    showEditModel(dependent_id) {
      let editData = this.dependent_info.find(
        (data) => data.dependent_id == dependent_id
      );
      this.dependent_info_individual = { ...editData };
      this.dependent_info_individual.birth_date = editData.birthdate_full;
      this.dependent_info_individual.height_feet = editData.height;
      this.has_height_weight = editData.height;
      this.dependent_info_individual.ssn = editData.ssn_full;
      this.$bvModal.show("dependent_add");
    },
    showDeleteModal(dependent_id) {
      this.dependent_delete_id = dependent_id;
      this.$bvModal.show("deleteDependent");
    },
    async deleteDependent(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
      this.formSubmitted = true;
      await axios
        .delete("/delete-dependent?dependent_id=" + this.dependent_delete_id)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.$bvModal.hide("deleteDependent");
            this.dependent_list_skeletion = true;
            this.getDependentDetails();
          }
        })
        .catch((error) => {
          app.$bvModal.hide("deleteDependent");
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        }).finally(() => {  this.formSubmitted = false;})
    },
    saveDependent(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
      let formData = this.dependent_info_individual;
      this.$v.dependent_info_individual.$touch();
      if (this.$v.dependent_info_individual.$anyError) {
      
        return;
      }
      this.formSubmitted = true;
      formData.height_feet = formData.height;
      this.dependent_list_skeletion = true;
      axios
        .put("/update-dependent-details", formData)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.hideModal();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
            app.dependent_list_skeletion = false;
          }
        }).finally(() => { this.formSubmitted = false;})
    },
    validateState(name, form) {
      const { $dirty, $error } = this.$v.dependent_info_individual[name];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    dependent_info_individual: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      birth_date: {
        required(value) {
          if (!value.length) {
            this.validationErrors.birth_date = "Birthdate is required";
            return false;
          }
          if (helper.validateDate(value) == false) {
            this.validationErrors.birth_date = "Invalid birthdate.";
            return false;
          }
          return true;
        },
      },
      ssn: {
        requiredSSN(value) {
          if (!value.length) {
            this.validationErrors.ssn = "SSN Number is required";
            return false;
          }
          if (value.length != 9) {
            this.validationErrors.ssn = "SSN number should be of 9 digits.";
            return false;
          }
          return true;
        },
        async validateSSN(value) {
          if (value.length == 9) {
            let ssnNumberValidation = await CommonValidator.validateSSN(value);
            if (ssnNumberValidation.status == false) {
              this.validationErrors.ssn = ssnNumberValidation.message;
            }
            return ssnNumberValidation.status;
          }
          return true;
        },
      },
      gender: {
        required,
      },
      weight: {
        required: requiredIf(function (val) {
          return this.has_height_weight;
        }),
        valueCheck(val) {
        
          if((val == '' || val== '0') && this.has_height_weight)  { this.validationErrors.weight = 'Weight is required'; return false; }
           if((val < 1 || isNaN(val)) && this.has_height_weight) { this.validationErrors.weight = 'Invalid weight'; return false; }
           return true;
        }
      },
      height: {
        required: requiredIf(function () {
          return this.has_height_weight;
        }),
        valueCheck(val) {
          if((val == '' || val== '0') && this.has_height_weight)  { this.validationErrors.height = 'Height is required'; return false; }
           if((val < 1 || isNaN(val)) && this.has_height_weight) { this.validationErrors.height = 'Invalid height'; return false; }
           return true;
        }
      },
    height_inches: {
        valueCheck(val) {
           if((val < 0 || isNaN(val) ) && this.has_height_weight) { return false; }
           return true;
        }
      },
      
    },
  },
};
</script>

<style  scoped>
.eyeIconSlash_dep_input_34 .toggleSSN:parent {
    position: absolute;
  left: 92.8%;
  margin-top: -3.9%;
}



</style>