<template>
  <div class="content_section registeration">
              <div class="rep_info">
                <h2>Representative Information</h2>
                <form class="" action="index.html" method="post">
                  <div class="input_box">
                    <label for="">Upline Rep ID <span class="red">*</span></label>
                    <div class="inputs">
                      <input type="text" name="" value=""/>
                      <p class="error_message">This is a required field</p>
                    </div>
                  </div>
                  <div class="buttonbox">
                    <button @click="saveAndContinue" class="next" type="button" name="button">Next</button>
                  </div>
                </form>
              </div>
            </div>
</template>
<script>

  export default {
    name: "RepresentativeInformation",
    props: {
      navdata: {
        type: Array
      }
    },
    mounted() {
      //console.log('PI ' + this.navdata[0].index)
    },
    methods: {
      saveAndContinue() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigation', this.$route.name);
      },
      navigatePrevious() {
        //Update APIs
        //Navigate to next page
        this.$emit('comEnvNavigationBack', this.$route.name);
      },
    }
  }
</script>
