import Vue from 'vue'
import VueRouter from 'vue-router'

// import Home from '../views/Home.vue'
// import MemberDashboard from "../views/MemberDashboard.vue";
import DashboardNew from "../views/DashboardNew.vue";
import AddRequest from '../components/DashboardNew/AddRequest.vue';
import RepresentativeRegistration from "../views/RepresentativeRegistration.vue";

import representativeInfo from "../components/rep_register/representativeInfo.vue";
import personalInfo from "../components/rep_register/personalInfo.vue";
import contactInfo from "../components/rep_register/contactInfo.vue";
import addressInfo from "../components/rep_register/addressInfo.vue";
import ViewMessage from "../views/ViewMessage.vue"
import MemberReferral from "../views/MemberReferral.vue";
import ViewKnowledge from "../views/KnowledgeCenter/ViewKnowledge.vue"

import registrationType from "../components/rep_register/registrationType.vue";
import businessInfo from "../components/rep_register/businessInfo.vue";
import paymentInfo from "../components/rep_register/paymentInfo.vue";
import signature from "../components/rep_register/signature.vue";

import GroupRegistration from "../views/GroupRegistration.vue";
import BillingDetails from "../components/group_register/billingDetails.vue";
import brokerInfo from "../components/group_register/brokerInfo.vue";
import businessAddress from "../components/group_register/businessAddress.vue";
import contact from "../components/group_register/contact.vue";
import groupContribution from "../components/group_register/groupContribution.vue";
import groupInfo from "../components/group_register/groupInfo.vue";
import paymentInfomation from "../components/group_register/paymentInfomation.vue";
import selectPlan from "../components/group_register/selectPlan.vue";
import signatureVerification from "../components/group_register/signatureVerification.vue";
import ProviderList from "@/views/ProviderList/ProviderList.vue";
// import Ticketing from "../views/Ticketing.vue"


// import repRegistration from "../views/RepRegistration/repRegistration.vue";
// import personalInfo from "../views/RepRegistration/personalInfo.vue";
// import contactInfo from "../views/RepRegistration/contactInfo.vue";
// import addressInfo from "../views/RepRegistration/addressInfo.vue";
//
// import registrationType from "../views/RepRegistration/registrationType.vue";
// import businessInfo from "../views/RepRegistration/businessInfo.vue";
// import paymentInfo from "../views/RepRegistration/paymentInfo.vue";
// import signature from "../views/RepRegistration/signature.vue";

import Login from '../views/Login.vue'
import SignUp from '../views/SignUp/SignUp.vue'
import SignUpContinue from '../views/SignUp/SignUpContinue.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import SendCode from '../views/SendCode.vue'
import PasswordChange from '../views/PasswordChange.vue'
import PlanDetail from "../views/PlanDetail.vue";
import TerminatePlan from "../views/TerminatePlan.vue";
import SignUpVerification from "../views/SignUp/SignUpVerification.vue";
import SignUpOtp from "../views/SignUp/SignUpOtp.vue";
import VerificationSuccess from "../views/SignUp/VerificationSuccess.vue";
// import TerminateForm from "../views/TerminateForm.vue";
// import SinglePlanDetail from "../views/SinglePlanDetail.vue"
import LoginSave from '../views/LoginSave.vue'
import VerifyOTP from "../views/LoginOTP.vue"
import VerifyPasswordChangeOTP from "../views/VerifyPasswordOTP.vue"
import store from "../store/store"

import ContractPersonalInfo from "../components/contractform/PersonalInfo.vue"
import ContractContactInfo from "../components/contractform/ContactInfo.vue"
import ContractAddressInfo from "../components/contractform/AddressInfo.vue"
import ContractBillingInfo from "../components/contractform/BillingInfo.vue"
import ContractAgreement from "../components/contractform/Agreement.vue";
import ContractCommissionSheet from "../components/contractform/CommissionSheet.vue"
import ContractFeeStructure from "../components/contractform/FeeStructure.vue"

import Personal from "../components/W9form/PersonalInfo.vue"
import Address from "../components/W9form/AddressInfo.vue"
import TaxpayerId from "../components/W9form/TaxpayerId.vue"
import Certification from "../components/W9form/Certification.vue"
import GeneralInstruction from "../components/W9form/GeneralInstruction.vue"
import SpecificInstruction from "../components/W9form/SpecificInstruction.vue"

import contractTemplate from "../components/contractTemplate/contract.vue"
import w9Template from "../components/contractTemplate/w9.vue"

import ContractMessage from "../components/contractform/ContractMessage.vue"

import ReviewOption from '../components/review_rating/ReviewOption.vue'
import ReviewForm from '../components/review_rating/ReviewForm.vue'
import MessagePage from '../components/review_rating/MessagePage.vue'
import ForgotEmail from "@/views/ForgotEmail/ForgotEmail"
import RecoverEmail from "@/views/ForgotEmail/RecoverEmail"
import RenewalDocument from "@/views/DocumentViewDetails"
import PlanChangedConfirmationSign from "@/views/PlanChangedConfirmationSign"
import PolicySignature from "@/views/PolicySignature"
//import DefaultOtp from "@/views/DefaultOtp"
import TermRequestSign from "../views/TermRequestSign.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },

  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    props: true,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/sign-up-continue',
    name: 'SignUpContinue',
    component: SignUpContinue,
    props: true,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/send-code',
    name: 'SendCode',
    component: SendCode,
    // props : true,
    // beforeEnter: (to, from, next) => {
    //   notLoggedInAccess(to, from, next)
    // }
  },
  {
    path: '/verify-otp',
    name: 'VerifyOTP',
    component: VerifyOTP,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/verify-password-change-otp',
    name: 'VerifyPasswordChangeOTP',
    component: VerifyPasswordChangeOTP,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/sign-up-verification',
    name: 'SignUpVerification',
    component: SignUpVerification,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/sign-up-verify-otp',
    name: 'SignUpOtp',
    component: SignUpOtp,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/verification-success',
    name: 'VerificationSuccess',
    component: VerificationSuccess,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/change-password',
    name: 'PasswordChange',
    component: PasswordChange,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/policy-details/:policyID',
    name: 'PlanDetail',
    component: PlanDetail
  },
  {
    path: '/terminate-plan/:policyID',
    name: 'TerminatePlan',
    component: TerminatePlan
  },
  // {
  //   path: "/member-dashboard",
  //   component: MemberDashboard,
  //   name: "MemberDashboard"
  // },
  {
    path: "/member-dashboard",
    component: DashboardNew,
    name: "DashboardNew"
  },
  {
    path: "/login-save",
    component: LoginSave,
    name: "LoginSave",
},

  {
    path:'/member-add-request',
    component: AddRequest,
    beforeEnter: requiredAuthentication,
    name: "AddRequest"
  },
  {
    path: "/view-message",
    component: ViewMessage,
    name: "ViewMessage"
  },
  {
    path: "/member-referral",
    component: MemberReferral,
    name: "MemberReferral",
    beforeEnter: isGroupEmployer
  },
  // {
  //   path: "/ticketing",
  //   component: Ticketing,
  //   name: "Ticketing",
  //   beforeEnter: isGroupEmployer
  // },
  {
    path: "/tickets",
    name: "Tickets",
    component:() => import('@/views/Tickets/Tickets.vue'),
    beforeEnter: requiredAuthentication
  },
  {
    path: "/provider-list",
    component: ProviderList,
    name: "ProviderList",
    beforeEnter: requiredAuthentication
  },
  {
    path: "/view-Knowledge-feeds",
    component: ViewKnowledge,
    name: "ViewKnowledge",
    beforeEnter: requiredAuthentication
  },
  {
    path: '/neura-blogs',
    name: 'NeuraBlogs',
    component: () => import('@/views/NeuraBlogs/NeuraBlogs.vue'),
    beforeEnter: requiredAuthentication
  },
  {
    path: '/document-view-details',
    name: 'DocumentViewDetails',
    component: RenewalDocument,
    beforeEnter: requiredAuthentication
  },
  {
    path: '/plan-change-sign/:policyID',
    name: 'PlanChangedConfirmationSign',
    component: PlanChangedConfirmationSign,
  },
  {
    path: '/policy-pending-sign/:policyID',
    name: 'PolicySignature',
    component: PolicySignature,
  },
  {
    path: '/term-request-sign/:requestID',
    name: 'TermRequestSignature',
    component: TermRequestSign,
  },
  {
    path: "/RepresentativeRegistration",
    name: "RepresentativeRegistration",
    children: [
      {
        path: "/representativeInfo",
        component: representativeInfo,
        name: "representative-info"
      },
      {
        path: "/personalInfo",
        component: personalInfo,
        name: "personal-info"
      },
      {
        path: "/contactInfo",
        component: contactInfo,
        name: "contact-info"
      },
      {
        path: "/addressInfo",
        component: addressInfo,
        name: "address-info"
      },
      {
        path: "/registrationType",
        component: registrationType,
        name: "reg-type"
      },
      {
        path: "/businessInfo",
        component: businessInfo,
        name: "business-info"
      },
      {
        path: "/paymentInfo",
        component: paymentInfo,
        name: "payment-info"
      },
      {
        path: "/signature",
        component: signature,
        name: "signature-verification"
      }
    ],
    component: RepresentativeRegistration
  },
  {
    path: "/GroupRegistration",
    name: "GroupRegistration",
    children: [
      {
        path: "/billingDetails",
        component: BillingDetails,
        name: "BillingDetails"
      },
      {
        path: "/brokerInfo",
        component: brokerInfo,
        name: "BrokerInfo"
      },
      {
        path: "/businessAddress",
        component: businessAddress,
        name: "BusinessAddress"
      },
      {
        path: "/contact",
        component: contact,
        name: "Contact"
      },
      {
        path: "/groupContribution",
        component: groupContribution,
        name: "GroupContribution"
      },
      {
        path: "/groupInfo",
        component: groupInfo,
        name: "GroupInformation"
      },
      {
        path: "/paymentInfomation",
        component: paymentInfomation,
        name: "PaymentInformation"
      },
      {
        path: "/selectPlan",
        component: selectPlan,
        name: "SelectPlan"
      },
      {
        path: "/signatureVerification",
        component: signatureVerification,
        name: "SignatureVerification"
      }
    ],
    component: GroupRegistration,
  },
  {
    path: "/contract-form/personal_info",
    component: ContractPersonalInfo,
    name: "ContractPersonalInfo"
  },
  {
    path: "/contract-form/contact_info",
    component: ContractContactInfo,
    name: "ContractContactInfo"
  },
  {
    path: "/contract-form/address_info",
    component: ContractAddressInfo,
    name: "ContractAddressInfo"
  },
  {
    path: "/contract-form/billing_info",
    component: ContractBillingInfo,
    name: "ContractBillingInfo"
  },
  {
    path: "/contract-form/agreement_and_signature",
    component: ContractAgreement,
    name: "ContractAgreement"
  },
  {
    path: "/contract-form/commission_sheet",
    component: ContractCommissionSheet,
    name: "ContractCommissionSheet"
  },
  {
    path: "/contract-form/fee_structure",
    component: ContractFeeStructure,
    name: "ContractFeeStructure"
  },
  {
    path: "/contract-form/contract_message/:type",
    component: ContractMessage,
    name: "ContractMessage"
  },
  {
    path: "/w9-form/personal_info",
    component: Personal,
    name: "Personal"
  },
  {
    path: "/w9-form/address_info",
    component: Address,
    name: "Address"
  },
  {
    path: "/w9-form/taxpayer_id",
    component: TaxpayerId,
    name: "TaxpayerId"
  },
  {
    path: "/w9-form/certification",
    component: Certification,
    name: "Certification"
  },
  {
    path: "/w9-form/general_instruction",
    component: GeneralInstruction,
    name: "GeneralInstruction"
  },
  {
    path: "/w9-form/specific_instruction",
    component: SpecificInstruction,
    name: "SpecificInstruction"
  },
  {
    path: "/contractTemplate/w9/:id",
    component: w9Template,
    name: "w9Template"
  },
  {
    path: "/contractTemplate/:type/:id",
    component: contractTemplate,
    name: "contractTemplate"
  },
  {
    path: "/contractTemplate/:contract_type/:temp_id/:contract_id",
    component: contractTemplate,
    name: "contractTemplate"
  },
  {
    path: '/review_rating/review-option',
    name: 'ReviewOption',
    component: ReviewOption
  },
  {
    path: '/review_rating/reviewform',
    name: 'ReviewForm',
    component: ReviewForm
  },
  {
    path: '/review_rating/submit-message',
    name: 'MessagePage',
    component: MessagePage
  },
  {
    path: '/forgot-email',
    name: 'ForgotEmail',
    component: ForgotEmail,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/recover-email',
    name: 'RecoverEmail',
    component: RecoverEmail,
    beforeEnter: (to, from, next) => {
      notLoggedInAccess(to, from, next)
    }
  },
  {
    path: '/report-an-issue',
    name: 'TicketSubmission',
    component: () => import('@/views/TicketSubmission.vue')
  }
]


const notLoggedInAccess = (to, from, next) => {
  if (store.state.authenticated == false) {
    next()
  } else {
    next('/member-dashboard')
  }
}
function requiredAuthentication(to, from, next) {
  console.log('auth', store.getters.authenticated)
  if (store.state.authenticated == true) {

    return next()
  }
  return next({ path: "/" })
}
function isGroupEmployer(to, from, next) {

  if (store.state.groupType != 'employer') {
    return next()
  }
  return next({ path: "/" })
}
const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  //base: 'http://localhost:8000/api.member/v1',
  routes
})

export default router
