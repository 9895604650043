 <template>
 <div class="content_wrapper">
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <w-9-form-sidebar/>
            <div class="right_container">
                   <h3>PERSONAL INFORMATION</h3>
                   <div class="form">
                       <div>
                           <label>Name (as shown on your income tax return)</label><br>
                            <input type="text" name="name" v-model="formFields.name.value" value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['name_message']}}</small>
                       </div>

                       <div>
                        <label>Business name/disregarded entity name(if different from above)</label><br>
                            <input type="text" name="business_name" v-model="formFields.business_name.value" value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['business_name_message']}}</small>
                        </div>

                        <div>
                            <label>Federal tax classification of the person (check one)</label><br>
                            <div class="option">
                                <div>
                                    <input type="radio" name="radio" :checked="formFields.radio.value=='individual'" @change="onChange($event)" value="individual">
                                    <label>Individual/sole proprietor or single-member LLC</label>
                                </div>
                                <div>
                                    <input type="radio" name="radio" :checked="formFields.radio.value=='c_corporation'" @change="onChange($event)" value="c_corporation">
                                    <label>C Corporation</label>
                                </div>
                                <div>
                                    <input type="radio" name="radio" :checked="formFields.radio.value=='s_corporation'" @change="onChange($event)" value="s_corporation">
                                    <label>S Corporation</label>
                                </div>
                                <div>
                                    <input type="radio" name="radio" :checked="formFields.radio.value=='partnership'" @change="onChange($event)" value="partnership">
                                    <label>Partnership</label>
                                </div>
                                <div>
                                    <input type="radio" name="radio" :checked="formFields.radio.value=='trust'" @change="onChange($event)" value="trust">
                                    <label>Trust/estate</label>
                                </div>
                                <div>
                                    <input type="radio" name="radio" :checked="formFields.radio.value=='limited_liability'" @change="onChange($event)" value="limited_liability">
                                    <label>Limited liability company</label>
                                </div>
                                <div>
                                    <input type="radio" name="radio" :checked="formFields.radio.value=='other'" @change="onChange($event)" value="other">
                                    <label>Other(see instructions)</label>
                                </div>
                            </div>
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['federal_tax_message']}}</small>
                            <div  class="note">
                                <span><b>Note: </b>(Check the appropriate box in the line above for the tax classification of the single-member owner. Do not check LLC if the LLC is classified as a single-member LLC that is disregarded from the owner unless the owner of the LLC is another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member LLC that is disregarded from the owner should check the appropriate box for the tax classification of its owner.)</span>
                            </div>
                        </div>
                            
                        <div>
                            <label>Exempt payee code (if any)</label><br>
                            <input type="text" name="payee_code" v-model="formFields.payee_code.value" value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['payee_code_message']}}</small>
                        </div>

                        <div>
                            <label>Exemption from FATCA reporting code (if any)</label><br>
                            <input type="text" name="fatca_code" v-model="formFields.fatca_code.value" value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['fatca_code_message']}}</small>
                        </div>

                        <div>
                            <label>List account number(s) (optional)</label><br>
                            <input type="text" name="acc_number" v-model="formFields.acc_number.value" value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['acc_number_message']}}</small>
                        </div>                    
                   </div> 
                   <div class="button_container">
                    <!-- <a class="back"  @click="navigatePrevious">Back</a> -->
                        <div></div>
                        <div></div>
                        <div class="button">
                            <button class="next" @click="savePersonalInfo">Next</button>
                        </div>
                    </div>

            </div>
        </div>
    </div>
    </template>
    <style src="../../assets/css/W9_form/personal_info.css" scoped>

</style>
<script>
import AgentInfo from "../../components/common/AgentInfo"
import W9FormSidebar from '../../components/partial/W9FormSidebar.vue'
import html2canvas from 'html2canvas';
import axios from 'axios'
export default {
    components:{ AgentInfo, W9FormSidebar },
    data:function(){
        return{
            tempId: '',
            agentid: '',
            formFields: {
                name: {
                    value: '',
                    required: true,
                    read_only: false,
                },
                business_name: {
                    value: '',
                    required: false,
                    read_only: false,
                },
                radio: {
                    value: '',
                    required: true,
                    read_only: false,
                },
                payee_code: {
                    value: '',
                    required: false,
                    read_only: false,
                },
                fatca_code: {
                    value: '',
                    required: false,
                    read_only: false,
                },
                acc_number: {
                    value: '',
                    required: false,
                    read_only: false,
                },
            },
            formErrorFlag: false,
            formErrors: [],
            optionText: '',
        }
    },
    mounted(){
        window.localStorage.clear();
        let app = this;
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('id')) {
            let urlagentId = urlParams.get('id');
            app.agentid = atob(urlagentId);
            window.localStorage.setItem('agentId', app.agentid);
        }
        app.getPersonalInfo();
    },
    methods:{
        onChange(event) {
            let app =this;
              app.optionText = event.target.value;
              console.log(app.optionText);
        },
        getPersonalInfo: function () {
            let app = this;
            axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-personal-information-w9?', {
            params: {
                agentid: app.agentid
            }
            })
            .then(function (response) {
            let fetchedData = [];
            if (response.data.status == 'success') {
                app.tempId = response.data.data.tempid;
                window.localStorage.setItem('tempId', app.tempId);
                window.localStorage.setItem('type', 'W9-Form');
                for(var i in response.data.data.result) {
                    fetchedData.push(response.data.data.result[i]);
                }
                fetchedData.forEach(function(item) {
                    app.formFields[item.field_name].value = item.value;
                    app.formFields[item.field_name].read_only = item.read_only;
                });
            }
            })
            .catch(function (error) {
                if(error.message == 'Form completed.'){
                    window.location = '/contract-form/contract_message/completed';
                }else if(error.message == 'Agent does not exist.'){
                    window.location = '/contract-form/contract_message/not_found';
                } else {
                    window.location = '/contract-form/contract_message/error';
                }
            });
        },
        savePersonalInfo: function () {
            let app = this;
            app.formErrorFlag = false;
            app.formErrors    = [];
            let federaltax = '';
            console.log(app.formFields.radio.value);
            console.log(app.optionText);
            if (app.formFields.radio.value == '' || app.formFields.radio.value == null) {
                federaltax = app.optionText;
            } else {
                federaltax = app.formFields.radio.value;
            }
            console.log(federaltax);
            let params = {
            agentid:                app.agentid,                
            name :                  app.formFields.name.value,
            business_name :         app.formFields.business_name.value,
            federal_tax :           federaltax,
            exempt_payee_code :     app.formFields.payee_code.value,
            exempt_fatca_reporting : app.formFields.fatca_code.value,
            list_account_numbers :  app.formFields.acc_number.value,
            tempid :  app.tempId,
            };
            console.log(params);
            //discard null values
            for (const key of Object.keys(params)) {
                if (params[key] === null) {
                    delete params[key];
                }
            }

            axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-personal-information-w9', params)
            .then(function (response) {
                if (response.data.status == 'success') {
                    console.log("success");
                    window.localStorage.setItem('tempId', response.data.data.tempid);
                    app.tempId = response.data.data.tempid;
                    app.takeScreenshot();
                    app.$router.push("address_info");
                    window.localStorage.setItem('personal_complete', 'complete');
                }
            })
            .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                        app.formErrorFlag = true;
                        app.formErrors[item.target_element+'_message'] = item.error_message;
                    });
                }
            });
        },
        takeScreenshot(){
            const el = this.$refs.save;
            (async () => {
                this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'personal');
            })();
            setTimeout(() => { }, 100);
           },
        saveScreenShot (image) {
            let app = this;
            let data = {           
                'agent_id': app.agentid,
                'level': '0',
                'contract_type': 'w9',
                'image': image,
                'name': 'personal',
                "tempid" : this.tempId,
            };
            let config = {
              method: 'post',
              url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
              headers: { },
              data : data
            };

          axios(config)
                  .then(function (response) {
                    if (response.data.status == 'success') {
                      console.log('screenshot saved');
                    }
                  })
                  .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                  });
        },   
    }
}
</script>