var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_section group_information"
  }, [_c('div', {
    staticClass: "group_info"
  }, [_c('h2', [_vm._v("GROUP INFORMATION")]), _c('form', {
    attrs: {
      "action": "index.html",
      "method": "post"
    }
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _vm._m(5), _c('div', {
    staticClass: "buttonbox"
  }, [_c('button', {
    staticClass: "previous",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Previous")]), _c('button', {
    staticClass: "save",
    attrs: {
      "type": "button",
      "name": "button"
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.saveAndContinue
    }
  }, [_vm._v("Next")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Group Name "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box half"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Estimate number of members "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box half"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Desired Start Date "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Group Type "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('select', {
    attrs: {
      "name": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" Group Type...")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Employer Group")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Trade/Professional Association")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("General Business Association")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("PEO (Professional Employer Organization)")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Franchise Association")])])])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Federal Tax ID Number (TIN/EIN) "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "name": "",
      "value": ""
    }
  })])]);

},function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input_box"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Group Industry "), _c('span', {
    staticClass: "red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "inputs"
  }, [_c('select', {
    attrs: {
      "name": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Choose a State")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select One")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select Two")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select Three")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select Four")])])])]);

}]

export { render, staticRenderFns }