<script>
const VALIDATE_SSN = process.env.VUE_APP_VALIDATE_SSN;
const VALIDATE_PHONE_NUMBER = process.env.VUE_APP_VALIDATE_PHONE_NUMBER;
const VALIDATE_ADDRESS = process.env.VUE_APP_VALIDATE_ADDRESS;
const VALIDATE_ROUTING_NUMBER = process.env.VUE_APP_VALIDATE_ROUTING_NUMBER;
const VALIDATE_EMAIL = process.env.VUE_APP_VALIDATE_EMAIL;

export default {
  VALIDATE_SSN: VALIDATE_SSN,
  VALIDATE_PHONE_NUMBER: VALIDATE_PHONE_NUMBER,
  VALIDATE_ADDRESS: VALIDATE_ADDRESS,
  VALIDATE_ROUTING_NUMBER: VALIDATE_ROUTING_NUMBER,
  VALIDATE_EMAIL: VALIDATE_EMAIL,
};
</script>