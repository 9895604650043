<template>
  <div class="re_wrapper">
       <div class="bg_overlay">
         <div class="re_container">
           <h1>Rep Registration</h1>
         <div class="cnt_wrapper">
           <div class="reg_container" v-if="tabName">
             <menu-nav></menu-nav>
             <!-- <router-view v-bind:navdata="nav_array" @comEnvNavigation="comEnvNavigationFn" @comEnvNavigationBack="comEnvNavigationBackFn"></router-view> -->
             <router-view v-bind:navdata="nav_array" @comEnvNavigation="comEnvNavigationFn" @comEnvNavigationBack="comEnvNavigationBackFn"></router-view>
              <!-- <component-one :key="skewKey" v-if ="tabName=='One'" v-on:myEvent="myFun"></component-one> -->
            <!-- <component-two :key="skewKey" v-if ="tabName=='Two'" v-on:myEventew="myFun" ></component-two>
             <component-three :key="skewKey" v-if ="tabName=='Three'" v-on:myEvent="myFun"></component-three>
             <component-four v-if ="tabName=='Four'" v-on:myEvent="myFun"></component-four>
             <component-five v-if ="tabName=='Five'" v-on:myEvent="myFun"></component-five>
             <component-six v-if ="tabName=='Six'" v-on:myEvent="myFun"></component-six>
             <component-seven v-if ="tabName=='Seven'" v-on:myEvent="myFun"></component-seven>
             <component-eight v-if ="tabName=='Eight'" v-on:myEvent="myFun"></component-eight> -->
             <!-- <div class="content_section registeration">
               <div class="rep_info">
                 <h2>Representative Information</h2>
                 <form class="" action="index.html" method="post">
                   <div class="input_box">
                     <label for="">Upline Rep ID <span class="red">*</span></label>
                     <div class="inputs">
                       <input type="text" name="" value=""/>
                     </div>
                   </div>
                   <div class="buttonbox">
                     <button class="next" type="button" name="button">Next</button>
                   </div>
                 </form>
               </div>
             </div> -->
           </div>
         </div>
       </div>
       </div>
     </div>
</template>

<script>
import menuNav from "../components/rep_register/menuNav";

// import representativeInfo from "../components/rep_register/representativeInfo";
// import personalInfo from "../components/rep_register/personalInfo";
// import contactInfo from "../components/rep_register/contactInfo";
// import addressInfo from "../components/rep_register/addressInfo";
// import registrationType from "../components/rep_register/registrationType";
// import businessInfo from "../components/rep_register/businessInfo";
// import paymentInfo from "../components/rep_register/paymentInfo";
// import signature from "../components/rep_register/signature";
export default {
    name: "RepresentativeRegistration",
    data: function () {
      return {
        tabName: 'One',
        skewKey : 1,
        nav_array: [
          {"index": 1, "title": 'Representative Info', "name": 'representative-info', "visible": true},
          {"index": 2, "title": 'Personal Info', "name": 'personal-info', "visible": true},
          {"index": 3, "title": 'Contact Info', "name": 'contact-info', "visible": true},
          {"index": 4, "title": 'Address Info', "name": 'address-info', "visible": true},
          {"index": 5, "title": 'Reg Type', "name": 'reg-type', "visible": true},
          {"index": 6, "title": 'Business Info', "name": 'business-info', "visible": true},
          {"index": 7, "title": 'Payment Info', "name": 'payment-info', "visible": true},
          {"index": 8, "title": 'Signature & Verification', "name": 'signature-verification', "visible": true}
        ]
      }
    },
    components: {
      'menu-nav': menuNav,
      // 'component-one': representativeInfo,
      // 'component-two': personalInfo,
      // 'component-three': contactInfo,
      // 'component-four': addressInfo,
      // 'component-five': registrationType,
      // 'component-six': businessInfo,
      // 'component-seven': paymentInfo,
      // 'component-eight': signature
    },
    methods: {
      // myFun(data){
      //   alert(data);
      //   this.tabName=data;
      //   this.skewKey = this.skewKey + 1
      //   alert("tabname");
      // },
      comEnvNavigationFn(currentRoute){
        let currentIndex = 0;
        this.nav_array.forEach((nav, index) => {
            if(nav.name == currentRoute){
              currentIndex = index;
            }
        });
        let newIndex = currentIndex + 1;
        this.$router.push({ name: this.nav_array[newIndex].name, params: { id: '1' }})
      },
      comEnvNavigationBackFn(currentRoute){
        let currentIndex = 0;
        this.nav_array.forEach((nav, index) => {
          if(nav.name == currentRoute){
            currentIndex = index;
          }
        });
        let newIndex = currentIndex - 1;
        this.$router.push({ name: this.nav_array[newIndex].name, params: { id: '1' }})
      },

    },

    mounted(){
      console.log(this.$route.name);
    }
  }
</script>

<style>

</style>
