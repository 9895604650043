<template>
  <b-modal
    id="activate_form"
    :centered="true"
    title="Plan Re-activation"
    @hide="resetFormFields"
    no-close-on-backdrop
  >
    <div class="mb-4">
      <strong> Plan Detail:</strong>
      <span class="text-secondary">
        {{ plan_detail }}
      </span>
    </div>
    <InputTextArea
      :rules="{ required: true }"
      label="Re-activation Reason"
      label-for="activate-reason"
      :vmodel.sync="form.reason"
      input-class="shadow-none"
      :vObject="$v.form.reason"
    />

    <div class="signature">
      <div class="signature_title">
        <p>
          Application Signature (Required)
          <span
            >(Sign Name using your mouse or touch screen in the box below)</span
          >
        </p>
      </div>
      <signature-pad @sign="getSign"></signature-pad>
      <div v-if="signError" style="display: block" class="invalid-feedback">
        Sign is required.
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="secondary"
        size="sm"
        class="float-right"
        @click="resetFormFields()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        class="float-right"
        @click="reActivatePlan"
      >
        <b-spinner v-if="formSubmitted == true" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import axios from "axios";
import SignaturePad from "../../../components/SignaturePad.vue";
import { required } from "vuelidate/lib/validators";
import helper from "../../../resource/Helper";
import InputTextArea from "../../form/InputTextArea.vue";
const formData = {
  sign: "",
  reason: "",
  reactivation_reason: "",
  policy_id: "",
  plan_id: null,
};
export default {
  name: "PlanReactivationModal",
  data: () => ({
    form: { ...formData },
    formSubmitted: false,
    signError: "",
    isUpdate: false,
  }),
  props: {
    current_plan: {
      required: true,
    },
    current_policy_id: {
      required: true,
    },
  },
  components: {
    SignaturePad,
    InputTextArea,
  },
  computed: {
    plan_detail() {
      if (this.current_plan) {
        const { plan_name, tier, price } = { ...this.current_plan };
        return `${plan_name} / ${tier} / $${price}`;
      } else {
        return "";
      }
    },
  },
  validations: {
    form: {
      reason: {
        required,
      },
    },
  },
  methods: {
    getSign(sign = "") {
      this.form.sign = sign;
      this.signError = false;
      let signature_box = document.getElementById("signature");
      signature_box.style.borderColor = "#dcdcdc";
    },
    resetFormFields() {
      this.$bvModal.hide("activate_form");
      this.form = { ...formData };
      this.signError = false;
      this.$nextTick(() => this.$v.$reset());
    },
    isFormValid() {
      this.$v.$touch();
      if (this.$v.$invalid || this.signError) {
        return false;
      }
      return true;
    },
    reActivatePlan() {
      this.form.policy_id = this.current_policy_id;
      this.form.plan_id = [];
      this.form.plan_id.push(this.current_plan.plan_id);
      this.form.reactivation_reason = this.form.reason;
      if (this.form.sign == "") {
        this.signError = true;
        let signature_box = document.getElementById("signature");
        signature_box.style.borderColor = "red";
      }
      if (!this.isFormValid()) {
        return;
      }
      this.formSubmitted = true;
      axios
        .post("/send-reactivation-request", this.form)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              this,
              response.data.status,
              response.data.message
            );
            this.getProductDetails();
            this.$emit("onSuccess");
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(this, "error", error.response.data.message);
          }
        })
        .finally(() => {
          this.formSubmitted = false;
          this.resetFormFields();
        });
    },
  },
};
</script>
<style>
.error-box {
  border: 1px solid red;
}
</style>
