var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content_wrapper"
  }, [_c('agent-info'), _c('div', {
    ref: "save",
    staticClass: "contract_content"
  }, [_c('w-9-form-sidebar'), _c('div', {
    staticClass: "right_container"
  }, [_c('h3', [_vm._v("Part II | Certification")]), _c('div', {
    staticClass: "description"
  }, [_c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion-1",
      modifiers: {
        "accordion-1": true
      }
    }],
    staticStyle: {
      "text-align": "left",
      "font-size": "18px"
    },
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v("Certification Instructions"), _vm.isVisible ? _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("-")]) : _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("+")])])], 1), _c('b-collapse', {
    attrs: {
      "id": "accordion-1",
      "accordion": "my-accordion",
      "role": "tabpanel"
    },
    model: {
      value: _vm.isVisible,
      callback: function ($$v) {
        _vm.isVisible = $$v;
      },
      expression: "isVisible"
    }
  }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
    staticClass: "certify_agreement"
  }, [_c('p', [_vm._v("Under penalties of perjury, I certify that:"), _c('br'), _c('br'), _c('ol', [_c('li', [_vm._v("The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and")]), _c('li', [_vm._v("I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and")]), _c('li', [_vm._v("I am a U.S. citizen or other U.S. person (defined below); and")]), _c('li', [_vm._v("The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.")])]), _c('strong', [_vm._v("Certification Instructions:")]), _vm._v(" You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. For mortgage interest paid, acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and generally, payments other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later. ")])])])], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion-2",
      modifiers: {
        "accordion-2": true
      }
    }],
    staticStyle: {
      "text-align": "left",
      "font-size": "18px"
    },
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v("Signature "), _vm.isNotVisible ? _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("-")]) : _c('strong', {
    staticClass: "ml-auto",
    staticStyle: {
      "position": "absolute",
      "right": "20px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("+")])])], 1), _c('b-collapse', {
    attrs: {
      "id": "accordion-2",
      "visible": "",
      "accordion": "my-accordion",
      "role": "tabpanel"
    },
    model: {
      value: _vm.isNotVisible,
      callback: function ($$v) {
        _vm.isNotVisible = $$v;
      },
      expression: "isNotVisible"
    }
  }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
    staticClass: "agreement_signature"
  }, [_c('span', {
    staticClass: "sign_container"
  }, [_c('span', {
    staticClass: "signature_block"
  }, [_c('span', {
    staticClass: "sign"
  }, [_vm._v(" Signature of U.S. person"), _c('br'), _c('canvas', {
    staticClass: "signature",
    attrs: {
      "id": "rep_signature"
    }
  }), _c('button', {
    staticClass: "clear",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.clear
    }
  }, [_vm._v("Clear")]), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['signature_of_us_person_message']))]) : _vm._e()]), _c('br'), _c('span', [_vm._v(" Date"), _c('br'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formFields.date.value,
      expression: "formFields.date.value"
    }],
    attrs: {
      "type": "text",
      "name": "date",
      "readonly": _vm.formFields.date.read_only == true,
      "value": "",
      "required": ""
    },
    domProps: {
      "value": _vm.formFields.date.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.formFields.date, "value", $event.target.value);
      }
    }
  })]), _vm.formErrorFlag == true ? _c('small', {
    staticClass: "error_message"
  }, [_vm._v(_vm._s(_vm.formErrors['date_message']))]) : _vm._e()])])])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "button_container"
  }, [_c('button', {
    staticClass: "back",
    attrs: {
      "type": "button",
      "name": "button"
    },
    on: {
      "click": _vm.navigatePrevious
    }
  }, [_vm._v("Back")]), _c('div', {
    staticClass: "button"
  }, [_c('button', {
    staticClass: "next",
    on: {
      "click": _vm.saveCertification
    }
  }, [_vm._v("Next")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }