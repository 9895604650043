<template>
  <div class="benefit_store_new dashboard-card">
    <h3>Benefit Store</h3>

    <b-skeleton-img v-if="skeletonLoader" card-img="top" aspect="3:2"></b-skeleton-img>
    <div class="benefit" v-if="!skeletonLoader">
      <VueSlickCarousel :autoplay="true" :arrows="false" :dots="true">

        <div v-if="benefitStore.benefit" class="slider">
          
          <div class="benefit_icon">
            <a target="_blank" :href=benefitStore.link><img src='../../assets/images/benefit_store_icon.png' width="95" height="95" alt="benefit_icon"></a>
          </div>

          <div class="detail">
            <h4><a target="_blank" :href=benefitStore.link>Go To Benefit Store</a></h4>
            <p>
             View and apply for additional benefits!
            </p>
          </div>
        </div>


        <div class="slider" v-for="(item) in benefits" :key="item.id">
          
          <div class="benefit_icon">
            <!-- <a target="_blank" :href=item.link><img :src=item.image width="95" height="95" alt="benefit_icon"></a> -->
            <img :src=item.image width="95" height="95" alt="benefit_icon">
          </div>

          <div class="detail">
            <h4>{{ item.title }}</h4>
            <!--<h4><a target="_blank" :href=item.link>{{ item.title }}</a></h4> -->
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VueSlickCarousel from "vue-slick-carousel";
import axios from "axios";
import store from "../../store/store";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      benefits: [],
      skeletonLoader: true,
      benefitMemberId: store.state.memberId,
      benefitStore: {
        link:"",
        benefit:1
      },
    };
  },
  mounted() {
    this.getAdvertisement();
  },
  methods: {
    getAdvertisement() {
      let app = this;
      axios.get("/get-advertisement-list").then((response) => {
        app.benefits = response.data.data;
        this.skeletonLoader = false;
      });
    },
  },
  watch: {
    "$store.state.memberId": {
      handler: function (nv) {
        this.benefitMemberId = nv;
        this.benefitStore.link = "https://enroll.purenroll.com/member-home?uid="+btoa(nv)
      },
      immediate: true, // provides initial (not changed yet) state
    },
  },
};
</script>