<template>
  <div class="beneficiary_info_new dashboard-card dashboard-card-lt mt-4">
    <h3>
      Beneficiary Information
    </h3>
    <div class="table_content">
      <b-skeleton-table v-if="beneficiary_list_skeletion" :rows="3" :columns="3" :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <table v-if="!beneficiary_list_skeletion">
        <thead>
          <tr>
            <td>Name</td>
            <td>Relation</td>
            <td>Type</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in beneficiary_info" :key="index">
            <td style="font-family: SF UI Display Medium">
              {{ item.first_name }} {{ item.last_name }}
            </td>
            <td>{{ item.relationship }}</td>
            <td>{{ item.is_contigent? "Contingent":"Beneficiary" }}</td>
            <td><a @click="showEditModel(item.beneficiary_id)"> <i class="fas fa-edit"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal :ok-disabled="formSubmitted" id="beneficiary_add" :centered="true" @ok="saveBeneficiary" :title="beneficiary_info_individual.is_contigent?'Contingent Beneficiary Information':'Beneficiary Information'" no-close-on-backdrop>
      <b-form-group label="First Name" label-for="ben_input-1" invalid-feedback="First name is required." class="required">
        
        <b-form-input :state="validateState('first_name')" v-model="$v.beneficiary_info_individual.first_name.$model" id="ben_input-1" required></b-form-input>
      </b-form-group>

      <b-form-group label="Middle Name" label-for="ben_input-2">
        <b-form-input v-model="beneficiary_info_individual.middle_name" id="ben_input-2"></b-form-input>
      </b-form-group>

      <b-form-group label="Last Name" label-for="input-3" invalid-feedback="Last name is required." class="required">
        <b-form-input :state="validateState('last_name')" v-model="$v.beneficiary_info_individual.last_name.$model" id="ben_input-3" required></b-form-input>
      </b-form-group>

      <b-form-group label="Relation" label-for="ben_input-7" invalid-feedback="Relation is required." class="required">
        <b-form-select :state="validateState('relation')" v-model="$v.beneficiary_info_individual.relation.$model" id="ben_input-7" :options="relation_list" required>
        </b-form-select>
      </b-form-group>

      <!--<b-form-group label="Date of Birth" label-for="ben_input-3" :invalid-feedback="validationErrors.birth_date">
        <b-input-group>
          <b-form-input id="ben_input-3" :state="validateState('birth_date')"  v-model="$v.beneficiary_info_individual.birth_date.$model">
          </b-form-input>
          <b-input-group-append>
            <b-form-datepicker v-model="$v.beneficiary_info_individual.birth_date.$model" button-only right :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }" required></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->

      <b-form-group label="Date of Birth" label-for="input-3" :invalid-feedback="validationErrors.birth_date" class="required">
        <b-form-datepicker locale="en" id="input-3" v-model="$v.beneficiary_info_individual.birth_date.$model" :placeholder="beneficiary_info_individual.birth_date" :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }" required></b-form-datepicker>
      </b-form-group>

      <b-form-group label="SSN" label-for="ben_input-34" :invalid-feedback="validationErrors.ssn" class="required">
        <b-form-input type="text" :state="validateState('ssn')" v-model="$v.beneficiary_info_individual.ssn.$model" id="ben_input-34" required></b-form-input>
        <eye-button id="ben_input-34" hideOnDefault='true' type="number"></eye-button>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import helper from "../../resource/Helper";
import EyeButton from "../common/EyeButton";
import { required } from "vuelidate/lib/validators";
import CommonValidator from "../../resource/CommonValidator";
export default {
  components: {
    EyeButton,
  },
  data() {
    return {
      beneficiary_info: [],
      beneficiary_info_individual: {},
      relation_list: helper.getRelationList(),
      beneficiary_list_skeletion: true,
      validationErrors: {
        birth_date: "Birth date is required",
        ssn: "SSN Number is required.",
      },
      formSubmitted: false
    };
  },
  mounted() {
    this.getEmployerDetails();
  },
  methods: {
    getEmployerDetails() {
      let app = this;
      return axios.get("/get-beneficiaries").then((response) => {
        app.beneficiary_info = response.data.data;
        app.beneficiary_list_skeletion = false;
      });
    },
    hideModal() {
      this.$bvModal.hide("beneficiary_add");
      this.getEmployerDetails();
    },
    showEditModel(beneficiary_id) {
      let editData = this.beneficiary_info.find(
        (data) => data.beneficiary_id == beneficiary_id
      );
      this.beneficiary_info_individual = { ...editData };
      this.beneficiary_info_individual.birth_date = editData.birthdate_full;
      this.beneficiary_info_individual.ssn = editData.ssn_full;
      this.beneficiary_info_individual.relation = editData.relationship;
      console.log(this.beneficiary_info_individual);
      this.$bvModal.show("beneficiary_add");
    },
    saveBeneficiary(bvModalEvt) {
      let app = this;
      bvModalEvt.preventDefault();
      let formData = this.beneficiary_info_individual;
      bvModalEvt.preventDefault();
      this.$v.beneficiary_info_individual.$touch();
      if (this.$v.beneficiary_info_individual.$anyError) {
        return;
      }
      this.formSubmitted = true;
      axios
        .put("/update-beneficiary-details", formData)
        .then((response) => {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            this.beneficiary_list_skeletion = true;
            this.hideModal();
          }
        })
        .catch((error) => {
          if (error.response.data.status == "error") {
            helper.infoMessage(app, "error", error.response.data.message);
          }
        }).finally(() => { this.formSubmitted=false; })
    },
    validateState(name, form) {
      const { $dirty, $error } = this.$v.beneficiary_info_individual[name];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    beneficiary_info_individual: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      birth_date: {
        required(value) {
          if (!value.length) {
            this.validationErrors.birth_date = "Birthdate is required";
            return false;
          }
          if (helper.validateDate(value) == false) {
            this.validationErrors.birth_date = "Invalid birthdate.";
            return false;
          }
          return true;
        },
      },
      ssn: {
        requiredSSN(value) {
          if (!value.length) {
            this.validationErrors.ssn = "SSN Number is required";
            return false;
          }
          if (value.length != 9) {
            this.validationErrors.ssn = "SSN number should be of 9 digits.";
            return false;
          }
          return true;
        },
        async validateSSn(value) {
          if (value.length == 9) {
            let ssnNumberValidation = await CommonValidator.validateSSN(value);
            if (ssnNumberValidation.status == false) {
              this.validationErrors.ssn = ssnNumberValidation.message;
            }
            return ssnNumberValidation.status;
          }
          return true;
        },
      },
      relation: {
        required,
      },
    },
  },
};
</script>

<style scoped>
.toggleSSN {
  position: absolute;
  left: 88.8%;
  margin-top: -3.9%;
}
@media only screen and (max-width:733px){
  .toggleSSN{
    margin-top:-30px;
  }
}
@media only screen and (max-width:505px){
  .toggleSSN{
    margin-top:-30px;
    left:80%;
  }
}

</style>