<template>
    <div class="content_wrapper">
         <agent-info></agent-info>
        <div class="contract_content" ref="save">
            <w-9-form-sidebar/>

            <div class="right_container">
                   
                   <h3>  <span>PART I</span><br>TAXPAYER IDENTIFICATION NUMBER</h3>
                   <div class="description">
                    <p>Enter your TIN in the appropriate box. The TIN provided must match the name given on line 1 to avoid backup withholding. For individuals, this is generally your social security number (SSN). However, for a resident alien, sole proprietor, or disregarded entity, see the instructions for Part I, later. For other entities, it is your employer identification number (EIN). If you do not have a number, see How to get a TIN, later.</p>

                   <div class="form">
                       <div>
                           <label>Social security number</label><br>
                           <input type="text" name="ssn" v-model="formFields.ssn.value" :readonly="formFields.ssn.read_only == true" value="" maxlength = "9">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['social_security_message']}}</small>

                       </div>

                       <div>
                        <label>Employer identification number</label><br>
                        <input type="text" name="employer_id" v-model="formFields.employer_id.value" :readonly="formFields.employer_id.read_only == true" value="">
                            <small class="error_message" v-if="formErrorFlag == true">{{formErrors['employer_identification_number_message']}}</small>

                    </div>
                   </div> 

                   <p style="color:#0594b8;"><b>Note:</b> If the account is in more than one name, see the instructions for line 1. Also see What Name and Number To Give the Requester for guidelines on whose number to enter.</p>

                </div>
                   <div class="button_container">
                        <button class="back" type="button" name="button" @click="navigatePrevious">Back</button>
                    <div class="button">
                    
                         <button class="next" @click="saveTaxPayerInfo">Next</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<style src="../../assets/css/W9_form/taxpayer.css" scoped>
 </style>
<script>
import AgentInfo from "../../components/common/AgentInfo";
import W9FormSidebar from '../../components/partial/W9FormSidebar.vue';
import axios from 'axios';
import html2canvas from 'html2canvas';

export default {
    components:{ AgentInfo, W9FormSidebar },
    data() {
        return {
            tempid: '',
            formFields: {
                ssn: {
                    value: '',
                    required: false,
                    read_only: false,
                },
                employer_id: {
                    value: '',
                    required: false,
                    read_only: false,
                }
            },
            formErrorFlag: false,
            formErrors: [],
        }
    },
    mounted(){
        let app = this;
        app.tempid= window.localStorage.getItem('tempId');
        app.getTaxPayerInfo();
    },
    methods:{
        getTaxPayerInfo: function () {
            let app = this;
            axios.get(process.env.VUE_APP_CORENROLL_SYSTEM+'/get-taxpayer-information-w9?', {
            params: {
                tempid: app.tempid
            }
            })
            .then(function (response) {
            let fetchedData = [];
            if (response.data.status == 'success') {
                for(var i in response.data.data.result) {
                    fetchedData.push(response.data.data.result[i]);
                }
                fetchedData.forEach(function(item) {
                    app.formFields[item.field_name].value = item.value;
                    app.formFields[item.field_name].read_only = item.read_only;
                });
            }
            })
            .catch(function (error) {
                if(error.message == 'Error, the contract is already completed from this agent.'){
                    window.location = '/contract-form/contract_message/completed';
                }else if(error.message == 'Agent does not exist.'){
                    window.location = '/contract-form/contract_message/not_found';
                } else {
                  window.location = '/contract-form/contract_message/error';
                }
            });
        },
        saveTaxPayerInfo: function () {
            let app = this;
            app.formErrorFlag = false;
            app.formErrors    = [];
            if(app.formFields.ssn.value == null && app.formFields.employer_id.value == null){
                app.formErrorFlag = true;
                app.formErrors['social_security_message'] = " SSN is required.";
                app.formErrors['employer_identification_number_message'] = " Employer identification number is required.";
                return false;
            }
            const el = this.$refs.save;
            (async () => {
              this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'));
            })();
            let params = {
            tempid:                          app.tempid,                
            social_security :                app.formFields.ssn.value,
            employer_identification_number : app.formFields.employer_id.value,
            };
            console.log(params);
            //discard null values
            for (const key of Object.keys(params)) {
                if (params[key] === null) {
                    delete params[key];
                }
            }

            axios.post(process.env.VUE_APP_CORENROLL_SYSTEM+'/save-taxpayer-information-w9', params)
            .then(function (response) {
                if (response.data.status == 'success') {
                    console.log("success");
                    // window.localStorage.setItem('tempId', response.data.data.tempid);
                    app.$router.push("certification");
                    window.localStorage.setItem('taxpayer_complete', 'complete');
                } else {
                  window.location = '/contract-form/contract_message/error';
                }
            })
            .catch(function (error) {
                console.log(error.response.data.data);
                if (error.response.data.status == 'error') {
                    error.response.data.data.forEach(function(item) {
                        app.formErrorFlag = true;
                        app.formErrors[item.target_element+'_message'] = item.error_message;
                    });
                }
            });
        },
        saveScreenShot (image) {
            let data = {           
                'agent_id': window.localStorage.getItem('agentId'),
                'level': '0',
                'contract_type': 'w9',
                'image': image,
                'name': 'taxpayer',
                "tempid" : this.tempid,
            };
            let config = {
              method: 'post',
              url: process.env.VUE_APP_CORENROLL_SYSTEM+'/save-contract-screenshot',
              headers: { },
              data : data
            };

          axios(config)
                  .then(function (response) {
                    if (response.data.status == 'success') {
                      console.log('screenshot saved');
                    }
                  })
                  .catch(function (error) {
                    console.log('screenshot not saved');
                    console.log(error);
                  });
        },    
        navigatePrevious() {
            this.$router.push("address_info");
            window.localStorage.setItem('address_complete', 'incomplete');
        },
    }
}
</script>
